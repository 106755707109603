import { Injectable } from '@angular/core';
import { JwtHelper } from '../../utils/jwt-helper/jwt-helper';
import { ProjectService } from './project.service';
import { map, mergeMap, tap, switchMap, take } from 'rxjs/operators';
import { combineLatest, Observable, of, OperatorFunction } from 'rxjs';
import { throwIfGQLError } from '../../utils/graphql-result-error-interceptor/graphql-result-error.interceptor';
import { ObservableToastService } from '../../utils/observable-operators/observable-toast.service';
import { AuthService } from '../auth-service';
import { ProjectPermissions } from '@genetpdm/model';
import { LoggingService } from '../logging-service/logging.service';
import {
  GetProjectMembershipQueryGQL,
  ProjectPermissionsQueryGQL,
} from '@genetpdm/model/graphql';

@Injectable({
  providedIn: 'root',
})
export class ProjectPermissionsService {
  private observable: Observable<ProjectPermissions>;

  constructor(
    private authService: AuthService,
    private projectService: ProjectService,
    private permissionsQuery: ProjectPermissionsQueryGQL,
    private projectMembershipQuery: GetProjectMembershipQueryGQL,
    private observableToastService: ObservableToastService,
    private logger: LoggingService,
  ) {}

  public getLatestPermissions$(): Observable<ProjectPermissions> {
    if (this.observable) return this.observable;

    return combineLatest([
      this.projectService.getCurrentProject$(),
      this.authService.getBlobAccessToken$(), // BlobToken because this still emits groups
    ]).pipe(
      mergeMap((results) => {
        if (results[0] == null) return of(new ProjectPermissions());

        return this.getProjectMemberships$(results[0].id).pipe(
          switchMap((p) => {
            if (!p.readProject) {
              // Fallback for Non AD registered users
              const decodedToken = JwtHelper.decodeToken(results[1].token);
              return this.getNonAdPermissions$(results[0].id, decodedToken.oid);
            }
            return of(p);
          }),
          take(1)
        );

        // const decodedToken = JwtHelper.decodeToken(results[1].token);
        // if (
        // 	(<string[]>decodedToken.groups).some(
        // 		(id) => id == results[0].ad_manager_group_id.toString(),
        // 	)
        // ) {
        // 	return this.getAdManagerGroupPermissions$();
        // }
        // if (
        // 	(<string[]>decodedToken.groups).some(
        // 		(id) => id == results[0].ad_worker_group_id.toString(),
        // 	)
        // ) {
        // 	return this.getAdUserGroupPermissions$();
        // }
        // Fallback for too long group member list
      }),
      take(1)
    );
  }

  private getProjectMemberships$(
    projectId: uuid,
  ): Observable<ProjectPermissions> {
    return this.projectMembershipQuery
      .watch({ project_id: projectId })
      .valueChanges.pipe(
        this.defaultPipe(
          'getProjectMemberships',
          `Received update for ${projectId}`,
          switchMap((r) => {
            if (r.data.get_project_memberships.manager_access) {
              this.logger.logSuccess(
                'Successfully granted manager permissions.',
              );
              return this.getAdManagerGroupPermissions$(projectId);
            }
            if (r.data.get_project_memberships.user_access) {
              this.logger.logSuccess('Successfully granted user permissions.');
              return this.getAdUserGroupPermissions$(projectId);
            }
            return of(new ProjectPermissions());
          }),
          null,
        ),
      );
  }

  private getNonAdPermissions$(
    projectId: uuid,
    userId: uuid,
  ): Observable<ProjectPermissions> {
    return this.permissionsQuery.watch({ user_id: userId }).valueChanges.pipe(
      this.defaultPipe(
        'getNonAdPermissions',
        `Received update for ${projectId}`,
        map((r) =>
          ProjectPermissions.fromEnumArray(
            projectId,
            r.data.relations_worker_of_project.map((p) => p.permission),
          ),
        ),
        null,
      ),
    );
  }

  private getAdUserGroupPermissions$(
    projectId: uuid,
  ): Observable<ProjectPermissions> {
    const permissions = new ProjectPermissions();
    permissions.projectId = projectId;

    permissions.insertVariant = true;
    permissions.insertComponent = true;
    permissions.insertTask = true;
    permissions.updateComponent = true;
    permissions.updateInstance = true;
    permissions.checkOut = true;
    permissions.readProject = true;
    permissions.readTask = true;

    return of(permissions);
  }

  private getAdManagerGroupPermissions$(
    projectId: uuid,
  ): Observable<ProjectPermissions> {
    const permissions = new ProjectPermissions();
    permissions.projectId = projectId;

    permissions.insertVariant = true;
    permissions.insertComponent = true;
    permissions.insertTask = true;
    permissions.updateComponent = true;
    permissions.updateInstance = true;
    permissions.checkOut = true;
    permissions.readProject = true;
    permissions.readTask = true;
    permissions.deleteVariant = true;
    permissions.insertPermission = true;
    permissions.updateProject = true;
    permissions.updateTask = true;

    return of(permissions);
  }

  private defaultPipe<T, TOut>(
    methodName: string,
    successMessage: string,
    mapper: OperatorFunction<T, TOut>,
    defaultReturnValue: TOut = null,
  ): OperatorFunction<T, TOut> {
    const logger = this.logger;
    const obserobservableToastService = this.observableToastService;

    return function (source$: Observable<T>): Observable<TOut> {
      return source$.pipe(
        throwIfGQLError(),
        tap((_) => logger.logTrace(methodName + ': ' + successMessage)),
        mapper,
        obserobservableToastService.catchAndToast(defaultReturnValue),
      );
    };
  }
}
