<div class="card">
  <div class="card-header">
    <p>
      A File Explorer wil open on you Desktop, if you have installed the Genet
      Catia API.
      <br />
      If the Explorer is not opening, make sure, the API is installed an
      running.
    </p>
  </div>
  <div class="card-body">
    <p-listbox
      *ngIf="nodes !== null"
      [options]="nodes"
      [style]="{ width: '15rem' }"
    ></p-listbox>
  </div>
  <br />
  <div class="card-footer">
    <form class="form-inline">
      <div>
        <button
          pButton
          type="button"
          label="Open Explorer"
          (click)="handleClickLoad($event)"
          style="width: auto; margin-right: 21px"
        ></button>
        <button
          pButton
          type="button"
          label="Accept"
          (click)="handleClickAccept($event)"
          style="width: auto; margin-right: 21px"
        ></button>
        <button
          pButton
          type="button"
          label="Cancel"
          (click)="handleClickCancel($event)"
        ></button>
      </div>
    </form>
  </div>
</div>
