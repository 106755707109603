import { Component, OnInit } from '@angular/core';
import { Guid } from 'guid-typescript';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OperatorFunction } from 'rxjs';
import { ActionsService } from '../../../@core/services/actions-service/actions.service';
import { ObservableToastService } from '../../../@core/utils/observable-operators/observable-toast.service';
import {
  EnumsChecklistTypeEnum,
  GetChecklistTemplateByPkQueryGQL,
} from '@genetpdm/model/graphql';

@Component({
  selector: 'new-checklist-dialog',
  template: `
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-3" style="width: 100%">
            <div style="border: 1px; border-style: solid; padding: 5px; width: 100%">
              <p>
                Please provide a good name for the checklist.
                <br />
                E.g. 'Department_PartCategory_Details_220101'
              </p>
              <span
                class="p-float-label"
                style="margin-bottom:10px; margin-top: 20px; width: 100%"
              >
                <input
                  type="text"
                  pInputText
                  [(ngModel)]="checklistName"
                  style="width: 100%;"
                />
                <label>Checklist Name</label>
              </span>
            </div>
            <span
              class="p-float-label"
              style="margin-bottom:10px; margin-top: 50px;"
            >
              <input
                type="text"
                pInputText
                [(ngModel)]="title"
                style="width: 100%;"
              />
              <label>Checklist Item Title</label>
            </span>
            <span
              class="p-float-label"
              style="margin-bottom:10px; margin-top: 20px;"
            >
              <textarea
                id="float-input"
                [(ngModel)]="description"
                [rows]="5"
                [cols]="30"
                pInputTextarea
                autoResize="autoResize"
                style="width: 100%;"
              ></textarea>
              <label for="float-input">Description</label>
            </span>
          </div>
          <div class="col-1">
            <div style="padding-top: 50px">
              <p-button
                label="Add"
                icon="fas fa-plus"
                (onClick)="addChecklistItem()"
              ></p-button>
            </div>
          </div>
          <div class="col">
            <p-listbox
              [options]="checklistItems"
              [(ngModel)]="selectedChecklistItems"
              [metaKeySelection]="false"
              [checkbox]="true"
              [multiple]="true"
              [listStyle]="{ 'max-height': '250px' }"
              [style]="{ width: '50rem', 'margin-bottom': '15px' }"
            >
              <ng-template let-item pTemplate="item">
                <div>{{ item.title }}</div>
                <div style="margin-left: 15px">{{ item.description }}</div>
              </ng-template>
            </p-listbox>
            <span>
              <p-toggleButton
                [(ngModel)]="isReleaseChecklistType"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
              ></p-toggleButton>
              This is a Checklist for a Release Process
            </span>
            <p-button
              class="p-button-rounded-sm p-button-text"
              icon="pi pi-trash"
              iconPos="right"
              (onClick)="removeChecklistItems()"
              [style]="{ 'margin-top': '10px', 'margin-left': '30px' }"
            ></p-button>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <div>
            <p-button
              label="Submit"
              icon="pi pi-check"
              (onClick)="addChecklist()"
            ></p-button>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class ChecklistDialogComponent implements OnInit {
  checklistId: string;
  checklistName = '';

  title = '';
  description = '';

  checklistItems: ChecklistItem[] = [];
  selectedChecklistItems: ChecklistItem[] = [];

  isReleaseChecklistType: boolean;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private actionsService: ActionsService,
    private observableToastService: ObservableToastService,
    private getChecklistTemplateByPk: GetChecklistTemplateByPkQueryGQL
  ) {}

  addChecklistItem() {
    this.checklistItems = this.checklistItems.concat({
      title: this.title,
      description: this.description,
    });

    this.title = '';
    this.description = '';
  }

  removeChecklistItems() {
    this.checklistItems = this.checklistItems.filter(
      (item) => !this.selectedChecklistItems.some((i) => i.title == item.title)
    );
    this.selectedChecklistItems = [];
  }

  async addChecklist() {
    if (this.checklistName.length < 5)
      throw new Error('Please use a longer name for the checklist!');

    if (this.checklistItems.length < 1)
      throw new Error('A Checklist needs to have at least one item!');

    const checklistType = this.isReleaseChecklistType
      ? EnumsChecklistTypeEnum.Release
      : EnumsChecklistTypeEnum.Default;

    await this.actionsService.upsertChecklistTemplate$(
      this.checklistId,
      this.checklistName,
      checklistType,
      JSON.stringify(this.checklistItems)
    );

    this.ref.close();
  }

  ngOnInit(): void {
    this.checklistId = this.config?.data?.checkListId ?? Guid.create();

    if (this.config?.data?.checkListId != null) {
      this.getChecklistTemplateByPk
        .watch({ id: this.config?.data?.checkListId })
        .valueChanges.pipe(this.catchAndToast())
        .subscribe((result) =>
          this.initializeFromExisting(result.data.checklist_template_by_pk)
        );
    }
  }

  private initializeFromExisting(checklist: ChecklistResponse) {
    this.checklistName = checklist.name;

    this.checklistItems = JSON.parse(checklist.data);
    this.selectedChecklistItems = [];
  }

  private catchAndToast<T>(defaultValue?: T): OperatorFunction<T, T> {
    return this.observableToastService.catchAndToast(defaultValue);
  }
}

interface ChecklistItem {
  title: string;
  description: string;
}

interface ChecklistResponse {
  readonly id: string;
  readonly name: string;
  readonly data: any;
  readonly type: EnumsChecklistTypeEnum;
}
