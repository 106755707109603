<div class="layout-topbar">
  <div class="topbar-start">
    <button
      #menubutton
      type="button"
      class="topbar-menubutton p-link p-trigger transition-duration-300"
      (click)="onMenuButtonClick()"
    >
      <i class="pi pi-bars"></i>
    </button>
  </div>
  <div class="layout-topbar-menu-section">
    <app-sidebar></app-sidebar>
  </div>
  <div class="topbar-end">
    <ul class="topbar-menu">
      <li>Version: {{ pdmVersion }}</li>
      <li
        [class]="
          layoutService.isHorizontal()
            ? 'block topbar-item'
            : 'block sm:hidden topbar-item'
        "
      >
        <a
          pStyleClass="@next"
          enterClass="ng-hidden"
          enterActiveClass="px-scalein"
          leaveToClass="ng-hidden"
          leaveActiveClass="px-fadeout"
          [hideOnOutsideClick]="true"
          pRipple
        >
          <i class="pi pi-search"></i>
        </a>
        <ul
          [class]="'ng-hidden topbar-menu active-topbar-menu p-3 w-15rem  z-5'"
          style="bottom: -5.8rem"
        >
          <span class="p-input-icon-left w-full">
            <i class="pi pi-search"></i>
            <input type="text" pInputText placeholder="Search" class="w-full" />
          </span>
        </ul>
      </li>
      <li class="topbar-item">
        <a
          pStyleClass="@next"
          enterClass="ng-hidden"
          enterActiveClass="px-scalein"
          leaveToClass="ng-hidden"
          leaveActiveClass="px-fadeout"
          [hideOnOutsideClick]="true"
          pRipple
          class="cursor-pointer"
        >
          <i class="fa-solid fa-user"></i>
        </a>
        <ul
          [class]="'topbar-menu active-topbar-menu p-4 w-15rem   z-5 ng-hidden'"
        >
          <li role="menuitem" class="m-0 mb-3">
            <a
              href="#"
              class="flex align-items-center hover:text-primary-500 transition-duration-200"
              pStyleClass="@grandparent"
              enterClass="ng-hidden"
              enterActiveClass="px-scalein"
              leaveToClass="ng-hidden"
              leaveActiveClass="px-fadeout"
            >
              <i class="pi pi-fw pi-lock mr-2"></i>
              <span>Privacy</span>
            </a>
          </li>
          <li role="menuitem" class="m-0 mb-3">
            <a
              href="#"
              class="flex align-items-center hover:text-primary-500 transition-duration-200"
              pStyleClass="@grandparent"
              enterClass="ng-hidden"
              enterActiveClass="px-scalein"
              leaveToClass="ng-hidden"
              leaveActiveClass="px-fadeout"
            >
              <i class="pi pi-fw pi-cog mr-2"></i>
              <span>Settings</span>
            </a>
          </li>
          <li role="menuitem" class="m-0">
            <a
              href="#"
              class="flex align-items-center hover:text-primary-500 transition-duration-200"
              pStyleClass="@grandparent"
              enterClass="ng-hidden"
              enterActiveClass="px-scalein"
              leaveToClass="ng-hidden"
              leaveActiveClass="px-fadeout"
            >
              <i class="pi pi-fw pi-sign-out mr-2"></i>
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="">
        <button
          pButton
          type="button"
          icon="pi pi-cog"
          class="p-button-text p-button-secondary flex-shrink-0"
          (click)="onConfigButtonClick()"
        ></button>
      </li>
      <!-- <li class="">
        <button
          pButton
          type="button"
          icon="pi pi-arrow-left"
          class="p-button-text p-button-secondary flex-shrink-0"
          (click)="onSidebarButtonClick()"
        ></button>
      </li> -->
    </ul>
  </div>
</div>
