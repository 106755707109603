import { createAction, props } from '@ngrx/store';
import { UserSettings } from '@genetpdm/model';
import { EnumsLocationEnum } from '@genetpdm/model/graphql';

export enum SettingsActionType {
  LoadSettings = '[Component] Load Settings',
  LoadSettingsSuccess = '[Settings API] Load Settings Success',
  LoadSettingsFailure = '[Settings API] Load Settings Failure',
  SetCurrentLocation = '[Component] Update Location',
  SetCurrentLocationSuccess = '[Component] Update Location Success',
  SetCurrentLocationFailure = '[Component] Update Location Failure',
  SetCurrentProject = '[Component] Update Project',
  SetCurrentProjectSuccess = '[Component] Update Project Success',
  SetCurrentProjectFailure = '[Component] Update Project Failure',
}

export const LoadSettings = createAction(SettingsActionType.LoadSettings);

export const LoadSettingsSuccess = createAction(
  SettingsActionType.LoadSettingsSuccess,
  props<{ settings: UserSettings }>()
);

export const LoadSettingsFailure = createAction(
  SettingsActionType.LoadSettingsFailure,
  props<{ error: Error }>()
);

export const SetCurrentLocation = createAction(
  SettingsActionType.SetCurrentLocation,
  props<{ location: EnumsLocationEnum; previousLocation: EnumsLocationEnum }>()
);

export const SetCurrentLocationSuccess = createAction(
  SettingsActionType.SetCurrentLocationSuccess,
  props<{ location: EnumsLocationEnum }>()
);

export const SetCurrentLocationFailure = createAction(
  SettingsActionType.SetCurrentLocationFailure,
  props<{ error: Error; previousLocation: EnumsLocationEnum }>()
);

export const SetCurrentProject = createAction(
  SettingsActionType.SetCurrentProject,
  props<{ projectId: uuid; previousProjectId: uuid }>()
);

export const SetCurrentProjectSuccess = createAction(
  SettingsActionType.SetCurrentProjectSuccess,
  props<{ projectId: uuid }>()
);

export const SetCurrentProjectFailure = createAction(
  SettingsActionType.SetCurrentProjectFailure,
  props<{ error: Error; previousProjectId: uuid }>()
);
