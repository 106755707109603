import { Component } from '@angular/core';
import { EnumsLocationEnum, ServiceAvailabilitySubscriptionGQL, ServiceLoadSubscriptionGQL } from '@genetpdm/model/graphql';
import { AuthService, CatiaAPIPingService, GraphQLModule, LoadingAnimationService } from '../@core/services';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, map, mergeMap } from 'rxjs';
import moment from 'moment';
import { ReportBugDialogComponent } from '../@shared/dialogs/report-bug-dialog/report-bug-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: `app-footer.component.html`,
  styleUrls: ['./app-footer.component.scss'],
})
export class AppFooterComponent {progress$: Observable<number>;
	show$: Observable<boolean>;
	indeterminate$: Observable<string>;
	loggedIn$: Observable<boolean>;

	currentYear: string;

	location: EnumsLocationEnum = EnumsLocationEnum.Muc;

	constructor(
		private authService: AuthService,
		private catiaAPIPingService: CatiaAPIPingService,
		private dialogService: DialogService,
		private loadingAnimationService: LoadingAnimationService,
		private graphQlModule: GraphQLModule,
		private serviceAvailabilitySubscription: ServiceAvailabilitySubscriptionGQL,
		private serviceLoadSubscription: ServiceLoadSubscriptionGQL,
	) {
		this.catiaAPIPingService.connected$.subscribe((connected) => {
			this.localCatiaApiText = connected ? 'connected' : 'unconnected';
			this.localCatiaApiColor = connected ? 'green' : 'yellow';
		});

		this.show$ = this.loadingAnimationService.show$;
		this.progress$ = this.loadingAnimationService.progress$;
		this.indeterminate$ = this.loadingAnimationService.indeterminate$.pipe(
			map((r) => (r ? 'indeterminate' : 'determinate')),
		);
		this.loggedIn$ = this.authService.loggedIn$;

		this.currentYear = moment(Date.now()).format('YYYY');

		this.initializeServiceHealthMonitor();
	}

	private localCatiaApiTitle = 'Local Catia Worker';
	private localCatiaApiText = 'unconnected';
	private localCatiaApiColor = 'yellow';

	public get localCatiaApiLink(): string {
		return (
			'https://img.shields.io/static/v1?label=' +
			this.localCatiaApiTitle +
			'&message=' +
			this.localCatiaApiText +
			'&color=' +
			this.localCatiaApiColor
		);
	}

	private centralCatiaServiceTitle = 'Catia CGR Service';
	private centralCatiaServiceText = 'online';
	private centralCatiaServiceColor = 'green';

	public get centralCatiaServiceLink(): string {
		return (
			'https://img.shields.io/static/v1?label=' +
			this.centralCatiaServiceTitle +
			'&message=' +
			this.centralCatiaServiceText +
			'&color=' +
			this.centralCatiaServiceColor
		);
	}

	private generalPDMHealthTitle = 'Sync Service Health';
	private generalPDMHealthText = 'good';
	private generalPDMHealthColor = 'green';

	public get generalPDMHealthLink(): string {
		return (
			'https://img.shields.io/static/v1?label=' +
			this.generalPDMHealthTitle +
			'&message=' +
			this.generalPDMHealthText +
			'&color=' +
			this.generalPDMHealthColor
		);
	}

	handleClick(event) {
		this.dialogService.open(ReportBugDialogComponent, {
			header: 'Report Bug',
			width: '70%',
		});
	}

	initializeServiceHealthMonitor() {
		this.graphQlModule.isInitialized$
			.pipe(mergeMap(() => this.serviceAvailabilitySubscription.subscribe()))
			.subscribe((result) =>
				this.updateServiceAvailability([
					...result.data.services_service_availability,
				]),
			);

		this.graphQlModule.isInitialized$
			.pipe(mergeMap(() => this.serviceLoadSubscription.subscribe()))
			.subscribe((result) =>
				this.updateServiceLoad([...result.data.services_service_load]),
			);
	}

	updateServiceAvailability(availabilities: ServiceAvailability[]) {
		if (
			availabilities.some(
				(a) =>
					a.name.includes('Synchronization') &&
					a.location == this.location &&
					a.available == false,
			)
		) {
			this.generalPDMHealthText = 'bad';
			this.generalPDMHealthColor = 'red';
		} else {
			this.generalPDMHealthText = 'good';
			this.generalPDMHealthColor = 'green';
		}

		if (
			availabilities.some(
				(a) => a.name.includes('Catia') && a.available == false,
			)
		) {
			this.centralCatiaServiceText = 'offline';
			this.centralCatiaServiceColor = 'red';
		} else {
			this.centralCatiaServiceText = 'online';
			this.centralCatiaServiceColor = 'green';
		}
	}

	updateServiceLoad(loads: ServiceLoad[]) {
		if (
			loads.some(
				(a) =>
					a.name.includes('Synchronization') &&
					a.location == this.location &&
					a.load > 50 &&
					this.generalPDMHealthText != 'bad',
			)
		) {
			this.generalPDMHealthText = 'high usage';
			this.generalPDMHealthColor = 'yellow';
		} else {
			this.generalPDMHealthText = 'good';
			this.generalPDMHealthColor = 'green';
		}
	}
}

interface ServiceAvailability {
	name: string;
	location: EnumsLocationEnum;
	available: boolean;
}

interface ServiceLoad {
	name: string;
	location: EnumsLocationEnum;
	load: number;
}
