import { Injectable } from '@angular/core';
import {
  Project,
  ProjectConfiguration,
  Routes,
  Session,
  User,
} from '@genetpdm/model';
import {
  CadComponentMessage,
  CadEdgeMessage,
  CadStructureMessage,
  FileSystemObjectMessage,
  PositionVectorMessage,
  Vector3Message,
} from '@genetsystems/genet-pdm-messaging-model';
import { Observable, map, of, combineLatest } from 'rxjs';
import { concatMap, filter, first, tap } from 'rxjs/operators';
import {
  CadComponentFlatDataFragment,
  CadInstanceFlatDataFragment,
  EnumsLocationEnum,
  GetLatestComponentInstanceVersionsQueryGQL,
  GetLatestComponentVersionsQueryGQL,
  PositionDataFragment,
  RecursiveGetRightComponentsTenLevelsGQL,
  RecursiveGetRightComponentsTenLevelsQueryResult,
} from '@genetpdm/model/graphql';
import { CatiaService } from './catia.service';
import { Store } from '@ngrx/store';
import {
  SessionGenerationType,
  selectComponentInstancesByRightIds,
  selectCurrentProject,
  selectCurrentProjectConfiguration,
  selectCurrentSession,
  selectCurrentUser,
  selectSettings,
} from '../../state';
import { LoggingService } from '../logging-service/logging.service';

@Injectable({
  providedIn: 'root',
})
export class CatiaProxyService {
  constructor(
    private store: Store,
    private catiaService: CatiaService,
    private recursiveComponentsQuery: RecursiveGetRightComponentsTenLevelsGQL,
    private latestComponentsQuery: GetLatestComponentVersionsQueryGQL,
    private latestInstancesQuery: GetLatestComponentInstanceVersionsQueryGQL,
    private logger: LoggingService,
  ) {}

  public openStructure$(componentId: uuid) {
    const structure = {
      edges: new Set<uuid>(),
      nodes: new Set<uuid>(),
    };
    structure.nodes.add(componentId);

    return combineLatest([
      this.getCurrentProject$(),
      this.getUncRootDirectory$(),
      this.getCadStructureMessage$(structure),
    ]).pipe(
      concatMap(([project, uncRoot, cadStructure]) =>
        this.catiaService.openStructure$(project.id, uncRoot, cadStructure),
      ),
      first(),
    );
  }

  public getInstancePositions$() {
    return this.getStructureIdSet$().pipe(
      concatMap((idSet) =>
        combineLatest([
          this.getCadStructureMessage$(idSet),
          this.getCurrentProject$(),
        ]),
      ),
      concatMap(([structure, project]) =>
        this.catiaService.getInstancePositions$(project.id, structure),
      ),
      first(),
    );
  }

  public generateStructure$(
    sessionId: uuid,
    generationType: SessionGenerationType,
  ) {
    const useComponents =
      generationType == SessionGenerationType.ComponentBased ||
      generationType == SessionGenerationType.ComponentBasedNoPosition;

    const useFastGeneration =
      generationType == SessionGenerationType.ComponentBasedNoPosition ||
      generationType == SessionGenerationType.ProductBasedNoPosition;

    return this.getStructureIdSet$().pipe(
      concatMap(this.allFilesSynchronizedToUserLocation$.bind(this)),
      concatMap((idSet) =>
        combineLatest([
          this.getCurrentSession$(),
          this.getCurrentProject$(),
          this.getUncRootDirectory$(),
          this.getUncSessionRootDirectory$(),
          this.getCadStructureMessage$(idSet),
        ]),
      ),
      concatMap(([session, project, uncRoot, sessionRoot, cadStructure]) =>
        this.catiaService.generateStructure$(
          project.id,
          uncRoot,
          sessionRoot,
          session.name,
          sessionId,
          cadStructure,
          useComponents,
          useFastGeneration,
        ),
      ),
      first(),
    );
  }

  public adaptStructure$(
    sessionId: uuid,
    generationType: SessionGenerationType,
  ) {
    const useComponents =
      generationType == SessionGenerationType.ComponentBased ||
      generationType == SessionGenerationType.ComponentBasedNoPosition;

    return this.getStructureIdSet$().pipe(
      concatMap(this.allFilesSynchronizedToUserLocation$.bind(this)),
      concatMap((idSet) =>
        combineLatest([
          this.getCurrentSession$(),
          this.getCurrentProject$(),
          this.getUncRootDirectory$(),
          this.getUncSessionRootDirectory$(),
          this.getCadStructureMessage$(idSet),
        ]),
      ),
      concatMap(([session, project, uncRoot, sessionRoot, cadStructure]) =>
        this.catiaService.adaptStructure$(
          project.id,
          uncRoot,
          sessionRoot,
          session.name,
          sessionId,
          cadStructure,
          useComponents,
        ),
      ),
      first(),
    );
  }

  public importStructure$(
    fileSystemObjects: FileSystemObjectMessage[],
    uploadId: string,
    variantId: string,
  ) {
    return combineLatest([
      this.getCurrentProject$(),
      this.getCurrentUser$(),
    ]).pipe(
      concatMap(([project, user]) =>
        this.catiaService
          .startImportStructure$(
            uploadId,
            project.id,
            variantId,
            `upload/${user.name}/${uploadId}`, // Change TargetRoutePrefix here if necessary
            fileSystemObjects.map((f) => f.getFullpath()),
          )
          .pipe(map((result) => result.getStructure())),
      ),
      first(),
    );
  }

  public openLastSession$(session: Session) {
    return combineLatest([
      this.getUncRootDirectory$(),
      this.getCurrentProject$(),
      this.getCurrentUser$(),
    ]).pipe(
      concatMap(([uncRoot, project, user]) =>
        this.catiaService.openLastSession$(
          project.id,
          uncRoot,
          session.name,
          user.name,
          session.shared,
        ),
      ),
      first(),
    );
  }

  private getUncRootDirectory$(): Observable<string> {
    return combineLatest([
      this.getCurrentProjectConfiguration$(),
      this.getCurrentLocation$(),
    ]).pipe(
      map(
        ([projectConfig, location]) =>
          projectConfig.locations.find((l) => l.location == location).path,
      ),
    );
  }

  private getUncSessionRootDirectory$(): Observable<string> {
    return combineLatest([
      this.getUncRootDirectory$(),
      this.getCurrentSession$(),
      this.getCurrentUser$(),
      this.getCurrentLocation$(),
    ]).pipe(
      map(
        ([rootPath, session, user, location]) =>
          rootPath +
          '\\' +
          Routes.getSessionsFolderRoute(
            location.toString(),
            user.name,
            session.name,
          ).replace(/\//g, '\\'),
      ),
    );
  }

  private getCurrentProject$(): Observable<Project> {
    return this.store
      .select(selectCurrentProject)
      .pipe(filter((p) => p != null));
  }

  private getCurrentProjectConfiguration$(): Observable<ProjectConfiguration> {
    return this.store
      .select(selectCurrentProjectConfiguration)
      .pipe(filter((p) => p != null));
  }

  private getCurrentLocation$(): Observable<EnumsLocationEnum> {
    return this.store.select(selectSettings).pipe(
      filter((p) => p != null),
      map((settings) => settings.location),
    );
  }

  private getCurrentUser$(): Observable<User> {
    return this.store.select(selectCurrentUser).pipe(filter((p) => p != null));
  }

  private getCurrentUserId$(): Observable<uuid> {
    return this.store
      .select(selectSettings)
      .pipe(map((settings) => settings.user_id));
  }

  private getCurrentSession$(): Observable<Session> {
    return this.store
      .select(selectCurrentSession)
      .pipe(filter((p) => p != null));
  }

  private getParentInstances$(componentIds: uuid[]) {
    return this.store
      .select(selectComponentInstancesByRightIds(componentIds))
      .pipe(filter((p) => p != null));
  }

  private allFilesSynchronizedToUserLocation$(
    structureIdSet: CadStructureIdSet,
  ): Observable<CadStructureIdSet> {
    //TODO: Implement sync check
    return of(structureIdSet);
  }

  private getStructureIdSet$(): Observable<CadStructureIdSet> {
    return this.getCurrentSession$().pipe(
      map((session) => session.components.map((c) => c.component_id)),
      concatMap((componentIds) =>
        this.getParentInstances$(componentIds).pipe(
          map((parents) => ({
            componentIds,
            parentIds: parents.map((p) => p.id),
          })),
        ),
      ),
      concatMap(({ componentIds, parentIds }) =>
        this.recursiveComponentsQuery
          .watch({
            component_ids: componentIds,
            parent_instances_ids: parentIds,
          })
          .valueChanges.pipe(
            map((r) => r.data),
            map((d) => this.getStructureIdSet(d)),
          ),
      ),
    );
  }

  private getStructureIdSet(
    queryResult: RecursiveGetRightComponentsTenLevelsQueryResult,
  ): CadStructureIdSet {
    const structure = {
      edges: new Set<uuid>(),
      nodes: new Set<uuid>(),
    };

    queryResult.cad_component.forEach((node) => structure.nodes.add(node.id));
    queryResult.cad_instance.forEach((edge) => structure.edges.add(edge.id));

    queryResult.cad_component
      .flatMap((component) => component.child_instances)
      .forEach((instance) => {
        this.checkCircularLoop(instance);
        this.addCadStructureRecursive(instance, structure);
      });

    return structure;
  }

  private checkCircularLoop(cadInstance: CadInstance): boolean {
    const visitedInstances = new Set<uuid>();

    const dfs = (instance: CadInstance): boolean => {
      if (visitedInstances.has(instance.id)) {
        this.logger.logWarn(
          `Circular loop detected! Caused by Parent of Instance ID: ${instance.id}.`,
        );
        return true; // Circular loop detected
      }

      visitedInstances.add(instance.id);

      if (instance.right_component == null) {
        return false;
      }

      if (instance.right_component.child_instances) {
        for (const childInstance of instance.right_component.child_instances) {
          if (dfs(childInstance)) {
            return true; // Circular loop detected
          }
        }
      }

      visitedInstances.delete(instance.id);

      return false;
    };

    return dfs(cadInstance);
  }

  private addCadStructureRecursive(
    cadInstance: CadInstance,
    cadStructure: CadStructureIdSet,
  ) {
    if (!cadInstance) {
      return cadStructure;
    }

    if (cadInstance.right_component == null) {
      // this.logger.logWarn(
      //   'Products nested over 34 levels. This might be a circular loop! Please check your data.',
      // );
      return cadStructure;
    }

    if (!cadStructure.nodes.has(cadInstance.right_component.id)) {
      cadStructure.nodes.add(cadInstance.right_component.id);
    }
    if (!cadStructure.edges.has(cadInstance.id)) {
      cadStructure.edges.add(cadInstance.id);
    }

    if (cadInstance.right_component.child_instances) {
      const children = cadInstance.right_component.child_instances;

      children.forEach((instance) =>
        this.addCadStructureRecursive(instance, cadStructure),
      );
    }
    return cadStructure;
  }

  private getCadStructureMessage$(
    structureIdSet: CadStructureIdSet,
  ): Observable<CadStructureMessage> {
    const componentsQuery = this.latestComponentsQuery.watch(
      {
        component_ids: Array.from(structureIdSet.nodes.values()).map((g) =>
          g.toString(),
        ),
      },
      { fetchPolicy: 'no-cache' },
    );

    const instancesQuery = this.latestInstancesQuery.watch(
      {
        instance_ids: Array.from(structureIdSet.edges.values()).map((g) =>
          g.toString(),
        ),
      },
      { fetchPolicy: 'no-cache' },
    );

    return combineLatest([
      componentsQuery.valueChanges,
      instancesQuery.valueChanges,
      this.getCurrentUserId$(),
    ]).pipe(
      map((args) => {
        const nodes = args[0].data.view_cad_component_flat.map((c) =>
          this.toCadComponentMessage(c, args[2]),
        );
        const edges = args[1].data.view_cad_instance_flat.map((e) =>
          this.toCadEdgeMessage(e),
        );

        const structure = new CadStructureMessage();
        structure.setComponentsList(nodes);
        structure.setEdgesList(edges);
        return structure;
      }),
    );
  }

  private toCadComponentMessage(
    component: CadComponentFlatDataFragment,
    userId: uuid,
  ): CadComponentMessage {
    const node = new CadComponentMessage();
    node.setId(component.component_id.toString());
    node.setRoute(component.route);

    if (component.checked_out && component.worker_id == userId) {
      node.setRoute(component.check_out_route);
    }
    node.setPartnumber(component.part_number);
    node.setNomenclature(component.nomenclature);
    node.setDefinition(component.definition);
    node.setDescription(component.description);
    node.setComponenttype(this.getComponentType(component.component_type));

    if ((component.cgr_routes?.length ?? 0) > 0) {
      node.setCgrpathsList(component.cgr_routes.map((c) => c.route));
    }

    return node;
  }

  private getComponentType(type: string) {
    if (type == 'part') return 0;
    if (type == 'product') return 1;
    if (type == 'cgr_product') return 2;
    throw new Error('Unknown Component Type: ' + type);
  }

  private toCadEdgeMessage(
    instance: CadInstanceFlatDataFragment,
  ): CadEdgeMessage {
    const edge = new CadEdgeMessage();
    edge.setId(instance.instance_id.toString());
    edge.setLeftid(instance.left_id.toString());
    edge.setRightid(instance.component_id.toString());
    edge.setInstancename(instance.instance_name);
    edge.setInstancetype(this.getInstanceType(instance.instance_type));
    edge.setRowindex(instance.rowindex);
    if (instance.position)
      edge.setPosition(this.toPositionMessage(instance.position));

    return edge;
  }

  private toPositionMessage(
    position: PositionDataFragment,
  ): PositionVectorMessage {
    const rot1 = new Vector3Message();
    rot1.setX1(position.rot1_x1.toString());
    rot1.setX2(position.rot1_x2.toString());
    rot1.setX3(position.rot1_x3.toString());
    const rot2 = new Vector3Message();
    rot2.setX1(position.rot2_x1.toString());
    rot2.setX2(position.rot2_x2.toString());
    rot2.setX3(position.rot2_x3.toString());
    const rot3 = new Vector3Message();
    rot3.setX1(position.rot3_x1.toString());
    rot3.setX2(position.rot3_x2.toString());
    rot3.setX3(position.rot3_x3.toString());
    const pos = new Vector3Message();
    pos.setX1(position.pos_x.toString());
    pos.setX2(position.pos_y.toString());
    pos.setX3(position.pos_z.toString());

    const posMessage = new PositionVectorMessage();
    posMessage.setRot1(rot1);
    posMessage.setRot2(rot2);
    posMessage.setRot3(rot3);
    posMessage.setPos(pos);

    return posMessage;
  }

  private getInstanceType(type: string) {
    if (type == 'part_instance') return 0;
    if (type == 'product_instance') return 1;
    if (type == 'cgr_product_instance') return 2;
    throw new Error('Unknown Instance Type: ' + type);
  }
}

export interface CadStructureIdSet {
  edges: Set<uuid>;
  nodes: Set<uuid>;
}

export interface CadInstance {
  readonly id: uuid;
  readonly right_component?: CadComponent;
}

export interface CadComponent {
  readonly id: uuid;
  readonly child_instances: readonly CadInstance[];
}
