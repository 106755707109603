import { Component, OnInit } from '@angular/core';
import { Guid } from 'guid-typescript';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  ActionsService,
  ComponentMetadataService,
} from '../../../@core/services';
import { ComponentInstanceVersion } from '@genetpdm/model';

@Component({
  selector: 'new-variant-dialog',
  template: `
    <div class="card">
      <div class="card-header">
        <h3>
          Create new Variant for: {{ component.component_name }} -
          {{ component.component.id }}
        </h3>
        <p>Current Version: {{ currentVersion }}</p>
      </div>
      <div class="card-body">
        <form>
          <div class="form-group">
            <input
              type="text"
              pInputText
              placeholder="Variant Name"
              [(ngModel)]="variantName"
              [ngModelOptions]="{ standalone: true }"
              style="width: 100%"
            />
          </div>
          <hr />
          <div class="form-group">
            <p>Are you sure, you want to create a new Variant?</p>
            <p-checkbox
              [(ngModel)]="enableAction"
              [ngModelOptions]="{ standalone: true }"
              [binary]="true"
              inputId="update"
            ></p-checkbox>
            <label for="update" style="margin-left: 5px; margin-bottom: 0px"
              >Create New Variant</label
            >
          </div>
          <hr />
          <div class="form-group" *ngIf="enableAction">
            <p>Do you want to clone child components?</p>
            <p-checkbox
              [(ngModel)]="cloneChildren"
              [ngModelOptions]="{ standalone: true }"
              [binary]="true"
              inputId="clone"
            ></p-checkbox>
            <label for="clone" style="margin-left: 5px; margin-bottom: 0px"
              >Clone Child Components</label
            >
          </div>
          <hr />
          <div class="form-group" *ngIf="cloneChildren">
            <p>Do you want to include parts in the cloning?</p>
            <p-checkbox
              [(ngModel)]="includeParts"
              [ngModelOptions]="{ standalone: true }"
              [binary]="true"
              inputId="include"
            ></p-checkbox>
            <label for="include" style="margin-left: 5px; margin-bottom: 0px"
              >Include Components of Type: Part</label
            >
          </div>
          <hr />
          <div class="form-group">
            <p-button
              label="Create New Variant"
              (onClick)="handleSubmit($event)"
              icon="fas fa-upload"
              iconPos="left"
              *ngIf="enableAction"
            ></p-button>
            <p-button
              label="Cancel"
              (onClick)="handleCancel($event)"
              icon="fas fa-times"
              iconPos="left"
              [style]="{ 'margin-left': '10px' }"
            ></p-button>
          </div>
        </form>
      </div>
    </div>
  `,
})
export class NewVariantDialogComponent implements OnInit {
  variantName: string;

  currentVersion = 0;

  component: ComponentInstanceVersion;

  enableAction = false;

  cloneChildren = false;
  includeParts = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private componentService: ComponentMetadataService,
    private actionsService: ActionsService
  ) {}

  ngOnInit(): void {
    // save component
    // set prop from component
    this.component = this.config.data?.component;

    this.componentService
      .getLatestVersionIndex$(this.component.component.id)
      .subscribe((r) => (this.currentVersion = r));
  }

  async handleSubmit(event) {
    if (!this.enableAction) this.ref.close(null);

    await this.actionsService.createVariant$(
      this.variantName,
      this.component.component.id,
      this.cloneChildren,
      this.includeParts
    );

    this.ref.close(null);
  }

  handleCancel(event) {
    this.ref.close(null);
  }
}
