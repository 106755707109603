import { Component, OnInit } from '@angular/core';
import { Guid } from 'guid-typescript';
import moment from 'moment';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { forkJoin } from 'rxjs';
import { ActionsService, BlobService } from '../../../@core/services';
import { ComponentInstanceVersion } from '@genetpdm/model';

@Component({
  selector: 'quick-replace-component-dialog',
  template: `
    <div class="card">
      <div class="card-header">
        <h3>
          Quick Replace: {{ component.component_name }} -
          {{ component.component.id }}
        </h3>
      </div>
      <div class="card-body">
        <form>
          <div *ngIf="component.checked_out">
            <h3>Cannot Replace a checked out component!</h3>
          </div>
          <div
            class="form-group"
            *ngIf="!disableUpload && !component.checked_out"
          >
            <p>
              Add additional files to the change log. (E.g. Screenshots,
              PPTs...)
            </p>
            <p-fileUpload
              name="CheckInAddedFiles"
              [customUpload]="true"
              (uploadHandler)="handleUpload($event)"
              [multiple]="false"
              ><ng-template pTemplate="content" let-files>
                <div
                  style="height: 50px; text-align: center"
                  *ngIf="!files.length"
                >
                  <i
                    class="far fa-copy fa-3x"
                    style="margin-right: 10px; opacity: 0.2;"
                  ></i>
                  Drag & Drop files here...
                </div>
              </ng-template></p-fileUpload
            >
          </div>
          <div class="form-group" *ngIf="disableUpload">
            <h4>Upload succeeded!</h4>
            <p>You can now proceed to Submit.</p>
          </div>
          <hr />
          <div class="form-group">
            <p-button
              label="Replace with File"
              (onClick)="handleSubmit($event)"
              icon="fas fa-upload"
              iconPos="left"
              *ngIf="disableUpload"
            ></p-button>
            <p-button
              label="Cancel"
              (onClick)="handleCancel($event)"
              icon="fas fa-times"
              iconPos="left"
              [style]="{ 'margin-left': '10px' }"
            ></p-button>
          </div>
        </form>
      </div>
    </div>
  `,
})
export class QuickReplaceComponentDialogComponent implements OnInit {
  component: ComponentInstanceVersion;

  additionalBlobs: string[] = [];

  enableSubmit = false;
  disableUpload = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private blobService: BlobService,
    private actionsService: ActionsService
  ) {}

  ngOnInit(): void {
    this.component = this.config.data.component;
    // this.partNumber = this.config.data.partNumber;
    // this.componentId = this.config.data.componentId;
    // this.projectId = this.config.data.projectId;
    // this.checkedOut = this.config.data.checkedOut;
  }

  handleUpload(event) {
    this.enableSubmit = false;

    const logId = this.component.component.id.toString().split('-')[0];
    const routePrefix = `Logs/${logId}/${moment(Date.now()).format(
      'YYYYMMDDHHmmss'
    )}/`;
    const files = <File[]>event.files;
    const uploads$ = files.map((file) =>
      this.blobService.uploadBlob$(
        file,
        routePrefix + file.name,
        this.component.component.project_id
      )
    );

    forkJoin(uploads$).subscribe((r) => {
      this.additionalBlobs = r.map((b) => b.route);
      this.enableSubmit = true;
      this.disableUpload = true;
    });
  }

  handleSubmit(event) {
    if (!this.enableSubmit) this.ref.close(null);

    this.actionsService.quickReplaceComponent$([
      {
        component_id: this.component.component.id.toString(),
        upload_route: this.additionalBlobs[0],
      },
    ]);

    this.ref.close(null);
  }

  handleCancel(event) {
    this.ref.close(null);
  }
}
