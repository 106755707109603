export interface Logger {
  logTrace(message: string): void;
  logDebug(message: string): void;
  logInfo(message: string): void;
  //= logInfo, but shows user a success message
  logSuccess(message: string): void;
  logWarn(message: string): void;
  logError(message: string): void;
  //= logError, but shows user a message
  logFailed(message: string): void;
  logCritical(message: string): void;
}
