import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BlobService } from '../blob-service/blob.service';
import { catchError, map, shareReplay, take } from 'rxjs/operators';
import { LoggingService } from '../logging-service/logging.service';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  imageMap: Map<string, Observable<string>> = new Map<
    string,
    Observable<string>
  >();

  constructor(
    private blobService: BlobService,
    private logger: LoggingService
  ) {}

  getImageDataUrl$(
    imageRoute: string | undefined,
    projectId: uuid
  ): Observable<string> {
    if (imageRoute && imageRoute != '') {
      if (this.imageMap.has(imageRoute)) {
        return this.imageMap.get(imageRoute);
      }

      this.imageMap.set(
        imageRoute,
        this.blobService.downloadBlobAsDataUrl$(imageRoute, projectId).pipe(
          catchError((err) => {
            this.logger.logError(err.message);
            return of(this.getNotFound());
          }),
          map((base64) =>
            base64.replace('data:application/octet-stream', 'data:image/jpeg')
          ),
          take(1),
          shareReplay(1)
        )
      );

      return this.imageMap.get(imageRoute);
    }

    return of(this.getNothingAssociated());
  }

  private getNotFound(): string {
    return '/assets/img/empty.jpg';
  }

  private getNothingAssociated(): string {
    return '/assets/img/nothingassociated.png';
  }
}
