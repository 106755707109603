import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpClient } from '@angular/common/http';
import { LoggingService } from '../../../@core/services/logging-service/logging.service';
import { AuthService } from '../../../@core/services';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'report-bug-dialog',
  template: `
    <div class="card">
      <div class="card-body">
        <form>
          <div class="form-group">
            <input
              type="text"
              pInputText
              placeholder="Name"
              [(ngModel)]="userName"
              [ngModelOptions]="{ standalone: true }"
              style="width: 100%; margin: 5px"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              pInputText
              placeholder="E-Mail"
              [(ngModel)]="mail"
              [ngModelOptions]="{ standalone: true }"
              style="width: 100%; margin: 5px"
            />
          </div>
          <div class="form-group">
            <p-dropdown
              [options]="categories"
              [(ngModel)]="selectedCategory"
              placeholder="Select Category"
              optionLabel="name"
              [ngModelOptions]="{ standalone: true }"
              [style]="{ margin: '5px' }"
            ></p-dropdown>
          </div>
          <hr />
          <div class="form-group">
            <input
              type="text"
              pInputText
              placeholder="Title"
              [(ngModel)]="title"
              [ngModelOptions]="{ standalone: true }"
              style="width: 100%; margin: 5px"
            />
          </div>
          <div class="form-group">
            <textarea
              pInputTextarea
              [rows]="5"
              [cols]="30"
              placeholder="Description"
              [(ngModel)]="description"
              [ngModelOptions]="{ standalone: true }"
              style="width: 100%; margin: 5px"
            ></textarea>
          </div>
          <hr />
          <div class="form-group">
            <p-button
              label="Report Bug"
              (onClick)="handleSubmit($event)"
              icon="fas fa-upload"
              iconPos="left"
            ></p-button>
            <p-button
              label="Cancel"
              (onClick)="handleCancel($event)"
              icon="fas fa-times"
              iconPos="left"
              [style]="{ 'margin-left': '10px' }"
            ></p-button>
          </div>
        </form>
      </div>
    </div>
  `,
})
export class ReportBugDialogComponent implements OnInit {
  title: string;
  mail: string;
  userName: string;
  description: string;

  categories: Category[];
  selectedCategory: Category;

  logs: string[];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private httpClient: HttpClient,
    private logger: LoggingService,
    private authService: AuthService
  ) {
    this.categories = [
      { name: 'System Failure / System Crash', id: 'system-failure' },
      { name: 'Unauthorized Exception', id: 'unauthorized' },
      { name: 'General Errors', id: 'error' },
      { name: 'Import Catia Structure', id: 'catia-import' },
      { name: 'Generate / Adapt Catia Structure', id: 'catia-generate' },
      { name: 'Check-In / Check-Out', id: 'check-out' },
      { name: 'Datamanagement', id: 'datamanagement' },
      { name: 'Explorer', id: 'explorer' },
      { name: 'Session', id: 'session' },
      { name: 'Settings', id: 'settings' },
      { name: 'Other...', id: 'other' },
    ];
  }

  ngOnInit(): void {
    this.authService.user$.subscribe((u) => {
      this.userName = u.name;
      this.mail = u.username;
    });
  }

  async handleSubmit(event) {
    const endpoint = environment.bugReportEndpoint;
    const headers = { 'Content-Type': 'application/json' };
    const payload = {
      category: this.selectedCategory.id,
      title: this.title,
      description: this.description,
      userName: this.userName,
      mail: this.mail,
    };

    this.httpClient
      .post(endpoint, payload, { headers })
      .subscribe((_) => this.logger.logInfo('Successfully Reported Bug!'));

    this.ref.close(null);
  }

  handleCancel(event) {
    this.ref.close(null);
  }
}

interface Category {
  name: string;
  id: string;
}
