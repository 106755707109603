import { DialogService } from 'primeng/dynamicdialog';
import { AddFileDialogComponent } from '../../dialogs/add-file-dialog/add-file-dialog.component';
import { NameDialogComponent } from '../../dialogs/name-dialog/name-dialog.component';
import { File, FilesService } from '../../../@core/services';
import { EnumsFileTypeEnum } from '@genetpdm/model/graphql';

export class FileTreeNode {
  public label: string;
  public data: ExtendedFile;
  public fileId: uuid;
  public leaf: boolean;
  public children: FileTreeNode[] = [];
  public expanded = false;

  constructor(
    private file: File,
    private filesService: FilesService
  ) {
    this.data = this.getExtendedFile(file);
    this.fileId = file.id;
    this.leaf = file.type == EnumsFileTypeEnum.File;
  }

  public expand(onNextAction: () => void) {
    this.filesService
      .getFilesByIds(this.data.child_files)
      .subscribe((results) => {
        this.children = results.map(
          (f) => new FileTreeNode(f, this.filesService)
        );
        onNextAction();
      });
  }

  private getExtendedFile(file: File): ExtendedFile {
    return {
      ...file,
      downloadAction: () => this.downloadFile(file.project_id, file.route),
      deleteAction: () => this.deleteFile(file.id),
    };
  }

  public downloadFile(projectId: uuid, route: string) {
    this.filesService.downloadFile(projectId, route);
  }

  private deleteFile(fileId: uuid) {}
}

export interface ExtendedFile extends File {
  downloadAction: () => void;
  deleteAction: () => void;
}

export interface Component {
  partNumber: string;
  componentId: uuid;
  projectId: uuid;
  version: number;
}

export class MenuBuilder {
  constructor(
    private filesService: FilesService,
    private dialogService: DialogService
  ) {}

  getMenuItemsForSelection(node: FileTreeNode, component: Component) {
    if (component == null) return [];

    return [
      {
        label: 'Download',
        icon: 'fas fa-download',
        visible: node != null && node.data.type == EnumsFileTypeEnum.File,
        command: (_) =>
          this.filesService.downloadFile(
            node?.data?.project_id,
            node?.data?.route
          ),
      },
      {
        label: 'Add File',
        icon: 'far fa-file-alt',
        visible: node == null || node?.data?.type == EnumsFileTypeEnum.Folder,
        command: (_) =>
          this.dialogService.open(AddFileDialogComponent, {
            header: 'Add Files',
            width: '70%',
            data: {
              componentId: component?.componentId,
              partNumber: component?.componentId,
              projectId: component?.projectId,
              version: component?.version,
            },
          }),
      },
      {
        label: 'Add Folder',
        icon: 'fas fa-folder-plus',
        visible: node == null || node?.data?.type == EnumsFileTypeEnum.Folder,
        command: (_) => {
          const ref = this.dialogService.open(NameDialogComponent, {
            header: 'Name',
            width: '70%',
          });

          ref.onClose.subscribe((name: string) =>
            this.filesService.addFolderToComponent(
              component.projectId,
              component.componentId,
              name
            )
          );
        },
      },
      {
        label: 'Add Folder',
        icon: 'fas fa-folder-plus',
        visible: node != null && node?.data?.type == EnumsFileTypeEnum.Folder,
        command: (_) => {
          const ref = this.dialogService.open(NameDialogComponent, {
            header: 'Name',
            width: '70%',
          });

          ref.onClose.subscribe((name: string) =>
            this.filesService.addFolderToFolder(
              component.projectId,
              component.componentId,
              name
            )
          );
        },
      },
    ];
  }
}
