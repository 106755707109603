import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LogAdapter, LogState, getLogId } from './state';
import { Log } from '@genetpdm/model';

export const selectLogState =
  createFeatureSelector<LogState>('Log');

export const selectAllLogs: (
  state: LogState
) => Log[] =
  LogAdapter.getSelectors().selectAll;

export const selectAllLogIds: (
  state: LogState
) => string[] = (state) =>
  LogAdapter.getSelectors()
    .selectIds(state)
    .map((id) => id as string);

export const selectLogsByIds = (ids: string[]) =>
  createSelector(selectAllLogs, (componentState) =>
    componentState.filter((c) => ids.includes(getLogId(c)))
  );
