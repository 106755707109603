import { Log } from '@genetpdm/model';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

/**
 * Define an EntityState for 'Log'.
 *
 *@remarks
 * An EntityState is a predefined State, that contains a dictionary of the entity and a sort order.
 *
 * @example
 *  export interface LogState {
 *      ids: number[];
 *      entities: {[key:number]: Log};
 *  }
 *
 * @param isLoading - An indicator, if the state is currently refreshing.
 * @param error - An indicator, if an error occured during an update through the backend.
 *
 */
export type LogState = EntityState<Log>;

/**
 * Define an Adapter for the EntityState for 'Log'.
 *
 *@remarks
 * An Adapter provides several useful methods to interact with the state like:
 * - getInitialState: Gets an empty state object
 * - addOne: add one entity to the collection
 * - addMany: add several entities
 * - addAll: replaces the whole collection with a new one
 * - removeOne: remove one entity
 * - removeMany: removes several entities
 * - removeAll: clear the whole collection
 * - updateOne: Update one existing entity
 * - updateMany: Update multiple existing entities
 * - upsertOne: Update or Insert one entity
 * - upsertMany: Update or Insert multiple entities
 */
export const LogAdapter: EntityAdapter<Log> = createEntityAdapter<Log>({
  selectId: (c) => getLogId(c),
  sortComparer: sortByDate,
});

export function getLogId(log: Log): string {
  return log.id;
}

export function sortByDate(a: Log, b: Log): number {
  return a.date.valueOf() - b.date.valueOf();
}
