<div class="footer">
  <span class="created-by"
    >© {{ currentYear }}
    <b
      ><a
        href="https://www.arrkeurope.com/de/services/engineering/"
        target="_blank"
        >ARRK Engineering</a
      ></b
    ></span
  >
  <div class="container">
    <div class="row">
      <div class="col text-center">
        <img src="{{ localCatiaApiLink }}" />
        <img src="{{ centralCatiaServiceLink }}" style="margin-left: 10px" />
        <img src="{{ generalPDMHealthLink }}" style="margin-left: 10px" />
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <p-progressBar
          *ngIf="show$ | async"
          [value]="progress$ | async"
          [showValue]="false"
          mode="{{ indeterminate$ | async }}"
        ></p-progressBar>
      </div>
    </div>
  </div>
  <div class="help">
    <button
      pButton
      type="button"
      label="Report Bug"
      (click)="handleClick($event)"
      *ngIf="loggedIn$ | async"
    ></button>
  </div>
</div>
