import { Injectable } from '@angular/core';
import { Logger } from './logger.interface';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalLoggingService implements Logger {
  public get logStream$(): Observable<LogEntry> {
    return this._logStream$.asObservable();
  }

  private _logStream$: ReplaySubject<LogEntry> = new ReplaySubject<LogEntry>();

  logTrace(message: string): void {
    this._logStream$.next({ message: message, severity: 'Trace' });
  }

  logDebug(message: string): void {
    this._logStream$.next({ message: message, severity: 'Debug' });
  }

  logInfo(message: string): void {
    this._logStream$.next({ message: message, severity: 'Info' });
  }

  logSuccess(message: string): void {
    this._logStream$.next({ message: message, severity: 'Success' });
  }

  logWarn(message: string): void {
    this._logStream$.next({ message: message, severity: 'Warning' });
  }

  logError(message: string): void {
    this._logStream$.next({ message: message, severity: 'Error' });
  }

  logFailed(message: string): void {
    this._logStream$.next({ message: message, severity: 'Error' });
  }

  logCritical(message: string): void {
    this._logStream$.next({ message: message, severity: 'Critical' });
  }
}

export interface LogEntry {
  message: string;
  severity: string;
}
