import { AddLog } from './actions';
import { LogState, LogAdapter } from './state';
import { createReducer, on } from '@ngrx/store';

export const InitialLogState: LogState = LogAdapter.getInitialState();

/**
 * Reducer to adapt the state to actions that were called.
 */
export const LogsReducer = createReducer(
  InitialLogState,
  on(AddLog, (state, log) =>
    LogAdapter.addOne(log.log, { ...state, isLoading: true })
  )
);
