import { Subscription } from 'rxjs';
import { TreeNode as TN } from 'primeng/api';

import { FileSystemObjectMessage } from '@genetsystems/genet-pdm-messaging-model';

export class TreeNode implements TN {
	//required properties for Tree
	public label: string;
	public data: FileSystemObjectMessage;
	public leaf?: boolean;
	public children?: TreeNode[];
	public expanded?: boolean;
	public draggable = false;
	public droppable = false;

	public subscription: Subscription = new Subscription();

	constructor(data: FileSystemObjectMessage) {
		this.data = data;
		this.label = data.getFilename();
		this.leaf = data.getType() == 0;
	}
}
