import { Injectable } from '@angular/core';
import { LoggingService, ProjectService } from '../../services';
import { SettingsService } from '../../services/settings-service/settings.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  AddProjects,
  AddProjectsFailed,
  AddProjectsSucceeded,
  ChangeCurrentProject,
  ChangeCurrentProjectFailed,
  ChangeCurrentProjectSucceeded,
  LoadProjectConfiguration,
  LoadProjectConfigurationFailed,
  LoadProjectConfigurationSuceeded,
  LoadProjects,
  LoadProjectsFailed,
  LoadProjectsSuceeded,
  LoadSelectedProjectId,
  LoadSelectedProjectIdFailed,
  LoadSelectedProjectIdSuceeded,
  UpdateProjects,
  UpdateProjectsFailed,
  UpdateProjectsSucceeded,
} from './actions';
import { tap, concatMap, combineLatest, map, catchError, of, take } from 'rxjs';
import { LoadRootComponents } from '../@app-state/app.actions';
import { ProjectPermissionsService } from '../../services/project-service/project-permissions.service';

@Injectable()
export class ProjectStoreEffects {
  constructor(
    private projectService: ProjectService,
    private settingsService: SettingsService,
    private permissionService: ProjectPermissionsService,
    private actions$: Actions,
    private logger: LoggingService,
  ) {}

  public loadProjectsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadProjects),
      tap(() => console.log('loadProjectsEffect triggered.')),
      concatMap((_) =>
        combineLatest([
          this.projectService.getAccessibleProjects$(),
          this.settingsService.getSelectedProjectId$(),
          this.permissionService.getLatestPermissions$(),
        ]).pipe(
          take(1),
          tap(() => console.log('loadProjectsEffect succeeded.')),
          map(([projects, id, permissions]) =>
            LoadProjectsSuceeded({
              projects: projects,
              selectedProjectId: id,
              permissions: permissions,
            }),
          ),
          catchError((error) =>
            of(
              LoadProjectsFailed({
                error: error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public loadProjectConfigurationSideEffect$ = createEffect(() =>
  this.actions$.pipe(
    ofType(LoadProjectsSuceeded),
    tap(() =>
      this.logger.logTrace('loadProjectConfigurationSideEffect triggered.'),
    ),
    concatMap((payload) =>
      of(LoadProjectConfiguration({ id: payload.selectedProjectId })),
    ),
  ),
);


  public loadProjectRootComponentsSideEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadProjectsSuceeded),
      tap(() =>
        this.logger.logTrace('loadProjectConfigurationSideEffect triggered.'),
      ),
      tap((_) => console.log('Load Projects succeeded')),
      concatMap((payload) =>
        of(LoadRootComponents({ projectId: payload.selectedProjectId })),
      ),
    ),
  );

  public loadProjectConfigurationSideEffect2$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadProjectsFailed),
        tap(() =>
          this.logger.logTrace('loadProjectConfigurationSideEffect2 failed.'),
        ),
      ),
    { dispatch: false },
  );


  public loadProjectConfigurationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadProjectConfiguration),
      tap(() =>
        this.logger.logTrace('loadProjectConfigurationEffect triggered.'),
      ),
      concatMap((request) =>
        this.projectService.getProjectConfiguration$(request.id).pipe(
          map((config) => LoadProjectConfigurationSuceeded({ config })),
          catchError((error) =>
            of(
              LoadProjectConfigurationFailed({
                error: error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public loadProjectIdEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSelectedProjectId),
      tap(() => this.logger.logTrace('loadProjectIdEffect triggered.')),
      concatMap(() =>
        this.settingsService.getSelectedProjectId$().pipe(
          map((id) => LoadSelectedProjectIdSuceeded({ selectedProjectId: id })),
          catchError((error) =>
            of(
              LoadSelectedProjectIdFailed({
                error: error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public addProjectsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddProjects),
      tap(() => this.logger.logTrace('addProjectsEffect triggered.')),
      concatMap((request) =>
        this.projectService.addProjects$(request.projects).pipe(
          map(() =>
            AddProjectsSucceeded({
              projects: request.projects,
            }),
          ),
          catchError((error) =>
            of(
              AddProjectsFailed({
                error: error,
                ids: request.projects.map((p) => p.id),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public updateProjectsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateProjects),
      tap(() => this.logger.logTrace('updateProjectsEffect triggered.')),
      concatMap((request) =>
        this.projectService.updateProjects$(request.projects).pipe(
          map(() =>
            UpdateProjectsSucceeded({
              projects: request.projects,
            }),
          ),
          catchError((error) =>
            of(
              UpdateProjectsFailed({
                error: error,
                ids: request.projects.map((p) => p.id as string),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public changeCurrentProjectEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeCurrentProject),
      tap(() => this.logger.logTrace('changeCurrentProjectEffect triggered.')),
      concatMap((request) =>
        this.projectService.setCurrentProject$(request.projectId).pipe(
          map(() =>
            ChangeCurrentProjectSucceeded({
              projectId: request.projectId,
            }),
          ),
          catchError((error) =>
            of(
              ChangeCurrentProjectFailed({
                error: error,
                id: request.projectId,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public addProjectSucceededEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AddProjectsSucceeded),
        tap(() => this.logger.logSuccess('Load Projects Succeeded')),
      ),
    { dispatch: false },
  );
  public addProjectFailedEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AddProjectsFailed),
        tap(() => this.logger.logFailed('Load Projects Failed')),
      ),
    { dispatch: false },
  );

  public loadProjectSucceededEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadProjectsSuceeded),
        tap(() => this.logger.logSuccess('Load Projects Succeeded')),
      ),
    { dispatch: false },
  );
  public loadProjectFailedEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadProjectsFailed),
        tap(() => this.logger.logFailed('Load Projects Failed')),
      ),
    { dispatch: false },
  );

  public changeCurrentProjectSucceededEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChangeCurrentProjectSucceeded),
        tap(() => this.logger.logSuccess('Change Current Project Succeeded')),
      ),
    { dispatch: false },
  );
  public changeCurrentProjectFailedEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChangeCurrentProjectFailed),
        tap(() => this.logger.logFailed('Change Current Project Failed')),
      ),
    { dispatch: false },
  );
}
