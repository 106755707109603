// THIS IS THE ONLINE DEVELOPMENT ENV
export const environment = {
	production: false,
	debug: false,
	urlBase: 'https://portal-dev.genetpdm.com',
  logLevel: {
    azure: 'Error',
    console: 'Trace',
    toast: 'Info',
  },
	azureAd: {
		clientId: 'a4a61f02-2a7a-427a-b7b2-beee4bf99fb8',
		tenantId: '30992635-7d31-42cc-9452-d91e875b2973',
		redirectUri: 'https://portal-dev.genetpdm.com',
		postLogoutRedirectUri: 'https://portal-dev.genetpdm.com',
		resources: {
			commandsAPI: {
				uri: 'api://a4a61f02-2a7a-427a-b7b2-beee4bf99fb8',
				scopes: ['api://a4a61f02-2a7a-427a-b7b2-beee4bf99fb8/default'],
			},
			blobStorage: {
				uri: 'https://storage.azure.com',
				scopes: [
					'https://storagegenetpdmdev.blob.core.windows.net/user_impersonation',
				],
			},
			cosmosDb: {
				uri: 'https://cosmos.azure.com',
				scopes: ['https://cosmos.azure.com/user_impersonation'],
			},
			serviceBus: {
				uri: 'https://servicebus.azure.net',
				scopes: ['https://servicebus.azure.net/user_impersonation'],
			},
			mscGraph: {
				uri: 'https://graph.microsoft.com/',
				scopes: [
					'https://graph.microsoft.com/email',
					'https://graph.microsoft.com/Group.Read.All',
					'https://graph.microsoft.com/offline_access',
					'https://graph.microsoft.com/openid',
					'https://graph.microsoft.com/profile',
					'https://graph.microsoft.com/User.Read',
					'https://graph.microsoft.com/User.ReadBasic.All',
				],
			},
		},
		roles: {
			user: 'User',
			admin: 'Admin',
			manager: 'Manager',
		},
	},
	graphqlWSEndpoint: 'wss://genet-pdm-dev.hasura.app/v1/graphql',
	graphqlHTTPEndpoint: 'https://genet-pdm-dev.hasura.app/v1/graphql',
	appInsights: {
		instrumentationKey: '9653ff73-5fcc-402f-98f7-9bf0eb968a07',
		sendImmediately: true,
		customDimensions: {
			application: 'Genet.PDM.Frontend',
			environment: 'development',
		},
	},
	cosmosDbOptions: {
		endpoint: 'https://cosmosdb-genetpdm-dev.documents.azure.com:443/',
	},
	blobStorageOptions: {
		account: 'storagegenetpdmdev',
	},
	interactiveBrowserCredentialOptions: {
		redirectUri: 'https://portal-dev.genetpdm.com',
		tenantId: '30992635-7d31-42cc-9452-d91e875b2973',
		clientId: 'a4a61f02-2a7a-427a-b7b2-beee4bf99fb8',
	},
	localApiOptions: {
		endpoint: 'http://localhost:3472',
		downloadLink:
			'https://storagegenetpdmdev.blob.core.windows.net/public/apps/catia-api/Publish.htm',
	},
	pollingRefreshRateMilliseconds: 10000,
	docsLink: 'https://docs.genetpdm.com',
	bugReportEndpoint:
		'https://prod-06.germanywestcentral.logic.azure.com:443/workflows/fbbefdcb7c364e319f7bf3854a1c5e35/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=yEvG4zTJvy4wkLGdjXQMDe0mu67ag74J0fEarefs3eE',
};
