import { OnInit, Component } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];

  ngOnInit() {
    this.model = [
      {
        label: 'Explorer',
        icon: 'pi pi-home',
        items: [
          {
            label: 'Design',
            icon: 'pi pi-fw pi-home',
            routerLink: ['/design'],
          },
          {
            label: 'Data-Management',
            icon: 'pi pi-fw pi-image',
            routerLink: ['/data-management'],
          },
        ],
      },
      {
        label: 'Project',
        icon: 'pi pi-fw pi-image',
        items: [
          {
            label: 'Project-Management',
            icon: 'pi pi-fw pi-sliders-v',
            routerLink: ['/project-management'],
          },
          {
            label: 'Calendar',
            icon: 'pi pi-fw pi-calendar',
            routerLink: ['/calendar'],
          },
          {
            label: 'Activity',
            icon: 'pi pi-fw pi-list',
            routerLink: ['/activity'],
          },
        ],
      },
      {
        label: 'Profile',
        icon: 'pi pi-fw pi-briefcase',
        items: [
          {
            label: 'Settings',
            icon: 'pi pi-fw pi-home',
            routerLink: ['/settings'],
          },
          {
            label: 'Administration',
            icon: 'pi pi-fw pi-user',
            routerLink: ['/administration'],
          },
        ],
      },
      {
        label: 'Documentation',
        icon: 'pi pi-fw pi-info-circle',
        items: [
          {
            label: 'Documentation',
            icon: 'pi pi-fw pi-info-circle',
            url: ['https://docs.genetpdm.com/'],
          },
          {
            label: 'FAQs',
            icon: 'pi pi-fw pi-question',
            routerLink: ['/faq'],
          },
        ],
      },
    ];
  }
}
