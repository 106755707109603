import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import {
  LoadRootVariantByProjectId,
  LoadVariantsById,
  LoadVariantsSuceeded,
  LoadVariantsFailed,
} from './actions';
import { VariantsService } from '../../services';

@Injectable()
export class VariantStoreEffects {
  constructor(
    private metadataService: VariantsService,
    private actions$: Actions
  ) {}

  public loadRootVariantEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRootVariantByProjectId),
      concatMap((request) =>
        this.metadataService.getRootVariant$(request.projectId).pipe(
          map((result) => LoadVariantsSuceeded({ variants: [result] })),
          catchError((error) =>
            of(
              LoadVariantsFailed({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  public loadVariantsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadVariantsById),
      concatMap((request) =>
        this.metadataService.getVariantsByIds$(request.ids).pipe(
          map((result) => LoadVariantsSuceeded({ variants: result })),
          catchError((error) =>
            of(
              LoadVariantsFailed({
                error: error,
              })
            )
          )
        )
      )
    )
  );
}
