import { Injectable } from '@angular/core';
import { Variant } from '@genetpdm/model';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VariantsService {
  //All methods should throw errors
  public getRootVariant$(projectId: uuid): Observable<Variant> {
    try {
      throw new Error('Not Implemented');
    } catch (error) {
      return throwError(() => error);
    }
  }

  public getVariantsByIds$(variantIds: uuid[]): Observable<Variant[]> {
    try {
      throw new Error('Not Implemented');
    } catch (error) {
      return throwError(() => error);
    }
  }
}
