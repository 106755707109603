export enum LoggerLevel {
  Trace = 0,
  Debug = 1,
  Info = 2,
  Warning = 3,
  Error = 4,
  Critical = 5,
  Off = 6,
}

export function getLogLevel(logLevel: string): LoggerLevel {
  return LoggerLevel[logLevel as keyof typeof LoggerLevel];
}
