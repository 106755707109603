import { Variant } from '@genetpdm/model';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

/**
 * Define an Adapter for the EntityState for 'Variant'.
 *
 *@remarks
 * An Adapter provides several useful methods to interact with the state like:
 * - getInitialState: Gets an empty state object
 * - addOne: add one entity to the collection
 * - addMany: add several entities
 * - addAll: replaces the whole collection with a new one
 * - removeOne: remove one entity
 * - removeMany: removes several entities
 * - removeAll: clear the whole collection
 * - updateOne: Update one existing entity
 * - updateMany: Update multiple existing entities
 * - upsertOne: Update or Insert one entity
 * - upsertMany: Update or Insert multiple entities
 */
export const VariantAdapter: EntityAdapter<Variant> =
  createEntityAdapter<Variant>({
    selectId: (c) => getVariantId(c),
    sortComparer: false,
  });

export function getVariantId(variant: Variant): string {
  const c = variant;
  return c.id;
}
