import { ComponentInstanceVersion } from '@genetpdm/model';
import { EnumsComponentTypeEnum } from '@genetpdm/model/graphql';
import { TreeNode } from 'primeng/api';

/**
 * Interface for defining a tree node in a tree view in an explorer
 */
export interface IExplorerTreeNode<T> extends TreeNode<T> {
  rowindex: number;
  origin: 'explorer' | 'catiainput'; //needs to be set, when dragged from upload window
  uploadNodeId: string; //needs to be set, when dragged from upload window
}

/**
 * Defines a tree node in the tree view with given fields
 */
export class ExplorerTreeNode
  implements IExplorerTreeNode<ComponentInstanceVersion>
{
  label?: string;
  data?: ComponentInstanceVersion;
  icon?: string;
  expandedIcon?: any;
  collapsedIcon?: any;
  children?: ExplorerTreeNode[];
  leaf?: boolean;
  expanded?: boolean;
  type?: string;
  parent?: ExplorerTreeNode;
  partialSelected?: boolean;
  style?: string;
  styleClass?: string;
  draggable?: boolean;
  droppable?: boolean;
  selectable?: boolean;
  key?: string;

  /**
   * The index of the row
   */
  public rowindex: number;
  public origin: 'explorer' | 'catiainput' = 'explorer'; //needs to be set, when dragged from upload window
  public uploadNodeId: string; //needs to be set, when dragged from upload window

  /**
   *
   * @param component The component from which a treenode is to be created
   * @param children The children of this treenode
   */
  constructor(
    component: ComponentInstanceVersion,
    children: ExplorerTreeNode[],
  ) {
    this.label = component?.latestVersion?.part_number ?? '';
    this.data = component;
    this.expanded = component?.isExpanded ?? false;
    this.children = children;
    this.leaf = component?.component?.type != EnumsComponentTypeEnum.Product;
    this.key = component?.instance?.id;
  }
}

/**
 * Interface for nodes to be uploaded
 */
export interface UploadNode {
  upload_id: string;
  upload_date: Date;
  variant_id: uuid;
}
