import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Store } from '@ngrx/store';
import { getSelectedProjectId } from '../../../@core/state';
import { SearchComponentVersionsQueryGQL } from '@genetpdm/model/graphql';

@Component({
  selector: 'options-dialog',
  template: `
    <div class="card">
      <div class="card-body">
        <span
          class="p-float-label"
          style="margin-left: 60px;margin-bottom:10px;margin-top: 20px min-width: 100%"
        >
          <input
            type="text"
            pInputText
            [(ngModel)]="filter"
            style="width: 20%; min-width: 20%"
          />
          <label>Search Component</label>
        </span>
        <p-pickList
          [source]="sourceList"
          [target]="targetList"
          sourceHeader="Available Components"
          targetHeader="Selected Components"
          [dragdrop]="true"
          [responsive]="true"
          [sourceStyle]="{ height: '30rem' }"
          [targetStyle]="{ height: '30rem' }"
        >
          <ng-template let-component pTemplate="item">
            <div>
              <div style="font-size:14px;float:left;">
                {{ component.component_name }}
              </div>
            </div>
          </ng-template>
        </p-pickList>
      </div>
      <div class="card-footer">
        <div class="row">
          <div>
            <p-button
              label="Submit"
              icon="pi pi-check"
              (onClick)="close()"
            ></p-button>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class AddComponentDialogComponent {
  sourceList: CadComponent[] = [];
  targetList: CadComponent[] = [];

  projectId: uuid;
  _filter = '';

  get filter(): string {
    return this._filter;
  }

  set filter(val) {
    this._filter = val;
    this.getComponents(val);
  }

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private searchComponentVersionsQuery: SearchComponentVersionsQueryGQL,
    private store: Store
  ) {
    this.store
      .select(getSelectedProjectId)
      .subscribe((p) => (this.projectId = p));
  }

  public getComponents(searchString: string) {
    this.searchComponentVersionsQuery
      .watch({
        project_id: this.projectId.toString(),
        search_text: `%${searchString}%`,
      })
      .valueChanges.subscribe((r) => {
        this.sourceList = r.data.view_cad_component_flat
          .filter(
            (c) =>
              !c.route.includes('upload') &&
              !this.targetList.some((x) => c.component_id == x.component_id)
          )
          .map((c) => ({
            component_id: c.component_id,
            component_name: c.component_name,
          }));
      });
  }

  public close() {
    this.ref.close({ components: this.targetList });
  }
}

interface CadComponent {
  component_name: string;
  component_id: uuid;
}
