<div
  class="layout-sidebar"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <div class="sidebar-header">
    <a [routerLink]="['/']" class="app-logo cursor-pointer">
      <svg
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1333.3333 400"
        height="100%"
        width="100%"
        xml:space="preserve"
        id="logo-arrk-engineering"
        class="logo-engineering"
        version="1.1"
      >
        <defs id="defs6">
          <clipPath id="clipPath20" clipPathUnits="userSpaceOnUse">
            <path
              id="path18"
              d="M 1748.51,1273.96 H 6373.03 V 2677.63 H 1748.51 Z"
            ></path>
          </clipPath>
        </defs>
        <g transform="matrix(1.3333333,0,0,-1.3333333,0,400)" id="g10">
          <g transform="scale(0.1)" id="g12">
            <g id="g14">
              <g clip-path="url(#clipPath20)" id="g16">
                <path
                  id="path22"
                  style="
                    fill: #1969bc;
                    fill-opacity: 1;
                    fill-rule: nonzero;
                    stroke: none;
                  "
                  d="m 1748.51,1274.07 1084.57,1403.56 175.88,-1403.56 h -329.34 l -74.14,569.01 -429.35,-569.01 h -427.62"
                ></path>
                <path
                  id="path24"
                  style="
                    fill: #1969bc;
                    fill-opacity: 1;
                    fill-rule: nonzero;
                    stroke: none;
                  "
                  d="m 3559.13,1870.44 163.68,-596.48 h 339.68 l -116.38,416.52 c 0,0 266.4,66.39 266.4,344 0,277.61 -290.55,292.26 -290.55,292.26 H 3360.71 L 3021.89,1274.07 h 342.27 l 259.94,796.29 h 139.77 c 0,0 76.3,-4.47 76.3,-84.43 0,-79.97 -72.21,-115.46 -117.62,-115.46 l -163.42,-0.03"
                ></path>
                <path
                  id="path26"
                  style="
                    fill: #1969bc;
                    fill-opacity: 1;
                    fill-rule: nonzero;
                    stroke: none;
                  "
                  d="m 4613.77,1870.44 163.69,-596.48 h 339.68 l -116.39,416.52 c 0,0 266.41,66.39 266.41,344 0,277.61 -290.55,292.26 -290.55,292.26 H 4415.36 L 4076.54,1274.07 h 342.27 l 259.94,796.29 h 139.77 c 0,0 76.3,-4.47 76.3,-84.43 0,-79.97 -72.21,-115.46 -117.62,-115.46 l -163.43,-0.03"
                ></path>
                <path
                  id="path28"
                  style="
                    fill: #1969bc;
                    fill-opacity: 1;
                    fill-rule: nonzero;
                    stroke: none;
                  "
                  d="m 5460.89,2329.33 h 331.06 L 5464.33,1274.23 H 5128.1 l 332.79,1055.1"
                ></path>
                <path
                  id="path30"
                  style="
                    fill: #1969bc;
                    fill-opacity: 1;
                    fill-rule: nonzero;
                    stroke: none;
                  "
                  d="m 6373.03,2329.33 h -353.48 l -348.3,-450.04 182.77,-605.06 h 350.04 l -186.23,601.61 355.2,453.49"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </a>
    <button
      class="layout-sidebar-anchor p-link"
      type="button"
      (click)="anchor()"
    ></button>
  </div>

  <div #menuContainer class="layout-menu-container">
    <app-menu></app-menu>
  </div>
</div>
