import { Injectable } from '@angular/core';
import { Logger } from './logger.interface';
import { ToastLoggingService } from './toast-logging.service';
import { AzureLoggingService } from './azure-logging.service';
import { ConsoleLoggingService } from './console-logging.service';
import { Observable, OperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalLoggingService } from './local-logging.service';

@Injectable({
  providedIn: 'root',
})
export class LoggingService implements Logger {
  private loggers: Logger[] = [];

  constructor(
    toastLogger: ToastLoggingService,
    azureLogger: AzureLoggingService,
    consoleLogger: ConsoleLoggingService,
    localLogger: LocalLoggingService
  ) {
    this.loggers.push(toastLogger);
    this.loggers.push(azureLogger);
    this.loggers.push(consoleLogger);
    this.loggers.push(localLogger);
  }

  public logTrace(message: string) {
    this.loggers.forEach((logger) => {
      logger.logTrace(message);
    });
  }

  public logDebug(message: string) {
    this.loggers.forEach((logger) => {
      logger.logDebug(message);
    });
  }

  public logInfo(message: string) {
    this.loggers.forEach((logger) => {
      logger.logInfo(message);
    });
  }

  public logSuccess(message: string) {
    this.loggers.forEach((logger) => {
      logger.logSuccess(message);
    });
  }

  public logWarn(message: string) {
    const transformed = this.transformErrorMessage(message);
    this.loggers.forEach((logger) => {
      logger.logWarn(transformed);
    });
  }

  public logError(message: string) {
    const transformed = this.transformErrorMessage(message);
    this.loggers.forEach((logger) => {
      logger.logError(transformed);
    });
  }

  public logFailed(message: string): void {
    const transformed = this.transformErrorMessage(message);
    this.loggers.forEach((logger) => {
      logger.logFailed(transformed);
    });
  }

  public logCritical(message: string) {
    const transformed = this.transformErrorMessage(message);
    this.loggers.forEach((logger) => {
      logger.logCritical(transformed);
    });
  }

  public catchAndLog<T>(): OperatorFunction<T, T> {
    return this.logErrorPipe;
  }

  private logErrorPipe<T>(source$: Observable<T>): Observable<T> {
    return source$.pipe(
      catchError((ex) => {
        this.logError(ex?.message);
        return throwError(() => ex);
      })
    );
  }

  private transformErrorMessage(message: string): string {
    let msg = message
      .replace(
        'Authentication hook unauthorized this request',
        'Not authorized! (Try refreshing the page.)'
      )
      .replace(
        'check constraint of an insert/update permission has failed',
        'You dont have the permissions to execute this action!'
      )
      .replace(
        'Response closed without headers',
        'Your local Genet PDM CatiaAPI is not reachable! Please start or install it. See FAQs or Manual for more info.'
      )
      .replace(
        `Value cannot be null. (Parameter 'payload')`,
        'Request Error: If you are using Edge, try using Chrome instead. Otherwise, try refreshing the page.'
      );
    if (msg.includes('Uncaught') || msg.includes('Observable cancelled'))
      msg = '';

    if (msg.includes('RPC'))
      msg =
        'Your local Genet PDM CatiaAPI is not reachable! Please restart or install it. See FAQs or Manual for more info.';

    return msg;
  }
}
