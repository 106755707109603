import {
  LoadSettings,
  LoadSettingsSuccess,
  LoadSettingsFailure,
  SetCurrentLocation,
  SetCurrentLocationSuccess,
  SetCurrentLocationFailure,
  SetCurrentProject,
  SetCurrentProjectSuccess,
  SetCurrentProjectFailure,
} from './actions';
import { ExplorerStoreReducerArray } from '../state-model';
import { on } from '@ngrx/store';

export const SettingsReducer: ExplorerStoreReducerArray = [
  on(LoadSettings, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(LoadSettingsSuccess, (state, { settings }) => ({
    ...state,
    settings: settings,
    isLoading: false,
    error: null,
  })),
  on(LoadSettingsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(SetCurrentLocation, (state, { location }) => ({
    ...state,
    settings: { ...state.settings, location: location },
    isLoading: true,
    error: null,
  })),
  on(SetCurrentLocationSuccess, (state, { location }) => ({
    ...state,
    settings: { ...state.settings, location: location },
    isLoading: false,
    error: null,
  })),
  on(SetCurrentLocationFailure, (state, { error, previousLocation }) => ({
    ...state,
    settings: { ...state.settings, location: previousLocation },
    isLoading: false,
    error,
  })),

  on(SetCurrentProject, (state, { projectId }) => ({
    ...state,
    settings: { ...state.settings, current_project_id: projectId },
    isLoading: true,
    error: null,
  })),
  on(SetCurrentProjectSuccess, (state, { projectId }) => ({
    ...state,
    settings: { ...state.settings, current_project_id: projectId },
    isLoading: false,
    error: null,
  })),
  on(SetCurrentProjectFailure, (state, { error, previousProjectId }) => ({
    ...state,
    settings: {
      ...state.settings,
      current_project_id: previousProjectId,
    },
    isLoading: false,
    error,
  })),
];
