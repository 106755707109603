import { createSelector } from '@ngrx/store';
import { AppState } from '../@app-state/app.state';
import {
  ProjectAdapter,
  ProjectConfigurationAdapter,
} from '../@app-state/app.adapter';
import { selectAppState } from '../@app-state/app.selectors';

export const selectAllProjects = createSelector(
  selectAppState,
  (state: AppState) => ProjectAdapter.getSelectors().selectAll(state.projects),
);

export const selectProjectEntities = createSelector(
  selectAppState,
  (state: AppState) =>
    ProjectAdapter.getSelectors().selectEntities(state.projects),
);

export const selectProjectConfigEntities = createSelector(
  selectAppState,
  (state: AppState) =>
    ProjectConfigurationAdapter.getSelectors().selectEntities(state.configs),
);

export const selectAllProjectConfigs = createSelector(
  selectAppState,
  (state: AppState) =>
    ProjectConfigurationAdapter.getSelectors().selectAll(state.configs),
);

export const selectAllProjectIds = createSelector(
  selectAppState,
  (state: AppState) =>
    ProjectAdapter.getSelectors()
      .selectIds(state.projects)
      .map((id) => id as string),
);

export const selectProjectsByIds = (ids: uuid[]) =>
  createSelector(selectAllProjects, (projects) =>
    projects.filter((c) => ids.includes(c.id)),
  );

export const getSelectedProjectId = createSelector(
  selectAppState,
  (state: AppState) => state.selectedProjectId,
);

export const selectCurrentProject = createSelector(
  selectProjectEntities,
  getSelectedProjectId,
  (dict, projectId) => projectId && dict[projectId],
);

export const selectCurrentProjectConfiguration = createSelector(
  selectProjectConfigEntities,
  getSelectedProjectId,
  (dict, id) => id && dict[id],
);
