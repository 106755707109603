import { Component, OnInit } from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ChecklistDialogComponent } from '../checklist-dialog/checklist-dialog.component';
import {
  EnumsChecklistTypeEnum,
  SearchChecklistTemplateQueryGQL,
} from '@genetpdm/model/graphql';

@Component({
  selector: 'checklist-search-dialog',
  template: `
    <div class="card">
      <div class="card-body">
        <span
          class="p-float-label"
          style="margin-bottom:10px; margin-top: 30px; min-width: 100%"
        >
          <input
            type="text"
            pInputText
            [(ngModel)]="filter"
            style="width: 100%;"
          />
          <label>Search Checklist Templates</label>
        </span>

        <p-listbox
          [options]="sourceList"
          [(ngModel)]="selectedItem"
          optionLabel="name"
          [style]="{ 'margin-bottom': '10px' }"
        ></p-listbox>

        <!--TODO: Make a Checklist Preview on the right side-->
      </div>

      <div class="card-footer">
        <div class="row">
          <div>
            <p-button
              label="Submit"
              icon="pi pi-check"
              (onClick)="close()"
              [style]="{ 'margin-right': '10px' }"
            ></p-button>
            <p-button
              label="Add new Checklist"
              icon="fas fa-plus"
              class="float:right"
              (onClick)="AddChecklist()"
            ></p-button>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class ChecklistSearchDialogComponent implements OnInit {
  sourceList: Checklist[] = [];
  selectedItem: Checklist;

  _filter = '';

  get filter(): string {
    return this._filter;
  }

  set filter(val) {
    this._filter = val;
    this.getChecklistTemplates(val);
  }

  checklistType: EnumsChecklistTypeEnum = EnumsChecklistTypeEnum.Default;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private dialogService: DialogService,
    public searchChecklistTemplateQuery: SearchChecklistTemplateQueryGQL
  ) {}

  public getChecklistTemplates(value: string) {
    this.searchChecklistTemplateQuery
      .watch({
        name: `%${value}%`,
        type: this.checklistType,
      })
      .valueChanges.subscribe((r) => {
        this.sourceList = r.data.checklist_template.map((c) => ({
          id: c.id,
          name: c.name,
        }));
      });
  }

  public AddChecklist() {
    this.dialogService.open(ChecklistDialogComponent, {
      header: 'Create new Checklist Template',
      width: '70%',
    });
  }

  public close() {
    this.ref.close({
      templateId: this.selectedItem.id,
      name: this.selectedItem.name,
    });
  }

  ngOnInit(): void {
    const searchReleaseChecklist = this.config?.data?.releaseChecklist ?? false;

    if (searchReleaseChecklist)
      this.checklistType = EnumsChecklistTypeEnum.Release;
  }
}

interface Checklist {
  id: string;
  name: string;
}
