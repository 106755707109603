import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { PrimeNGConfig } from 'primeng/api';
import { LayoutService } from './layout/service/app.layout.service';
import { StateService } from './@core/services/state-service/state.service';

/**
 * This is the root component of the PDM system.
 * It initializes the basic state of the application and triggers the loading of all settings and projects.
 */
@Component({
  selector: 'app-root',
  template: `
    <p-toast></p-toast>

    <p-dialog
      header="New Message from Administrator"
      [(visible)]="displayDialog"
    >
      {{ dialogMessage }}
    </p-dialog>

    <p-confirmDialog [style]="{ width: '300px' }"></p-confirmDialog>

    <router-outlet></router-outlet>
  `,
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {
  displayDialog = false;
  dialogMessage = '';

  /**
   *
   * @param store The ngrx store for global state management
   * @param primengConfig The configuration for primeNG
   * @param layoutService The service for managing the layout of the application
   * @param stateService Service that handles the global state of the application
   */
  constructor(
    private store: Store,
    private primengConfig: PrimeNGConfig,
    private layoutService: LayoutService,
    private stateService: StateService,
  ) {}

  /**
   * Initializes the state and the layout of the application
   */
  ngOnInit(): void {
    this.stateService.initializeState();

    this.primengConfig.ripple = true; // enables core ripple functionality

    //optional configuration with the default configuration
    this.layoutService.config = {
      ripple: true, // toggles ripple on and off
      inputStyle: 'outlined', // default style for input elements, can be "outlined" or "filled"
      menuMode: 'static', // layout mode of the menu, valid values are "static", "overlay", "slim", "slim_plus", "horizontal", "reveal" and "drawer"
      colorScheme: 'light', // color scheme of the template, valid values are "light" and "dark"
      theme: 'blue', // default component theme for PrimeNG
      menuTheme: 'light', // theme of the menu, valid values are "light" and "dark"
      topbarTheme: 'light', // theme of the topbar, valid values are "light" and "dark"
      scale: 12, // size of the body font size to scale the whole application
    };
  }
}
