import { Guid } from 'guid-typescript';

export class Log {
  public readonly id: uuid;
  public readonly message: string;
  public readonly severity: Severity;
  public readonly informUser: boolean;
  public readonly source: string;
  public readonly date: Date;

  constructor(
    message: string,
    severity: Severity,
    informUser: boolean = false,
    source: string = ''
  ) {
    this.id = Guid.create().toString();
    this.message = message;
    this.severity = severity;
    this.informUser = informUser;
    this.source = source;
    this.date = new Date();
  }
}

export enum Severity {
  Trace,
  Debug,
  Info,
  Success,
  Warning,
  Error,
  Critical,
}
