import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EnumsMaturityEnum } from '@genetpdm/model/graphql';
import { ComponentInstanceVersion } from '@genetpdm/model';
import { ImageService } from '../../../@core/services';
import { of } from 'rxjs';

@Component({
	selector: 'app-part-detail',
	templateUrl: './part-detail-view.component.html',
	styleUrls: ['./part-detail-view.component.scss'],
	providers: [DatePipe],
})
export class PartDetailViewComponent implements OnInit {
	component: ComponentInstanceVersion;

	maturities: any[];

	constructor(
		public ref: DynamicDialogRef,
		public config: DynamicDialogConfig,
    private imageService: ImageService
	) {
		this.maturities = Object.keys(EnumsMaturityEnum).map((item) => ({
			name: item.toString(),
		}));
	}

	ngOnInit() {
		this.component = this.config.data.component;
	}

    getImage$(component: ComponentInstanceVersion) {
      if (!component) return of("");

    return this.imageService.getImageDataUrl$(
      component.previewRoute,
      component.component.project_id
    );
  }
}
