import { AppState } from '../@app-state/app.state';
import { selectAppState } from '../@app-state/app.selectors';
import { createSelector } from '@ngrx/store';

export const selectIsLoading = createSelector(
  selectAppState,
  (state: AppState) => state.isLoading,
);

export const selectSelectedComponent = createSelector(
  selectAppState,
  (state: AppState) => state.selected,
);

export const selectAllTabContainers = createSelector(
  selectAppState,
  (state: AppState) => state.tabs,
);

export const selectActiveTabContainer = createSelector(
  selectAppState,
  (state: AppState) => state.tabs.find((t) => t.id == state.selectedTabId),
);

// export const selectCurrentComponentLogs = createSelector(
//   selectAppState,
//   (state: AppState) => state.tabs.find((t) => t.id == state.selectedTabId),
// );
