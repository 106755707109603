import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  DynamicDialogConfig,
  DynamicDialogRef,
  DialogService,
} from 'primeng/dynamicdialog';
import { Variant } from './variants-view.model';
import { filter, map, tap } from 'rxjs/operators';
import { OperatorFunction } from 'rxjs';
import { NewVariantDialogComponent } from '../../dialogs/new-variant-dialog/new-variant-dialog.component';
import {
  ComponentMetadataService,
  LoggingService,
} from '../../../@core/services';
import { ObservableToastService } from '../../../@core/utils/observable-operators/observable-toast.service';
import { ExplorerTreeNode } from '../../../@core/model/treenode';
import {
  DeleteVariantActionGQL,
  SwapVariantActionGQL,
} from '@genetpdm/model/graphql';
import { ComponentInstanceVersion, Variant as V } from '@genetpdm/model';
import { handleGraphQlErrors } from '../../../@core/services/utility/graphql-utilities';

@Component({
  selector: 'variants-view',
  template: `
    <h3>Variants of {{ this.component.part_number }}</h3>

    <p-table [value]="variants" [autoLayout]="true" *ngIf="variants">
      <ng-template pTemplate="header">
        <tr>
          <th>Variant-Name</th>
          <th>Derived From</th>
          <th>ID</th>
          <th>Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-variant>
        <tr>
          <td>{{ variant.name }}</td>
          <td>{{ variant.original_component_id }}</td>
          <td>{{ variant.id }}</td>
          <td>
            <button
              pButton
              pRipple
              type="button"
              title="Set to current Variant for you"
              icon="fas fa-user-cog"
              class="p-button-rounded p-button-secondary p-button-text"
              *ngIf="variant.setCurrentForSession"
              (click)="variant.setCurrentForSession()"
            ></button>
            <button
              pButton
              pRipple
              type="button"
              title="Set to current Variant for ALL"
              icon="fas fa-users-cog"
              class="p-button-rounded p-button-secondary p-button-text"
              *ngIf="variant.setCurrentForAll"
              (click)="variant.setCurrentForAll()"
            ></button>
            <button
              pButton
              pRipple
              type="button"
              title="Delete this Variant"
              icon="fas fa-trash-alt"
              class="p-button-rounded p-button-secondary p-button-text"
              *ngIf="variant.delete"
              (click)="variant.delete()"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <button
      pButton
      pRipple
      type="button"
      label="Create new Variant"
      class="p-button-raised p-button-secondary"
      *ngIf="!variants"
      (click)="this.createVariant()"
    ></button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class VariantsViewComponent implements OnInit {
  component: ComponentInstanceVersion;

  variants: Variant[];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private dialogService: DialogService,
    private componentMetadataService: ComponentMetadataService,
    private swapVariantAction: SwapVariantActionGQL,
    private deleteVariantAction: DeleteVariantActionGQL,
    private observableToastService: ObservableToastService,
    private logger: LoggingService
  ) {}

  ngOnInit(): void {
    this.component = this.config.data.component;

    this.componentMetadataService
      .getAllVariants$(this.component.component.id.toString())
      .pipe(
        map((v) =>
          v.map((va) => ({
            ...va,
            setCurrentForAll: () => this.setCurrentForAll(va),
            setCurrentForSession: () =>
              this.setCurrentForSession(va.variant_component_id),
            delete: () => this.deleteVariant(va),
          }))
        ),
        filter((v) => v.some((_) => true))
      )
      .subscribe((v) => (this.variants = v));
  }

  public createVariant() {
    this.dialogService.open(NewVariantDialogComponent, {
      header: 'Create New Variant',
      width: '70%',
      data: {
        component: this.component,
      },
    });
  }

  //Reconstructs the TreeNode, so that the variant is displayed.
  //This is only active as long as the parents children-subscription does not emit anything
  // or the page is reloaded
  private setCurrentForSession(component_id: uuid) {
    //TODO: Implement logic in store
    // this.componentNodeInExplorer.reconstruct(component_id);
    throw Error('Not yet implemented');
  }

  private setCurrentForAll(variant: V) {
    if (variant.original_component_id == null)
      throw new Error(
        'Cannot set Variant as current, due to missing original component.'
      );

    this.swapVariantAction
      .mutate({
        variant_id: variant.id,
        instance_id: this.component.instance_id,
      })
      .pipe(
        handleGraphQlErrors(),
        tap((_) => {
          this.logger.logSuccess(`Swapped Variant successfully.`);
        }),
        this.catchAndToast()
      )
      .subscribe((_) => this.ref.close());
  }

  private deleteVariant(variant: V) {
    this.deleteVariantAction
      .mutate({ variant_id: variant.id })
      .pipe(
        handleGraphQlErrors(),
        this.catchAndToast(),
        tap((_) => {
          this.logger.logSuccess(`Deleted Variant successfully.`);
        })
      )
      .subscribe((_) => this.ref.close());
  }

  private catchAndToast<T>(defaultValue?: T): OperatorFunction<T, T> {
    return this.observableToastService.catchAndToast(defaultValue);
  }
}
