import { Component, OnInit } from '@angular/core';
import {
	CadEdgeMessage,
	CadGetInstancePositionsCompletedMessage,
} from '@genetsystems/genet-pdm-messaging-model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OperatorFunction } from 'rxjs';
import { LoggingService } from '../../../@core/services/logging-service/logging.service';
import { CadInstancePositionInsertInput, UpsertInstancePositionsMutationGQL } from '@genetpdm/model/graphql';
import { ObservableToastService } from '../../../@core/utils/observable-operators/observable-toast.service';

@Component({
	selector: 'choose-positions-dialog',
	templateUrl: './choose-positions-dialog.component.html',
})
export class ChoosePositionsDialogComponent implements OnInit {
	loading: boolean;

	items: Position[] = [];
	selectedItems: Position[] = [];

	instanceInputs: CadInstancePositionInsertInput[] = [];

	public routeText = '';

	constructor(
		public ref: DynamicDialogRef,
		public config: DynamicDialogConfig,
		private upsertPositionsMutation: UpsertInstancePositionsMutationGQL,
		private observableToastService: ObservableToastService,
		private logger: LoggingService,
	) {}

	ngOnInit(): void {
		const result = <CadGetInstancePositionsCompletedMessage>(
			this.config.data.positionResult
		);

		this.instanceInputs = result
			.getOk()
			.getPositionedinstancesList()
			.map(
				(e) =>
					<CadInstancePositionInsertInput>{
						instance_id: e.getId(),
						pos_x: e.getPosition().getPos().getX1(),
						pos_y: e.getPosition().getPos().getX2(),
						pos_z: e.getPosition().getPos().getX3(),
						rot1_x1: e.getPosition().getRot1().getX1(),
						rot1_x2: e.getPosition().getRot1().getX2(),
						rot1_x3: e.getPosition().getRot1().getX3(),
						rot2_x1: e.getPosition().getRot2().getX1(),
						rot2_x2: e.getPosition().getRot2().getX2(),
						rot2_x3: e.getPosition().getRot2().getX3(),
						rot3_x1: e.getPosition().getRot3().getX1(),
						rot3_x2: e.getPosition().getRot3().getX2(),
						rot3_x3: e.getPosition().getRot3().getX3(),
					},
			);

		const edgesMap = new Map<string, CadEdgeMessage>();

		result
			.getRequest()
			.getStructure()
			.getEdgesList()
			.forEach((e) => edgesMap.set(e.getId(), e));

		this.items = this.instanceInputs
			.map((i) => this.getPosition(i, edgesMap))
			.filter((i) => i != null);
	}

	handleClickAccept(event) {
		this.updateInstancePositions();
		this.ref.close();
	}

	handleClickCancel(event) {
		this.ref.close(null);
	}

	private getPosition(
		input: CadInstancePositionInsertInput,
		edgesMap: Map<string, CadEdgeMessage>,
	): Position {
		try {
			return {
				instance_id: input.instance_id,
				position: this.getPositionString(input),
				component_id: edgesMap.get(input.instance_id).getRightid(),
				instance_name: edgesMap.get(input.instance_id).getInstancename(),
			};
		} catch (error) {
			return null;
		}
	}

	private updateInstancePositions(): void {
		const inputs = this.instanceInputs.filter((i) =>
			this.selectedItems.some((x) => x.instance_id == i.instance_id.toString()),
		);

		if (inputs.length == 0) {
			this.logger.logWarn(
				'No positions were updated. Please check, which positions, you want to update.',
			);
			this.ref.close();
		}

		this.upsertPositionsMutation
			.mutate({ instance_positions: inputs })
			.subscribe(() =>
				this.logger.logSuccess(
					'Successfully added positions',
				),
			);
	}

	private getPositionString(result: CadInstancePositionInsertInput) {
		return (
			`X: ${result.pos_x}, Y: ${result.pos_y}, Z: ${result.pos_z}; ` +
			`Rot1: ${result.rot1_x1}, ${result.rot1_x2}, ${result.rot1_x3}; ` +
			`Rot2: ${result.rot2_x1}, ${result.rot2_x2}, ${result.rot2_x3}; ` +
			`Rot3: ${result.rot3_x1}, ${result.rot3_x2}, ${result.rot3_x3}`
		);
	}

	private catchAndToast<T>(defaultValue?: T): OperatorFunction<T, T> {
		return this.observableToastService.catchAndToast(defaultValue);
	}
}

interface Position {
	component_id: uuid;
	instance_id: uuid;
	instance_name: string;
	position: string;
}
