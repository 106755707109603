import { Injectable } from '@angular/core';
import {
  ComponentAttachmentsDto,
  ComponentInstanceVersion,
  ComponentInstanceVersionsDto,
  ComponentSubversion,
  ComponentVersion,
  UploadNode,
  UploadedComponentBlob,
  RowChangeSet,
} from '@genetpdm/model';
import {
  GetLatestComponentInstanceVersionsByLeftIdsGQL,
  GetComponentSubversionsByComponentIdsGQL,
  GetComponentVersionsByComponentIdsGQL,
  GetLatestComponentInstanceVersionsByComponentIdsGQL,
  GetRootComponentInstanceVersionGQL,
  GetRootComponentInstanceVersionQueryResult,
  GetLatestComponentInstanceVersionsByComponentIdsQueryResult,
  GetLatestComponentInstanceVersionsByLeftIdsQueryResult,
  GetAllAttachedComponentDataQueryResult,
  RecursiveGetLeftComponentsTenLevelsGQL,
  RecursiveGetLeftComponentsTenLevelsQueryResult,
  GetLatestComponentInstanceVersionsByInstanceIdsGQL,
  GetComponentVersionIndicesGQL,
  UploadNodesUserSelectedSubscriptionGQL,
  GetComponentLogsGQL,
  GetAllVariantsQueryGQL,
  CheckedOutComponentVersionsIdsQueryGQL,
  GetAllDrawingsGQL,
  GetAllAttachedComponentDataGQL,
  GetMyComponentInstanceVersionsQueryGQL,
  GetMyComponentInstanceVersionsQueryResult,
  SearchComponentInstanceVersionQueryGQL,
  SearchComponentInstanceVersionQueryResult,
  UploadComponentBlobsSubscriptionGQL,
  UploadRootComponentsQueryGQL,
  ChangeRowIndexActionGQL,
  ChangeRowInput,
  CadComponentVersionBoolExp,
  Scalars,
  InputMaybe,
} from '@genetpdm/model/graphql';
import { from } from 'linq-to-typescript';
import {
  EMPTY,
  Observable,
  map,
  of,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { GQLResult, handleGraphQlErrors } from '../utility/graphql-utilities';
import { LoggingService } from '../logging-service/logging.service';
import { GetComponentInstanceVersionsFromDto } from '../../state';
import { catchError } from 'rxjs/operators';
import moment from 'moment';
import { throwIfGQLError } from '../../utils/graphql-result-error-interceptor/graphql-result-error.interceptor';
import { Guid } from 'guid-typescript';
import { toHasuraUTCDateString } from '../../utils/date-helper/date-helper';

@Injectable({
  providedIn: 'root',
})
export class ComponentMetadataService {
  constructor(
    private logger: LoggingService,
    private getLatestRootComponentInstanceVersion: GetRootComponentInstanceVersionGQL,
    private getLatestComponentInstanceVersions: GetLatestComponentInstanceVersionsByComponentIdsGQL,
    private getLatestComponentInstanceVersionsByLeftIds: GetLatestComponentInstanceVersionsByLeftIdsGQL,
    private getLatestComponentInstanceVersionsByInstanceIds: GetLatestComponentInstanceVersionsByInstanceIdsGQL,
    private getComponentVersionsByComponentIds: GetComponentVersionsByComponentIdsGQL,
    private checkedOutComponentsQuery: CheckedOutComponentVersionsIdsQueryGQL,
    private getComponentSubversionsByComponentIds: GetComponentSubversionsByComponentIdsGQL,
    private getParentTreeQuery: RecursiveGetLeftComponentsTenLevelsGQL,
    private getUploadNodesSubscription: UploadNodesUserSelectedSubscriptionGQL,
    private getUploadComponentBlobsSubscription: UploadComponentBlobsSubscriptionGQL,
    private getUploadRootNodesQuery: UploadRootComponentsQueryGQL,
    private versionIndicesQuery: GetComponentVersionIndicesGQL,
    private getVariantsQuery: GetAllVariantsQueryGQL,
    private getDrawingsQuery: GetAllDrawingsGQL,
    private getLogsQuery: GetComponentLogsGQL,
    private getAllAttachedComponentData: GetAllAttachedComponentDataGQL,
    private getMyComponentInstanceVersionsQuery: GetMyComponentInstanceVersionsQueryGQL,
    private searchComponentInstanceVersionQuery: SearchComponentInstanceVersionQueryGQL,
    private changeIndexMutation: ChangeRowIndexActionGQL,
  ) {}

  public getRootComponentByProjectId$(
    projectId: uuid,
  ): Observable<ComponentInstanceVersionsDto> {
    try {
      this.logger.logDebug(
        `[ComponentMetadataService] (getRootComponentByProjectId$): Getting Root Component...`,
      );

      return this.getLatestRootComponentInstanceVersion
        .watch({ project_id: projectId })
        .valueChanges.pipe(
          handleGraphQlErrors(),
          map(this.mapRootComponentInstance),
          take(1),
          tap((c) =>
            this.logger.logDebug(
              `[ComponentMetadataService] (getRootComponentByProjectId$): Received Root Component: ${c.components[0].name}.`,
            ),
          ),
        );
    } catch (error) {
      return throwError(() => error);
    }
  }

  public getAllCheckedOutComponents$(
    projectId: uuid,
  ): Observable<ComponentInstanceVersion[]> {
    this.logger.logDebug(
      `[ComponentMetadataService] (getAllCheckedOutComponents$): Request all Checked Out Components for projectId ${projectId}...`,
    );

    const dto$ = this.checkedOutComponentsQuery
      .watch({
        project_id: projectId,
      })
      .valueChanges.pipe(
        switchMap((r) =>
          this.getComponentsByIds$(
            r.data.cad_component_version.map((c) => c.component_id),
          ),
        ),
        tap((r) =>
          this.logger.logDebug(
            `[ComponentMetadataService] (getAllCheckedOutComponents$): Successfully Received CheckOut Components.`,
          ),
        ),
      );

    return dto$.pipe(map((dto) => GetComponentInstanceVersionsFromDto(dto)));
  }

  public getComponentsByIds$(
    ids: uuid[],
  ): Observable<ComponentInstanceVersionsDto> {
    this.logger.logDebug(
      `[ComponentMetadataService] (getComponentsByIds$): Requesting Components by IDs...`,
    );

    try {
      return this.getLatestComponentInstanceVersions
        .watch({ ids })
        .valueChanges.pipe(
          handleGraphQlErrors(),
          map((c) => this.mapComponent(c)),
          take(1),
          tap((c) =>
            this.logger.logDebug(
              `[ComponentMetadataService] (getComponentsByIds$):Successfully Received ${c.components.length} Components.`,
            ),
          ),
        );
    } catch (error) {
      return throwError(() => error);
    }
  }

  public getMyComponents$(
    projectId: uuid,
  ): Observable<ComponentInstanceVersionsDto> {
    this.logger.logDebug(
      `[ComponentMetadataService] (getMyComponents$): Requesting Components...`,
    );

    try {
      return this.getMyComponentInstanceVersionsQuery
        .watch({ project_id: projectId }, { fetchPolicy: 'no-cache' })
        .valueChanges.pipe(
          handleGraphQlErrors(),
          map((c) => this.mapMyComponents(c)),
          take(1),
          tap((c) =>
            this.logger.logDebug(
              `[ComponentMetadataService] (getMyComponents$):Successfully Received ${c.components.length} Components.`,
            ),
          ),
        );
    } catch (error) {
      return throwError(() => error);
    }
  }

  public searchComponents$(
    projectId: uuid,
    searchString: string,
  ): Observable<ComponentInstanceVersionsDto> {
    this.logger.logDebug(
      `[ComponentMetadataService] (getMyComponents$): Requesting Components...`,
    );

    const commands = searchString.split(' ').map((s) => s.trim());
    const days = commands
      .find((s) => s.startsWith('days:'))
      ?.replace('days:', '');
    let date = new Date(2000, 1, 1);

    if (days) {
      date = moment().subtract(days, 'days').toDate();
    }

    const user =
      commands.find((s) => s.startsWith('user:'))?.replace('user:', '') ?? '';

    const versions_condition =
      user.length > 0
        ? {
            _and: [
              {
                updated_at: {
                  _gte: date,
                },
              },
              {
                worker: {
                  user: {
                    name: {
                      _ilike: `%${user}%`,
                    },
                  },
                },
              },
            ],
          }
        : {
            updated_at: {
              _gte: date,
            },
          };
    const uuid = commands.find((s) => s.length == 36) ?? Guid.EMPTY;
    const name = commands
      .filter(
        (s) => !s.startsWith('days:') && !s.startsWith('user:') && s.length > 0,
      )
      .join(' ');

    try {
      return this.searchComponentInstanceVersionQuery
        .watch({
          project_id: projectId,
          search_text: `%${name}%`,
          search_uuid: uuid,
          versions_condition:
            versions_condition as unknown as CadComponentVersionBoolExp,
          // date: date,
        })
        .valueChanges.pipe(
          handleGraphQlErrors(),
          map((c) => this.mapSearchomponents(c)),
          take(1),
          tap((c) =>
            this.logger.logDebug(
              `[ComponentMetadataService] (searchComponents$):Successfully Received ${c.components.length} Components.`,
            ),
          ),
          tap((c) => {
            if (c.instances.length == 0) {
              this.logger.logInfo(
                `No Components found.`,
              );
            }
          }),
        );
    } catch (error) {
      return throwError(() => error);
    }
  }

  //= Get Children
  public getComponentsByLeftIds$(
    ids: uuid[],
  ): Observable<ComponentInstanceVersionsDto> {
    this.logger.logDebug(
      `[ComponentMetadataService] (getComponentsByLeftIds$): Getting Components by Left Ids...`,
    );

    try {
      console.log('Getting componentsByLeftId for ' + ids.join(','));
      return this.getLatestComponentInstanceVersionsByLeftIds
        .watch({ ids })
        .valueChanges.pipe(
          handleGraphQlErrors(),
          map((c) => this.mapInstance(c)),
          take(1),
          tap((r) =>
            this.logger.logDebug(
              `[ComponentMetadataService] (getComponentsByLeftIds$): Successfully Received ${r.components.length} Components by left Id.`,
            ),
          ),
          map((r) => this.changeRowIndexesIfNecessary(r)),
        );
    } catch (error) {
      console.error('Error getting componentsByLeftId for ' + ids.join(','));
      return throwError(() => error);
    }
  }

  public getComponentsByInstanceIds$(
    ids: uuid[],
  ): Observable<ComponentInstanceVersionsDto> {
    this.logger.logDebug(
      `[ComponentMetadataService] (getComponentsByInstanceIds$): Getting Components by Instance Ids...`,
    );

    try {
      return this.getLatestComponentInstanceVersionsByInstanceIds
        .watch({ ids })
        .valueChanges.pipe(
          handleGraphQlErrors(),
          map((c) => this.mapInstance(c)),
          take(1),
          tap((c) =>
            this.logger.logDebug(
              `[ComponentMetadataService] (getComponentsByInstanceIds$):Successfully received ${c.components.length} Components by Instance Id.`,
            ),
          ),
        );
    } catch (error) {
      console.error(
        'Error getting componentsByInstanceId for ' + ids.join(','),
      );
      return throwError(() => error);
    }
  }

  public getComponentVersionsByComponentIds$(
    ids: uuid[],
  ): Observable<ComponentVersion[]> {
    this.logger.logDebug(
      `[ComponentMetadataService] (getComponentVersionsByComponentIds$): Get ComponentVersions by Ids...`,
    );

    try {
      return this.getComponentVersionsByComponentIds
        .watch({ ids })
        .valueChanges.pipe(
          handleGraphQlErrors(),
          take(1),
          map((result) => [...result.data.cad_component_version]),
          tap((r) =>
            this.logger.logDebug(
              `[ComponentMetadataService] (getComponentVersionsByComponentIds$):Successfully received ComponentVersions by Ids.`,
            ),
          ),
        );
    } catch (error) {
      return throwError(() => error);
    }
  }

  public getComponentSubversionsByComponentIds$(
    ids: uuid[],
  ): Observable<ComponentSubversion[]> {
    this.logger.logDebug(
      `[ComponentMetadataService] (getComponentSubversionsByComponentIds$): Get Subversions By Ids...`,
    );

    try {
      return this.getComponentSubversionsByComponentIds
        .watch({ ids })
        .valueChanges.pipe(
          handleGraphQlErrors(),
          take(1),
          map((result) => [...result.data.cad_component_subversion]),
          tap((r) =>
            this.logger.logDebug(
              `[ComponentMetadataService] (getComponentSubversionsByComponentIds$):Successfully Received Subversions By Ids.`,
            ),
          ),
        );
    } catch (error) {
      return throwError(() => error);
    }
  }

  public getLatestVersionIndex$(componentId: uuid) {
    this.logger.logDebug(
      `[ComponentMetadataService] (getLatestVersionIndex$): Get latest Version Indices...`,
    );

    return this.versionIndicesQuery
      .watch({ component_id: componentId })
      .valueChanges.pipe(
        handleGraphQlErrors(),
        map((c) =>
          c.data.cad_component_version
            .map((v) => v.index)
            .sort((a, b) => a - b)
            .pop(),
        ),
        take(1),
        tap(() =>
          this.logger.logDebug(
            `[ComponentMetadataService] (getLatestVersionIndex$):Successfully received latest Version Indices.`,
          ),
        ),
      );
  }

  public getAllVersionIndices$(componentId: uuid) {
    this.logger.logDebug(
      `[ComponentMetadataService] (getAllVersionIndices$): Get All Version Indices...`,
    );

    return this.versionIndicesQuery
      .watch({ component_id: componentId })
      .valueChanges.pipe(
        handleGraphQlErrors(),
        map((r) => r.data.cad_component_version.map((v) => v.index)),
        tap((r) =>
          this.logger.logDebug(
            `[ComponentMetadataService] (getAllVersionIndices$):Successfully received All Version Indices.`,
          ),
        ),
      );
  }

  public getComponentLogs$(componentId: uuid) {
    this.logger.logDebug(
      `[ComponentMetadataService] (getComponentLogs$): Get Component Logs...`,
    );

    return this.getLogsQuery
      .watch({ component_id: componentId })
      .valueChanges.pipe(
        handleGraphQlErrors(),
        map((r) => [...r.data.view_cad_component_log_version_flat]),
        tap((r) =>
          this.logger.logDebug(
            `[ComponentMetadataService] (getComponentLogs$):Successfully received Component Logs.`,
          ),
        ),
      );
  }

  /**
   * Returns an Observable Stream of uploaded components, where "md5_hash" is null, when it is not yet uploaded.
   * Can be used to track progress of an upload.
   */
  public getUploadedComponentBlobs$(
    upload_id: string,
  ): Observable<UploadedComponentBlob[]> {
    return this.getUploadComponentBlobsSubscription
      .subscribe({
        upload_id: upload_id,
      })
      .pipe(
        map((r) => [...r.data.view_upload_cad_component_blob_flat]),
        catchError((err) => {
          this.logger.logError(err.message);
          return of(<UploadedComponentBlob[]>[]);
        }),
      );
  }

  /**
   * Returns an Observable of the root components for an upload node.
   */
  public getUploadRootNodes$(upload_id: string) {
    return this.getUploadRootNodesQuery
      .watch({
        upload_id: upload_id,
      })
      .valueChanges.pipe(
        map((r) => [
          ...r.data.upload_by_pk.cad_components.map((c) => ({
            component_id: c.cad_component.id,
            component_name: c.cad_component.name,
            component_type: c.cad_component.type,
          })),
        ]),
      );
  }

  public getUploadedNodesForCurrentUser$(): Observable<UploadNode[]> {
    this.logger.logDebug(
      `[ComponentMetadataService] (getUploadedNodesForCurrentUser$): Get upload Nodes...`,
    );

    return this.getUploadNodesSubscription.subscribe().pipe(
      handleGraphQlErrors(),
      map((r) => [...r.data.view_upload_user_selected]),
      tap((r) =>
        this.logger.logDebug(
          `[ComponentMetadataService] (getUploadedNodesForCurrentUser$): Successfully received upload Nodes.`,
        ),
      ),
    );
  }

  public getParentTreeInstances$(
    instance_id: uuid,
  ): Observable<ComponentInstanceVersionsDto> {
    this.logger.logDebug(
      `[ComponentMetadataService] (getParentTreeInstances$): Get Parent Tree Instances...`,
    );

    return this.getParentTreeQuery
      .watch({ instance_id: instance_id.toString() })
      .valueChanges.pipe(
        handleGraphQlErrors(),
        map((r) => r.data),
        switchMap(this.getTreeFromQueryResult$.bind(this)),
        take(1),
        tap((r) =>
          this.logger.logDebug(
            `[ComponentMetadataService] (getParentTreeInstances$):Successfully received Parent Tree Instances.`,
          ),
        ),
      );
  }

  public getAllVariants$(component_id: string) {
    this.logger.logDebug(
      `[ComponentMetadataService] (getAllVariants$): Get All Variants...`,
    );

    return this.getVariantsQuery.watch({ component_id }).valueChanges.pipe(
      map((r) => [
        ...r.data.variant.map((v) => ({
          ...v,
          variant_component_id: v.variant_root_component_id,
          variant_component_name: v.cad_components[0].name,
          variant_component_type: v.cad_components[0].type,
        })),
      ]),
      tap((r) =>
        this.logger.logDebug(
          `[ComponentMetadataService] (getAllVariants$):Successfully received All Variants.`,
        ),
      ),
    );
  }

  public getAllDrawings$(componentId: uuid) {
    this.logger.logDebug(
      `[ComponentMetadataService] (getAllDrawings$): Get All Drawings...`,
    );

    return this.getDrawingsQuery
      .watch({ component_id: componentId })
      .valueChanges.pipe(
        handleGraphQlErrors(),
        map((r) => [...r.data.cad_drawing]),
        tap(() =>
          this.logger.logDebug(
            `[ComponentMetadataService] (getAllDrawings$):Successfully received All Drawings.`,
          ),
        ),
      );
  }

  public getAllAttachmentsByComponentIds$(
    ids: uuid[],
  ): Observable<ComponentAttachmentsDto> {
    this.logger.logDebug(
      `[ComponentMetadataService] (getAllAttachmentsByComponentIds$): Requesting Attachments by IDs...`,
    );

    try {
      return this.getAllAttachedComponentData
        .watch({ component_ids: ids })
        .valueChanges.pipe(
          handleGraphQlErrors(),
          map(this.mapAttachments),
          take(1),
          tap((c) =>
            this.logger.logDebug(
              `[ComponentMetadataService] (getAllAttachmentsByComponentIds$):Successfully Received ${c.versions.length} Components.`,
            ),
          ),
        );
    } catch (error) {
      return throwError(() => error);
    }
  }

  // Changes order of instances, if more than one rowindex is set to 0
  private changeRowIndexesIfNecessary(
    componentInstances: ComponentInstanceVersionsDto,
  ): ComponentInstanceVersionsDto {
    if (componentInstances.instances.filter((i) => i.rowindex == 0).length == 1)
      return componentInstances;

    const changes: RowChangeSet[] = [];
    for (let index = 0; index < componentInstances.instances.length; index++) {
      const instance = componentInstances.instances.sort(
        (a, b) => a.rowindex - b.rowindex,
      )[index];
      changes.push({ instance_id: instance.id, rowindex: index });
    }

    const input = changes.map((e) => <ChangeRowInput>e);

    this.changeIndexMutation
      .mutate({
        inputs: input,
      })
      .pipe(
        throwIfGQLError(),
        tap(() => {
          this.logger.logDebug(
            `[ComponentMutationService] (changeRowIndexes$): Successfully changed Order of components.`,
          );
        }),
        take(1),
      )
      .subscribe();

    return {
      ...componentInstances,
      instances: componentInstances.instances
        .map((i) => {
          const rowindex =
            changes.find((c) => c.instance_id == i.id)?.rowindex ?? i.rowindex;
          return { ...i, rowindex: rowindex };
        })
        .sort((a, b) => a.rowindex - b.rowindex),
    };
  }

  private mapComponent(
    gqlResult: GQLResult<GetLatestComponentInstanceVersionsByComponentIdsQueryResult>,
  ): ComponentInstanceVersionsDto {
    const components = gqlResult.data.cad_component;
    const latestVersions = gqlResult.data.cad_component_version;
    const latestSubVersions = gqlResult.data.cad_component_subversion;
    const instances = gqlResult.data.cad_instance;
    const releaseProcesses =
      latestVersions.flatMap((v) => v.release_processes) ?? [];

    return new ComponentInstanceVersionsDto(
      [...components],
      [...instances],
      [...latestVersions],
      [...latestSubVersions],
      [...releaseProcesses],
    );
  }

  private mapMyComponents(
    gqlResult: GQLResult<GetMyComponentInstanceVersionsQueryResult>,
  ): ComponentInstanceVersionsDto {
    const resultComponents =
      gqlResult.data.genet_user_settings[0].user.working_on.map(
        (c) => c.cad_component,
      );

    const componentVersions = resultComponents.map((c) =>
      this.mapComponentResult(c),
    );

    return new ComponentInstanceVersionsDto(
      [...componentVersions.map((c) => c.components).flat()],
      [...componentVersions.map((c) => c.instances).flat()],
      [...componentVersions.map((c) => c.versions).flat()],
      [...componentVersions.map((c) => c.subversions).flat()],
      [],
    );
  }

  private mapSearchomponents(
    gqlResult: GQLResult<SearchComponentInstanceVersionQueryResult>,
  ): ComponentInstanceVersionsDto {
    const resultComponents = gqlResult.data.cad_component;

    const componentVersions = resultComponents.map((c) =>
      this.mapComponentResult(c),
    );

    return new ComponentInstanceVersionsDto(
      [...componentVersions.map((c) => c.components).flat()],
      [...componentVersions.map((c) => c.instances).flat()],
      [...componentVersions.map((c) => c.versions).flat()],
      [...componentVersions.map((c) => c.subversions).flat()],
      [],
    );
  }

  private mapComponentResult(component: any): ComponentInstanceVersionsDto {
    try {
      const i = 1;

      //Destructuring operator https://basarat.gitbook.io/typescript/future-javascript/destructuring
      const {
        versions: versions,
        child_instances: instances,
        ...comp
      } = component;

      const version = versions[0];

      const { subversions: subversions, ..._ } = version;

      return new ComponentInstanceVersionsDto(
        [comp],
        [...instances],
        [...versions],
        [...subversions],
        [],
      );
    } catch (error) {
      console.error(error);
      return new ComponentInstanceVersionsDto([], [], [], [], []);
    }
  }

  private mapAttachments(
    gqlResult: GQLResult<GetAllAttachedComponentDataQueryResult>,
  ): ComponentAttachmentsDto {
    const versions = gqlResult.data.cad_component_version;
    const subVersions = gqlResult.data.cad_component_subversion;
    const drawings = gqlResult.data.cad_drawing;
    const tasks = gqlResult.data.relations_cad_component_of_task.map(
      (r) => r.task,
    );
    const files = gqlResult.data.relations_file_of_component.map((r) => r.file);
    const variants = gqlResult.data.variant;

    return new ComponentAttachmentsDto(
      [...versions],
      [...subVersions],
      [...drawings],
      [...files],
      [...tasks],
      [...variants],
    );
  }

  private mapInstance(
    gqlResult: GQLResult<GetLatestComponentInstanceVersionsByLeftIdsQueryResult>,
  ): ComponentInstanceVersionsDto {
    const instances = from(gqlResult.data.cad_instance);
    const components = instances.select((i) => i.right_component);
    const componentInstances = components.selectMany((i) => i.cad_instances);

    const allInstances = instances
      .select((i) => {
        const { right_component: _, ...instance } = i;
        return instance;
      })
      .concatenate(componentInstances)
      .distinct((x, y) => x.id == y.id)
      .toArray();

    const latestVersions = components.selectMany((c) => c.versions);
    const latestSubVersions = latestVersions.selectMany((v) => v.subversions);
    const releaseProcesses = latestVersions.selectMany(
      (v) => v.release_processes,
    );

    return new ComponentInstanceVersionsDto(
      [...components.toArray()],
      [...allInstances],
      [...latestVersions.toArray()],
      [...latestSubVersions.toArray()],
      [...(releaseProcesses?.toArray() ?? [])],
    );
  }

  private mapRootComponentInstance(
    gqlResult: GQLResult<GetRootComponentInstanceVersionQueryResult>,
  ): ComponentInstanceVersionsDto {
    const resultComponent = from(
      gqlResult.data.variant[0].cad_components,
    ).first();

    //Destructuring operator https://basarat.gitbook.io/typescript/future-javascript/destructuring
    const {
      versions: versions,
      child_instances: instances,
      ...component
    } = resultComponent;

    const { subversions: subversions, ..._ } = from(versions).first();

    return new ComponentInstanceVersionsDto(
      [component],
      [...instances],
      [...versions],
      [...subversions],
      [],
    );
  }

  private getTreeFromQueryResult$(
    queryResult: RecursiveGetLeftComponentsTenLevelsQueryResult,
  ) {
    const parentsChildrenMap = new Map<uuid, uuid>();

    if (queryResult.cad_instance_by_pk == null) return EMPTY;

    if (
      queryResult.cad_instance_by_pk?.left_component?.cad_instances?.length > 0
    ) {
      queryResult.cad_instance_by_pk.left_component.cad_instances.forEach(
        (instance) =>
          this.addParentsChildrenRecursive(
            parentsChildrenMap,
            instance,
            queryResult.cad_instance_by_pk.id,
          ),
      );
    }

    const allInstanceIds = getMapItems(false).concat(getMapItems(true));

    return this.getComponentsByInstanceIds$(allInstanceIds);

    function getMapItems(values: boolean): uuid[] {
      const returnKeys = [];
      const keys = values
        ? parentsChildrenMap.values()
        : parentsChildrenMap.keys();
      let currentKey = keys.next();
      do {
        returnKeys.push(currentKey.value);
        currentKey = keys.next();
      } while (!currentKey.done);
      return returnKeys;
    }
  }

  private addParentsChildrenRecursive(
    parentsChildrenMap: Map<uuid, uuid>,
    parentInstance: Cad_Instance,
    childInstanceId: uuid,
  ) {
    if (!parentInstance) {
      return parentsChildrenMap;
    }

    if (!parentsChildrenMap.has(parentInstance.id)) {
      parentsChildrenMap.set(parentInstance.id, childInstanceId);
    }

    if (parentInstance.left_component) {
      parentInstance.left_component.cad_instances.forEach((i) =>
        this.addParentsChildrenRecursive(
          parentsChildrenMap,
          i,
          parentInstance.id,
        ),
      );
    }

    return parentsChildrenMap;
  }
}

export interface Cad_Instance {
  readonly id: uuid;
  readonly left_component?: Cad_Component;
}

export interface Cad_Component {
  readonly cad_instances: readonly Cad_Instance[];
}
