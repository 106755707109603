import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';

import { SettingsService } from '../../services';
import {
  LoadSettings,
  LoadSettingsFailure,
  LoadSettingsSuccess,
  SetCurrentLocation,
  SetCurrentLocationFailure,
  SetCurrentLocationSuccess,
  SetCurrentProject,
  SetCurrentProjectFailure,
  SetCurrentProjectSuccess,
} from './actions';

@Injectable()
export class SettingsStoreEffects {
  constructor(
    private actions$: Actions,
    private settingsService: SettingsService
  ) {}

  loadSettingsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSettings),
      concatMap(() =>
        this.settingsService.getSettings$.pipe(
          map((settings) =>
            LoadSettingsSuccess({
              settings: settings,
            })
          ),
          catchError((error) => of(LoadSettingsFailure({ error })))
        )
      )
    )
  );

  setLocationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetCurrentLocation),
      concatMap((request) =>
        this.settingsService.setCurrentLocation$(request.location).pipe(
          map(() =>
            SetCurrentLocationSuccess({
              location: request.location,
            })
          ),
          catchError((error) => {
            return of(
              SetCurrentLocationFailure({
                error,
                previousLocation: request.previousLocation,
              })
            );
          })
        )
      )
    )
  );

  setProjectEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetCurrentProject),
      concatMap((request) =>
        this.settingsService.setCurrentProject$(request.projectId).pipe(
          map(() =>
            SetCurrentProjectSuccess({
              projectId: request.projectId,
            })
          ),
          catchError((error) => {
            return of(
              SetCurrentProjectFailure({
                error,
                previousProjectId: request.previousProjectId,
              })
            );
          })
        )
      )
    )
  );
}
