import { Injectable } from '@angular/core';
import {
	FileSystemAPIClient,
	FileSystemObjectMessage,
	GetMd5RequestMessage,
	GetMd5ResponseMessage,
	OpenDirectoryMessage,
	OpenDirectoryResponseMessage,
	OpenFileExplorerMessage,
	OpenFileExplorerResponseMessage,
} from '@genetsystems/genet-pdm-messaging-model';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class FileSystemAPIService {
	constructor(private fileSystemAPI: FileSystemAPIClient) {}

	/**
	 * Returns all file system objects under the requested path on the connected grpc service.
	 * @param path
	 */
	public openFileExplorer$(
		useDirectoryExplorer: boolean = false,
	): Observable<FileSystemObjectMessage[]> {
		return new Observable((observer) => {
			try {
				const request = new OpenFileExplorerMessage();
				request.setDirectoryexplorer(useDirectoryExplorer);

				this.fileSystemAPI.openFileExplorer(request, (error, response) => {
					if (error) {
						observer.error(
							'ERROR: Could not connect to your local machine. Please make sure your GenetPDM local API is up and running.',
						);
						return;
					}
					if (
						response.getResultCase() ==
						OpenFileExplorerResponseMessage.ResultCase.ERROR
					) {
						throw new Error(response.getError().getMessage());
					}

					observer.next(response.getOk().getFilesystemobjectList());
					observer.complete();
				});
			} catch (error) {
				observer.error(error.message);
			}
		});
	}

	/**
	 * Opens a directory in Windows File Explorer.
	 * @param path
	 */
	public openDirectory$(directory: string): Observable<boolean> {
		return new Observable((observer) => {
			try {
				const request = new OpenDirectoryMessage();
				request.setDirectorypath(directory);

				this.fileSystemAPI.openDirectoy(request, (error, response) => {
					if (error) {
						observer.error(
							'ERROR: Could not connect to your local machine. Please make sure your GenetPDM local API is up and running.',
						);
						return;
					}
					if (
						response.getResultCase() ==
						OpenDirectoryResponseMessage.ResultCase.ERROR
					) {
						throw new Error(response.getError().getMessage());
					}

					observer.next(true);
					observer.complete();
				});
			} catch (error) {
				observer.error(error.message);
			}
		});
	}

	public getMd5Hash$(path: string): Observable<string> {
		return new Observable((observer) => {
			try {
				const request = new GetMd5RequestMessage();
				request.setFilepath(path);

				this.fileSystemAPI.getMd5Hash(request, (error, response) => {
					if (error) {
						observer.error(
							'ERROR: Could not connect to your local machine. Please make sure your GenetPDM local API is up and running.',
						);
						return;
					}
					if (
						response.getResultCase() == GetMd5ResponseMessage.ResultCase.ERROR
					) {
						throw new Error(response.getError().getMessage());
					}

					observer.next(response.getOk().getMd5());
					observer.complete();
				});
			} catch (error) {
				observer.error(error.message);
			}
		});
	}
}
