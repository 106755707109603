import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'name-dialog',
  template: `
    <div class="card">
      <div class="card-header">
        <h3>Please provide a name</h3>
      </div>
      <div class="card-body">
        <form>
          <div class="form-group" style="margin-top: 15px;">
            <span class="p-float-label">
              <input
                type="text"
                pInputText
                id="name"
                [(ngModel)]="name"
                [ngModelOptions]="{ standalone: true }"
                style="width: 100%"
              />
              <label for="name">Name</label>
            </span>
          </div>
          <hr />
          <div class="form-group">
            <p-button
              label="Submit"
              (onClick)="handleSubmit($event)"
              icon="fas fa-check"
              iconPos="left"
              *ngIf="enableAction"
            ></p-button>
            <p-button
              label="Cancel"
              (onClick)="handleCancel($event)"
              icon="fas fa-times"
              iconPos="left"
              [style]="{ 'margin-left': '10px' }"
            ></p-button>
          </div>
        </form>
      </div>
    </div>
  `,
})
export class NameDialogComponent {
  name: string;

  get enableAction(): boolean {
    return this.name.length > 1;
  }

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  async handleSubmit(event) {
    this.ref.close(name);
  }

  handleCancel(event) {
    this.ref.close(null);
  }
}
