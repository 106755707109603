import { Injectable } from '@angular/core';
import {
  Component,
  ComponentInstance,
  ComponentInstanceVersion,
  ComponentVersion,
  RowChangeSet,
} from '@genetpdm/model';
import {
  ChangeRowIndexActionGQL,
  ChangeRowInput,
  DeleteComponentInstanceActionGQL,
  DeleteInstancePositionMutationGQL,
  DuplicateInstanceActionGQL,
  DuplicateInstanceInput,
  IntegrateUploadComponentInput,
  IntegrateUploadComponentsActionGQL,
  UpdateCadComponentGQL,
  UpdateCadComponentVersionGQL,
  UpdateCadInstanceGQL,
  UpdateInstanceLeftIdMutationGQL,
} from '@genetpdm/model/graphql';
import { Update } from '@ngrx/entity';
import {
  throwError,
  combineLatest,
  mergeAll,
  take,
  tap,
  OperatorFunction,
} from 'rxjs';
import { RequiredPartial } from '../utility/required-type';
import { handleGraphQlErrors } from '../utility/graphql-utilities';
import { Guid } from 'guid-typescript';
import { LoggingService } from '../logging-service/logging.service';
import { throwIfGQLError } from '../../utils/graphql-result-error-interceptor/graphql-result-error.interceptor';

@Injectable({
  providedIn: 'root',
})
export class ComponentMutationService {
  constructor(
    private logger: LoggingService,
    private updateCadComponent: UpdateCadComponentGQL,
    private updateCadComponentVersion: UpdateCadComponentVersionGQL,
    private changeLeftMutation: UpdateInstanceLeftIdMutationGQL,
    private changeIndexMutation: ChangeRowIndexActionGQL,
    private deleteComponentInstanceAction: DeleteComponentInstanceActionGQL,
    private duplicateInstanceAction: DuplicateInstanceActionGQL,
    private updateCadInstance: UpdateCadInstanceGQL,
    private integrateUploadComponentsMutation: IntegrateUploadComponentsActionGQL,
    private deleteInstancePositionMutation: DeleteInstancePositionMutationGQL
  ) {}

  public changeRowIndexes$(changes: RowChangeSet[]) {
    this.logger.logDebug(
      `[ComponentMutationService] (changeRowIndexes$): Changing Order of components...`
    );

    const input = changes.map((e) => <ChangeRowInput>e);

    return this.changeIndexMutation
      .mutate({
        inputs: input,
      })
      .pipe(
        throwIfGQLError(),
        tap((r) => {
          this.logger.logDebug(
            `[ComponentMutationService] (changeRowIndexes$): Successfully changed Order of components.`
          );
        }),
        take(1)
      );
  }

  public updateComponent$(component: Update<Component>) {
    this.logger.logDebug(
      `[ComponentMutationService] (updateComponent$): Updating Component...`
    );

    try {
      return this.updateCadComponent
        .mutate({
          id: component.id as string,
          component: <RequiredPartial<Component, 'id'>>component.changes,
        })
        .pipe(
          handleGraphQlErrors(),
          tap((r) => {
            this.logger.logDebug(
              `[ComponentMutationService] (updateComponent$): Successfully Updated Component.`
            );
          }),

          take(1)
        );
    } catch (error) {
      return throwError(() => error);
    }
  }

  /**
   * Changes the leftID of the instance to the new Parent ID.
   * @param instanceID
   * @param newParentComponentID
   */
  public changeParent$(instanceID: Guid, newParentComponentID: Guid) {
    this.logger.logDebug(
      `[ComponentMutationService] (changeParent$): Execute Change Parent Mutation: instance: ${instanceID}, parent: ${newParentComponentID}...`
    );

    return this.changeLeftMutation
      .mutate({
        instance_id: instanceID.toString(),
        new_left_id: newParentComponentID.toString(),
      })
      .pipe(
        throwIfGQLError(),
        tap((_) => {
          this.logger.logDebug(
            `[ComponentMutationService] (changeParent$): Change Parent Mutation successful: instance: ${instanceID}, parent: ${newParentComponentID}`
          );
        }),

      );
  }

  public updateComponentVersions$(components: Update<ComponentVersion>[]) {
    this.logger.logDebug(
      `[ComponentMutationService] (updateComponentVersions$): Updating ComponentVersions...`
    );

    try {
      return combineLatest(
        components
          .map(
            (u) => <RequiredPartial<ComponentVersion, 'component_id'>>u.changes
          )
          .map((c) =>
            this.updateCadComponentVersion.mutate({
              id: c.component_id,
              version: c.index,
              component: c,
            })
          )
      ).pipe(
        mergeAll(),
        handleGraphQlErrors(),
        tap((r) => {
          this.logger.logDebug(
            `[ComponentMutationService] (updateComponentVersions$): Successfully Updated ComponentVersions.`
          );
        }),

        take(1)
      );
    } catch (error) {
      return throwError(() => error);
    }
  }

  public deleteComponentInstances$(components: ComponentInstanceVersion[]) {
    this.logger.logDebug(
      `[ComponentMutationService] (deleteComponentInstances$): Deleting Component Instances...`
    );

    try {
      return combineLatest(
        components
          .map((c) => ({
            component_id: c.component.id,
            instance_id: c.instance.id,
          }))
          .map((c) =>
            this.deleteComponentInstanceAction.mutate({
              component_id: c.component_id,
              instance_id: c.instance_id,
            })
          )
      ).pipe(
        mergeAll(),
        handleGraphQlErrors(),
        tap((r) => {
          this.logger.logDebug(
            `[ComponentMutationService] (deleteComponentInstances$): Successfully Deleted ComponentInstances.`
          );
        }),

        take(1)
      );
    } catch (error) {
      return throwError(() => error);
    }
  }

  public duplicateComponentInstances$(inputs: DuplicateInstanceInput[]) {
    this.logger.logDebug(
      `[ComponentMutationService] (duplicateComponentInstances$): Duplicating Instances...`
    );

    try {
      return combineLatest(
        inputs.map((input) => this.duplicateInstanceAction.mutate({ input }))
      ).pipe(
        mergeAll(),
        handleGraphQlErrors(),
        tap((r) => {
          this.logger.logDebug(
            `[ComponentMutationService] (duplicateComponentInstances$): Successfully Duplicated ComponentInstances.`
          );
        }),

        take(1)
      );
    } catch (error) {
      return throwError(() => error);
    }
  }

  public updateComponentInstances$(instances: Update<ComponentInstance>[]) {
    this.logger.logDebug(
      `[ComponentMutationService] (updateComponentInstances$): Updating ComponentInstances...`
    );

    try {
      return combineLatest(
        instances
          .map((u) => <RequiredPartial<ComponentInstance, 'id'>>u.changes)
          .map((i) =>
            this.updateCadInstance.mutate({
              id: i.id,
              instance: i,
            })
          )
      ).pipe(
        mergeAll(),
        handleGraphQlErrors(),
        tap((r) => {
          this.logger.logDebug(
            `[ComponentMutationService] (updateComponentInstances$): Successfully updated ComponentInstances.`
          );
        }),

        take(1)
      );
    } catch (error) {
      return throwError(() => error);
    }
  }

  public deletePosition$(instanceId: Guid) {
    this.logger.logDebug(
      `[ComponentMutationService] (deletePosition$): Removing Positions...`
    );

    return this.deleteInstancePositionMutation
      .mutate({ instance_id: instanceId.toString() })
      .pipe(
        throwIfGQLError(),
        tap((r) => {
          this.logger.logInfo(
            `[ComponentMutationService] (deletePosition$): Successfully removed position from: ${instanceId}`
          );
        }),
      );
  }

  public integrateUploadComponent$(
    input: IntegrateUploadComponentInput,
    uploadId: string
  ) {
    this.logger.logDebug(
      `[ComponentMutationService] (integrateUploadComponent$): Integrating Components from Upload...`
    );

    return this.integrateUploadComponentsMutation
      .mutate({ upload_id: uploadId, content: input })
      .pipe(
        handleGraphQlErrors(),
        tap((r) => {
          this.logger.logDebug(
            `[ComponentMutationService] (integrateUploadComponent$): Successfully Integrated Components.`
          );
        }),
        take(1)
      );
  }
}
