import {
  Component,
  ComponentInstance,
  ComponentSubversion,
  ComponentVersion,
  Project,
  ProjectConfiguration,
  ReleaseProcess,
} from '@genetpdm/model';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

/**
 * Define an Adapter for the EntityState for 'Component'.
 *
 *@remarks
 * An Adapter provides several useful methods to interact with the state like:
 * - getInitialState: Gets an empty state object
 * - addOne: add one entity to the collection
 * - addMany: add several entities
 * - addAll: replaces the whole collection with a new one
 * - removeOne: remove one entity
 * - removeMany: removes several entities
 * - removeAll: clear the whole collection
 * - updateOne: Update one existing entity
 * - updateMany: Update multiple existing entities
 * - upsertOne: Update or Insert one entity
 * - upsertMany: Update or Insert multiple entities
 */
export const ComponentAdapter: EntityAdapter<Component> =
  createEntityAdapter<Component>({
    selectId: (c) => c.id,
    sortComparer: false,
  });

export const ComponentInstanceAdapter: EntityAdapter<ComponentInstance> =
  createEntityAdapter<ComponentInstance>({
    selectId: (c) => c.id,
    sortComparer: false,
  });

export const ComponentVersionAdapter: EntityAdapter<ComponentVersion> =
  createEntityAdapter<ComponentVersion>({
    selectId: (c) => getComponentVersionId(c),
    sortComparer: false,
  });

export const ComponentSubversionAdapter: EntityAdapter<ComponentSubversion> =
  createEntityAdapter<ComponentSubversion>({
    selectId: (c) => getComponentSubversionId(c),
    sortComparer: false,
  });

  export const ProjectAdapter: EntityAdapter<Project> =
  createEntityAdapter<Project>({
    selectId: (c) => c.id,
    sortComparer: false,
  });

export const ProjectConfigurationAdapter: EntityAdapter<ProjectConfiguration> =
  createEntityAdapter<ProjectConfiguration>({
    selectId: (c) => c.id,
    sortComparer: false,
  });

export const ReleaseProcessAdapter: EntityAdapter<ReleaseProcess> =
  createEntityAdapter<ReleaseProcess>({
    selectId: (c) => c.id,
    sortComparer: false,
  });

export function getComponentVersionId(
  componentVersion: ComponentVersion
): string {
  const c = componentVersion;
  return `${c.component_id}-${c.index}`;
}

export function getComponentSubversionId(
  componentSubversion: ComponentSubversion
): string {
  const c = componentSubversion;
  return `${c.component_id}-${c.component_version}-${c.index}`;
}
