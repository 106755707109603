import { Injectable, ErrorHandler } from '@angular/core';
import { LoggingService } from '../../logging-service/logging.service';

@Injectable({
  providedIn: 'root',
})
export class ToastErrorHandler implements ErrorHandler {
  constructor(private logger: LoggingService) {}

  handleError(err: any): void {
    this.logger.logFailed(err.toString());
  }
}
