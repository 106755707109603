<div class="card">
  <div class="card-body">
    <p-tree
      [value]="nodes"
      [loading]="loading"
      selectionMode="single"
      [(selection)]="selectedNode"
    >
    </p-tree>
  </div>
  <br />
  <div class="card-footer">
    <form class="form-inline">
      <div>
        <button
          pButton
          type="button"
          label="Refresh"
          (click)="handleClickRefresh($event)"
          style="width: auto; margin-right: 21px"
        ></button>
        <button
          pButton
          type="button"
          label="Accept"
          (click)="handleClickAccept($event)"
          style="width: auto; margin-right: 21px"
        ></button>
        <button
          pButton
          type="button"
          label="Cancel"
          (click)="handleClickCancel($event)"
        ></button>
      </div>
    </form>
  </div>
</div>
