import { Injectable } from '@angular/core';
import { TreeNode } from './upload-node-fetcher.model';
import { LoggingService } from '../../../@core/services/logging-service/logging.service';
import { Store } from '@ngrx/store';
import {
  LoadUploads,
  selectUserUploads,
} from '../../../@core/state/upload/upload.command';

@Injectable({
  providedIn: 'root',
})
export class UploadNodeFetchService {
  public loading: boolean;

  public nodes: TreeNode[];

  constructor(
    private store: Store,
    private logger: LoggingService,
  ) {
    this.store.select(selectUserUploads).subscribe((nodes) => {
      this.nodes = nodes
        .sort(
          (a, b) =>
            Date.parse(b.date.toString()) - Date.parse(a.date.toString()),
        )
        .map((n) => new TreeNode(n));
      this.loading = false;
    });

    this.logger.logTrace(`UploadNodeSubscriberService Initialized.`);
  }

  // '2022-08-08T15:03:00.57932+00:00'

  public loadNodes() {
    this.loading = true;
    this.store.dispatch(LoadUploads());
  }
}
