import { Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Observable, OperatorFunction, concatMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PromptService {

  constructor(private confirmationService: ConfirmationService,){}

  public yesNoPromptPipe<T>(
    messageSelector: (t: T) => string
  ): OperatorFunction<T, T> {
    return (t: Observable<T>) => this.openPrompt(t, messageSelector);
  }

  private openPrompt<T>(
    source$: Observable<T>,
    messageSelector: (t: T) => string
  ): Observable<T> {
    return source$.pipe(
      concatMap((source) => {
        return new Observable<T>((observer) => {
          this.confirmationService.confirm({
            message: messageSelector(source),
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              observer.next(source);
              observer.complete();
            },
            reject: () => {
              observer.complete();
            }
          });
        });
      })
    );
  }
}
