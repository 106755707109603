import { createSelector } from '@ngrx/store';
import { User, UserSettings } from '@genetpdm/model';
import { selectAppState } from '../@app-state/app.selectors';
import { AppState } from '../@app-state/app.state';

export const selectSettings = createSelector(
  selectAppState,
  (state: AppState): UserSettings => state.settings,
);

export const selectCurrentUserId = createSelector(
  selectSettings,
  (state: UserSettings): uuid => state.user_id,
);

export const selectCurrentUser = createSelector(
  selectSettings,
  (state: UserSettings): User => state.user,
);

export const selectCurrentProjectId = createSelector(
  selectSettings,
  (state: UserSettings): uuid => state.current_project_id,
);
