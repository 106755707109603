import { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import { MutationResult, SubscriptionResult } from 'apollo-angular';
import { Observable, OperatorFunction, of, throwError } from 'rxjs';
import { concatMap } from 'rxjs/operators';

export type GQLResult<T> =
  | ApolloQueryResult<T>
  | SubscriptionResult<T>
  | FetchResult<T>
  | MutationResult<T>;

export function handleGraphQlErrors<T>(): OperatorFunction<
  GQLResult<T>,
  GQLResult<T>
> {
  return _throwOrPassGQLError;
}

function _throwOrPassGQLError<T>(
  source$: Observable<GQLResult<T>>
): Observable<GQLResult<T>> {
  return source$.pipe(
    concatMap((result) => {
      if (!result) {
        return throwError(
          () => new Error('Connection to Hasura had an error.')
        );
      }
      if (result.errors) {
        return throwError(
          () =>
            new Error(
              GetUnauthorizedMessageOrElse(
                result.errors.map((e) => e.message).join('\n')
              )
            )
        );
      }
      return of(result);
    })
  );
}

function GetUnauthorizedMessageOrElse(message: string) {
  if (
    message.includes('not found in type') ||
    message.includes('no such type exists in the schema') ||
    message.includes('authori') // TODO: Check real string
  ) {
    return 'User Not Authorized for Query or Mutation!';
  }
  return message;
}
