import { Injectable } from '@angular/core';
import { Subscription, Subject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { CatiaService } from './catia.service';
import { LoggingService } from '../logging-service/logging.service';

@Injectable({
  providedIn: 'root',
})
export class CatiaAPIPingService {
  private subscription: Subscription;

  private connected: boolean;

  private _connected$ = new Subject<boolean>();
  public connected$: Observable<boolean>;

  constructor(
    private catiaService: CatiaService,
    private loggingService: LoggingService,
  ) {
    this.connected$ = this._connected$.asObservable().pipe(shareReplay(1));
  }

  public subscribeToPing() {
    if (this.subscription) return this.subscription;

    this.subscription = this.catiaService.ping$(30, 2).subscribe((result) => {
      if (result.success && !this.connected) {
        this.connected = true;
        this._connected$.next(true);
        this.loggingService.logSuccess(
          'Successfully connected to local Catia Service!'
        );
      }
      if (!result.success) {
        if (this.connected) this._connected$.next(false);
        this.connected = false;
        this.loggingService.logWarn(
          'Local Catia Service not running. Please install and start the Genet PDM CatiaAPI!'
        );
      }
    });

    return this.subscription;
  }
}
