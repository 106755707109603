import { ComponentInstanceVersion, Session } from '@genetpdm/model';
import { CadGetInstancePositionsCompletedMessage } from '@genetsystems/genet-pdm-messaging-model';
import { createAction, props } from '@ngrx/store';

export enum SessionActionTypes {
  LoadSessions = '[Component] Load Sessions',
  LoadSessionsSucceeded = '[Backend] Load Sessions Succeeded',
  LoadSessionsFailed = '[Backend] Load Sessions Failed',
  OpenCreateSessionDialog = '[Component] OpenCreateSessionDialog',
  CreateSession = '[Component] Create Sessions',
  CreateSessionSucceeded = '[Backend] Create Sessions Succeeded',
  CreateSessionFailed = '[Backend] Create Sessions Failed',
  DeleteSession = '[Component] Delete Session',
  DeleteSessionSucceeded = '[Backend] Delete Session Succeeded',
  DeleteSessionFailed = '[Backend] Delete Session Failed',

  SelectSession = '[Component] Select Session',

  AddToSession = '[Component] AddToSession',
  RemoveFromSession = '[Component] RemoveFromSession',
  AddToSessionFailed = '[Component] AddToSessionFailed',
  RemoveFromSessionFailed = '[Component] RemoveFromSessionFailed',

  ExpandSessionInstance = '[Component] ExpandSessionInstance',
  CollapseSessionInstance = '[Component] CollapseSessionInstance',

  PublishSession = '[Component] Publish Sessions',
  UnPublishSession = '[Component] UnPublish Sessions',
  FreezeSession = '[Component] Freeze Sessions',
  UnFreezeSession = '[Component] UnFreeze Sessions',
  RefreshSessions = '[Component] Refresh Sessions',
  OpenRenameSessionDialog = '[Component] Open Rename Sessions',
  RenameSession = '[Component] Rename Sessions',
  ClearSession = '[Component] Clear Session',
  ClearSessions = '[Component] Clear Sessions',

  OpenLastSession = '[Component] OpenLastSession',
  GenerateSession = '[Component] Generate Session',
  AdaptSession = '[Component] Adapt Session',
  ExtractPositionsFromSession = '[Component] Extract Positions Session',
  SetPositions = '[Effect] Set Positions',
  NavigateToPart = '[Component] NavigateToPart',
}

//#region [Load]

/**
 * Request to load all components with specified ids from backend.
 */
export const LoadSessions = createAction(SessionActionTypes.LoadSessions);

/**
 * Backend returns components.
 */
export const LoadSessionsSuceeded = createAction(
  SessionActionTypes.LoadSessionsSucceeded,
  props<{ sessions: Session[] }>(),
);

export const SelectSession = createAction(
  SessionActionTypes.SelectSession,
  props<{ sessionId: uuid }>(),
);

/**
 * Backend returns error.
 * Failed to load components with specified ids.
 *
 * @remarks
 * Use the ids to reset the components in the state.
 */
export const LoadSessionsFailed = createAction(
  SessionActionTypes.LoadSessionsFailed,
  props<{ error: Error; projectId: uuid }>(),
);

//#endregion

//#region [Refresh]

/**
 * Request refreshing all components with specified ids.
 */
export const RefreshSessions = createAction(
  SessionActionTypes.RefreshSessions,
  props<{ sessionId: uuid }>(),
);

//#endregion

//#region [Add]

/**
 * Create session.
 */
export const OpenCreateSessionDialog = createAction(
  SessionActionTypes.OpenCreateSessionDialog,
);

export const CreateSession = createAction(
  SessionActionTypes.CreateSession,
  props<{ session: Session }>(),
);

/**
 * Create succeeded.
 */
export const CreateSessionSucceeded = createAction(
  SessionActionTypes.CreateSessionSucceeded,
  props<{ session: Session }>(),
);

/**
 * Create failed.
 */
export const CreateSessionFailed = createAction(
  SessionActionTypes.CreateSessionFailed,
  props<{ error: Error; sessionId: uuid }>(),
);

//#endregion

//#region [Delete]

/**
 * Request the backend to delete all specified components.
 *
 * @remarks
 * We add whole components to the payload, since we can recover the state in case of failure.
 */
export const DeleteSession = createAction(
  SessionActionTypes.DeleteSession,
  props<{ session: Session }>(),
);

/**
 * Deletion Succeeded
 */
export const DeleteSessionSucceeded = createAction(
  SessionActionTypes.DeleteSessionSucceeded,
);

/**
 * Deletion failed.
 *
 * @remarks
 * Recover the state with the payload.
 */
export const DeleteSessionFailed = createAction(
  SessionActionTypes.DeleteSessionFailed,
  props<{ error: Error; session: Session }>(),
);

//#endregion

export const AddToSession = createAction(
  SessionActionTypes.AddToSession,
  props<{ sessionId: uuid; component: ComponentInstanceVersion }>(),
);

export const RemoveFromSession = createAction(
  SessionActionTypes.RemoveFromSession,
  props<{ sessionId: uuid; component: ComponentInstanceVersion }>(),
);

export const AddToSessionFailed = createAction(
  SessionActionTypes.AddToSessionFailed,
  props<{ sessionId: uuid; componentId: uuid; error: Error }>(),
);

export const RemoveFromSessionFailed = createAction(
  SessionActionTypes.RemoveFromSessionFailed,
  props<{
    sessionId: uuid;
    component: ComponentInstanceVersion;
    error: Error;
  }>(),
);

export const ExpandSessionInstance = createAction(
  SessionActionTypes.ExpandSessionInstance,
  props<{ componentId: uuid }>(),
);

export const CollapseSessionInstance = createAction(
  SessionActionTypes.CollapseSessionInstance,
  props<{ componentId: uuid }>(),
);

export const PublishSession = createAction(
  SessionActionTypes.PublishSession,
  props<{ session: Session }>(),
);

export const UnPublishSession = createAction(
  SessionActionTypes.UnPublishSession,
  props<{ session: Session }>(),
);

export const FreezeSession = createAction(
  SessionActionTypes.FreezeSession,
  props<{ session: Session }>(),
);

export const UnFreezeSession = createAction(
  SessionActionTypes.UnFreezeSession,
  props<{ session: Session }>(),
);

export const OpenRenameSessionDialog = createAction(
  SessionActionTypes.OpenRenameSessionDialog,
  props<{ session: Session }>(),
);

export const RenameSession = createAction(
  SessionActionTypes.RenameSession,
  props<{ session: Session; newName: string }>(),
);

export const ClearSession = createAction(
  SessionActionTypes.ClearSession,
  props<{ session: Session }>(),
);

export const OpenLastSession = createAction(
  SessionActionTypes.OpenLastSession,
  props<{ session: Session }>(),
);

export enum SessionGenerationType {
  ComponentBased,
  ProductBased,
  ComponentBasedNoPosition,
  ProductBasedNoPosition,
}

export const GenerateSession = createAction(
  SessionActionTypes.GenerateSession,
  props<{ session: Session; generationType: SessionGenerationType }>(),
);

export const AdaptSession = createAction(
  SessionActionTypes.AdaptSession,
  props<{ session: Session; generationType: SessionGenerationType }>(),
);

export const ExtractPositionsFromSession = createAction(
  SessionActionTypes.ExtractPositionsFromSession,
);

export const SetPositions = createAction(
  SessionActionTypes.SetPositions,
  props<{ positions: CadGetInstancePositionsCompletedMessage }>(),
);

export const NavigateToPart = createAction(
  SessionActionTypes.NavigateToPart,
  props<{ instanceId: uuid }>(),
);
