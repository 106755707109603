import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService,
} from '@azure/msal-angular';
import {
  GetMsalGuardConfig,
  GetMsalInstance,
  GetMsalInterceptorConfig,
} from './auth-service.provider';

export const MSAL_PROVIDERS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true,
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: GetMsalInstance,
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: GetMsalGuardConfig,
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: GetMsalInterceptorConfig,
  },
  MsalService,
  MsalGuard,
  MsalBroadcastService,
];

@NgModule({
  imports: [CommonModule],
  providers: [],
})
export class AuthModule {
  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    throwIfAlreadyLoaded(parentModule, 'AuthModule');
  }

  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [...MSAL_PROVIDERS],
    };
  }
}

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import Core modules in the AppModule only.`
    );
  }
}
