import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActionsService } from '../../../@core/services';
import { Store } from '@ngrx/store';
import { selectCurrentProject } from '../../../@core/state';

@Component({
  selector: 'export-reviewed-components-dialog',
  template: `
    <div class="card">
      <div class="card-header">
        <h3>Export all Reviewed Components</h3>
      </div>
      <div class="card-body">
        <p>
          Click on 'Submit' to export all components with the state 'Reviewed'.
        </p>
        <p>
          Components will be exported to the Folder 'Export/(Date)_(Name)'. When
          a Product was reviewed, all of its sub-parts and -products are also
          assumed reviewed.
        </p>
        <p>
          After a successful export, the components will be updated to a new
          version.
        </p>
        <hr />
        <div class="form-group" style="margin-top: 25px;">
          <span class="p-float-label">
            <input
              type="text"
              pInputText
              id="name"
              [(ngModel)]="exportName"
              [ngModelOptions]="{ standalone: true }"
              style="width: 100%"
            />
            <label for="name">Export Folder Name (without date)</label>
          </span>
        </div>
        <div class="form-group" style="margin-top: 10px">
          <p-button
            label="Submit"
            (onClick)="handleSubmit($event)"
            icon="fas fa-upload"
            iconPos="left"
          ></p-button>
          <p-button
            label="Cancel"
            (onClick)="handleCancel($event)"
            icon="fas fa-times"
            iconPos="left"
            [style]="{ 'margin-left': '10px' }"
          ></p-button>
        </div>
      </div>
    </div>
  `,
})
export class ExportReviewedComponentsDialogComponent implements OnInit {
  exportName: string;
  projectId: uuid;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private actionsService: ActionsService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectCurrentProject)
      .subscribe((p) => (this.projectId = p.id));
  }

  async handleSubmit(event) {
    await this.actionsService.exportReviewedComponents$(
      this.projectId,
      this.exportName
    );

    this.ref.close(null);
  }

  handleCancel(event) {
    this.ref.close(null);
  }

  private getFileName(route: string) {
    return route.split('\\').pop().split('/').pop();
  }
}
