import { createSelector, State, Store } from '@ngrx/store';
import { AppState } from './app.state';
import { from } from 'linq-to-typescript';
import {
  Component,
  ComponentInstanceVersion,
  ComponentInstance,
  ComponentVersion,
  ComponentSubversion,
} from '@genetpdm/model';
import { EnumsInstanceTypeEnum } from '@genetpdm/model/graphql';
import { Guid } from 'guid-typescript';
import { ExplorerTreeNode } from '../../model/treenode';
import {
  ComponentAdapter,
  ComponentInstanceAdapter,
  ComponentVersionAdapter,
  ComponentSubversionAdapter,
  ReleaseProcessAdapter,
} from './app.adapter';
import { IExplorerViewModel } from '../../../@shared/components/explorer/explorer.view-model';
import { ComponentInstanceVersionsDto } from '@genetpdm/model';

//Important: Only use this to get AppState!
export const selectAppState = (state: any) => state.explorer as AppState;

export const selectCurrentSelectedNode = createSelector(
  selectAppState,
  (state) => state.selected,
);

//** Select all root component instances, from the currently selected tab */
export const selectVisibleRootComponentInstanceVersions = createSelector(
  selectAppState,
  (state) => {
    const currentTabComponentIds =
      from(state.tabs).firstOrDefault((tab) => tab.id == state.selectedTabId)
        ?.componentIds ?? [];
    const componentMap = ComponentAdapter.getSelectors().selectEntities(
      state.components,
    );

    const components = from(currentTabComponentIds)
      .select((id) => componentMap[id] ?? null)
      .toArray();

    return GetComponentInstanceVersions(
      currentTabComponentIds,
      components,
      ComponentInstanceAdapter.getSelectors().selectAll(state.instances),
      ComponentVersionAdapter.getSelectors().selectAll(state.componentVersions),
      ComponentSubversionAdapter.getSelectors().selectAll(
        state.componentSubversions,
      ),
      state.expandedComponentIds,
      state.settings.user.name,
      state.synchronizingComponentIds //hope its right?
    );
  },
);

export const selectSynchronizingComponents = createSelector(
  selectAppState,
  (state: AppState) => state.synchronizingComponentIds,
);

export const selectVisibleComponentInstanceVersions = createSelector(
  selectVisibleRootComponentInstanceVersions,
  selectSynchronizingComponents,
  selectAppState,
  (rootComponents, synchronizingComponentIds ,state) =>
    from(rootComponents)
      .concatenate(
        from(
          GetComponentInstanceVersions(
            state.expandedComponentIds,
            ComponentAdapter.getSelectors().selectAll(state.components),
            ComponentInstanceAdapter.getSelectors().selectAll(state.instances),
            ComponentVersionAdapter.getSelectors().selectAll(
              state.componentVersions,
            ),
            ComponentSubversionAdapter.getSelectors().selectAll(
              state.componentSubversions,
            ),
            state.expandedComponentIds,
            state.settings.user.name,
            state.synchronizingComponentIds //hope its right?
          ),
        ),
      )
      .concatenate(
        from(
          GetChildComponentInstanceVersions(
            state.expandedComponentIds,
            ComponentAdapter.getSelectors().selectAll(state.components),
            ComponentInstanceAdapter.getSelectors().selectAll(state.instances),
            ComponentVersionAdapter.getSelectors().selectAll(
              state.componentVersions,
            ),
            ComponentSubversionAdapter.getSelectors().selectAll(
              state.componentSubversions,
            ),
            state.settings.user.name,
            state.synchronizingComponentIds
          ),
        ),
      )
      .toArray(),
);

export const selectExplorerViewModel = createSelector(
  selectAppState,
  selectVisibleRootComponentInstanceVersions,
  selectVisibleComponentInstanceVersions,
  (state, rootComponents, components) => {
    const tree = buildTree(rootComponents, components);
    const tabs = state.tabs;
    const releaseProcesses = ReleaseProcessAdapter.getSelectors().selectAll(
      state.releaseProcesses,
    );

    const state_ = <IExplorerViewModel>{
      nodes: tree,
      columns: state.columns,
      tabs: [...tabs].sort((a, b) => a.index - b.index),
      selectedTab: from(tabs).first((t) => t.id == state.selectedTabId),
      selectedNode: state.selected,
      releaseProcesses: releaseProcesses,
      permissions: state.permissions,
      isLoading: state.isLoading,
      error: state.error,
    };

    return state_;
  },
);

export const selectAllComponentInstances = createSelector(
  selectAppState,
  (state) => ComponentInstanceAdapter.getSelectors().selectAll(state.instances),
);

export const selectComponentInstancesByRightIds = (ids: uuid[]) =>
  createSelector(selectAllComponentInstances, (instances) =>
    instances.filter((c) => ids.includes(c.right_id)),
  );

export const selectComponentInstancesByIds = (ids: uuid[]) =>
  createSelector(selectAllComponentInstances, (instances) =>
    instances.filter((c) => ids.includes(c.right_id)),
  );



export function GetComponentInstanceVersionsFromDto(
  dto: ComponentInstanceVersionsDto,
  currentUser: string = '',
) {
  const ids = dto.components.map((c) => c.id);
  return GetComponentInstanceVersions(
    ids,
    dto.components,
    dto.instances,
    dto.versions,
    dto.subversions,
    ids,
    currentUser,
    ids
  );
}



export function GetComponentInstanceVersions(
  ids: uuid[],
  components: Component[],
  instances: ComponentInstance[],
  versions: ComponentVersion[],
  subVersions: ComponentSubversion[],
  expandedComponentIds: uuid[],
  currentUser: string = '',
  synchronizingIds: string[],
) {
  return from(components)
    .where((c) => c && ids.includes(c.id))
    .select(
      (c) =>
        new ComponentInstanceVersion(
          c,
          getOrGenerateInstance(instances, c.id),
          from(versions)
            .where((i) => i.component_id == c.id)
            .toArray(),
          from(subVersions)
            .where((i) => i.component_id == c.id)
            .toArray(),
          from(expandedComponentIds).any((id) => c.id == id),
          'Root Variant',
          currentUser,
          synchronizingIds.includes(c.id)
        ),
    )
    .toArray();
}

// Extend GetChildComponentInstanceVersions function
export function GetChildComponentInstanceVersions(
  ids: uuid[],
  components: Component[],
  instances: ComponentInstance[],
  versions: ComponentVersion[],
  subVersions: ComponentSubversion[],
  currentUser: string = '',
  synchronizingIds: string[],
) {
  return from(instances)
    .where((i) => ids.includes(i.left_id))
    .select(
      (i) =>
        new ComponentInstanceVersion(
          from(components).firstOrDefault((c) => c.id == i.right_id),
          i,
          from(versions)
            .where((v) => v.component_id == i.right_id)
            .toArray(),
          from(subVersions)
            .where((v) => v.component_id == i.right_id)
            .toArray(),
          false,
          'Root Variant',
          currentUser,
          synchronizingIds.includes(i.right_id)
        ),
    );
}

export function getOrGenerateInstance(
  instances: ComponentInstance[],
  right_id: uuid,
): ComponentInstance {
  const instance = from(instances).firstOrDefault(
    (i) => i.right_id == right_id,
  );

  if (instance == null) {
    return {
      id: Guid.create().toString(),
      name: 'Root Instance',
      left_id: Guid.EMPTY,
      right_id: right_id,
      project_id: right_id,
      rowindex: 0,
      type: EnumsInstanceTypeEnum.ProductInstance,
      position: null,
    };
  }
  return instance;
}

export function buildTree(
  rootComponents: ComponentInstanceVersion[],
  components: ComponentInstanceVersion[],
): ExplorerTreeNode[] {
  if (components.length == 0) return [];

  const componentInstancesByLeftId = from(components).toMap(
    (c) => c.instance.left_id,
  );

  const tree = from(rootComponents)
    .select((c) => buildTreeRecursive(c, componentInstancesByLeftId))
    .toArray();
  return tree;
}

export function buildTreeWithRootNodes(
  rootComponents: ComponentInstanceVersion[],
  components: ComponentInstanceVersion[],
): ExplorerTreeNode[] {
  if (components.length == 0) return [];

  const componentInstancesByLeftId = from(components).toMap(
    (c) => c.instance.left_id,
  );

  const tree = from(rootComponents)
    .select((c) => buildTreeRecursive(c, componentInstancesByLeftId))
    .toArray();
  return tree;
}

export function buildTreeRecursive(
  current: ComponentInstanceVersion,
  componentsByLeftId: Map<string, ComponentInstanceVersion[]>,
): ExplorerTreeNode {
  try {
    const children = componentsByLeftId.has(current?.component?.id ?? null)
      ? componentsByLeftId.get(current.component.id)
      : [];

    const childrenTreeNodes: ExplorerTreeNode[] = [];

    for (const child of from(children)
      .distinct((a, b) => a.instance?.id == b.instance?.id)
      .select((c) => buildTreeRecursive(c, componentsByLeftId))) {
      childrenTreeNodes.push(child);
    }

    return new ExplorerTreeNode(
      current,
      childrenTreeNodes.sort(
        (a, b) => a?.data?.instance?.rowindex - b?.data?.instance?.rowindex,
      ),
    );
  } catch (error) {
    return null;
  }
}
