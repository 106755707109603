import { AfterViewInit, Component } from '@angular/core';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { StlService } from '../../../@core/services/stl-service/stl.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ComponentInstanceVersion, Routes } from '@genetpdm/model';

@Component({
  selector: 'genetpdm-three-js-viewer-view',
  templateUrl: './three-js-viewer-view.component.html',
  styleUrls: ['./three-js-viewer-view.component.css'],
})
export class ThreeJSViewerViewComponent implements AfterViewInit {
  component: ComponentInstanceVersion;
  constructor(
    private stlService: StlService,
    public config: DynamicDialogConfig
  ) {}

  scene!: THREE.Scene;
  camera!: THREE.PerspectiveCamera;
  renderer!: THREE.WebGLRenderer;
  controls!: OrbitControls;
  loader!: STLLoader;

  ngOnInit() {
    this.component = this.config.data.component;
  }

  ngAfterViewInit(): void {
    this.loader = new STLLoader();
    this.initScene();
    this.initRenderer();
    this.initOrbitControls();

    //this.createCube();

    const stl$ = this.stlService.getStlDataUrl$(
      `Previews/STLS/${this.component.component_name}.stl`,
      this.component.component.project_id
    );
    console.log('this is the stl');
    stl$.subscribe((url: string) => {
      this.loadSTLModel(url);
    });

    this.addLighting();

    const axesHelper = new THREE.AxesHelper(100);
    this.scene.add(axesHelper);

    // eslint-disable-next-line @typescript-eslint/no-this-alias, prefer-const
    let component: ThreeJSViewerViewComponent = this;
    // eslint-disable-next-line no-var
    var animate = function () {
      requestAnimationFrame(animate);
      component.controls.update();
      component.renderer.render(component.scene, component.camera);
    };
    animate();
  }

  addLighting(): void {
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.7);
    ambientLight.position.set(0, 0, 10);
    this.scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(10, 10, -2);
    this.scene.add(directionalLight);

    const directionalLight2 = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight2.position.set(-10, -10, -2);
    this.scene.add(directionalLight2);

    // const directionalLight3 = new THREE.DirectionalLight(0xffffff, 1);
    // directionalLight3.position.set(2, 2, 0);
    // this.scene.add(directionalLight3);

    // const directionalLight4 = new THREE.DirectionalLight(0xffffff, 0.7);
    // directionalLight4.position.set(-2, -2, 0);
    // this.scene.add(directionalLight4);
  }

  initRenderer(): void {
    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    document.getElementById('container')?.appendChild(this.renderer.domElement);
  }

  initOrbitControls(): void {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.update();
  }

  createCube(): void {
    const geometry = new THREE.BoxGeometry();
    const material = new THREE.MeshStandardMaterial({ color: 0xff0000 });
    const cube = new THREE.Mesh(geometry, material);
    this.camera.position.set(0, 0, -10);
    this.camera.lookAt(cube.position);
    this.scene.add(cube);
  }

  loadSTLModel(stlFile: string): void {
    console.log('Loading stl');
    let mesh: THREE.Mesh;
    this.loader.load(stlFile, (geometry) => {
      geometry.computeBoundingBox();
      console.log('STL model loaded:');
      console.log('Vertices:', geometry.attributes['position'].count);
      console.log('Faces:', geometry.attributes['position'].count / 3);
      console.log('Bounding Box:', geometry.boundingBox);
      const material = new THREE.MeshPhongMaterial({
        color: 0xffffff,
        specular: 0xffffff,
        combine: THREE.AddOperation,
        shininess: 50,
        reflectivity: 1.0,
      });
      mesh = new THREE.Mesh(geometry, material);
      this.camera.position.set(0, 0, -80);
      this.scene.add(mesh);
    });
    console.log('Stl loaded');
  }

  initScene(): void {
    this.scene = new THREE.Scene();

    this.camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight
    );

    this.scene.background = new THREE.Color(0x333366);
  }
}
