import { createAction, props } from '@ngrx/store';
import { Log } from '@genetpdm/model';

export enum LogActionTypes {
  AddLog = '[Component] Add Log',
}

/**
 * Request to add a log entry
 */
export const AddLog = createAction(
  LogActionTypes.AddLog,
  props<{ log: Log }>()
);
