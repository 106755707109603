/* eslint-disable @typescript-eslint/no-explicit-any */
import hash from 'hash.js';

export function hashObject(obj: any) {
  const sortedObj = sortObject(obj);
  const str = JSON.stringify(sortedObj);
  return hash.sha256().update(str).digest('hex');
}

function sortObject(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(sortObject);
  }

  const sortedObj: any = {};
  Object.keys(obj)
    .sort()
    .forEach((key) => {
      sortedObj[key] = sortObject(obj[key]);
    });

  return sortedObj;
}
