import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { forkJoin } from 'rxjs';
import { ProjectService } from '../../../@core/services/project-service/project.service';
import moment from 'moment';
import { LoggingService } from '../../../@core/services/logging-service/logging.service';
import {
  ActionsService,
  BlobService,
  ComponentMetadataService,
  FileSystemAPIService,
  SettingsService,
} from '../../../@core/services';
import {
  CheckInType,
  LatestBlobSubscriptionGQL,
} from '@genetpdm/model/graphql';
import { ComponentInstanceVersion } from '@genetpdm/model';

@Component({
  selector: 'check-in-dialog',
  template: `
    <div class="card">
      <div class="card-header">
        <h3>
          Check In: {{ component.latestVersion.part_number }} -
          {{ component.component.id }}
        </h3>
        <p>Version: {{ component.latestVersion.index }}</p>
      </div>
      <div class="card-body">
        <form>
          <div class="form-group">
            <input
              type="text"
              pInputText
              placeholder="Comment"
              [(ngModel)]="comment"
              [ngModelOptions]="{ standalone: true }"
              style="width: 100%"
              required
            />
          </div>
          <hr />
          <div class="form-group" *ngIf="!disableUpload">
            <p>
              Add additional files to the change log. (E.g. Screenshots,
              PPTs...)
            </p>
            <p-fileUpload
              name="CheckInAddedFiles"
              [customUpload]="true"
              (uploadHandler)="handleUpload($event)"
              [multiple]="true"
              ><ng-template pTemplate="content" let-files>
                <div
                  style="height: 50px; text-align: center"
                  *ngIf="!files.length"
                >
                  <i
                    class="far fa-copy fa-3x"
                    style="margin-right: 10px; opacity: 0.2;"
                  ></i>
                  Drag & Drop files here...
                </div>
              </ng-template></p-fileUpload
            >
          </div>
          <div class="form-group" *ngIf="disableUpload">
            <h4>Upload succeeded!</h4>
            <p>You can now proceed to Check In.</p>
          </div>
          <hr />
          <!-- <div class="form-group">
            <p>
              Do you want to increment the version of the part, before you
              check-in? This will make a new version. The latest subversion of
              the previous part will be kept permanently. Use this function, if
              you want to explicitly keep the last state of the part, without
              your current changes, permanently. The current change will be
              checked-in as subversion 1 of the new version.
            </p>
            <p-checkbox
              [(ngModel)]="incrementVersion"
              [ngModelOptions]="{ standalone: true }"
              [binary]="true"
              inputId="increment"
            ></p-checkbox>
            <label for="increment" style="margin-left: 5px; margin-bottom: 0px"
              >Increment Version</label
            >
          </div> -->
          <div class="form-group" *ngIf="!incrementVersion">
            <p>
              Do you want to keep the part checked-out and only push the current
              change?
            </p>
            <p-checkbox
              [(ngModel)]="updateOnly"
              [ngModelOptions]="{ standalone: true }"
              [binary]="true"
              inputId="update"
            ></p-checkbox>
            <label for="update" style="margin-left: 5px; margin-bottom: 0px"
              >Update Only</label
            >
          </div>
          <hr />
          <div class="form-group" *ngIf="componentIsNotHighestVersion">
            <div class="container">
              <div class="row">
                <div class="col-md-1">
                  <i class="fas fa-exclamation-triangle fa-5x"></i>
                </div>
                <div class="col-md-10">
                  <p>
                    The component you have selected to Check-In is not the
                    current highest version.
                  </p>
                  <p>
                    If you want to restore this component, please check the
                    following box.
                  </p>
                  <p>
                    Restore will increase the version of the component and make
                    this the current version.
                  </p>
                  <p-checkbox
                    [(ngModel)]="enableRestore"
                    [ngModelOptions]="{ standalone: true }"
                    [binary]="true"
                    inputId="restore"
                  ></p-checkbox>
                  <label
                    for="restore"
                    style="margin-left: 5px; margin-bottom: 0px"
                    >Enable Restore</label
                  >
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="form-group" style="text-align: right">
            <p-button
              label="Check-In"
              (onClick)="handleSubmit($event)"
              icon="fas fa-upload"
              iconPos="left"
              *ngIf="enableSubmit"
            ></p-button>
            <p-button
              label="Cancel"
              (onClick)="handleCancel($event)"
              icon="fas fa-times"
              iconPos="left"
              [style]="{ 'margin-left': '10px' }"
            ></p-button>
          </div>
        </form>
      </div>
    </div>
  `,
})
export class CheckInDialogComponent implements OnInit {
  currentLocation: string;

  component: ComponentInstanceVersion;

  componentIsNotHighestVersion = false;
  enableRestore = false;

  incrementVersion = false;
  updateOnly = false;

  comment = '';

  additionalBlobs: string[] = [];

  enableSubmit = true;
  disableUpload = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private componentService: ComponentMetadataService,
    private projectService: ProjectService,
    private settingsService: SettingsService,
    private actionsService: ActionsService,
    private blobService: BlobService,
    private fileSystemApi: FileSystemAPIService,
    private latestBlobSubscription: LatestBlobSubscriptionGQL,
    private logger: LoggingService,
  ) {}

  ngOnInit(): void {
    this.component = this.config.data.component;
    this.updateOnly = this.config.data.checkInType == CheckInType.Update;
    this.incrementVersion =
      this.config.data.checkInType == CheckInType.IncreaseVersion;

    this.componentService
      .getLatestVersionIndex$(this.component.component.id)
      .subscribe(
        (r) =>
          (this.componentIsNotHighestVersion =
            this.component.latestVersion.index < r),
      );

    this.settingsService.getLocation$.subscribe(
      (location) => (this.currentLocation = location),
    );
  }

  handleUpload(event) {
    this.enableSubmit = false;

    const logId = this.component.component.id.split('-')[0];
    const routePrefix = `Logs/${logId}/${moment(Date.now()).format(
      'YYYYMMDDHHmmss',
    )}/`;
    const files = <File[]>event.files;
    const uploads$ = files.map((file) =>
      this.blobService.uploadBlob$(
        file,
        routePrefix + file.name,
        this.component.component.project_id,
      ),
    );

    forkJoin(uploads$).subscribe((r) => {
      this.additionalBlobs = r.map((b) => b.route);
      this.enableSubmit = true;
      this.disableUpload = true;
    });
  }

  handleSubmit(event) {
    let check_in_type: CheckInType = CheckInType.Default;

    if (this.enableRestore) check_in_type = CheckInType.Restore;

    if (this.updateOnly) check_in_type = CheckInType.Update;

    if (this.incrementVersion) check_in_type = CheckInType.IncreaseVersion;

    this.ref.close({
      component: this.component,
      checkInType: check_in_type,
      comment: this.comment,
      additionalBlobs: this.additionalBlobs,
    });
  }

  handleCancel(event) {
    this.ref.close(null);
  }
}
