/* eslint-disable */
import {
  NgModule,
  Component,
  Inject,
  ElementRef,
  forwardRef,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Optional,
  Input,
  TrackByFunction,
  ChangeDetectorRef,
} from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  PrimeNGConfig,
  TreeNode,
  SharedModule,
  TreeDragDropService,
} from 'primeng/api';
import { RippleModule } from 'primeng/ripple';
import { Tree as _Tree, UITreeNode as _UITreeNode } from 'primeng/tree';
import { ChevronRightIcon } from 'primeng/icons/chevronright';
import { ChevronDownIcon } from 'primeng/icons/chevrondown';
import { CheckIcon } from 'primeng/icons/check';
import { MinusIcon } from 'primeng/icons/minus';
import { PlusIcon } from 'primeng/icons/plus';
import { SpinnerIcon } from 'primeng/icons/spinner';
import { SearchIcon } from 'primeng/icons/search';
import { ScrollerModule } from 'primeng/scroller';
import { FormsModule } from '@angular/forms';

//To Update from primeng, update npm package and
//simply clone the template parts from the github repo of primeng

@Component({
  selector: 'p-treeNode',
  template: `
    <ng-template [ngIf]="node">
      <li
        *ngIf="tree.droppableNodes"
        class="p-treenode-droppoint"
        [ngClass]="{ 'p-treenode-droppoint-active': draghoverPrev }"
        (drop)="onDropPoint($event, -1)"
        (dragover)="onDropPointDragOver($event)"
        (dragenter)="onDropPointDragEnter($event, -1)"
        (dragleave)="onDropPointDragLeave($event)"
      ></li>
      <li
        *ngIf="!tree.horizontal"
        [ngClass]="[
          'p-treenode',
          node.styleClass || '',
          isLeaf() ? 'p-treenode-leaf' : ''
        ]"
        [ngStyle]="{ height: itemSize + 'px' }"
        [style]="node.style"
      >
        <div
          class="p-treenode-content"
          [style.paddingLeft]="level * indentation + 'rem'"
          (click)="onNodeClick($event)"
          (contextmenu)="onNodeRightClick($event)"
          (touchend)="onNodeTouchEnd()"
          (drop)="onDropNode($event)"
          (dragover)="onDropNodeDragOver($event)"
          (dragenter)="onDropNodeDragEnter($event)"
          (dragleave)="onDropNodeDragLeave($event)"
          [draggable]="tree.draggableNodes"
          (dragstart)="onDragStart($event)"
          (dragend)="onDragStop($event)"
          [attr.tabindex]="0"
          [ngClass]="{
            'p-treenode-selectable':
              tree.selectionMode && node.selectable !== false,
            'p-treenode-dragover': draghoverNode,
            'p-highlight': isSelected()
          }"
          role="treeitem"
          (keydown)="onKeyDown($event)"
          [attr.aria-posinset]="this.index + 1"
          [attr.aria-expanded]="this.node.expanded"
          [attr.aria-selected]="isSelected()"
          [attr.aria-label]="node.label"
          [attr.data-id]="node.key"
        >
          <button
            type="button"
            [attr.aria-label]="tree.togglerAriaLabel"
            class="p-tree-toggler p-link"
            (click)="toggle($event)"
            pRipple
            tabindex="-1"
          >
            <ng-container *ngIf="!tree.togglerIconTemplate">
              <ChevronRightIcon
                *ngIf="!node.expanded"
                [styleClass]="'p-tree-toggler-icon'"
              />
              <ChevronDownIcon
                *ngIf="node.expanded"
                [styleClass]="'p-tree-toggler-icon'"
              />
            </ng-container>
            <span *ngIf="tree.togglerIconTemplate" class="p-tree-toggler-icon">
              <ng-template
                *ngTemplateOutlet="
                  tree.togglerIconTemplate;
                  context: { $implicit: node.expanded }
                "
              ></ng-template>
            </span>
          </button>
          <div
            class="p-checkbox p-component"
            [ngClass]="{ 'p-checkbox-disabled': node.selectable === false }"
            *ngIf="tree.selectionMode === 'checkbox'"
            [attr.aria-checked]="isSelected()"
          >
            <div
              class="p-checkbox-box"
              [ngClass]="{
                'p-highlight': isSelected(),
                'p-indeterminate': node.partialSelected
              }"
            >
              <ng-container *ngIf="!tree.checkboxIconTemplate">
                <CheckIcon
                  *ngIf="isSelected()"
                  [styleClass]="'p-checkbox-icon'"
                />
                <MinusIcon
                  *ngIf="node.partialSelected"
                  [styleClass]="'p-checkbox-icon'"
                />
              </ng-container>
              <ng-template
                *ngTemplateOutlet="
                  tree.checkboxIconTemplate;
                  context: {
                    $implicit: isSelected(),
                    partialSelected: node.partialSelected
                  }
                "
              ></ng-template>
            </div>
          </div>
          <span
            [class]="getIcon()"
            *ngIf="node.icon || node.expandedIcon || node.collapsedIcon"
          ></span>
          <span class="p-treenode-label">
            <span *ngIf="!tree.getTemplateForNode(node)">{{ node.label }}</span>
            <span *ngIf="tree.getTemplateForNode(node)">
              <ng-container
                *ngTemplateOutlet="
                  tree.getTemplateForNode(node);
                  context: { $implicit: node }
                "
              ></ng-container>
            </span>
            <!-- CUSTOM CODE HERE -->
            <span
              *ngIf="
                node?.data?.checked_out && node?.data?.worker_is_current_user
              "
            >
              <i
                class="fas fa-download"
                title="Component is Checked-Out"
                style="margin-left: 5px"
              ></i
            ></span>
            <!-- END CUSTOM CODE HERE -->
          </span>
        </div>
        <ul
          class="p-treenode-children"
          style="display: none;"
          *ngIf="!tree.virtualScroll && node.children && node.expanded"
          [style.display]="node.expanded ? 'block' : 'none'"
          role="group"
        >
          <p-treeNode
            *ngFor="
              let childNode of node.children;
              let firstChild = first;
              let lastChild = last;
              let index = index;
              trackBy: tree.trackBy
            "
            [node]="childNode"
            [parentNode]="node"
            [firstChild]="firstChild"
            [lastChild]="lastChild"
            [index]="index"
            [itemSize]="itemSize"
            [level]="level + 1"
          ></p-treeNode>
        </ul>
      </li>
      <li
        *ngIf="tree.droppableNodes && lastChild"
        class="p-treenode-droppoint"
        [ngClass]="{ 'p-treenode-droppoint-active': draghoverNext }"
        (drop)="onDropPoint($event, 1)"
        (dragover)="onDropPointDragOver($event)"
        (dragenter)="onDropPointDragEnter($event, 1)"
        (dragleave)="onDropPointDragLeave($event)"
      ></li>
      <table *ngIf="tree.horizontal" [class]="node.styleClass">
        <tbody>
          <tr>
            <td class="p-treenode-connector" *ngIf="!root">
              <table class="p-treenode-connector-table">
                <tbody>
                  <tr>
                    <td
                      [ngClass]="{ 'p-treenode-connector-line': !firstChild }"
                    ></td>
                  </tr>
                  <tr>
                    <td
                      [ngClass]="{ 'p-treenode-connector-line': !lastChild }"
                    ></td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td
              class="p-treenode"
              [ngClass]="{ 'p-treenode-collapsed': !node.expanded }"
            >
              <div
                class="p-treenode-content"
                tabindex="0"
                [ngClass]="{
                  'p-treenode-selectable': tree.selectionMode,
                  'p-highlight': isSelected()
                }"
                (click)="onNodeClick($event)"
                (contextmenu)="onNodeRightClick($event)"
                (touchend)="onNodeTouchEnd()"
                (keydown)="onNodeKeydown($event)"
              >
                <span
                  *ngIf="!isLeaf()"
                  [ngClass]="'p-tree-toggler'"
                  (click)="toggle($event)"
                >
                  <ng-container *ngIf="!tree.togglerIconTemplate">
                    <PlusIcon
                      *ngIf="!node.expanded"
                      [styleClass]="'p-tree-toggler-icon'"
                      [ariaLabel]="tree.togglerAriaLabel"
                    />
                    <MinusIcon
                      *ngIf="node.expanded"
                      [styleClass]="'p-tree-toggler-icon'"
                      [ariaLabel]="tree.togglerAriaLabel"
                    />
                  </ng-container>
                  <span
                    *ngIf="tree.togglerIconTemplate"
                    class="p-tree-toggler-icon"
                  >
                    <ng-template
                      *ngTemplateOutlet="
                        tree.togglerIconTemplate;
                        context: { $implicit: node.expanded }
                      "
                    ></ng-template>
                  </span>
                </span>
                <span
                  [class]="getIcon()"
                  *ngIf="node.icon || node.expandedIcon || node.collapsedIcon"
                ></span>
                <span class="p-treenode-label">
                  <span *ngIf="!tree.getTemplateForNode(node)">{{
                    node.label
                  }}</span>
                  <span *ngIf="tree.getTemplateForNode(node)">
                    <ng-container
                      *ngTemplateOutlet="
                        tree.getTemplateForNode(node);
                        context: { $implicit: node }
                      "
                    ></ng-container>
                  </span>
                </span>
              </div>
            </td>
            <td
              class="p-treenode-children-container"
              *ngIf="node.children && node.expanded"
              [style.display]="node.expanded ? 'table-cell' : 'none'"
            >
              <div class="p-treenode-children">
                <p-treeNode
                  *ngFor="
                    let childNode of node.children;
                    let firstChild = first;
                    let lastChild = last;
                    trackBy: tree.trackBy
                  "
                  [node]="childNode"
                  [firstChild]="firstChild"
                  [lastChild]="lastChild"
                ></p-treeNode>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  `,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'p-element',
  },
})
export class UITreeNode extends _UITreeNode {
  tree: Tree;

  constructor(@Inject(forwardRef(() => Tree)) tree) {
    super(tree);
  }
}

@Component({
  selector: 'p-tree',
  template: `
    <div
      [ngClass]="{
        'p-tree p-component': true,
        'p-tree-selectable': selectionMode,
        'p-treenode-dragover': dragHover,
        'p-tree-loading': loading,
        'p-tree-flex-scrollable': scrollHeight === 'flex'
      }"
      [ngStyle]="style"
      [class]="styleClass"
      *ngIf="!horizontal"
      (drop)="onDrop($event)"
      (dragover)="onDragOver($event)"
      (dragenter)="onDragEnter()"
      (dragleave)="onDragLeave($event)"
    >
      <div class="p-tree-loading-overlay p-component-overlay" *ngIf="loading">
        <i
          *ngIf="loadingIcon"
          [class]="'p-tree-loading-icon pi-spin ' + loadingIcon"
        ></i>
        <ng-container *ngIf="!loadingIcon">
          <SpinnerIcon
            *ngIf="!loadingIconTemplate"
            [spin]="true"
            [styleClass]="'p-tree-loading-icon'"
          />
          <span *ngIf="loadingIconTemplate" class="p-tree-loading-icon">
            <ng-template *ngTemplateOutlet="loadingIconTemplate"></ng-template>
          </span>
        </ng-container>
      </div>
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>

      <p-scroller
        #scroller
        *ngIf="virtualScroll"
        [items]="serializedValue"
        [tabindex]="-1"
        styleClass="p-tree-wrapper"
        [style]="{ height: scrollHeight !== 'flex' ? scrollHeight : undefined }"
        [scrollHeight]="scrollHeight !== 'flex' ? undefined : '100%'"
        [itemSize]="virtualScrollItemSize || _virtualNodeHeight"
        [lazy]="lazy"
        (onScroll)="onScroll.emit($event)"
        (onScrollIndexChange)="onScrollIndexChange.emit($event)"
        (onLazyLoad)="onLazyLoad.emit($event)"
        [options]="virtualScrollOptions"
      >
        <ng-template
          pTemplate="content"
          let-items
          let-scrollerOptions="options"
        >
          <ul
            *ngIf="items"
            class="p-tree-container"
            [ngClass]="scrollerOptions.contentStyleClass"
            [style]="scrollerOptions.contentStyle"
            role="tree"
            [attr.aria-label]="ariaLabel"
            [attr.aria-labelledby]="ariaLabelledBy"
          >
            <p-treeNode
              #treeNode
              *ngFor="
                let rowNode of items;
                let firstChild = first;
                let lastChild = last;
                let index = index;
                trackBy: trackBy
              "
              [level]="rowNode.level"
              [rowNode]="rowNode"
              [node]="rowNode.node"
              [firstChild]="firstChild"
              [lastChild]="lastChild"
              [index]="getIndex(scrollerOptions, index)"
              [itemSize]="scrollerOptions.itemSize"
              [indentation]="indentation"
            ></p-treeNode>
          </ul>
        </ng-template>
        <ng-container *ngIf="loaderTemplate">
          <ng-template pTemplate="loader" let-scrollerOptions="options">
            <ng-container
              *ngTemplateOutlet="
                loaderTemplate;
                context: { options: scrollerOptions }
              "
            ></ng-container>
          </ng-template>
        </ng-container>
      </p-scroller>
      <ng-container *ngIf="!virtualScroll">
        <div #wrapper class="p-tree-wrapper" [style.max-height]="scrollHeight">
          <ul
            class="p-tree-container"
            *ngIf="getRootNode()"
            role="tree"
            [attr.aria-label]="ariaLabel"
            [attr.aria-labelledby]="ariaLabelledBy"
          >
            <p-treeNode
              *ngFor="
                let node of getRootNode();
                let firstChild = first;
                let lastChild = last;
                let index = index;
                trackBy: trackBy
              "
              [node]="node"
              [firstChild]="firstChild"
              [lastChild]="lastChild"
              [index]="index"
              [level]="0"
            ></p-treeNode>
          </ul>
        </div>
      </ng-container>

      <div
        class="p-tree-empty-message"
        *ngIf="
          !loading && (getRootNode() === null || getRootNode()?.length === 0)
        "
      >
        <ng-container *ngIf="!emptyMessageTemplate">
          {{ emptyMessageLabel }}
        </ng-container>
        <ng-container *ngIf="!emptyMessageTemplate">
          <ng-container *ngTemplateOutlet="emptyMessageTemplate"></ng-container>
        </ng-container>
      </div>
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </div>
    <div
      [ngClass]="{
        'p-tree p-tree-horizontal p-component': true,
        'p-tree-selectable': selectionMode
      }"
      [ngStyle]="style"
      [class]="styleClass"
      *ngIf="horizontal"
    >
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
      <div class="p-tree-loading-mask p-component-overlay" *ngIf="loading">
        <i
          *ngIf="loadingIcon"
          [class]="'p-tree-loading-icon pi-spin ' + loadingIcon"
        ></i>
        <ng-container *ngIf="!loadingIcon">
          <SpinnerIcon
            *ngIf="!loadingIconTemplate"
            [spin]="true"
            [styleClass]="'p-tree-loading-icon'"
          />
          <span *ngIf="loadingIconTemplate" class="p-tree-loading-icon">
            <ng-template *ngTemplateOutlet="loadingIconTemplate"></ng-template>
          </span>
        </ng-container>
      </div>
      <table *ngIf="value && value[0]">
        <p-treeNode [node]="value[0]" [root]="true"></p-treeNode>
      </table>
      <div
        class="p-tree-empty-message"
        *ngIf="
          !loading && (getRootNode() === null || getRootNode().length === 0)
        "
      >
        <ng-container *ngIf="!emptyMessageTemplate">
          {{ emptyMessageLabel }}
        </ng-container>
        <ng-container *ngIf="emptyMessageTemplate">
          <ng-container *ngTemplateOutlet="emptyMessageTemplate"></ng-container>
        </ng-container>
      </div>
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./tree.css'],
  host: {
    class: 'p-element',
  },
})
export class Tree extends _Tree {
  @Input() trackBy: TrackByFunction<any> = (index: number, item: any) => item;

  constructor(
    public el: ElementRef,
    @Optional() public dragDropService: TreeDragDropService,
    public config: PrimeNGConfig,
    cd: ChangeDetectorRef,
  ) {
    super(el, dragDropService, config, cd);
  }

  onDragOver(event) {
    if (this.droppableNodes) {
      event.dataTransfer.dropEffect = 'move';
      event.preventDefault();
    }
  }

  onDrop(event) {
    if (this.droppableNodes) {
      event.preventDefault();
      let dragNode = this.dragNode;
      if (this.allowDrop(dragNode, null, this.dragNodeScope)) {
        let dragNodeIndex = this.dragNodeIndex;
        this.value = this.value || [];

        this.onNodeDrop.emit({
          originalEvent: event,
          dragNode: dragNode,
          dropNode: null,
          index: dragNodeIndex,
        });

        this.dragDropService.stopDrag({
          node: dragNode,
        });
      }
    }
  }

  //onDragEnter(event) {
  //	if (
  //		this.droppableNodes &&
  //		this.allowDrop(this.dragNode, null, this.dragNodeScope)
  //	) {
  //		this.dragHover = true;
  //	}
  //}

  onDragLeave(event) {
    if (this.droppableNodes) {
      let rect = event.currentTarget.getBoundingClientRect();
      if (
        event.x > rect.left + rect.width ||
        event.x < rect.left ||
        event.y > rect.top + rect.height ||
        event.y < rect.top
      ) {
        this.dragHover = false;
      }
    }
  }

  allowDrop(
    dragNode: TreeNode,
    dropNode: TreeNode,
    dragNodeScope: any,
  ): boolean {
    if (!dragNode) {
      //prevent random html elements to be dragged
      return false;
    } else if (this.isValidDragScope(dragNodeScope)) {
      let allow: boolean = true;
      if (dropNode) {
        if (dragNode === dropNode) {
          allow = false;
        } else {
          let parent = dropNode.parent;
          while (parent != null) {
            if (parent === dragNode) {
              allow = false;
              break;
            }
            parent = parent.parent;
          }
        }
      }

      return allow;
    } else {
      return false;
    }
  }

  isValidDragScope(dragScope: any): boolean {
    const dropScope = this.droppableScope;

    if (dropScope) {
      if (typeof dropScope === 'string') {
        if (typeof dragScope === 'string') return dropScope === dragScope;
        else if (dragScope instanceof Array)
          return dragScope.indexOf(dropScope) != -1;
      } else if (dropScope instanceof Array) {
        if (typeof dragScope === 'string') {
          return dropScope.indexOf(dragScope) != -1;
        } else if (dragScope instanceof Array) {
          for (let s of dropScope) {
            for (let ds of dragScope) {
              if (s === ds) {
                return true;
              }
            }
          }
        }
      }
      return false;
    } else {
      return true;
    }
  }
}
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ScrollerModule,
    ScrollingModule,
    RippleModule,
    ChevronRightIcon,
    ChevronDownIcon,
    CheckIcon,
    MinusIcon,
    PlusIcon,
    SpinnerIcon,
    SearchIcon,
  ],
  exports: [Tree, SharedModule, ScrollingModule],
  declarations: [Tree, UITreeNode],
})
export class TreeModule {}
