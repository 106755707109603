import { Project, ProjectConfiguration } from '@genetpdm/model';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { ProjectPermissions } from '../../../../../../../libs/model/src/models/permissions.model';

export enum ProjectActionTypes {
  LoadSelectedProjectId = '[Component] Load Selected ProjectId',
  LoadSelectedProjectIdSucceeded = '[Backend] Load Selected ProjectId Succeeded',
  LoadSelectedProjectIdFailed = '[Backend] Load Selected ProjectId Failed',
  LoadProjects = '[Component] Load Projects',
  SyncStateWithNewProject = '[Component] Syncing with new project',
  LoadProjectsSucceeded = '[Backend] Load Projects Succeeded',
  LoadProjectsFailed = '[Backend] Load Projects Failed',
  LoadProjectConfiguration = '[Component] Load Project Configuration',
  LoadProjectConfigurationSucceeded = '[Backend] Load Project Configuration Succeeded',
  LoadProjectConfigurationFailed = '[Backend] Load Project Configuration Failed',
  AddProjects = '[Component] Add Projects',
  AddProjectsSucceeded = '[Backend] Add Projects Succeeded',
  AddProjectsFailed = '[Backend] Add Projects Failed',
  UpdateProjects = '[Component] Update Projects',
  UpdateProjectsSucceeded = '[Backend] Update Projects Succeeded',
  UpdateProjectsFailed = '[Backend] Update Projects Failed',
  ChangeCurrentProject = '[Component] ChangeCurrentProject',
  ChangeCurrentProjectSucceeded = '[Backend] ChangeCurrentProject Succeeded',
  ChangeCurrentProjectFailed = '[Backend] ChangeCurrentProject Failed',
}

//#region [Load]

/**
 * Request to load the currently selected projectId
 */
export const LoadSelectedProjectId = createAction(
  ProjectActionTypes.LoadSelectedProjectId
);

/**
 * Backend returns projects.
 */
export const LoadSelectedProjectIdSuceeded = createAction(
  ProjectActionTypes.LoadSelectedProjectIdSucceeded,
  props<{ selectedProjectId: uuid }>()
);

/**
 * Backend returns error.
 * Failed to load projects with specified ids.
 *
 * @remarks
 * Use the ids to reset the projects in the state.
 */
export const LoadSelectedProjectIdFailed = createAction(
  ProjectActionTypes.LoadSelectedProjectIdFailed,
  props<{ error: Error }>()
);

/**
 * Syncs the state with the changed project and permission
 */
export const LoadProjects = createAction(ProjectActionTypes.LoadProjects);

export const SyncStateWithNewProject = createAction(
  ProjectActionTypes.SyncStateWithNewProject,
  props<{ selectedProjectId: uuid }>()
);


/**
 * Backend returns projects.
 */
export const LoadProjectsSuceeded = createAction(
  ProjectActionTypes.LoadProjectsSucceeded,
  props<{ projects: Project[]; selectedProjectId: uuid, permissions: ProjectPermissions }>()
);

/**
 * Backend returns error.
 * Failed to load projects with specified ids.
 *
 * @remarks
 * Use the ids to reset the projects in the state.
 */
export const LoadProjectsFailed = createAction(
  ProjectActionTypes.LoadProjectsFailed,
  props<{ error: Error }>()
);

/**
 * Request to load the configuration for project id
 */
export const LoadProjectConfiguration = createAction(
  ProjectActionTypes.LoadProjectConfiguration,
  props<{ id: uuid }>()
);

/**
 * Backend returns projects.
 */
export const LoadProjectConfigurationSuceeded = createAction(
  ProjectActionTypes.LoadProjectConfigurationSucceeded,
  props<{ config: ProjectConfiguration }>()
);

/**
 * Backend returns error.
 * Failed to load projects with specified ids.
 *
 * @remarks
 * Use the ids to reset the projects in the state.
 */
export const LoadProjectConfigurationFailed = createAction(
  ProjectActionTypes.LoadProjectConfigurationFailed,
  props<{ error: Error }>()
);

//#endregion

//#region [Add]

/**
 * Request the backend to add specified instances.
 */
export const AddProjects = createAction(
  ProjectActionTypes.AddProjects,
  props<{ projects: Project[] }>()
);

/**
 * Backend added the requested instances and returns the inserted instances.
 */
export const AddProjectsSucceeded = createAction(
  ProjectActionTypes.AddProjectsSucceeded,
  props<{ projects: Project[] }>()
);

/**
 * Backend failed to add the requested instances.
 *
 * @remarks
 * Use the ids to remove the instacnes from the state.
 *
 * @returns The Error and the unchanged ids of the requested components.
 */
export const AddProjectsFailed = createAction(
  ProjectActionTypes.AddProjectsFailed,
  props<{ error: Error; ids: uuid[] }>()
);

//#endregion

//#region [Update]

/**
 * Request the backend to update all specified properties.
 */
export const UpdateProjects = createAction(
  ProjectActionTypes.UpdateProjects,
  props<{ projects: Update<Project>[] }>()
);

/**
 * Backend updated the requested properties and returns the updated components.
 */
export const UpdateProjectsSucceeded = createAction(
  ProjectActionTypes.UpdateProjectsSucceeded,
  props<{ projects: Update<Project>[] }>()
);

/**
 * Backend failed to update the requested properties.
 *
 * @remarks
 * Use the ids to reset the components in the state.
 *
 * @returns The Error and the unchanged ids of the requested components.
 */
export const UpdateProjectsFailed = createAction(
  ProjectActionTypes.UpdateProjectsFailed,
  props<{ error: Error; ids: uuid[] }>()
);

//#endregion

export const ChangeCurrentProject = createAction(
  ProjectActionTypes.ChangeCurrentProject,
  props<{ projectId: uuid }>()
);

export const ChangeCurrentProjectSucceeded = createAction(
  ProjectActionTypes.ChangeCurrentProjectSucceeded,
  props<{ projectId: uuid }>()
);

export const ChangeCurrentProjectFailed = createAction(
  ProjectActionTypes.ChangeCurrentProjectFailed,
  props<{ error: Error; id: uuid }>()
);
