import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TreeNode } from './file-api-explorer.model';
import { FileAPIExplorerService } from './file-api-explorer.service';

@Component({
	selector: 'app-file-api-explorer',
	templateUrl: './file-api-explorer.component.html',
	styleUrls: ['./file-api-explorer.component.css'],
})
export class FileApiExplorerComponent {
	get loading(): boolean {
		return this.service.loading;
	}

	public get nodes(): TreeNode[] {
		return this.service.nodes;
	}

	public routeText = '';

	constructor(
		private service: FileAPIExplorerService,
		public ref: DynamicDialogRef,
		public config: DynamicDialogConfig,
	) {}


	handleClickLoad(event) {
		this.service.loadNodes(false);
	}

	handleClickAccept(event) {
		this.ref.close(this.nodes.map((n) => n.data));
	}

	handleClickCancel(event) {
		this.ref.close(null);
	}
}
