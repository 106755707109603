import { DatePipe } from '@angular/common';
import {
	Component,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
} from '@angular/core';
import { Guid } from 'guid-typescript';
import { MenuItem } from 'primeng/api';
import {
	DialogService,
	DynamicDialogConfig,
	DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { OperatorFunction, Subscription } from 'rxjs';
import { FileTreeNode, MenuBuilder } from './attached-files-view.model';
import { Component as _Component } from './attached-files-view.model';
import { FilesService, ImageService } from '../../../@core/services';
import { ObservableToastService } from '../../../@core/utils/observable-operators/observable-toast.service';

@Component({
	selector: 'attached-files-view',
	styleUrls: ['./attached-files-view.scss'],
	template: `
				<h3>Attached Files of: {{ partNumber }}</h3>

				<p-menubar [model]="menuItems"> </p-menubar>
				<p-treeTable
					#tt
					[value]="nodes"
					[columns]="cols"
					[autoLayout]="true"
					selectionMode="single"
					[(selection)]="selectedNode"
					dataKey="name"
					[scrollable]="false"
					scrollHeight="flex"
					(onNodeExpand)="onNodeExpand($event)"
					styleClass="p-treetable-xs"
				>
					<ng-template pTemplate="header" let-columns>
						<tr>
							<th class="table-header">Name</th>
							<th class="table-header" *ngFor="let col of columns">
								{{ col.header }}
							</th>
							<th class="table-header">Actions</th>
						</tr>
					</ng-template>

					<ng-template
						pTemplate="body"
						let-rowNode
						let-rowData="rowData"
						let-columns="columns"
						let-index="index"
					>
						<tr
							class="table-row"
							draggable="true"
							[ttRow]="rowNode"
							[ttDraggableRow]="rowNode"
							[preventExtraction]="true"
							[ttSelectableRow]="rowNode"
							[ttContextMenuRow]="rowNode"
							[ngClass]="index % 2 ? 'even-row' : 'odd-row'"
						>
							<td>
								<p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
								<em
									class="far fa-folder"
									*ngIf="
										rowNode.node.leaf === false &&
										(rowNode.node.expanded === null ||
											rowNode.node.expanded === false)
									"
								></em>
								<em
									class="far fa-folder-open"
									*ngIf="
										rowNode.node?.leaf === false &&
										rowNode.node?.expanded === true
									"
								></em>
								<em
									class="far fa-file-pdf"
									*ngIf="
										rowNode.node?.leaf === true &&
										rowNode.node?.data?.extension === '.pdf'
									"
								></em>
								<em
									class="far fa-file-excel"
									*ngIf="
										rowNode.node?.leaf === true &&
										rowNode.node?.data?.extension === '.xlsx'
									"
								></em>
								<em
									class="far fa-file-word"
									*ngIf="
										rowNode.node?.leaf === true &&
										rowNode.node?.data?.extension === '.docx'
									"
								></em>
								<em
									class="far fa-file-powerpoint"
									*ngIf="
										rowNode.node?.leaf === true &&
										rowNode.node?.data?.extension === '.pptx'
									"
								></em>
								<em
									class="far fa-file-alt"
									*ngIf="
										rowNode.node?.leaf === true &&
										rowNode.node?.data?.extension !== '.pptx' &&
										rowNode.node?.data?.extension !== '.pdf' &&
										rowNode.node?.data?.extension !== '.docx' &&
										rowNode.node?.data?.extension !== '.xlsx'
									"
								></em>
								{{ rowNode.node?.data?.name ?? '' }}
							</td>
							<td *ngFor="let col of columns; let i = index">
								<div *ngIf="col.field === 'updated_at'; else elseBlock">
									{{ rowNode.node.data.updated_at | date: 'medium' }}
								</div>
								<ng-template #elseBlock>
									{{ rowNode.node?.data[col.field] ?? '' }}
								</ng-template>
							</td>
							<td>
								<button
									pButton
									pRipple
									type="button"
									title="Download"
									icon="fas fa-file-download"
									class="p-button-rounded p-button-secondary p-button-text"
									*ngIf="rowNode.node?.data?.downloadAction"
									(click)="rowNode.node.data.downloadAction()"
								></button>
								<button
									pButton
									pRipple
									type="button"
									title="Delete"
									icon="far fa-trash-alt"
									class="p-button-rounded p-button-secondary p-button-text"
									*ngIf="rowNode.node?.data?.deleteAction"
									(click)="rowNode.node.data.deleteAction()"
									#btn
								></button>
							</td>
						</tr>
					</ng-template>

					<ng-template pTemplate="emptymessage">
						<tr>
							<td [attr.colspan]="cols.length + 2">No data found.</td>
						</tr>
					</ng-template>
				</p-treeTable>

	`,
	providers: [DatePipe, DialogService],
})
export class AttachedFilesViewComponent implements OnChanges, OnDestroy {
	@Input() partNumber: string;
	@Input() componentId: uuid;
	@Input() projectId: uuid;
	@Input() version: number;

	menuItems: MenuItem[];

	nodes: FileTreeNode[] = [];

	private _selectedNode: FileTreeNode = null;
	get selectedNode(): any {
		return this._selectedNode;
	}
	set selectedNode(value: any) {
		if (this.selectedNode != value) {
			this._selectedNode = value;
			this.refreshMenu();
		}
	}

	// Name column is hardcoded in html
	cols: any[] = [
		{ field: 'route', header: 'Route' },
		{ field: 'extension', header: 'Extension' },
		{ field: 'worker', header: 'Worker' },
		{ field: 'changedate', header: 'Last Change Date' },
	];

	menuBuilder: MenuBuilder;

	subscription: Subscription;

	constructor(
		public ref: DynamicDialogRef,
		public config: DynamicDialogConfig,
		private filesService: FilesService,
		public dialogService: DialogService,
		private observableToastService: ObservableToastService,
	) {
		this.menuBuilder = new MenuBuilder(filesService, dialogService);
		this.refreshMenu();
	}

	ngOnDestroy(): void {
		if (this.subscription != null && !this.subscription.closed) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}

	ngOnChanges(_changes: SimpleChanges): void {
		if (this.componentId === null) return;

		this.partNumber = this.config.data.partNumber;
		this.componentId = this.config.data.componentId;
		this.projectId = this.config.data.projectId;
		this.version = this.config.data.version;

		if (this.subscription != null && !this.subscription.closed) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}

		this.filesService
			.getFilesOfComponent(this.componentId)
			.pipe(this.catchAndToast())
			.subscribe(
				(files) =>
					(this.nodes = files.map(
						(f) => new FileTreeNode(f, this.filesService),
					)),
			);
	}

	onNodeExpand(event) {
		const node = event.node as FileTreeNode;
		if (node === null) return;

		node.expand(() => (this.nodes = [...this.nodes]));
	}

	private refreshMenu() {
		const component = <_Component>{
			componentId: this.componentId,
			projectId: this.projectId,
			partNumber: this.partNumber,
			version: this.version,
		};

		const newItems = this.menuBuilder.getMenuItemsForSelection(
			this.selectedNode,
			component,
		);

		this.menuItems = newItems;
	}

	private catchAndToast<T>(defaultValue?: T): OperatorFunction<T, T> {
		return this.observableToastService.catchAndToast(defaultValue);
	}
}
