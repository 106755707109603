import { Component, OnInit } from '@angular/core';
import {
  AllUsersGQL,
  InsertTaskWorkerMutationGQL,
} from '@genetpdm/model/graphql';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'user-search-dialog',
  template: `
    <div class="card">
      <div class="card-body">
        <p-pickList
          [source]="sourceList"
          [target]="targetList"
          sourceHeader="Available Workers"
          targetHeader="Selected Workers"
          [dragdrop]="true"
          [responsive]="true"
          [sourceStyle]="{ height: '30rem' }"
          [targetStyle]="{ height: '30rem' }"
          filterBy="userName"
        >
          <ng-template let-worker pTemplate="item">
            <div>
              <div style="font-size:14px;float:left;">
                {{ worker.userName }}
              </div>
            </div>
          </ng-template>
        </p-pickList>
      </div>
      <div class="card-footer">
        <div class="row">
          <div>
            <p-button
              label="Submit"
              icon="pi pi-check"
              (onClick)="close()"
            ></p-button>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class UserSearchDialogComponent implements OnInit {
  sourceList: Worker[] = [];
  targetList: Worker[] = [];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public allUsers: AllUsersGQL,
    public insertTaskWorkers: InsertTaskWorkerMutationGQL
  ) {}

  public getWorkers() {
    this.allUsers.watch().valueChanges.subscribe(
      (result) =>
        (this.sourceList = result.data.genet_user.map((u) => ({
          id: u.id,
          userName: u.name,
        })))
    );
  }

  public close() {
    this.ref.close({ workers: this.targetList });
  }

  ngOnInit(): void {
    this.getWorkers();
  }
}

interface Worker {
  userName: string;
  id: uuid;
}
