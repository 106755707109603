import { Inject, Injectable } from '@angular/core';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
} from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  IPublicClientApplication,
  PopupRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { BrowserHeaders } from 'browser-headers';
import { firstValueFrom, from, Observable, Subject, timer } from 'rxjs';
import {
  distinctUntilChanged,
  first,
  map,
  filter,
  catchError,
  tap,
  shareReplay,
  concatMap,
  switchMap,
} from 'rxjs/operators';
import { AccountInfo } from '@azure/msal-common';
import { TokenCredential } from '@azure/identity';
import { AccessToken } from '@azure/core-auth';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _loggedIn$ = new Subject<boolean>();
  public loggedIn$ = this._loggedIn$.pipe(
    distinctUntilChanged(),
    shareReplay(1),
  );

  public user$: Observable<AccountInfo>;

  constructor(
    @Inject(MSAL_GUARD_CONFIG)
    private msalGuardConfig: MsalGuardConfiguration,
    @Inject(MSAL_INSTANCE)
    private msalInstance: IPublicClientApplication,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
  ) {
    const authSuccess$ = this.msalBroadcastService.msalSubject$.pipe(
      filter(
        (msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_SUCCESS ||
          msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS,
      ),
    );

    this.user$ = authSuccess$.pipe(
      tap((_) => this.refreshLoggedIn()),
      map((_) => this.authService.instance.getActiveAccount()),
      shareReplay(1),
    );

    this.user$.subscribe();
  }

  private refreshLoggedIn() {
    this._loggedIn$.next(this.authService.instance.getAllAccounts().length > 0);

    this.authService.instance.setActiveAccount(
      this.authService.instance.getAllAccounts()[0],
    );
  }

  public login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService
        .loginPopup({
          ...this.msalGuardConfig.authRequest,
        } as PopupRequest)
        .subscribe(() => this.refreshLoggedIn());
    } else {
      this.authService.loginPopup().subscribe(() => this.refreshLoggedIn());
    }
  }

  public logout() {
    this.authService.logout();
  }

  public isAdmin() {
    const account: Account = this.authService.instance.getAllAccounts()[0];

    return account.idTokenClaims?.roles?.includes(
      environment.azureAd.roles.admin,
    );
  }

  public isManager() {
    const account: Account = this.authService.instance.getAllAccounts()[0];

    return account.idTokenClaims?.roles?.includes(
      environment.azureAd.roles.manager,
    );
  }

  private apiAccessToken$: Observable<AccessToken>;

  public getAPIAccessToken$(): Observable<AccessToken> {
    if (this.apiAccessToken$) return this.apiAccessToken$;

    const account = this.authService.instance.getActiveAccount();
    const request = {
      ...account,
      scopes: environment.azureAd.resources.commandsAPI.scopes,
      // Needed for upfront consent, as the API uses this on behalf of...
      extraScopesToConsent: [
        ...environment.azureAd.resources.blobStorage.scopes,
      ],
    };

    const tokenRefreshTicker$ = timer(0, 3500000);

    this.apiAccessToken$ = tokenRefreshTicker$.pipe(
      concatMap(() => this.getAccessTokenInternal$(request)),
      tap((_) => {
        console.log('Received API Token.');
        if (environment.debug) console.log(_.token);
      }),
      shareReplay(1),
    );

    return this.apiAccessToken$;
  }

  private blobAccessToken$: Observable<AccessToken>;

  public getBlobAccessToken$(): Observable<AccessToken> {
    if (this.blobAccessToken$) return this.blobAccessToken$;

    const account = this.authService.instance.getActiveAccount();
    const request = {
      ...account,
      scopes: environment.azureAd.resources.blobStorage.scopes,
    };

    this.blobAccessToken$ = this.getAccessTokenInternal$(request).pipe(
      tap((_) => {
        console.log('Received Blob Token.');
        if (environment.debug) console.log(_.token);
      }),
    );

    return this.blobAccessToken$;
  }

  private getAccessTokenInternal$(
    request: SilentRequest | PopupRequest,
  ): Observable<AccessToken> {
    return from(this.msalInstance.initialize()).pipe(
      switchMap(() => this.getTokenSilent$(request)),
      catchError((err) => {
        console.error('Silent Sign On failed: ' + err.message);
        return this.getTokenPopup$(request);
      }),
    );
  }

  // private tryGetSingleSignOnToken$(
  //	request: SsoSilentRequest,
  // ): Observable<AccessToken> {
  //	return this.authService.ssoSilent(request).pipe(
  //		map((res) => ({
  //			token: res.accessToken,
  //			expiresOnTimestamp: res.expiresOn.getUTCDate(),
  //		})),
  //	);
  // }

  private getTokenSilent$(request: SilentRequest): Observable<AccessToken> {
    return from(
      this.authService.instance.acquireTokenSilent(request).then((res) => ({
        token: res.accessToken,
        expiresOnTimestamp: res.expiresOn.getUTCDate(),
      })),
    );
  }

  private getTokenPopup$(request: PopupRequest): Observable<AccessToken> {
    return from(
      this.authService.instance.acquireTokenPopup(request).then((res) => ({
        token: res.accessToken,
        expiresOnTimestamp: res.expiresOn.getUTCDate(),
      })),
    );
  }
}

export function getBrowserHeaders$(authService: AuthService) {
  return getAuthHeaderMap$(authService).pipe(
    map((r) => new BrowserHeaders(r)),
    first((r) => r != null),
  );
}

export function getAuthHeaderMap$(
  authService: AuthService,
): Observable<{ [paramName: string]: any }> {
  return authService.getAPIAccessToken$().pipe(
    filter((token) => {
      return token != null;
    }),
    first(),
    map((s) => {
      return { authorization: 'Bearer ' + s.token };
    }),
  );
}

export function getBlobTokenCredential(
  authService: AuthService,
): TokenCredential {
  return {
    getToken: (_scopes, _options) =>
      firstValueFrom(authService.getBlobAccessToken$()),
  };
}

export function getProfilePicture$(authService: AuthService) {
  return authService.getAPIAccessToken$().pipe(
    filter((token) => {
      return token != null;
    }),
    first(),
    switchMap((token) =>
      from(
        fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
          headers: { Authorization: 'Bearer ' + token },
        }),
      ),
    ),
    switchMap((response) => from(response.blob())),
  );
}

interface Account extends AccountInfo {
  idTokenClaims?: {
    roles?: string[];
  };
}
