import { VariantAdapter } from './adapter';
import { AppState } from '../@app-state/app.state';
import { createSelector } from '@ngrx/store';
import { from } from 'linq-to-typescript';
import { selectAppState } from '../@app-state/app.selectors';

export const selectAllVariants = createSelector(
  selectAppState,
  (state: AppState) => VariantAdapter.getSelectors().selectAll(state.variants),
);

export const selectVariantEntities = createSelector(
  selectAppState,
  (state: AppState) =>
    VariantAdapter.getSelectors().selectEntities(state.variants),
);

export const selectAllVariantIds = createSelector(
  selectAppState,
  (state: AppState) =>
    VariantAdapter.getSelectors()
      .selectIds(state.variants)
      .map((id) => id as string),
);

export const selectRootVariant = (projectId: uuid) =>
  createSelector(selectAllVariants, (variants) =>
    from(variants).firstOrDefault(
      (v) => v.project_id == projectId && v.is_root,
    ),
  );

export const selectRootComponentId = (projectId: uuid) =>
  createSelector(
    selectRootVariant(projectId),
    (variant) => variant.variant_root_component_id,
  );
