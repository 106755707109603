import { on, select } from '@ngrx/store';
import { InitialComponentCompositeState } from '../@app-state/app.state';
import {
  SelectComponent,
  SelectTab,
  AddTab,
  RemoveTab,
  OpenComponentInTab,
  CheckOutComponent,
  CancelCheckOutComponent,
  ChangeParent,
  CheckInComponent,
  ClearPositionFromInstance,
  DeleteInstance,
  FavoriteComponent,
  FreezeComponent,
  IncreaseComponentVersion,
  MoveRow,
  RenameComponent,
  UnFavoriteComponent,
  UnFreezeComponent,
  CheckInComponentSucceeded,
  UpdateCheckInComponent,
  StartNewReleaseProcess,
  NavigateToComponentSucceeded,
} from './user-interface.actions';
import { ExplorerStoreReducerArray } from '../state-model';
import {
  ComponentAdapter,
  ComponentInstanceAdapter,
  ComponentVersionAdapter,
  getComponentVersionId,
} from '../@app-state/app.adapter';
import { distinct } from '../../utils/array-helper/array-helper';
import { CheckOutComponentSucceeded } from './user-interface.actions';
import { from } from 'linq-to-typescript';
import { ReleaseProcessAdapter } from '../@app-state/app.adapter';
import { EnumsComponentStateEnum } from '@genetpdm/model/graphql';

export const UserInterfaceReducer: ExplorerStoreReducerArray = [
  on(SelectComponent, (state, payload) => ({
    ...state,
    selected: payload.treeNode,
  })),
  on(SelectTab, (state, payload) => ({
    ...state,
    selectedTabId: state.tabs.some((t) => t.id == payload.id)
      ? payload.id
      : 'root',
  })),
  on(AddTab, (state, payload) => ({
    ...state,
    tabs: [
      ...state.tabs.filter((t) => t.id != payload.container.id),
      { ...payload.container },
    ],
    selectedTabId: payload.container.id,
  })),
  on(RemoveTab, (state, payload) => ({
    ...state,
    //Don't remove initial tabs
    tabs: InitialComponentCompositeState.tabs.some((t) => t.id == payload.id)
      ? [...state.tabs]
      : [...state.tabs.filter((t) => t.id != payload.id)],
    selectedTabId: 'root',
  })),
  on(OpenComponentInTab, (state, payload) => ({
    ...state,
    //Don't remove initial tabs
    tabs: from([
      ...state.tabs,
      {
        id: payload.component.component_name,
        title: payload.component.component_name,
        icon: 'fa-solid fa-folder-tree',
        componentIds: [payload.component.component.id],
        index: 5,
      },
    ])
      .distinct((a, b) => a.id == b.id)
      .toArray(),
    selectedTabId: payload.component.component_name,
  })),

  on(CheckOutComponent, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(CheckOutComponentSucceeded, (state, payload) => ({
    ...state,
    selected: {
      ...state.selected,
      data: state.selected.data.getWithUpdatedCheckoutState(true),
    },
    componentVersions: ComponentVersionAdapter.updateOne(
      {
        id: getComponentVersionId(payload.component.latestVersion),
        changes: {
          checked_out: true,
        },
      },
      state.componentVersions,
    ),
    isLoading: false,
    error: null,
  })),
  on(CheckInComponent, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(UpdateCheckInComponent, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(CheckInComponentSucceeded, (state, payload) => ({
    ...state,
    selected: {
      ...state.selected,
      data: state.selected.data.getWithUpdatedCheckoutState(false),
    },
    componentVersions: ComponentVersionAdapter.updateOne(
      {
        id: getComponentVersionId(payload.component.latestVersion),
        changes: {
          checked_out: false,
        },
      },
      state.componentVersions,
    ),
    isLoading: false,
    error: null,
  })),
  on(CancelCheckOutComponent, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(IncreaseComponentVersion, (state, payload) => ({
    ...state,
    componentVersions: ComponentVersionAdapter.upsertOne(
      {
        ...payload.component.latestVersion,
        index: payload.component.latestVersion.index + 1,
      },
      state.componentVersions,
    ),
  })),
  // on(RestoreComponentVersion, (state, payload) => ({
  //   ...state,
  // })),
  on(FavoriteComponent, (state, payload) => ({
    ...state,
    favoriteComponentIds: [
      ...state.favoriteComponentIds,
      payload.component.component.id,
    ].filter(distinct),
  })),
  on(UnFavoriteComponent, (state, payload) => ({
    ...state,
    favoriteComponentIds: state.favoriteComponentIds.filter(
      (id) => id == payload.component.component.id,
    ),
  })),
  on(RenameComponent, (state, payload) => ({
    ...state,
    componentVersions: ComponentVersionAdapter.updateOne(
      {
        id: getComponentVersionId(payload.component.latestVersion),
        changes: {
          part_number: payload.newName,
        },
      },
      state.componentVersions,
    ),
    components: ComponentAdapter.updateOne(
      {
        id: payload.component.component.id,
        changes: {
          name: payload.newName,
        },
      },
      state.components,
    ),
  })),
  // on(CreateNewVariant, (state, payload) => ({
  //   ...state,
  // })),
  on(ClearPositionFromInstance, (state, payload) => ({
    ...state,
    instances: ComponentInstanceAdapter.updateOne(
      {
        id: payload.component.instance.id,
        changes: {
          position: null,
        },
      },
      state.instances,
    ),
  })),
  on(FreezeComponent, (state, payload) => ({
    ...state,
    componentVersions: ComponentVersionAdapter.updateOne(
      {
        id: getComponentVersionId(payload.component.latestVersion),
        changes: {
          frozen: true,
        },
      },
      state.componentVersions,
    ),
  })),
  on(UnFreezeComponent, (state, payload) => ({
    ...state,
    componentVersions: ComponentVersionAdapter.updateOne(
      {
        id: getComponentVersionId(payload.component.latestVersion),
        changes: {
          frozen: false,
        },
      },
      state.componentVersions,
    ),
  })),
  // on(DuplicateComponent, (state, payload) => ({
  //   ...state,
  // })),
  on(DeleteInstance, (state, payload) => ({
    ...state,
    instances: ComponentInstanceAdapter.removeOne(
      payload.component.instance.id,
      state.instances,
    ),
  })),
  on(ChangeParent, (state, payload) => ({
    ...state,
    instances: ComponentInstanceAdapter.updateOne(
      {
        id: payload.component.instance.id,
        changes: {
          left_id: payload.newParentComponent.component.id,
        },
      },
      state.instances,
    ),
  })),
  on(MoveRow, (state, payload) => ({
    ...state,
    instances: ComponentInstanceAdapter.updateMany(
      payload.orderedSiblingInstances.map((i) => ({
        id: i.instanceId,
        changes: {
          rowindex: i.row,
        },
      })),
      state.instances,
    ),
  })),
  on(StartNewReleaseProcess, (state, payload) => ({
    ...state,
    releaseProcesses: ReleaseProcessAdapter.addOne(
      {
        id: payload.id,
        name: 'New Release Process',
        state: EnumsComponentStateEnum.Pending,
        checklist: null,
        component_id: payload.component.component.id,
        component_version: payload.component.latestVersion.index,
        project_id: payload.component.component.project_id,
        created_at: new Date(),
        updated_at: new Date(),
        released: false,
        cancelled: false,
        checklist_template_id: payload.templateId,
        initializer_id: '',
      },
      state.releaseProcesses,
    ),
  })),
  on(NavigateToComponentSucceeded, (state, payload) => ({
    ...state,
    selectedInstanceId: payload.selectedInstanceId,
  })),
];
