import { Injectable } from '@angular/core';
import { Project, ProjectConfiguration } from '@genetpdm/model';
import {
  Observable,
  combineLatest,
  concatMap,
  filter,
  map,
  mergeAll,
  mergeMap,
  shareReplay,
  take,
  tap,
  throwError,
} from 'rxjs';
import { Update } from '@ngrx/entity';
import {
  CadProjectInsertInput,
  CatiaConfiguration,
  ChangeSelectedProjectMutationGQL,
  GetCatiaConfigurationsQueryGQL,
  GetProjectConfigurationGQL,
  GetProjectsGQL,
  InsertProjectsGQL,
  SelectedProjectSubscriptionGQL,
  SetProjectConfigurationActionGQL,
  UpdateProjectGQL,
} from '@genetpdm/model/graphql';
import { handleGraphQlErrors } from '../utility/graphql-utilities';
import { RequiredPartial } from '../utility/required-type';
import { GraphQLModule } from '../graphql-service/graphql.module';
import { SettingsService } from '../settings-service/settings.service';
import { LoggingService } from '../logging-service/logging.service';
import { LoadProjects } from '../../state';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private selectedProject$: Observable<Project>;

  constructor(
    private getProjects: GetProjectsGQL,
    private getProjectConfiguration: GetProjectConfigurationGQL,
    private updateProject: UpdateProjectGQL,
    private insertProjects: InsertProjectsGQL,
    private getCatiaConfigsQuery: GetCatiaConfigurationsQueryGQL,
    private setProjectConfigAction: SetProjectConfigurationActionGQL,
    private changeProjectMutation: ChangeSelectedProjectMutationGQL,
    private selectedProjectSubscription: SelectedProjectSubscriptionGQL,
    private graphQlModule: GraphQLModule,
    private settingsService: SettingsService,
    private logger: LoggingService,
    private store: Store,
  ) {}

  private isInitialized$() {
    return this.graphQlModule.isInitialized$.pipe(
      filter((initialized) => initialized),
    );
  }

  public getAccessibleProjects$(): Observable<Project[]> {
    try {
      return this.isInitialized$().pipe(
        concatMap(() => this.getProjects.watch().valueChanges),
        handleGraphQlErrors(),
        map((result) => [...result.data.cad_project]),
        tap((p) => console.log(`Received project: ${p[0].name}`)),
        take(1),
      );
    } catch (error) {
      return throwError(() => error);
    }
  }

  public getProjectConfiguration$(
    projectId: uuid,
  ): Observable<ProjectConfiguration> {
    try {
      return this.getProjectConfiguration
        .watch({ id: projectId }, { fetchPolicy: 'no-cache' })
        .valueChanges.pipe(
          handleGraphQlErrors(),
          map((result) => result.data.get_project_configuration),
          tap((r) =>
            console.log(`Received project configuration: ${r.project_name}`),
          ),
          take(1),
        );
    } catch (error) {
      return throwError(() => error);
    }
  }

  public updateProjects$(projects: Update<Project>[]) {
    try {
      return combineLatest(
        projects
          .map((u) => <RequiredPartial<Project, 'id'>>u.changes)
          .map((c) =>
            this.updateProject.mutate({
              id: c.id,
              project: c,
            }),
          ),
      ).pipe(mergeAll(), handleGraphQlErrors(), take(1));
    } catch (error) {
      return throwError(() => error);
    }
  }

  public setCurrentProject$(project_id: uuid) {
    return this.settingsService.getUser$().pipe(
      mergeMap((user) =>
        this.setCurrentProjectByUserID$(user.localAccountId, project_id),
      ),
      take(1),
      tap((_) => {
        this.logger.logSuccess(
          `Set Current Project to ${project_id} successful.`,
        );

      }

      ),
    );
  }

  /**
   * Gets the current selected project of the authenticated user.
   */
  public getCurrentProject$(): Observable<Project> {
    if (this.selectedProject$) return this.selectedProject$;

    this.selectedProject$ = this.graphQlModule.isInitialized$.pipe(
      mergeMap((_) => this.getCurrentProjectInternal$()),
      tap((r) => {
        if (!r) return;
      }),
      shareReplay(1),
    );

    return this.selectedProject$;
  }

  public addProjects$(projects: Project[]) {
    try {
      return this.insertProjects
        .mutate({ projects: projects.map((p) => p as CadProjectInsertInput) })
        .pipe(handleGraphQlErrors(), take(1));
    } catch (error) {
      return throwError(() => error);
    }
  }

  public setProjectConfiguration$(projectConfiguration: ProjectConfiguration) {
    const config = {
      id: projectConfiguration.id,
      project_name: projectConfiguration.project_name,
      project_description: projectConfiguration.project_description,
      project_number: projectConfiguration.project_number,
      cad_configuration_id: projectConfiguration.cad_configuration_id,
      locations: projectConfiguration.locations,
      file_to_blob_allowed: projectConfiguration.file_to_blob_allowed,
      blob_to_file_allowed: projectConfiguration.blob_to_file_allowed,
      file_events_allowed: projectConfiguration.file_events_allowed,
    };

    return this.setProjectConfigAction.mutate({ input: config }).pipe(
      handleGraphQlErrors(),
      map((r) => r.data.set_project_configuration),
    );
  }

  public getCadConfigurations$(): Observable<readonly CatiaConfiguration[]> {
    return this.graphQlModule.isInitialized$.pipe(
      mergeMap((_) => this.getCatiaConfigsQuery.watch().valueChanges),
      handleGraphQlErrors(),
      take(1),
      map((r) => r.data.get_catia_configurations),
    );
  }

  private setCurrentProjectByUserID$(user_id: uuid, project_id: uuid) {
    return this.changeProjectMutation
      .mutate({
        user_id: user_id.toString(),
        project_id: project_id.toString(),
      })
      .pipe(
        handleGraphQlErrors(),
        tap((_) => this.logger.logSuccess(`Set Current Project successful.`)),
      );
  }

  private getCurrentProjectInternal$(): Observable<Project> {
    return this.selectedProjectSubscription.subscribe().pipe(
      map((r) => {
        if (r.data.genet_user_settings.some((d) => d.current_project != null)) {
          return r.data.genet_user_settings[0].current_project;
        }
        return null;
      }),
    );
  }
}
