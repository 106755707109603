import { on } from '@ngrx/store';
import {
  LoadVariantsSuceeded,
  LoadVariantsFailed,
  LoadVariantsById,
  LoadRootVariantByProjectId,
} from './actions';
import { VariantAdapter } from './adapter';
import { ExplorerStoreReducerArray } from '../state-model';

/**
 * createReducer is called in component-composite.state.ts
 * @remarks
 * Install VSCode Extension "Colored Regions" and add colors to your settings.json, to display colored regions here.
 */
export const VariantsReducer: ExplorerStoreReducerArray = [
  //#region [Load]
  on(LoadVariantsById, (state) => ({ ...state, isLoading: true })),
  on(LoadRootVariantByProjectId, (state) => ({ ...state, isLoading: true })),
  on(LoadVariantsSuceeded, (state, result) => ({
    ...state,
    variants: VariantAdapter.upsertMany(result.variants, state.variants),
    isLoading: false,
    error: null,
  })),
  on(LoadVariantsFailed, (state, result) => ({
    ...state,
    isLoading: false,
    error: result.error,
  })),
  //#endregion
];
