import { Injectable } from '@angular/core';
import { Logger } from './logger.interface';
import { environment } from '../../../../environments/environment';
import { getLogLevel, LoggerLevel } from './logger-level';

@Injectable({
  providedIn: 'root',
})
export class ConsoleLoggingService implements Logger {
  logTrace(message: string): void {
    if (getLogLevel(environment.logLevel.console) <= LoggerLevel.Trace)
      console.log(message);
  }

  logDebug(message: string): void {
    if (getLogLevel(environment.logLevel.console) <= LoggerLevel.Debug)
      console.log(message);
  }

  logInfo(message: string): void {
    if (getLogLevel(environment.logLevel.console) <= LoggerLevel.Info)
      console.log(message);
  }

  logSuccess(message: string): void {
    if (getLogLevel(environment.logLevel.console) <= LoggerLevel.Info)
      console.log(message);
  }

  logWarn(message: string): void {
    if (getLogLevel(environment.logLevel.console) <= LoggerLevel.Warning)
      console.warn(message);
  }

  logError(message: string): void {
    if (getLogLevel(environment.logLevel.console) <= LoggerLevel.Error)
      console.error(message);
  }

  logFailed(message: string): void {
    if (getLogLevel(environment.logLevel.console) <= LoggerLevel.Error)
      console.error(message);
  }

  logCritical(message: string): void {
    if (getLogLevel(environment.logLevel.console) <= LoggerLevel.Critical)
      console.error(message);
  }
}
