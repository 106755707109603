import { Injectable, ErrorHandler } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LogErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    console.error(err);
  }
}
