import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TreeNode } from './upload-node-fetcher.model';
import { UploadNodeFetchService } from './upload-node-fetcher.service';

@Component({
	selector: 'app-upload-node-fetcher',
	templateUrl: './upload-node-fetcher.component.html',
	styleUrls: ['./upload-node-fetcher.component.css'],
})
export class UploadNodeFetcherComponent {
	get loading(): boolean {
		return this.service.loading;
	}

	get nodes(): TreeNode[] {
		return this.service.nodes;
	}

	public selectedNode: any;

	constructor(
		private service: UploadNodeFetchService,
		public ref: DynamicDialogRef,
		public config: DynamicDialogConfig,
	) {}

	handleClickRefresh(event) {
		this.service.loadNodes();
	}

	handleClickAccept(event) {
		this.ref.close(this.selectedNode.data);
	}

	handleClickCancel(event) {
		this.ref.close(null);
	}
}
