import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AddLog } from './actions';
import { Log, Severity } from '@genetpdm/model';
import { LoggingService } from '../../services';

@Injectable()
export class LogStoreEffects {
  constructor(private logger: LoggingService, private actions$: Actions) {}

  public addLogEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AddLog),
        tap((request) => this.log(request.log))
      ),
    { dispatch: false }
  );

  private log(logMessage: Log): void {
    switch (logMessage.severity) {
      case Severity.Trace:
        this.logger.logTrace(logMessage.message);
        break;
      case Severity.Debug:
        this.logger.logDebug(logMessage.message);
        break;
      case Severity.Info:
        this.logger.logInfo(logMessage.message);
        break;
      case Severity.Success:
        this.logger.logSuccess(logMessage.message);
        break;
      case Severity.Warning:
        this.logger.logWarn(logMessage.message);
        break;
      case Severity.Error:
        this.logger.logError(logMessage.message);
        break;
      case Severity.Critical:
        this.logger.logCritical(logMessage.message);
        break;
      default:
        break;
    }
  }
}
