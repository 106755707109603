import { Component } from '@angular/core';
import { Guid } from 'guid-typescript';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SettingsService } from '../../../@core/services/settings-service/settings.service';
import { Store } from '@ngrx/store';
import { selectCurrentProject } from '../../../@core/state/project/selectors';
import { SessionService } from '../../../@core/services/session-service/session.service';
import { CreateSession } from '../../../@core/state';
import { tap } from 'rxjs';

@Component({
  selector: 'new-session-dialog',
  template: `
    <div class="card">
      <div class="card-header">
        <h3>Create new Session</h3>
      </div>
      <div class="card-body">
        <form>
          <div class="form-group">
            <input
              type="text"
              pInputText
              placeholder="Session Name"
              [(ngModel)]="sessionName"
              [ngModelOptions]="{ standalone: true }"
              style="width: 100%"
              required
            />
          </div>
          <hr />
          <div class="form-group">
            <p>Should the Session be public accessible?</p>
            <p-checkbox
              [(ngModel)]="isPublicSession"
              [ngModelOptions]="{ standalone: true }"
              [binary]="true"
              inputId="update"
            ></p-checkbox>
            <label for="update" style="margin-left: 5px; margin-bottom: 0px"
              >Make Session Public</label
            >
          </div>
          <div class="form-group" *ngIf="isPublicSession">
            <p>
              Should the public Session be readonly? (Only you can maintain it)
            </p>
            <p-checkbox
              [(ngModel)]="isReadOnly"
              [ngModelOptions]="{ standalone: true }"
              [binary]="true"
              inputId="update"
            ></p-checkbox>
            <label for="update" style="margin-left: 5px; margin-bottom: 0px"
              >Make Session Readonly</label
            >
          </div>
          <hr />
          <div class="form-group">
            <p-button
              label="Create New Session"
              (onClick)="handleSubmit($event)"
              icon="fas fa-upload"
              iconPos="left"
            ></p-button>
            <p-button
              label="Cancel"
              (onClick)="handleCancel($event)"
              icon="fas fa-times"
              iconPos="left"
              [style]="{ 'margin-left': '10px' }"
            ></p-button>
          </div>
        </form>
      </div>
    </div>
  `,
})
export class NewSessionDialogComponent {
  sessionName = '';

  isPublicSession = false;
  isReadOnly = false;

  userId: uuid = null;
  projectId: uuid = null;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private settingsService: SettingsService,
    private store: Store
  ) {
    this.store
      .select(selectCurrentProject)
      .pipe(tap(() => console.log('[SessionDialog]: Received project')))
      .subscribe(
        (p) => (this.projectId = p?.id ?? Guid.createEmpty().toString())
      );

    this.settingsService
      .getUser$()
      .subscribe((u) => (this.userId = u.localAccountId));
  }

  handleSubmit(event) {
    if (this.userId == null) {
      throw new Error('UserId was not fetched yet.');
    }
    if (this.projectId == null) {
      throw new Error('ProjectId was not fetched yet.');
    }
    if (this.sessionName == '') {
      throw new Error('Session Name is empty.');
    }

    this.ref.close({
      id: Guid.create().toString(),
      name: this.sessionName,
      project_id: this.projectId,
      readonly: this.isReadOnly,
      shared: this.isPublicSession,
      user_id: this.userId,
      components: [],
    });
  }

  handleCancel(event) {
    this.ref.close(null);
  }
}
