import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { forkJoin, OperatorFunction } from 'rxjs';
import { MenuItem } from 'primeng/api';
import {
  ActionsService,
  File as _File,
  LoggingService,
  SettingsService,
} from '../../../@core/services';
import { BlobService } from '../../../@core/services/blob-service/blob.service';
import {
  AddExistingFilesToComponentMutationGQL,
  CreateFileInput,
  RelationsFileOfComponentInsertInput,
  SearchFilesByRouteQueryGQL,
} from '@genetpdm/model/graphql';
import { ObservableToastService } from '../../../@core/utils/observable-operators/observable-toast.service';

@Component({
  selector: 'add-file-dialog',
  styleUrls: ['./add-file-dialog.scss'],
  template: `
    <div class="card">
      <div class="card-header">
        <h3>Add File for: {{ partNumber }} - {{ componentId }}</h3>
        <p>Version: {{ version }}</p>
      </div>
      <div class="card-body">
        <p-tabMenu [model]="tabMenuItems" [activeItem]="activeTabItem">
        </p-tabMenu>
        <div
          *ngIf="activeTabItem !== null && activeTabItem.label === 'Add New'"
        >
          <form>
            <div class="form-group" *ngIf="!disableUpload">
              <p>Please upload the file(s).</p>
              <p-fileUpload
                name="DrawingFile"
                [customUpload]="true"
                (uploadHandler)="handleUpload($event)"
                [multiple]="true"
                ><ng-template pTemplate="content" let-files>
                  <div
                    style="height: 50px; text-align: center"
                    *ngIf="!files.length"
                  >
                    <i
                      class="far fa-copy fa-3x"
                      style="margin-right: 10px; opacity: 0.2;"
                    ></i>
                    Drag & Drop files here...
                  </div>
                </ng-template></p-fileUpload
              >
            </div>
            <div class="form-group" *ngIf="disableUpload">
              <h4>Upload succeeded!</h4>
              <p>You can now proceed.</p>
            </div>
            <div class="form-group" *ngIf="!disableUpload">
              <p>Please press the "Upload" Button above to upload the files.</p>
            </div>
            <hr />
            <div class="form-group" style="text-align: right">
              <p-button
                label="Add Files"
                (onClick)="handleSubmitNewFile($event)"
                icon="fas fa-upload"
                iconPos="left"
                *ngIf="enableSubmit"
              ></p-button>
              <p-button
                label="Cancel"
                (onClick)="handleCancel($event)"
                icon="fas fa-times"
                iconPos="left"
                [style]="{ 'margin-left': '10px' }"
              ></p-button>
            </div>
          </form>
        </div>
        <div
          *ngIf="
            activeTabItem !== null && activeTabItem.label === 'Add Existing'
          "
          style="padding-top: 25px;"
        >
          <span
            class="p-float-label"
            style="margin-left: 60px;margin-bottom:10px;margin-top: 20px min-width: 100%"
          >
            <input
              type="text"
              pInputText
              [(ngModel)]="filter"
              style="width: 20%; min-width: 20%"
            />
            <label>Search File</label>
          </span>
          <p-pickList
            [source]="sourceList"
            [target]="targetList"
            sourceHeader="Available Files"
            targetHeader="Selected Files"
            [dragdrop]="true"
            [responsive]="true"
            [sourceStyle]="{ height: '30rem' }"
            [targetStyle]="{ height: '30rem' }"
          >
            <ng-template let-file pTemplate="item">
              <div class="file-item">
                <div class="file-list-detail">
                  <h5 class="p-mb-2">{{ file.name }}</h5>
                  <i class="pi pi-tag file-category-icon"></i>
                  <span class="file-category">{{ file.tags.join(', ') }}</span>
                </div>
                <div class="file-list-action">
                  <h6 class="mb-2">{{ file.extension }}</h6>
                  <span> {{ file.updated_at | date : 'medium' }}</span>
                </div>
              </div>
            </ng-template>
          </p-pickList>
          <p-button
            label="Submit"
            icon="pi pi-check"
            (onClick)="handleSubmitExistingFile($event)"
          ></p-button>
        </div>
      </div>
    </div>
  `,
})
export class AddFileDialogComponent implements OnInit {
  currentLocation: string;

  projectId: uuid;
  componentId: uuid;
  partNumber = '';
  version = 0;

  additionalBlobs: string[] = [];

  enableSubmit = false;
  disableUpload = false;

  tabMenuItems: MenuItem[];

  _activeTabItem: MenuItem;
  get activeTabItem(): MenuItem {
    return this._activeTabItem;
  }
  set activeTabItem(value: MenuItem) {
    this._activeTabItem = value;
  }

  sourceList: _File[] = [];
  targetList: _File[] = [];

  _filter = '';
  get filter(): string {
    return this._filter;
  }

  set filter(val) {
    this._filter = val;
    this.searchFiles(val);
  }

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private settingsService: SettingsService,
    private actionsService: ActionsService,
    private blobService: BlobService,
    private searchFilesQuery: SearchFilesByRouteQueryGQL,
    private addExistingFilesMutation: AddExistingFilesToComponentMutationGQL,
    private logger: LoggingService,
    private observableToastService: ObservableToastService
  ) {
    this.tabMenuItems = this.getMenuItems();
  }

  ngOnInit(): void {
    this.partNumber = this.config.data.partNumber;
    this.projectId = this.config.data.projectId;
    this.componentId = this.config.data.componentId;

    this.settingsService.getLocation$.subscribe(
      (location) => (this.currentLocation = location)
    );
  }

  handleUpload(event) {
    this.enableSubmit = false;

    const routePrefix = `Miscellaneous/Files/${this.componentId}/`;
    const files = <File[]>event.files;
    const uploads$ = files.map((file) =>
      this.blobService.uploadBlob$(
        file,
        routePrefix + file.name,
        this.projectId
      )
    );

    forkJoin(uploads$).subscribe((r) => {
      this.additionalBlobs = r.map((b) => b.route);
      this.enableSubmit = true;
      this.disableUpload = true;
    });
  }

  handleSubmitNewFile(event) {
    const input = <CreateFileInput>{
      component_id: this.componentId,
      project_id: this.projectId,
      routes: this.additionalBlobs,
    };

    this.actionsService.createFiles$(input).subscribe();

    this.ref.close();
  }

  handleSubmitExistingFile(_event) {
    const input = this.targetList.map(
      (f) =>
        <RelationsFileOfComponentInsertInput>{
          component_id: this.componentId,
          file_id: f.id,
        }
    );

    this.addExistingFilesMutation
      .mutate({ relations: input })
      .pipe(this.catchAndToast())
      .subscribe((_) => {
        this.ref.close();
      });
  }

  public searchFiles(searchString: string) {
    this.searchFilesQuery
      .watch({
        project_id: this.projectId.toString(),
        name: `%${searchString}%`,
      })
      .valueChanges.subscribe((r) => {
        this.sourceList = r.data.file
          .map((f) => ({
            ...f,
            worker: f.worker.name,
            child_files: f.child_files.map((c) => c.child_id),
            tags: f.tags.map((t) => t.tag),
          }))
          .filter((c) => !this.targetList.some((x) => c.name == x.name));
      });
  }

  private setActiveTab(_filter: string) {
    this.activeTabItem = this.tabMenuItems.filter(
      (item, i) => item.label === _filter
    )[0];
  }

  handleCancel(event) {
    this.ref.close(null);
  }

  private getMenuItems() {
    return [
      {
        label: 'Add New',
        icon: 'far fa-plus-square',
        command: (_) => this.setActiveTab('Add New'),
      },
      {
        label: 'Add Existing',
        icon: 'fas fa-link',
        command: (_) => this.setActiveTab('Add Existing'),
      },
    ];
  }

  private catchAndToast<T>(defaultValue?: T): OperatorFunction<T, T> {
    return this.observableToastService.catchAndToast(defaultValue);
  }
}
