import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
  ErrorHandler,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandlerContainer } from './services/error-handler/error-handler-container';
import { CATIA_API_PROVIDERS } from './services/catia-service/catia-service.provider';
import { FILESYSTEM_API_PROVIDERS } from './services/filesystem-api-service/filesystem-api-service.provider';
import { HttpErrorInterceptor } from './utils/http-error-interceptor/http-error.interceptor';

export const ERROR_HANDLER = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
  },
  {
    provide: ErrorHandler,
    useClass: ErrorHandlerContainer,
  },
];

@NgModule({
  imports: [CommonModule],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...ERROR_HANDLER,
        ...CATIA_API_PROVIDERS,
        ...FILESYSTEM_API_PROVIDERS,
      ],
    };
  }
}

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import Core modules in the AppModule only.`
    );
  }
}
