import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Session } from '@genetpdm/model';

@Component({
  selector: 'rename-session-dialog',
  template: `
    <div class="card">
      <div class="card-header">
        <h3>Rename Session: {{ sessionName }}</h3>
      </div>
      <div class="card-body">
        <form>
          <div class="form-group">
            <input
              type="text"
              pInputText
              placeholder="New Session Name"
              [(ngModel)]="newSessionName"
              [ngModelOptions]="{ standalone: true }"
              style="width: 100%"
            />
          </div>
          <hr />
          <div class="form-group">
            <p-button
              label="Rename Session"
              (onClick)="handleSubmit($event)"
              icon="fas fa-upload"
              iconPos="left"
            ></p-button>
            <p-button
              label="Cancel"
              (onClick)="handleCancel($event)"
              icon="fas fa-times"
              iconPos="left"
              [style]="{ 'margin-left': '10px' }"
            ></p-button>
          </div>
        </form>
      </div>
    </div>
  `,
})
export class RenameSessionDialogComponent implements OnInit {
  session: Session;

  sessionId: uuid;
  sessionName = '';

  newSessionName = '';
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    this.session = this.config.data.session;
    this.sessionId = this.session.id;
    this.sessionName = this.session.name;
  }

  handleSubmit(event) {
    this.ref.close({ name: this.newSessionName });
  }

  handleCancel(event) {
    this.ref.close(null);
  }
}
