import { of, combineLatest } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  switchMap,
  map,
  mergeAll,
  take,
  tap,
  filter,
  withLatestFrom,
} from 'rxjs/operators';
import {
  LoadComponents,
  LoadComponentsSuceeded,
  LoadRootComponentsSucceeded,
  LoadComponentsFailed,
  UpdateComponent,
  UpdateComponentSucceeded,
  UpdateComponentFailed,
  DeleteComponents,
  DeleteComponentsSucceeded,
  DeleteComponentsFailed,
  ExpandComponents,
  LoadRootComponents,
  UpdateComponentInstances,
  UpdateComponentInstancesSucceeded,
  UpdateComponentInstancesFailed,
  LoadTabComponents,
  LoadTabComponentsSuceeded,
  CollapseComponents,
} from './app.actions';

import {
  ComponentMetadataService,
  ComponentMutationService,
  ProjectService,
} from '../../services';
import { LoggingService } from '../../services/logging-service/logging.service';
import { TabLoadAction } from './app.actions';
import {
  SelectTab,
  ActionSuccess,
} from '../@user-interface/user-interface.actions';
import { selectAppState } from './app.selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class ExplorerStoreEffects {
  constructor(
    private metadataService: ComponentMetadataService,
    private mutationService: ComponentMutationService,
    private projectService: ProjectService,
    private actions$: Actions,
    private store: Store,
    private logger: LoggingService,
  ) {}

  public loadComponentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadComponents),
      tap(() => this.logger.logTrace('loadComponentsEffect triggered.')),
      switchMap((request) =>
        this.metadataService.getComponentsByIds$(request.ids).pipe(
          map((result) => LoadComponentsSuceeded({ components: result })),
          catchError((error) =>
            of(LoadComponentsFailed({ error: error, ids: request.ids })),
          ),
        ),
      ),
    ),
  );

  public loadRootComponentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRootComponents),
      tap(() => this.logger.logTrace('loadRootComponentsEffect triggered.')),
      switchMap((request) =>
        this.metadataService
          .getRootComponentByProjectId$(request.projectId)
          .pipe(
            map((result) =>
              LoadRootComponentsSucceeded({ components: result }),
            ),
            catchError((error) =>
              of(
                LoadComponentsFailed({
                  error: error,
                  ids: [request.projectId],
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public loadRootComponentsSucceededEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRootComponentsSucceeded),
      tap(() =>
        this.logger.logTrace('loadRootComponentsSucceededEffect triggered.'),
      ),
      switchMap((result) => of(LoadComponentsSuceeded(result))),
    ),
  );

  public expandPreviousComponentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadRootComponentsSucceeded),
      tap(() => this.logger.logTrace('expanding previous components.')),
      map(() =>
        ExpandComponents({
          componentIds: JSON.parse(localStorage.getItem('expanded')) ?? [],
        }),
      ),
    ),
  );

  public selectTabEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SelectTab),
      tap(() => this.logger.logTrace('selectTabEffect triggered.')),
      filter((t) => t.id == 'checkedOut' || t.id == 'myParts'),
      map((t) => {
        if (t.id == 'checkedOut') {
          return LoadTabComponents({ action: TabLoadAction.checkedOut });
        }
        return LoadTabComponents({ action: TabLoadAction.myParts });
      }),
    ),
  );

  public loadTabComponentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTabComponents),
      tap(() => this.logger.logTrace('LoadTabComponents triggered.')),
      withLatestFrom(this.projectService.getCurrentProject$()),
      switchMap(([request, project]) => {
        if (
          request.action == TabLoadAction.checkedOut ||
          request.action == TabLoadAction.myParts
        ) {
          return this.metadataService.getMyComponents$(project.id).pipe(
            map((result) =>
              LoadTabComponentsSuceeded({
                action: request.action,
                components: result,
              }),
            ),
            catchError((error) =>
              of(LoadComponentsFailed({ error: error, ids: [] })),
            ),
          );
        }
        return of(ActionSuccess({ message: '' }));
      }),
    ),
  );

  public loadTabComponentsSideEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTabComponentsSuceeded),
      tap(() => this.logger.logTrace('loadTabComponentsSideEffect triggered.')),
      map((payload) =>
        LoadRootComponentsSucceeded({ components: payload.components }),
      ),
    ),
  );

  public updateComponentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateComponent),
      tap(() => this.logger.logTrace('updateComponentEffect triggered.')),
      switchMap((request) =>
        combineLatest([
          this.mutationService.updateComponent$({
            id: request.component.id as string,
            changes: { name: request.component_name },
          }),
          this.mutationService.updateComponentVersions$([request.component]),
        ]).pipe(
          mergeAll(),
          map(() => UpdateComponentSucceeded()),
          catchError((error) =>
            of(
              UpdateComponentFailed({
                error: error,
                oldComponent: request.oldComponent,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public updateInstancesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateComponentInstances),
      tap(() => this.logger.logTrace('updateInstancesEffect triggered.')),
      switchMap((request) =>
        this.mutationService.updateComponentInstances$(request.instances).pipe(
          map(() => UpdateComponentInstancesSucceeded()),
          catchError((error) =>
            of(
              UpdateComponentInstancesFailed({
                error: error,
                oldInstances: request.oldInstances,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public deleteComponentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteComponents),
      tap(() => this.logger.logTrace('deleteComponentsEffect triggered.')),
      switchMap((request) =>
        this.mutationService.deleteComponentInstances$(request.components).pipe(
          map(() => DeleteComponentsSucceeded()),
          catchError((error) =>
            of(
              DeleteComponentsFailed({
                error: error,
                components: request.components,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public saveExpandedComponents$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExpandComponents),
        switchMap(() => this.store.select(selectAppState).pipe(take(1))),
        tap((request) => {
          console.log('expanded states saved');
          localStorage.setItem(
            'expanded',
            JSON.stringify(request.expandedComponentIds),
          );
        }),
      ),
    { dispatch: false },
  );

  public saveCollapsedComponents$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollapseComponents),
        tap((request) => {
          console.log('collapsed components deleted');

          let expandedIds = JSON.parse(localStorage.getItem('expanded')) ?? [];

          expandedIds = expandedIds.filter(
            (item) => !request.componentIds.includes(item),
          );

          localStorage.setItem('expanded', JSON.stringify(expandedIds));
        }),
      ),
    { dispatch: false },
  );

  public expandComponentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpandComponents),
      tap((request) =>
        console.log(
          `expandComponentsEffect triggered for ${request.componentIds.join(
            ',',
          )}`,
        ),
      ),
      switchMap((request) =>
        this.metadataService.getComponentsByLeftIds$(request.componentIds).pipe(
          take(1),
          map((result) => LoadComponentsSuceeded({ components: result })),
          catchError((error) =>
            of(
              LoadComponentsFailed({ error: error, ids: request.componentIds }),
            ),
          ),
        ),
      ),
    ),
  );

  public loadComponentsSucceededEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadComponentsSuceeded),
        tap(() => console.log('LoadComponentsSuceeded.')),
      ),
    { dispatch: false },
  );

  public loadComponentsFailedEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadComponentsFailed),
        tap((e) => console.error('LoadComponentsFailed: ' + e.error.message)),
      ),
    { dispatch: false },
  );
}
