import {
  ComponentInstance,
  ComponentInstanceVersion,
  ComponentInstanceVersionsDto,
  ComponentVersion,
  SynchronizingComponent,
} from '@genetpdm/model';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

export enum ExplorerActionTypes {
  LoadComponents = '[Component] Load Components',
  LoadComponentsSucceeded = '[Backend] Load Components Succeeded',
  LoadRootComponents = '[Component] Load Root Components',
  LoadRootComponentsSucceeded = '[Backend] Load Root Components Succeeded',
  LoadComponentsFailed = '[Backend] Load Components Failed',

  LoadComponentVersionsSuceeded = '[Backend] Versions updated',
  ComponentSynchronizationStatusChanged = '[Backend] ComponentSynchronizationStatusChanged',

  LoadTabComponents = '[Component] Load Tab Components',
  LoadTabComponentsSucceeded = '[Backend] Load Tab Components Succeeded',

  LoadComponentAttachedData = '[Component] LoadComponentAttachedData',
  LoadComponentAttachedDataSucceeded = '[Backend] LoadComponentAttachedDataSucceeded',

  UpdateComponent = '[Component] Update Components',
  UpdateComponentSucceeded = '[Backend] Update Components Succeeded',
  UpdateComponentFailed = '[Backend] Update Components Failed',
  RemoveInstancesFromState = '[Component] Removing Instance from State Succeeded',

  UpdateInstances = '[Component] UpdateInstances',
  UpdateInstancesSucceeded = '[Backend] UpdateInstances Succeeded',
  UpdateInstancesFailed = '[Backend] UpdateInstances Failed',

  DeleteComponents = '[Component] Delete Components',
  DeleteComponentsSucceeded = '[Backend] Delete Components Succeeded',
  DeleteComponentsFailed = '[Backend] Delete Components Failed',

  ExpandComponents = '[Component] Expand Components',
  CollapseComponents = '[Component] Collapse Components',
}

//#region [Load]

/**
 * Request to load all components with specified ids from backend.
 */
export const LoadComponents = createAction(
  ExplorerActionTypes.LoadComponents,
  props<{ ids: uuid[] }>(),
);

/**
 * Request to load all components with specified ids from backend.
 */
export const LoadRootComponents = createAction(
  ExplorerActionTypes.LoadRootComponents,
  props<{ projectId: uuid }>(),
);

/**
 * Backend returns components.
 */
export const LoadRootComponentsSucceeded = createAction(
  ExplorerActionTypes.LoadRootComponentsSucceeded,
  props<{ components: ComponentInstanceVersionsDto }>(),
);

/**
 * Backend returns components.
 */
export const LoadComponentsSuceeded = createAction(
  ExplorerActionTypes.LoadComponentsSucceeded,
  props<{ components: ComponentInstanceVersionsDto }>(),
);

/**
 * Backend returns components.
 */
export const LoadComponentVersionsSuceeded = createAction(
  ExplorerActionTypes.LoadComponentVersionsSuceeded,
  props<{ components: ComponentVersion[] }>(),
);

/**
 * Backend returns components.
 */
export const ComponentSynchronizationStatusChanged = createAction(
  ExplorerActionTypes.ComponentSynchronizationStatusChanged,
  props<{ components: SynchronizingComponent[] }>(),
);

/**
 * Backend returns error.
 * Failed to load components with specified ids.
 *
 * @remarks
 * Use the ids to reset the components in the state.
 */
export const LoadComponentsFailed = createAction(
  ExplorerActionTypes.LoadComponentsFailed,
  props<{ error: Error; ids: uuid[] }>(),
);

export enum TabLoadAction {
  root,
  checkedOut,
  myParts,
  search,
  subRoot,
}

export const LoadTabComponents = createAction(
  ExplorerActionTypes.LoadTabComponents,
  props<{ action: TabLoadAction; searchString?: string }>(),
);

export const LoadTabComponentsSuceeded = createAction(
  ExplorerActionTypes.LoadTabComponentsSucceeded,
  props<{ action: TabLoadAction; components: ComponentInstanceVersionsDto }>(),
);

export const LoadComponentAttachedData = createAction(
  ExplorerActionTypes.LoadComponentAttachedData,
  props<{ ids: uuid[] }>(),
);

export const LoadComponentAttachedDataSucceeded = createAction(
  ExplorerActionTypes.LoadComponentAttachedDataSucceeded,
  props<{ ids: uuid[] }>(),
);

//#endregion

//#region [Update]

/**
 * Request the backend to update all specified properties.
 */
export const UpdateComponent = createAction(
  ExplorerActionTypes.UpdateComponent,
  props<{
    component: Update<ComponentVersion>;
    component_name?: string;
    oldComponent: ComponentInstanceVersion;
  }>(),
);

/**
 * Request the backend to update all specified properties.
 */
export const RemoveInstancesFromState = createAction(
  ExplorerActionTypes.RemoveInstancesFromState,
  props<{
    instance_ids: string[];
  }>(),
);

/**
 * Backend updated the requested properties and returns the updated components.
 */
export const UpdateComponentSucceeded = createAction(
  ExplorerActionTypes.UpdateComponentSucceeded,
);

/**
 * Backend failed to update the requested properties.
 *
 * @remarks
 * Use the ids to reset the components in the state.
 *
 * @returns The Error and the unchanged ids of the requested components.
 */
export const UpdateComponentFailed = createAction(
  ExplorerActionTypes.UpdateComponentFailed,
  props<{ error: Error; oldComponent: ComponentInstanceVersion }>(),
);

//#endregion

/**
 * Request the backend to update all specified properties.
 */
export const UpdateComponentInstances = createAction(
  ExplorerActionTypes.UpdateInstances,
  props<{
    instances: Update<ComponentInstance>[];
    oldInstances?: ComponentInstance[];
  }>(),
);

/**
 * Backend updated the requested properties and returns the updated components.
 */
export const UpdateComponentInstancesSucceeded = createAction(
  ExplorerActionTypes.UpdateInstancesSucceeded,
);

/**
 * Backend failed to update the requested properties.
 *
 * @remarks
 * Use the ids to reset the components in the state.
 *
 * @returns The Error and the unchanged ids of the requested components.
 */
export const UpdateComponentInstancesFailed = createAction(
  ExplorerActionTypes.UpdateInstancesFailed,
  props<{ error: Error; oldInstances?: ComponentInstance[] }>(),
);

//#endregion

//#region [Delete]

/**
 * Request the backend to delete all specified components.
 *
 * @remarks
 * We add whole components to the payload, since we can recover the state in case of failure.
 */
export const DeleteComponents = createAction(
  ExplorerActionTypes.DeleteComponents,
  props<{ components: ComponentInstanceVersion[] }>(),
);

/**
 * Deletion Succeeded
 */
export const DeleteComponentsSucceeded = createAction(
  ExplorerActionTypes.DeleteComponentsSucceeded,
);

/**
 * Deletion failed.
 *
 * @remarks
 * Recover the state with the payload.
 */
export const DeleteComponentsFailed = createAction(
  ExplorerActionTypes.DeleteComponentsFailed,
  props<{ error: Error; components: ComponentInstanceVersion[] }>(),
);

//#endregion

/**
 * Request to expand component nodes and load all children
 */
export const ExpandComponents = createAction(
  ExplorerActionTypes.ExpandComponents,
  props<{ componentIds: uuid[] }>(),
);

/**
 * Request to collapse component nodes
 */
export const CollapseComponents = createAction(
  ExplorerActionTypes.CollapseComponents,
  props<{ componentIds: uuid[] }>(),
);
