import { createSelector } from '@ngrx/store';
import { from } from 'linq-to-typescript';
import {
  ComponentAdapter,
  ComponentInstanceAdapter,
  ComponentVersionAdapter,
  ComponentSubversionAdapter,
} from '../@app-state/app.adapter';
import {
  GetChildComponentInstanceVersions,
  GetComponentInstanceVersions,
  buildTreeWithRootNodes,
  selectAppState,
} from '../@app-state/app.selectors';
import { SessionAdapter } from './state';
import { ISessionViewModel } from '../../../modules/design/components/session/session.view-model';

export const selectCurrentSession = createSelector(selectAppState, (state) => {
  const sessions = SessionAdapter.getSelectors().selectAll(state.sessions);

  if (state.selectedSessionId == '') return null;

  const selectedSession = sessions.find((s) => s.id == state.selectedSessionId);

  return selectedSession;
});

export const selectCurrentSessionComponents = createSelector(
  selectAppState,
  selectCurrentSession,
  (state, session) => {
    if (!session?.components) {
      return [];
    }

    const componentIds = session.components.map((c) => c.component_id);

    const componentMap = ComponentAdapter.getSelectors().selectEntities(
      state.components,
    );

    const components = from(componentIds)
      .select((id) => componentMap[id] ?? null)
      .toArray();

    return GetComponentInstanceVersions(
      componentIds,
      components,
      ComponentInstanceAdapter.getSelectors().selectAll(state.instances),
      ComponentVersionAdapter.getSelectors().selectAll(state.componentVersions),
      ComponentSubversionAdapter.getSelectors().selectAll(
        state.componentSubversions,
      ),
      state.expandedSessionComponentIds,
      state.settings.user.name,
      state.synchronizingComponentIds
    );
  },
);

export const selectVisibleSessionComponentInstanceVersions = createSelector(
  selectCurrentSessionComponents,
  selectAppState,
  (rootComponents, state) =>
    from(rootComponents)
      .concatenate(
        from(
          GetComponentInstanceVersions(
            state.expandedSessionComponentIds,
            ComponentAdapter.getSelectors().selectAll(state.components),
            ComponentInstanceAdapter.getSelectors().selectAll(state.instances),
            ComponentVersionAdapter.getSelectors().selectAll(
              state.componentVersions,
            ),
            ComponentSubversionAdapter.getSelectors().selectAll(
              state.componentSubversions,
            ),
            state.expandedSessionComponentIds,
            state.settings.user.name,
            state.synchronizingComponentIds
          ),
        ),
      )
      .concatenate(
        from(
          GetChildComponentInstanceVersions(
            state.expandedSessionComponentIds,
            ComponentAdapter.getSelectors().selectAll(state.components),
            ComponentInstanceAdapter.getSelectors().selectAll(state.instances),
            ComponentVersionAdapter.getSelectors().selectAll(
              state.componentVersions,
            ),
            ComponentSubversionAdapter.getSelectors().selectAll(
              state.componentSubversions,
            ),
            state.settings.user.name,
            state.synchronizingComponentIds
          ),
        ),
      )
      .toArray(),
);

export const selectSessionViewModel = createSelector(
  selectAppState,
  selectCurrentSessionComponents,
  selectVisibleSessionComponentInstanceVersions,
  (state, rootComponents, components) => {
    try {
      const tree = buildTreeWithRootNodes(rootComponents, components);

      const state_ = <ISessionViewModel>{
        sessions: SessionAdapter.getSelectors().selectAll(state.sessions),
        selectedSessionId: state.selectedSessionId,
        nodes: tree,
        selectedNode: state.selected,
        isLoading: state.isLoading,
        error: state.error,
      };

      return state_;
    } catch (error) {
      console.error(error);
      return <ISessionViewModel>{
        sessions: SessionAdapter.getSelectors().selectAll(state.sessions),
        selectedSessionId: state.selectedSessionId,
        nodes: [],
        selectedNode: state.selected,
        isLoading: state.isLoading,
        error: error,
      };
    }
  },
);
