import { Injectable } from '@angular/core';
import { Logger } from './logger.interface';
import { LoggerLevel, getLogLevel } from './logger-level';

import { MessageService } from 'primeng/api';
import { environment } from '../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ToastLoggingService implements Logger {
  constructor(private messageService: MessageService) {}

  logTrace(message: string): void {
    if (this.ignoreMessage(message)) return;
    if (getLogLevel(environment.logLevel.toast) <= LoggerLevel.Trace)
      this.messageService.add({
        severity: 'info',
        summary: 'Trace',
        detail: message,
        sticky: false,
        life: 3000,
        closable: true,
      });
  }

  logDebug(message: string): void {
    if (this.ignoreMessage(message)) return;
    if (getLogLevel(environment.logLevel.toast) <= LoggerLevel.Debug)
      this.messageService.add({
        severity: 'info',
        summary: 'Debug',
        detail: message,
        sticky: false,
        life: 3000,
        closable: true,
      });
  }

  logInfo(message: string): void {
    if (this.ignoreMessage(message)) return;
    if (getLogLevel(environment.logLevel.toast) <= LoggerLevel.Info)
      this.messageService.add({
        severity: 'info',
        summary: 'Info',
        detail: message,
        sticky: false,
        life: 10000,
        closable: true,
      });
  }

  logSuccess(message: string): void {
    if (this.ignoreMessage(message)) return;
    if (getLogLevel(environment.logLevel.toast) <= LoggerLevel.Info)
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: message,
        sticky: false,
        life: 10000,
        closable: true,
      });
  }

  logWarn(message: string): void {
    if (this.ignoreMessage(message)) return;
    if (getLogLevel(environment.logLevel.toast) <= LoggerLevel.Warning)
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: message,
        sticky: false,
        life: 10000,
        closable: true,
      });
  }

  logError(message: string): void {
    //Use logFailed to display a message to the user
    return;
  }

  logFailed(message: string): void {
    if (this.ignoreMessage(message)) return;
    if (getLogLevel(environment.logLevel.toast) <= LoggerLevel.Error)
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: message,
        sticky: false,
        life: 10000,
        closable: true,
      });
  }

  logCritical(message: string): void {
    if (this.ignoreMessage(message)) return;
    if (getLogLevel(environment.logLevel.toast) <= LoggerLevel.Critical)
      this.messageService.add({
        severity: 'error',
        summary: 'Critical Error',
        detail: message,
        sticky: true,
        life: 10000,
        closable: true,
      });
  }

  private ignoreMessage(error: string): boolean {
    if (!environment.production) return false;
    return (
      error.includes('Cannot read') ||
      error.includes('is not a function') ||
      error.includes('Exception of type') ||
      error.includes('Uncaught') ||
      error.includes('Error while downloading') ||
      error.includes('not found in type')
    );
  }
}
