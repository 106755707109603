import { Component, ElementRef, ViewChild } from '@angular/core';
import { AppSidebarComponent } from './app.sidebar.component';
import { LayoutService } from './service/app.layout.service';
import { AuthService, getProfilePicture$ } from '../@core/services';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { versionInfo } from '../../environments/version';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  styleUrls: ['./app.topbar.component.scss'],
})
export class AppTopbarComponent {
  @ViewChild('menubutton') menuButton!: ElementRef;
  @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;
  activeItem!: number;

  profilePicture: SafeUrl;

  pdmVersion = '4.0.0';

  constructor(
    private authService: AuthService,
    public layoutService: LayoutService,
    public el: ElementRef,
    private sanitizer: DomSanitizer,
  ) {
    getProfilePicture$(this.authService).subscribe((blob) => {
      const objectURL = URL.createObjectURL(blob);
      this.profilePicture = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    });

    this.pdmVersion = versionInfo.version;
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onSidebarButtonClick() {
    this.layoutService.showSidebar();
  }

  onConfigButtonClick() {
    this.layoutService.showConfigSidebar();
  }
}
