import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { Log } from './logs-view.model';
import { MenuItem } from 'primeng/api';
import { AttachedFilesViewComponent } from '../attached-files-view/attached-files-view.component';
import {
  BlobService,
  ComponentMetadataService,
  ImageService,
} from '../../../@core/services';
import { ComponentInstanceVersion } from '@genetpdm/model';

@Component({
  selector: 'logs-view',
  template: `
    <h3>Change Logs of: {{ this.component.component_name }}</h3>

    <p-table [value]="logs" [autoLayout]="true">
      <ng-template pTemplate="header">
        <tr>
          <th>Date</th>
          <th>Worker</th>
          <th>ChangeType</th>
          <th>Comment</th>
          <th>Version</th>
          <th>Subversion</th>
          <th>Part Number</th>
          <th>Route</th>
          <th>VersionId</th>
          <th>Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-log>
        <tr
          [iTooltip]="log.preview_url$ | async"
          [showDelay]="500"
          tooltipPosition="right"
          tooltipZIndex="1200"
        >
          <td>{{ log.created_at | date : 'medium' }}</td>
          <td>{{ log.worker }}</td>
          <td>{{ log.type }}</td>
          <td>{{ log.comment }}</td>
          <td>{{ log.version }}</td>
          <td>{{ log.subversion }}</td>
          <td>{{ log.part_number }}</td>
          <td>{{ log.route }}</td>
          <td>{{ log.version_id }}</td>
          <td>
            <button
              pButton
              pRipple
              type="button"
              title="Download Subversion"
              icon="fas fa-file-download"
              class="p-button-rounded p-button-secondary p-button-text"
              *ngIf="log.downloadAction"
              (click)="log.downloadAction()"
            ></button>
            <button
              pButton
              pRipple
              type="button"
              title="Open attached Files"
              icon="fas fa-paperclip"
              class="p-button-rounded p-button-secondary p-button-text"
              *ngIf="log.attached_file"
              (click)="log.seeAttachedAction()"
              #btn
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  `,
  providers: [DatePipe, DialogService],
})
export class LogsViewComponent implements OnInit {
  component: ComponentInstanceVersion;

  logs: Log[];

  items: MenuItem[];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private componentService: ComponentMetadataService,
    private imageService: ImageService,
    private blobService: BlobService,
    public dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.component = this.config.data.component;


    this.componentService.getComponentLogs$(this.component.component.id).subscribe(
      (r) =>
        (this.logs = this.getDistinctArrayByID(
          r.map((i) => ({
            ...i,
            preview_url$: this.imageService.getImageDataUrl$(
              i.attached_file,
              this.component.component.project_id
            ),
            downloadAction:
              i.blob?.length > 0
                ? () => this.download(i.route, i.version_id)
                : null,
            seeAttachedAction: () => this.seeAttached(i.log_id, this.component.component.project_id),
          }))
        ))
    );
  }

  private getDistinctArrayByID(logs: Log[]) {
    return [...new Map(logs.map((item) => [item['log_id'], item])).values()];
  }

  private download(route: string, versionId: string) {
    this.blobService.downloadVersionToFileSystem(
      route,
      this.component.component.project_id,
      versionId
    );
  }

  // Obsolete
  private seeAttached(logId: uuid, projectId: uuid) {
    this.dialogService.open(AttachedFilesViewComponent, {
      header: 'Attached Files',
      width: '70%',
      data: {
        logId: logId,
        projectId: projectId,
      },
    });
  }
}
