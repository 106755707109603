import { NgModule } from '@angular/core';
import { Apollo, ApolloModule } from 'apollo-angular';
import {
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client/core';

import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { WebSocketLink } from '@apollo/client/link/ws';
import { of, Subject } from 'rxjs';
import { shareReplay, tap, map, catchError } from 'rxjs/operators';

import { getMainDefinition } from '@apollo/client/utilities';

import { setContext } from '@apollo/client/link/context';

import { AuthService, getAuthHeaderMap$ } from '../auth-service/auth.service';
import { CreateOrUpdateUserActionGQL } from '@genetpdm/model/graphql';
import { environment } from '../../../../environments/environment';
import { versionInfo } from '../../../../environments/version';


const GQL_WS_ENDPOINT = environment.graphqlWSEndpoint;
const GQL_HTTP_ENDPOINT = environment.graphqlHTTPEndpoint;

@NgModule({
  imports: [CommonModule, HttpClientModule, ApolloModule],
  providers: [],
  declarations: [],
})
export class GraphQLModule {
  private _isInitialized$ = new Subject<boolean>();
  public isInitialized$ = this._isInitialized$.pipe(shareReplay(1));

  private apolloInitialized$ = new Subject<boolean>();

  constructor(
    public apollo: Apollo,
    private authService: AuthService,
    private createOrUpdateUserAction: CreateOrUpdateUserActionGQL //private toastService: NbToastrService
  ) {
    getAuthHeaderMap$(this.authService).subscribe((headerMap) => {
      this.initApollo(headerMap);
    });

    const disposable = this.apolloInitialized$.subscribe((_) => {
      this.createOrUpdateUserAndInitialize();
      disposable.unsubscribe();
    });
  }

  private initApollo = (headers: any) => {
    console.log(`${this.constructor.name}: Initializing Apollo Client...`);

    const basic = setContext((operation, context) => ({
      headers: {
        Accept: 'charset=utf-8',
      },
    }));

    const auth = setContext((operation, context) => {
      return {
        headers: headers,
      };
    });

    console.log(headers['Authorization']);

    const httpLink = ApolloLink.from([
      basic,
      auth,
      new HttpLink({ uri: GQL_HTTP_ENDPOINT }),
    ]);

    const wsLink = new WebSocketLink({
      uri: GQL_WS_ENDPOINT,
      lazy: true, // For some reason, this is required when specifying multiple headers.
      options: {
        reconnect: true,
        lazy: true,
        connectionParams: {
          headers: headers,
        },
      },
    });

    // The split function takes three parameters:
    //
    // * A function that's called for each operation to execute
    // * The Link to use for an operation if the function returns a "truthy" value
    // * The Link to use for an operation if the function returns a "falsy" value
    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return true;
        //definition.kind === 'OperationDefinition' &&
        //definition.operation === 'subscription'
      },
      wsLink,
      httpLink
    );

    try {
      this.apollo.removeClient('default');
    } catch (error) {
      //Ignore
    }

    this.apollo.create(
      {
        link: splitLink,
        cache: new InMemoryCache(),
        name: 'GenetPDM Angular Frontend',
        version: versionInfo.version,
      },
      'default'
    );

    //@ts-ignore
    //const subscriptionClient =
    //	wsLink.subscriptionClient as SubscriptionClient;
    //
    //subscriptionClient.onConnecting(() =>
    //	console.log('GraphQL WSLink: connecting...'),
    //);
    //subscriptionClient.onConnected(() =>
    //	console.log('GraphQL WSLink: connected'),
    //);
    //subscriptionClient.onReconnecting(() =>
    //	console.log('GraphQL WSLink: reconnecting...'),
    //);
    //subscriptionClient.onReconnected(() =>
    //	console.log('GraphQL WSLink: reconnected'),
    //);
    //subscriptionClient.onError((err) =>
    //	console.log('GraphQL WSLink ERROR: ' + err),
    //);

    console.log(`${this.constructor.name}: Apollo Client initialized.`);

    this.apolloInitialized$.next(true);
  };

  private createOrUpdateUserAndInitialize() {
    this.createOrUpdateUserAction
      .mutate()
      .pipe(
        map((result) => result.data.create_or_update_user),
        catchError((ex) => of({ success: false, message: ex.message })),
        tap((result) => {
          if (result.success) {
            //this.toastService.success('Logged in successfully!');
          }
          if (!result.success) {
            // this.toastService.danger(
            //   'User could not be logged in: ' + result.message,
            //   'User Error',
            //   { duration: 20000 }
            // );
          }
        }),
        map((result) => result.success)
      )
      .subscribe(this._isInitialized$);
  }
}
