import { EnumsProjectPermissionEnum } from '../@graphql/_generated';

export class ProjectPermissions {
  public projectId: uuid;

  public readProject = true;

  public insertProject = false;

  public updateProject = false;

  public insertComponent = false;

  public updateComponent = false;

  public insertPermission = false;

  public checkOut = false;

  public updateInstance = false;

  public insertVariant = false;

  public deleteVariant = false;

  public readTask = false;

  public insertTask = false;

  public updateTask = false;

  public static fromEnumArray(
    projectId: uuid,
    permissions: EnumsProjectPermissionEnum[]
  ): ProjectPermissions {

    const permission = new ProjectPermissions();
    permission.projectId = projectId;

    Object.keys(permission).forEach((key) => {
      if (permissions.some((p) => p.toLowerCase() === key.toLowerCase())) {
        permission[key] = true;
      }
    });

    return permission;
  }
}
