/**
 * Service to set and retrieve settings
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { map, shareReplay, mergeMap, filter, tap, take } from 'rxjs/operators';
import { Guid } from 'guid-typescript';
import { AccountInfo } from '@azure/msal-common';
import { UserSettings } from '@genetpdm/model';
import {
  ChangeSelectedLocationMutationGQL,
  ChangeSelectedProjectMutationGQL,
  EnumsLocationEnum,
  GetUserSettingsSubscriptionGQL,
} from '@genetpdm/model/graphql';
import { handleGraphQlErrors } from '../utility/graphql-utilities';
import { GraphQLModule } from '../graphql-service/graphql.module';
import { AuthService } from '../auth-service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  public getSettings$: Observable<UserSettings>;

  private userId: Guid;

  public getLocation$: Observable<string>;

  constructor(
    private userSettingsSubscription: GetUserSettingsSubscriptionGQL,
    private changeLocationMutation: ChangeSelectedLocationMutationGQL,
    private changeProjectMutation: ChangeSelectedProjectMutationGQL,
    private authService: AuthService,
    private graphQLModule: GraphQLModule
  ) {
    this.getSettings$ = this.graphQLModule.isInitialized$.pipe(
      filter((initialized) => initialized),
      tap((_) => console.log('Requesting User Settings')),
      mergeMap(() => this.userSettingsSubscription.subscribe()),
      map((r) => r.data.genet_user_settings[0]),
      tap((_) =>
        console.log(
          'Received User Settings: ' +
            JSON.stringify(_)
        )
      ),
      shareReplay(1)
    );

    this.getLocation$ = this.getSettings$.pipe(
      map((s) => s.location.toString())
    );

    this.authService.user$.subscribe(
      (u) => (this.userId = Guid.parse(u.localAccountId))
    );
  }

  /**
   * Gets the current authenticated user.
   */
  getUser$(): Observable<AccountInfo> {
    return this.authService.user$;
  }

  getSelectedProjectId$() {
    return this.getSettings$.pipe(
      map((s) => s.current_project_id),
      take(1)
    );
  }

  setCurrentLocation$(location: EnumsLocationEnum) {
    return this.changeLocationMutation
      .mutate({
        user_id: this.userId.toString(),
        location: location,
      })
      .pipe(handleGraphQlErrors());
  }

  setCurrentProject$(projectId: uuid) {
    return this.changeProjectMutation
      .mutate({
        user_id: this.userId.toString(),
        project_id: projectId,
      })
      .pipe(handleGraphQlErrors());
  }
}
