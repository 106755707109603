/**
 * Provides a CatiaAPIClientFactory, so that CatiaAPIClient can be used from dependency injection
 */

import { FileSystemAPIClient } from '@genetsystems/genet-pdm-messaging-model';
import { environment } from '../../../../environments/environment';

export function fileSystemApiClientFactory() {
  return new FileSystemAPIClient(environment.localApiOptions.endpoint);
}

export const FILESYSTEM_API_PROVIDERS = [
  {
    provide: FileSystemAPIClient,
    useFactory: fileSystemApiClientFactory,
  },
];
