import { Component, OnInit } from '@angular/core';
import { ComponentInstanceVersion } from '@genetpdm/model';
import {
  EnumsTagSetInput,
  GetTagsOfComponentQueryGQL,
  GetTagsQueryGQL,
  InsertComponentTagsMutationGQL,
  RelationsTagOfComponentInsertInput,
} from '@genetpdm/model/graphql';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'add-component-tag-dialog',
  template: `
    <div class="card">
      <div class="card-header">
        <h3>
          Add Tag for: {{ component.latestVersion.part_number }} -
          {{ component.component.id }}
        </h3>
      </div>
      <div class="card-body">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <h5>Please type your prefered tag</h5>
              <span class="p-fluid">
                <p-autoComplete
                  [(ngModel)]="texts"
                  [suggestions]="results"
                  (completeMethod)="search($event)"
                  [multiple]="true"
                ></p-autoComplete>
              </span>
              <button
                pButton
                pRipple
                type="button"
                (click)="addTag(texts, selectedTags)"
                label="add Tag"
                [style]="{ 'margin-top': '20px', 'margin-bottom': '20px' }"
                [disabled]="!texts || texts.length === 0"
              ></button>

              <h5>Tags associated with this component:</h5>
              <p-listbox
                [options]="existingTags"
                [(ngModel)]="selectedTags"
                [metaKeySelection]="false"
                [checkbox]="true"
                [multiple]="true"
                [listStyle]="{ 'max-height': '250px' }"
              >
              </p-listbox>
              <button
                pButton
                pRipple
                [disabled]="!selectedTags || selectedTags.length === 0"
                type="button"
                (click)="createNewTag()"
                icon="pi pi-times"
                [style]="{
                  'margin-bottom': '20px',
                  'background-color': 'red',
                  color: 'white',
                  'border-color': 'red'
                }"
              ></button>
            </div>

            <div class="col-md-6">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon"
                  ><i class="pi pi-tag"></i
                ></span>
                <input
                  type="text"
                  pattern="[a-z0-9]+"
                  pInputText
                  placeholder="create new tag in lower case"
                  [(ngModel)]="tagToAdd"
                  #inputField="ngModel"
                />
              </div>
              <button
                pButton
                pRipple
                [disabled]="inputField.invalid"
                type="button"
                (click)="createNewTag()"
                label="new Tag"
                [style]="{ 'margin-top': '20px', 'margin-bottom': '20px' }"
              ></button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <p-button
          (onClick)="submit($event)"
          label="Submit"
          icon="pi pi-check"
          [style]="{ 'margin-top': '20px' }"
          iconPos="right"
        ></p-button>
      </div>
    </div>
  `,
})
export class AddComponentTagDialogComponent implements OnInit {
  results: string[];
  texts: string[] = [];
  searchstring: string;
  existingTags: string[];
  selectedTags: string[] = [];
  partNumber = '';
  component: ComponentInstanceVersion;

  tagToAdd: string;

  constructor(
    private getTagsQuery: GetTagsQueryGQL,
    private getTagsOfComponentQuery: GetTagsOfComponentQueryGQL,
    private insertComponentTagsMutation: InsertComponentTagsMutationGQL,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {}

  search(event) {
    this.getTagsByQuery(event.query);
  }

  getTagsByQuery(searchString: string) {
    return this.getTagsQuery
      .watch({
        search_string: `%${searchString}%`,
      })
      .valueChanges.subscribe(
        (r) => (this.results = r.data.enums_tag.map((i) => i.tag)),
      );
  }

  createNewTag() {
    const tag = <EnumsTagSetInput>{
      tag: this.tagToAdd,
    };
    const relation = <RelationsTagOfComponentInsertInput>{
      component_id: this.component.component.id,
      tag: tag.tag,
    };

    this.insertComponentTagsMutation
      .mutate({ tags: tag, relations: relation })
      .subscribe(() => this.getTagsOfComponent());

    this.tagToAdd = null;
  }

  addTag(tags: string[], tags2: string[]) {
    tags.concat(tags2);
    const relations = tags.map(
      (t) =>
        <RelationsTagOfComponentInsertInput>{
          component_id: this.component.component.id,
          tag: t,
        },
    );

    this.insertComponentTagsMutation
      .mutate({ tags: [], relations: relations })
      .subscribe((_) => this.getTagsOfComponent());

    this.texts = null;
  }

  getTagsOfComponent() {
    return this.getTagsOfComponentQuery
      .watch(
        {
          component_id: this.component.component.id,
        },
        { fetchPolicy: 'no-cache' },
      )
      .valueChanges.subscribe(
        (r) =>
          (this.existingTags = r.data.relations_tag_of_component.map(
            (t) => t.tag,
          )),
      );
  }

  submit(event) {
    this.ref.close(null);
  }

  ngOnInit(): void {
    this.component = this.config.data.component;
    this.partNumber = this.component.component_name;
    this.getTagsOfComponent();
  }
}
