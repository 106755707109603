export abstract class Routes {
  public static designFolderRoute = 'Design';
  public static externalFolderRoute = 'External';
  public static drawingsFolderRoute = 'Drawings';
  public static startModelsFolderRoute = 'StartModels';
  public static rootSessionFolderRoute = 'RootSession';
  public static previewsFolderRoute = 'Previews';
  public static stlFolderRoute = 'STLS';
  public static cacheFolderRoute = 'Cache';

  public static getSessionsFolderRoute(
    location: string,
    username: string,
    sessionName: string
  ) {
    return `Local/${location}/Sessions/${this.cleanName(
      username
    )}/${sessionName}`;
  }

  public static getCheckOutFolderRoute(location: string, username: string) {
    return `Local/${location}/CheckOut/${this.cleanName(username)}`;
  }

  public static getVariantFolderRoute(variantId: uuid, variantName: string) {
    return `Variants/${this.cleanName(variantName)}_${variantId}`;
  }

  public static getStlsFolderRoute(
    partName: string,
  ) {
    return `${this.previewsFolderRoute}/${this.stlFolderRoute}/${this.cleanName(partName)}.stl`;
  }

  public static getPrewiewImageRoute(partNmuber: string) {
    return `Previews/JPG/${partNmuber}.jpg`;
  }

  public static getCgrFolderRoute(
    parentComponentName: string,
    parentComponentId: uuid
  ) {
    return `CGRs/${this.cleanName(parentComponentName)}_${parentComponentId}`;
  }

  public static getMiscFolderRoute(identifier: string) {
    return `Miscellaneous/${identifier}`;
  }

  public static getUncPathFromRoute(
    route: string,
    location: string,
    getProjectUncRoot: () => string
  ) {
    return route.replace(`Local/${location}`, getProjectUncRoot());
  }

  private static cleanName(name: string): string {
    return name.replace(' ', '_');
  }
}
