import { Observable, throwError, of, OperatorFunction } from 'rxjs';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import { SubscriptionResult } from 'apollo-angular';
import { tap } from 'rxjs/operators';

export type GQLResult<T> =
  | ApolloQueryResult<T>
  | SubscriptionResult<T>
  | FetchResult<T>;

export function throwIfGQLError<T>(): OperatorFunction<
  GQLResult<T>,
  GQLResult<T>
> {
  return _throwOrPassGQLError;
}

function _throwOrPassGQLError<T>(
  source$: Observable<GQLResult<T>>
): Observable<GQLResult<T>> {
  return source$.pipe(
    tap((result) => {
      if (!result) {
        throwError(() => new Error('Connection to Hasura had an error.'));
      }
      if (result.errors) {
        throwError(
          () =>
            new Error(
              GetUnauthorizedMessageOrElse(
                result.errors?.map((e) => e.message).join('\n') ?? ''
              )
            )
        );
      }
    })
  );
}

function GetUnauthorizedMessageOrElse(message: string) {
  if (
    message.includes('not found in type') ||
    message.includes('no such type exists in the schema') ||
    message.includes('authori') // TODO: Check real string
  ) {
    return 'User Not Authorized for Query or Mutation!';
  }
  return message;
}
