import { on } from '@ngrx/store';
import {
  LoadProjects,
  LoadProjectsSuceeded,
  LoadProjectsFailed,
  UpdateProjects,
  UpdateProjectsSucceeded,
  UpdateProjectsFailed,
  AddProjects,
  AddProjectsFailed,
  AddProjectsSucceeded,
  LoadSelectedProjectId,
  LoadSelectedProjectIdSuceeded,
  LoadSelectedProjectIdFailed,
  LoadProjectConfiguration,
  LoadProjectConfigurationSuceeded,
  LoadProjectConfigurationFailed,
  SyncStateWithNewProject,
} from './actions';
import {
  ProjectAdapter,
  ProjectConfigurationAdapter,
} from '../@app-state/app.adapter';
import { ExplorerStoreReducerArray } from '../state-model';

/**
 * Reducer to adapt the state to actions that were called.
 *
 * @remarks
 * Install VSCode Extension "Colored Regions" and add colors to your settings.json, to display colored regions here.
 */
export const ProjectsReducer: ExplorerStoreReducerArray = [
  //#region [Load]
  on(LoadSelectedProjectId, (state) => ({ ...state, isLoading: true })),
  on(LoadSelectedProjectIdSuceeded, (state, result) => ({
    ...state,
    selectedProjectId: result.selectedProjectId,
    isLoading: false,
    error: null,
  })),
  on(LoadSelectedProjectIdFailed, (state, result) => ({
    ...state,
    selectedProjectId: '',
    isLoading: false,
    error: result.error,
  })),
  on(SyncStateWithNewProject, (state, result) => ({
    ...state,
    selectedProjectId: result.selectedProjectId,
    isLoading: false,
    error: null,
  })),
  on(LoadProjects, (state) => ({ ...state, isLoading: true })),
  on(LoadProjectsSuceeded, (state, result) => ({
    ...state,
    projects: ProjectAdapter.upsertMany(result.projects, state.projects),
    selectedProjectId: result.selectedProjectId,
    permissions: result.permissions,
    isLoading: false,
    error: null,
  })),
  on(LoadProjectsFailed, (state, result) => ({
    ...state,
    isLoading: false,
    error: result.error,
  })),

  on(LoadProjectConfiguration, (state) => ({ ...state, isLoading: true })),
  on(LoadProjectConfigurationSuceeded, (state, result) => ({
    ...state,
    configs: ProjectConfigurationAdapter.upsertOne(
      result.config,
      state.configs,
    ),
    isLoading: false,
    error: null,
  })),
  on(LoadProjectConfigurationFailed, (state, result) => ({
    ...state,
    isLoading: false,
    error: result.error,
  })),
  //#endregion

  //#region [Add]
  on(AddProjects, (state, payload) => ({
    ...state,
    projects: ProjectAdapter.upsertMany(payload.projects, state.projects),
    isLoading: true,
    error: null,
  })),
  on(AddProjectsSucceeded, (state, result) => ({
    ...state,
    projects: ProjectAdapter.upsertMany(result.projects, state.projects),
    isLoading: false,
    error: null,
  })),
  on(AddProjectsFailed, (state, result) => ({
    ...state,
    projects: ProjectAdapter.removeMany(result.ids, state.projects),
    isLoading: false,
    error: result.error,
  })),
  //#endregion

  //#region [Update]
  on(UpdateProjects, (state, payload) => ({
    ...state,
    projects: ProjectAdapter.updateMany(payload.projects, state.projects),
    isLoading: true,
    error: null,
  })),
  on(UpdateProjectsSucceeded, (state, result) => ({
    ...state,
    projects: ProjectAdapter.updateMany(result.projects, state.projects),
    isLoading: false,
    error: null,
  })),
  on(UpdateProjectsFailed, (state, result) => ({
    ...state,
    isLoading: false,
    error: result.error,
  })),
  //#endregion
];
