import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoadingAnimationService } from '../../services/loading-animation-service/loading-animation.service';
import { LoggingService } from '../../services';

@Injectable({
	providedIn: 'root',
})
export class ObservableToastService {
	constructor(
		private logger: LoggingService,
		private loadingAnimationService: LoadingAnimationService,
	) {}

	/**
	 * @description Observable Operator, that catches an error and displays a Toast.
	 * If a Default value is passed, this is passed onto the next Observable.
	 * Else mull is returned.
	 * @param source
	 * @param defaultValue
	 */
	public catchAndToast<T>(defaultValue: T = null): OperatorFunction<T, T> {
		const logger = this.logger;
		const loadingAnimationService = this.loadingAnimationService;
		return function (source$: Observable<T>): Observable<T> {
			return source$.pipe(
				catchError((err) => {
					logger.logError(err);
					loadingAnimationService.setShow(false);
					if (defaultValue) {
						return of(defaultValue);
					} else {
						return of(null);
					}
				}),
			);
		};
	}

	/**
	 * @description Observable Operator, that taps and displays a Toast.
	 */
	// public toast<T>(
	// 	messageSelector: (o: T) => string,
	// 	color: ToastColor,
	// ): OperatorFunction<T, T> {
	// 	const stat = <NbComponentStatus>color;
	// 	const logger = this.logger;
	// 	return function (source$: Observable<T>): Observable<T> {
	// 		return source$.pipe(tap((o) => logger.info(messageSelector(o), true)));
	// 	};
	// }
}

export enum ToastColor {
	white = 'basic',
	blue = 'info',
	green = 'success',
	yellow = 'warning',
	red = 'danger',
}
