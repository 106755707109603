import { Injectable } from '@angular/core';
import { TreeNode } from './file-api-explorer.model';
import { FileSystemAPIService, LoggingService } from '../../../@core/services';

@Injectable({
	providedIn: 'root',
})
export class FileAPIExplorerService {
	public nodes: TreeNode[];

	public loading: boolean;

	constructor(
		private logger: LoggingService,
		private filesystemApiService: FileSystemAPIService,
	) {
		this.loadNodes(false);
	}

	public loadNodes(useDirectoryExplorer: boolean) {
		this.loading = true;
		this.filesystemApiService
			.openFileExplorer$(useDirectoryExplorer)
			.subscribe({
				next: (data) => {
					this.nodes = data.map((d) => new TreeNode(d));
					this.logger.logTrace(`FileSystemAPI obtained root node`);
					this.loading = false;
				},
				error: (error) => {
					this.logger.logFailed(error);
					this.loading = false;
				},
			});
	}
}
