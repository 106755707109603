/* eslint-disable */
import { CommonModule, DOCUMENT } from '@angular/common';
import {
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgModule,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Renderer2,
  Inject,
  TrackByFunction,
  PLATFORM_ID,
} from '@angular/core';
import {
  FilterService,
  SharedModule,
  TreeDragDropService,
  TreeNode,
} from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { PaginatorModule } from 'primeng/paginator';
import { RippleModule } from 'primeng/ripple';
import { Subscription } from 'rxjs';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  TreeTable as _TreeTable,
  TreeTableModule as _TreeTableModule,
  TreeTableService,
  TreeTableToggler as _TreeTableToggler,
  TTBody as _TTBody,
  TTReorderableColumn as _TTReorderableColumn,
  TTResizableColumn as _TTResizableColumn,
  TTSelectableRow as _TTSelectableRow,
  TTSortableColumn as _TTSortableColumn,
  TTSortIcon as _TTSortIcon,
  TTContextMenuRow as _TTContextMenuRow,
  TTSelectableRowDblClick as _TTSelectableRowDblClick,
  TTCheckbox as _TTCheckbox,
  TTHeaderCheckbox as _TTHeaderCheckbox,
  TreeTableCellEditor as _TreeTableCellEditor,
  TTScrollableView as _TTScrollableView,
  TTEditableColumn as _TTEditableColumn,
} from 'primeng/treetable';
import { SortAltIcon } from 'primeng/icons/sortalt';
import { SortAmountUpAltIcon } from 'primeng/icons/sortamountupalt';
import { SortAmountDownIcon } from 'primeng/icons/sortamountdown';
import { CheckIcon } from 'primeng/icons/check';
import { MinusIcon } from 'primeng/icons/minus';
import { ChevronDownIcon } from 'primeng/icons/chevrondown';
import { ChevronRightIcon } from 'primeng/icons/chevronright';
import { SpinnerIcon } from 'primeng/icons/spinner';
import { ArrowUpIcon } from 'primeng/icons/arrowup';
import { ArrowDownIcon } from 'primeng/icons/arrowdown';

// To Update from primeng, update npm package and
// simply clone the template parts from the github repo of primeng

// #region TreeTable

@Component({
  selector: 'p-treeTable',
  template: `
    <div
      #container
      [ngStyle]="style"
      [class]="styleClass"
      data-scrollselectors=".p-treetable-scrollable-body"
      [ngClass]="{
        'p-treetable p-component': true,
        'p-treetable-hoverable-rows':
          rowHover ||
          selectionMode === 'single' ||
          selectionMode === 'multiple',
        'p-treetable-auto-layout': autoLayout,
        'p-treetable-resizable': resizableColumns,
        'p-treetable-resizable-fit':
          resizableColumns && columnResizeMode === 'fit',
        'p-treetable-flex-scrollable': scrollable && scrollHeight === 'flex'
      }"
    >
      <div class="p-treetable-loading" *ngIf="loading && showLoader">
        <div class="p-treetable-loading-overlay p-component-overlay">
          <i
            *ngIf="loadingIcon"
            [class]="'p-treetable-loading-icon pi-spin ' + loadingIcon"
          ></i>
          <ng-container *ngIf="!loadingIcon">
            <SpinnerIcon
              *ngIf="!loadingIconTemplate"
              [spin]="true"
              [styleClass]="'p-treetable-loading-icon'"
            />
            <span *ngIf="loadingIconTemplate" class="p-treetable-loading-icon">
              <ng-template
                *ngTemplateOutlet="loadingIconTemplate"
              ></ng-template>
            </span>
          </ng-container>
        </div>
      </div>
      <div *ngIf="captionTemplate" class="p-treetable-header">
        <ng-container *ngTemplateOutlet="captionTemplate"></ng-container>
      </div>
      <p-paginator
        [rows]="rows"
        [first]="first"
        [totalRecords]="totalRecords"
        [pageLinkSize]="pageLinks"
        styleClass="p-paginator-top"
        [alwaysShow]="alwaysShowPaginator"
        (onPageChange)="onPageChange($event)"
        [rowsPerPageOptions]="rowsPerPageOptions"
        *ngIf="
          paginator &&
          (paginatorPosition === 'top' || paginatorPosition === 'both')
        "
        [templateLeft]="paginatorLeftTemplate"
        [templateRight]="paginatorRightTemplate"
        [dropdownAppendTo]="paginatorDropdownAppendTo"
        [currentPageReportTemplate]="currentPageReportTemplate"
        [showFirstLastIcon]="showFirstLastIcon"
        [dropdownItemTemplate]="paginatorDropdownItemTemplate"
        [showCurrentPageReport]="showCurrentPageReport"
        [showJumpToPageDropdown]="showJumpToPageDropdown"
        [showPageLinks]="showPageLinks"
      >
        <ng-template pTemplate="firstpagelinkicon">
          <ng-container
            *ngTemplateOutlet="paginatorFirstPageLinkIconTemplate"
          ></ng-container>
        </ng-template>
        <ng-template pTemplate="previouspagelinkicon">
          <ng-container
            *ngTemplateOutlet="paginatorPreviousPageLinkIconTemplate"
          ></ng-container>
        </ng-template>
        <ng-template pTemplate="lastpagelinkicon">
          <ng-container
            *ngTemplateOutlet="paginatorLastPageLinkIconTemplate"
          ></ng-container>
        </ng-template>
        <ng-template pTemplate="nextpagelinkicon">
          <ng-container
            *ngTemplateOutlet="paginatorNextPageLinkIconTemplate"
          ></ng-container>
        </ng-template>
      </p-paginator>
      <div class="p-treetable-wrapper" *ngIf="!scrollable">
        <table #table [ngClass]="tableStyleClass" [ngStyle]="tableStyle">
          <ng-container
            *ngTemplateOutlet="
              colGroupTemplate;
              context: { $implicit: columns }
            "
          ></ng-container>
          <thead class="p-treetable-thead">
            <ng-container
              *ngTemplateOutlet="
                headerTemplate;
                context: { $implicit: columns }
              "
            ></ng-container>
          </thead>
          <tbody
            class="p-treetable-tbody"
            [pTreeTableBody]="columns"
            [pTreeTableBodyTemplate]="bodyTemplate"
          ></tbody>
          <tfoot class="p-treetable-tfoot">
            <ng-container
              *ngTemplateOutlet="
                footerTemplate;
                context: { $implicit: columns }
              "
            ></ng-container>
          </tfoot>
        </table>
      </div>
      <div class="p-treetable-scrollable-wrapper" *ngIf="scrollable">
        <div
          class="p-treetable-scrollable-view"
          #scrollableView
          [ttScrollableView]="columns"
          [frozen]="false"
          [scrollHeight]="scrollHeight"
          [ngStyle]="{
            left: frozenWidth,
            width: 'calc(100% - ' + frozenWidth + ')'
          }"
        ></div>
      </div>
      <p-paginator
        [rows]="rows"
        [first]="first"
        [totalRecords]="totalRecords"
        [pageLinkSize]="pageLinks"
        styleClass="p-paginator-bottom"
        [alwaysShow]="alwaysShowPaginator"
        (onPageChange)="onPageChange($event)"
        [rowsPerPageOptions]="rowsPerPageOptions"
        *ngIf="
          paginator &&
          (paginatorPosition === 'bottom' || paginatorPosition === 'both')
        "
        [templateLeft]="paginatorLeftTemplate"
        [templateRight]="paginatorRightTemplate"
        [dropdownAppendTo]="paginatorDropdownAppendTo"
        [currentPageReportTemplate]="currentPageReportTemplate"
        [showFirstLastIcon]="showFirstLastIcon"
        [dropdownItemTemplate]="paginatorDropdownItemTemplate"
        [showCurrentPageReport]="showCurrentPageReport"
        [showJumpToPageDropdown]="showJumpToPageDropdown"
        [showPageLinks]="showPageLinks"
      >
        <ng-template pTemplate="firstpagelinkicon">
          <ng-container
            *ngTemplateOutlet="paginatorFirstPageLinkIconTemplate"
          ></ng-container>
        </ng-template>
        <ng-template pTemplate="previouspagelinkicon">
          <ng-container
            *ngTemplateOutlet="paginatorPreviousPageLinkIconTemplate"
          ></ng-container>
        </ng-template>
        <ng-template pTemplate="lastpagelinkicon">
          <ng-container
            *ngTemplateOutlet="paginatorLastPageLinkIconTemplate"
          ></ng-container>
        </ng-template>
        <ng-template pTemplate="nextpagelinkicon">
          <ng-container
            *ngTemplateOutlet="paginatorNextPageLinkIconTemplate"
          ></ng-container>
        </ng-template>
      </p-paginator>
      <div *ngIf="summaryTemplate" class="p-treetable-footer">
        <ng-container *ngTemplateOutlet="summaryTemplate"></ng-container>
      </div>
      <div
        #resizeHelper
        class="p-column-resizer-helper"
        style="display:none"
        *ngIf="resizableColumns"
      ></div>
      <span
        #reorderIndicatorUp
        class="p-treetable-reorder-indicator-up"
        style="display: none;"
        *ngIf="reorderableColumns"
      >
        <ArrowDownIcon *ngIf="!reorderIndicatorUpIconTemplate" />
        <ng-template
          *ngTemplateOutlet="reorderIndicatorUpIconTemplate"
        ></ng-template>
      </span>
      <span
        #reorderIndicatorDown
        class="p-treetable-reorder-indicator-down"
        style="display: none;"
        *ngIf="reorderableColumns"
      >
        <ArrowUpIcon *ngIf="!reorderIndicatorDownIconTemplate" />
        <ng-template
          *ngTemplateOutlet="reorderIndicatorDownIconTemplate"
        ></ng-template>
      </span>
    </div>
  `,
  providers: [TreeTableService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./treetable.css'],
  host: {
    class: 'p-element',
  },
})
export class TreeTable extends _TreeTable {
  // #region Drag and Drop Extension

  @Input() validateDrop: boolean;

  @Output() onNodeDrop: EventEmitter<any> = new EventEmitter();

  @Output() onNodeDelete: EventEmitter<any> = new EventEmitter();

  @Input() draggableScope: any;

  @Input() droppableScope: any;

  @Input() draggableNodes: boolean;

  @Input() droppableNodes: boolean;

  @Input() rowTrackBy: TrackByFunction<any> = (index: number, item: any) =>
    item;

  public dragNodeTreeTable: TreeTable;

  public dragNode: TreeNode;

  public dragNodeSubNodes: TreeNode[];

  public dragNodeIndex: number;

  public dragNodeScope: any;

  public dragHover: boolean;

  public dragStartSubscription: Subscription;

  public dragStopSubscription: Subscription;

  // #endregion Drag and Drop Extension

  // #region RowSortable Extension

  @Input() enableCtrlRowMove: boolean;

  @Output() onRowMoved: EventEmitter<any> = new EventEmitter();

  // #endregion RowSortable Extension

  constructor(
    @Inject(DOCUMENT) document: Document,
    renderer: Renderer2,
    el: ElementRef,
    cd: ChangeDetectorRef,
    zone: NgZone,
    tableService: TreeTableService,
    filterService: FilterService,
    public dragDropService: TreeDragDropService,
  ) {
    super(document, renderer, el, cd, zone, tableService, filterService);
  }

  ngOnInit() {
    if (this.lazy) {
      this.onLazyLoad.emit(this.createLazyLoadMetadata());
    }
    if (this.droppableNodes) {
      this.dragStartSubscription = this.dragDropService.dragStart$.subscribe(
        (event) => {
          this.dragNodeTreeTable = event.tree;
          this.dragNode = event.node;
          this.dragNodeSubNodes = event.subNodes;
          this.dragNodeIndex = event.index;
          this.dragNodeScope = event.scope;
        },
      );

      this.dragStopSubscription = this.dragDropService.dragStop$.subscribe(
        (event) => {
          this.dragNodeTreeTable = null;
          this.dragNode = null;
          this.dragNodeSubNodes = null;
          this.dragNodeIndex = null;
          this.dragNodeScope = null;
          this.dragHover = false;
        },
      );
    }

    this.initialized = true;
  }

  onDragOver(event) {
    if (this.droppableNodes && (!this.value || this.value.length === 0)) {
      event.dataTransfer.dropEffect = 'move';
      event.preventDefault();
    }
  }

  onDrop(event) {
    if (this.droppableNodes && (!this.value || this.value.length === 0)) {
      event.preventDefault();
      const dragNode = this.dragNode;
      if (this.allowDrop(dragNode, null, this.dragNodeScope)) {
        const dragNodeIndex = this.dragNodeIndex;
        this.dragNodeSubNodes.splice(dragNodeIndex, 1);
        this.value = this.value || [];
        this.value.push(dragNode);

        this.dragDropService.stopDrag({
          node: dragNode,
        });
      }
    }
  }

  onDragEnter(event) {
    if (
      this.droppableNodes &&
      this.allowDrop(this.dragNode, null, this.dragNodeScope)
    ) {
      this.dragHover = true;
    }
  }

  onDragLeave(event) {
    if (this.droppableNodes) {
      const rect = event.currentTarget.getBoundingClientRect();
      if (
        event.x > rect.left + rect.width ||
        event.x < rect.left ||
        event.y > rect.top + rect.height ||
        event.y < rect.top
      ) {
        this.dragHover = false;
      }
    }
  }

  allowDrop(
    dragNode: TreeNode,
    dropNode: TreeNode,
    dragNodeScope: any,
  ): boolean {
    if (!dragNode) {
      // prevent random html elements to be dragged
      return false;
    } else if (this.isValidDragScope(dragNodeScope)) {
      let allow: boolean = true;
      if (dropNode) {
        if (dragNode === dropNode) {
          allow = false;
        } else {
          let parent = dropNode.parent;
          while (parent != null) {
            if (parent === dragNode) {
              allow = false;
              break;
            }
            parent = parent.parent;
          }
        }
      }

      return allow;
    } else {
      return false;
    }
  }

  isValidDragScope(dragScope: any): boolean {
    const dropScope = this.droppableScope;

    if (dropScope) {
      if (typeof dropScope === 'string') {
        if (typeof dragScope === 'string') {
          return dropScope === dragScope;
        } else if (dragScope instanceof Array) {
          return dragScope.indexOf(dropScope) != -1;
        }
      } else if (dropScope instanceof Array) {
        if (typeof dragScope === 'string') {
          return dropScope.indexOf(dragScope) != -1;
        } else if (dragScope instanceof Array) {
          for (const s of dropScope) {
            for (const ds of dragScope) {
              if (s === ds) {
                return true;
              }
            }
          }
        }
      }
      return false;
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.unbindDocumentEditListener();
    this.editingCell = null;
    this.initialized = null;

    if (this.dragStartSubscription) {
      this.dragStartSubscription.unsubscribe();
    }

    if (this.dragStopSubscription) {
      this.dragStopSubscription.unsubscribe();
    }
  }
}

// #endregion TreeTable

// #region TTBody Modification

// Added following lines to original:
// let-last="last"
//  index: rowIndex,
//  subindex: serializedNode.node?.data?.rowindex,
//  lastChild: last,
@Component({
  selector: '[pTreeTableBody]',
  template: `
    <ng-template
      ngFor
      let-serializedNode
      let-rowIndex="index"
      let-last="last"
      [ngForOf]="serializedNodes || tt.serializedValue"
      [ngForTrackBy]="tt.rowTrackBy"
    >
      <ng-container *ngIf="serializedNode.visible">
        <ng-container
          *ngTemplateOutlet="
            template;
            context: {
              $implicit: serializedNode,
              node: serializedNode.node,
              rowData: serializedNode.node.data,
              index: rowIndex,
              subindex: serializedNode.node?.data?.rowindex,
              lastChild: last,
              columns: columns
            }
          "
        ></ng-container>
      </ng-container>
    </ng-template>
    <ng-container *ngIf="tt.isEmpty()">
      <ng-container
        *ngTemplateOutlet="
          tt.emptyMessageTemplate;
          context: { $implicit: columns, frozen: frozen }
        "
      ></ng-container>
    </ng-container>
  `,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'p-element',
  },
})
export class TTBody extends _TTBody {
  constructor(
    public tt: TreeTable,
    public treeTableService: TreeTableService,
    public cd: ChangeDetectorRef,
  ) {
    super(tt, treeTableService, cd);
  }
}

// #endregion TTBody Modification

// #region Draggable Row Classes

@Directive({
  selector: '[ttDraggableRow]',
  host: { class: 'p-element' },
})
export class TTDraggableRow implements OnInit, OnDestroy {
  @Input('ttDraggableRow') rowNode: any;
  @Input('preventExtraction') preventExtraction: boolean;

  draghoverNode: boolean;

  subscription: Subscription;

  get node(): TreeNode {
    return this.rowNode.node || undefined;
  }
  get siblings(): TreeNode[] {
    return this.rowNode.parent ? this.rowNode.parent.children : this.tt.value;
  }

  constructor(public tt: TreeTable) {}

  ngOnInit() {}

  @HostListener('dragstart', ['$event'])
  onDragStart(event) {
    if (this.tt.draggableNodes && this.node.draggable !== false) {
      event.dataTransfer.setData('text', 'data');

      this.tt.dragDropService.startDrag({
        tree: this,
        node: this.node,
        subNodes: this.getSubNodes(),
        index: this.getIndex(),
        scope: this.tt.draggableScope,
      });
    } else {
      event.preventDefault();
    }
  }

  private getSubNodes() {
    if (this.preventExtraction) {
      return this.node.parent
        ? [...this.node.parent.children]
        : [...this.tt.value];
    }

    return this.node.parent ? this.node.parent.children : this.tt.value;
  }

  @HostListener('dragend', ['$event'])
  onDragStop(event) {
    this.tt.dragDropService.stopDrag({
      node: this.node,
      subNodes: this.getSubNodes(),
      index: this.getIndex(),
    });
  }

  @HostListener('dragover', ['$event'])
  onDropNodeDragOver(event) {
    event.dataTransfer.dropEffect = 'move';
    if (this.tt.droppableNodes) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener('drop', ['$event'])
  onDropNode(event) {
    if (this.tt.droppableNodes && this.node.droppable !== false) {
      event.preventDefault();
      event.stopPropagation();
      const dragNode = this.tt.dragNode;
      if (this.tt.allowDrop(dragNode, this.node, this.tt.dragNodeScope)) {
        if (this.tt.validateDrop) {
          this.tt.onNodeDrop.emit({
            originalEvent: event,
            dragNode: dragNode,
            dropNode: this.node,
            index: this.getIndex(),
            accept: () => {
              this.processNodeDrop(dragNode);
            },
          });
        } else {
          this.processNodeDrop(dragNode);
          this.tt.onNodeDrop.emit({
            originalEvent: event,
            dragNode: dragNode,
            dropNode: this.node,
            index: this.getIndex(),
          });
        }
      }
    }

    this.draghoverNode = false;
  }

  processNodeDrop(dragNode) {
    const dragNodeIndex = this.tt.dragNodeIndex;
    if (dragNodeIndex == -1) {
      console.log('Error: DragNodeIndex is -1');
      return;
    }
    if (!this.preventExtraction) {
      this.tt.dragNodeSubNodes.splice(dragNodeIndex, 1);
    }

    if (this.node.children) this.node.children.push(dragNode);
    else this.node.children = [dragNode];

    this.tt.dragDropService.stopDrag(null);

    this.tt.updateSerializedValue();
    this.tt.tableService.onUIUpdate(this.tt.value);
  }

  @HostListener('dragenter', ['$event'])
  onDropNodeDragEnter(event) {
    if (
      this.tt.droppableNodes &&
      this.node.droppable !== false &&
      this.tt.allowDrop(this.tt.dragNode, this.node, this.tt.dragNodeScope)
    ) {
      this.draghoverNode = true;
    }
  }

  @HostListener('dragleave', ['$event'])
  onDropNodeDragLeave(event) {
    if (this.tt.droppableNodes) {
      const rect = event.currentTarget.getBoundingClientRect();
      if (
        event.x > rect.left + rect.width ||
        event.x < rect.left ||
        event.y >= Math.floor(rect.top + rect.height) ||
        event.y < rect.top
      ) {
        this.draghoverNode = false;
      }
    }
  }

  getIndex(): number {
    return this.siblings.indexOf(this.node);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

// #endregion Draggable Row Classes

// #region TTRow Modification

@Directive({
  selector: '[ttRow]',
  host: {
    class: 'p-element',
    '[attr.tabindex]': '"0"',
  },
})
export class TTRow {
  @Input('ttRow') rowNode: any;

  get node() {
    return this.rowNode;
  }

  constructor(
    public tt: TreeTable,
    public el: ElementRef,
    public zone: NgZone,
  ) {}

  @HostListener('keydown.control.arrowup', ['$event'])
  onMoveUp(event) {
    if (!this.tt.enableCtrlRowMove) return;
    this.tt.toggleRowIndex = DomHandler.index(this.el.nativeElement) - 1;
    const nodes = this.node.parent ? this.node.parent.children : this.tt.value;
    const index = nodes.indexOf(this.node.node);
    if (index == 0) return;
    nodes.splice(index, 1);
    nodes.splice(index - 1, 0, this.node.node);
    event.preventDefault();
    this.tt.updateSerializedValue();
    this.tt.tableService.onUIUpdate(this.tt.value);
    this.restoreFocus();
    this.tt.onRowMoved.emit({
      originalEvent: event,
      node: this.rowNode.node,
      siblingNodes: nodes,
      oldIndex: index,
      newIndex: index - 1,
    });
  }

  @HostListener('keydown.control.arrowdown', ['$event'])
  onMoveDown(event) {
    if (!this.tt.enableCtrlRowMove) return;
    this.tt.toggleRowIndex = DomHandler.index(this.el.nativeElement) + 1;
    const nodes = this.node.parent ? this.node.parent.children : this.tt.value;
    const index = nodes.indexOf(this.node.node);
    if (index == nodes.length - 1) return;
    nodes.splice(index, 1);
    nodes.splice(index + 1, 0, this.node.node);
    event.preventDefault();
    this.tt.updateSerializedValue();
    this.tt.tableService.onUIUpdate(this.tt.value);
    this.restoreFocus();
    this.tt.onRowMoved.emit({
      originalEvent: event,
      node: this.rowNode.node,
      siblingNodes: nodes,
      oldIndex: index,
      newIndex: index + 1,
    });
  }

  @HostListener('keydown.arrowdown', ['$event'])
  onDown(event) {
    const nextRow = this.el.nativeElement.nextElementSibling; //  .nextElementSibling; // Due to Droppointrow
    if (nextRow) {
      nextRow.focus();
    }
    event.preventDefault();
  }

  @HostListener('keydown.arrowup', ['$event'])
  onUp(event) {
    const prevRow = this.el.nativeElement.previousElementSibling; //  .previousElementSibling;  // Due to Droppointrow
    if (prevRow) {
      prevRow.focus();
    }
    event.preventDefault();
  }

  @HostListener('keydown.arrowright', ['$event'])
  onRight(event) {
    if (!this.rowNode.node.expanded) {
      this.tt.toggleRowIndex = DomHandler.index(this.el.nativeElement);
      this.rowNode.node.expanded = true;

      this.tt.onNodeExpand.emit({
        originalEvent: event,
        node: this.rowNode.node,
      });

      this.tt.updateSerializedValue();
      this.tt.tableService.onUIUpdate(this.tt.value);
      this.restoreFocus();
    }
  }

  @HostListener('keydown.arrowleft', ['$event'])
  onLeft(event) {
    if (this.rowNode.node.expanded) {
      this.tt.toggleRowIndex = DomHandler.index(this.el.nativeElement);
      this.rowNode.node.expanded = false;

      this.tt.onNodeCollapse.emit({
        originalEvent: event,
        node: this.rowNode.node,
      });
      this.tt.updateSerializedValue();
      this.tt.tableService.onUIUpdate(this.tt.value);
      this.restoreFocus();
    }
  }

  @HostListener('keydown.delete', ['$event'])
  onDelete(event) {
    this.tt.onNodeDelete.emit({
      originalEvent: event,
      node: this.rowNode.node,
    });

    this.tt.updateSerializedValue();
    this.tt.tableService.onUIUpdate(this.tt.value);
    this.restoreFocus();
  }

  restoreFocus() {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        const row = DomHandler.findSingle(
          this.tt.containerViewChild.nativeElement,
          '.p-treetable-tbody',
        ).children[this.tt.toggleRowIndex];
        if (row) {
          row.focus();
        }
      }, 25);
    });
  }
}

// #endregion TTRow Modification

// #region StatusIconRow

@Component({
  selector: 'statusIconRow',
  template: `
    <i
      class="fas fa-chess-rook"
      title="Root-Component"
      *ngIf="rowNode.node.data.is_root"
      style="margin-left: 5px"
    ></i>
    <i
      class="fas fa-car"
      title="External Data / Readonly"
      *ngIf="rowNode.node.data.readonly"
      style="margin-left: 5px"
    ></i>
    <i
      class="fas fa-download"
      title="Component is Checked-Out"
      *ngIf="rowNode.node.data.checked_out"
      style="margin-left: 5px"
    ></i>
    <i
      class="fas fa-sync-alt"
      title="Component is Synchronizing"
      *ngIf="rowNode.node.data?.synchronizing"
      style="margin-left: 5px"
    ></i>
    <i
      class="far fa-snowflake"
      title="Component is frozen. Create new Version to edit."
      *ngIf="rowNode.node.data.frozen"
      style="margin-left: 5px"
    ></i>
    <i
      class="fas fa-crosshairs"
      title="Component is Positioned"
      *ngIf="rowNode.node.data.is_positioned"
      style="margin-left: 5px"
    ></i>
    <i
      class="fas fa-code-branch"
      title="Component is NOT in the main variant."
      *ngIf="!rowNode.node.data.is_root_variant"
      style="margin-left: 5px"
    ></i>
    <i
      class="fas fa-exclamation-triangle"
      title="Something is wrong with this Component."
      *ngIf="rowNode.node.data.has_error"
      style="margin-left: 5px"
    ></i>
    <i
      class="fas fa-cube"
      title="Bounding Box is not created"
      *ngIf="
        rowNode.node.data.bounding_box_up_to_date === null &&
        rowNode.node.data.component_type === 'part'
      "
      style="margin-left: 5px"
    ></i>
    <i
      class="fas fa-cube"
      title="Bounding Box is out of date"
      *ngIf="
        rowNode.node.data.bounding_box_up_to_date === false &&
        rowNode.node.data.component_type === 'part'
      "
      style="margin-left: 5px"
    ></i>
    <i
      class="fas fa-link"
      title="Task(s) attached"
      *ngIf="rowNode.node.data.tasks_attached"
      style="margin-left: 5px"
    ></i>
    <i
      class="fas fa-paperclip"
      title="File(s) attached"
      *ngIf="rowNode.node.data.files_attached"
      style="margin-left: 5px"
    ></i>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class StatusIconRow {
  @Input() rowNode: any;
  constructor(public tt: TreeTable) {}
}

// #endregion StatusIconRow

// Here the classes, which need dependency injection of the new TreeTable,
// are extended with an overwritten constructor
// The @Directive is necessary to copy, as the Treetable also references them in the html code,
// and otherwise, it would try to use the original classes

// #region Copied classes

@Component({
  selector: '[ttScrollableView]',
  template: `
    <div #scrollHeader class="p-treetable-scrollable-header">
      <div #scrollHeaderBox class="p-treetable-scrollable-header-box">
        <table
          class="p-treetable-scrollable-header-table"
          [ngClass]="tt.tableStyleClass"
          [ngStyle]="tt.tableStyle"
        >
          <ng-container
            *ngTemplateOutlet="
              frozen
                ? tt.frozenColGroupTemplate || tt.colGroupTemplate
                : tt.colGroupTemplate;
              context: { $implicit: columns }
            "
          ></ng-container>
          <thead class="p-treetable-thead">
            <ng-container
              *ngTemplateOutlet="
                frozen
                  ? tt.frozenHeaderTemplate || tt.headerTemplate
                  : tt.headerTemplate;
                context: { $implicit: columns }
              "
            ></ng-container>
          </thead>
        </table>
      </div>
    </div>
    <p-scroller
      *ngIf="tt.virtualScroll"
      #scroller
      [items]="tt.serializedValue"
      styleClass="p-treetable-scrollable-body"
      [style]="{
        height: tt.scrollHeight !== 'flex' ? tt.scrollHeight : undefined
      }"
      [scrollHeight]="scrollHeight !== 'flex' ? undefined : '100%'"
      [itemSize]="tt.virtualScrollItemSize || tt._virtualRowHeight"
      [lazy]="tt.lazy"
      (onLazyLoad)="tt.onLazyItemLoad($event)"
      [options]="tt.virtualScrollOptions"
    >
      <ng-template pTemplate="content" let-items let-scrollerOptions="options">
        <ng-container
          *ngTemplateOutlet="
            buildInItems;
            context: { $implicit: items, options: scrollerOptions }
          "
        ></ng-container>
      </ng-template>
    </p-scroller>
    <ng-container *ngIf="!tt.virtualScroll">
      <div
        #scrollBody
        class="p-treetable-scrollable-body"
        [ngStyle]="{
          'max-height': tt.scrollHeight !== 'flex' ? scrollHeight : undefined,
          'overflow-y': !frozen && tt.scrollHeight ? 'scroll' : undefined
        }"
      >
        <ng-container
          *ngTemplateOutlet="
            buildInItems;
            context: { $implicit: tt.serializedValue, options: {} }
          "
        ></ng-container>
      </div>
    </ng-container>
    <ng-template #buildInItems let-items let-scrollerOptions="options">
      <table
        #scrollTable
        [class]="tt.tableStyleClass"
        [ngClass]="scrollerOptions.contentStyleClass"
        [ngStyle]="tt.tableStyle"
        [style]="scrollerOptions.contentStyle"
      >
        <ng-container
          *ngTemplateOutlet="
            frozen
              ? tt.frozenColGroupTemplate || tt.colGroupTemplate
              : tt.colGroupTemplate;
            context: { $implicit: columns }
          "
        ></ng-container>
        <tbody
          class="p-treetable-tbody"
          [pTreeTableBody]="columns"
          [pTreeTableBodyTemplate]="
            frozen ? tt.frozenBodyTemplate || tt.bodyTemplate : tt.bodyTemplate
          "
          [serializedNodes]="items"
          [frozen]="frozen"
        ></tbody>
      </table>
      <div
        #scrollableAligner
        style="background-color:transparent"
        *ngIf="frozen"
      ></div>
    </ng-template>
    <div
      #scrollFooter
      *ngIf="tt.footerTemplate"
      class="p-treetable-scrollable-footer"
    >
      <div #scrollFooterBox class="p-treetable-scrollable-footer-box">
        <table
          class="p-treetable-scrollable-footer-table"
          [ngClass]="tt.tableStyleClass"
          [ngStyle]="tt.tableStyle"
        >
          <ng-container
            *ngTemplateOutlet="
              frozen
                ? tt.frozenColGroupTemplate || tt.colGroupTemplate
                : tt.colGroupTemplate;
              context: { $implicit: columns }
            "
          ></ng-container>
          <tfoot class="p-treetable-tfoot">
            <ng-container
              *ngTemplateOutlet="
                frozen
                  ? tt.frozenFooterTemplate || tt.footerTemplate
                  : tt.footerTemplate;
                context: { $implicit: columns }
              "
            ></ng-container>
          </tfoot>
        </table>
      </div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'p-element',
  },
})
export class TTScrollableView extends _TTScrollableView {
  constructor(
    @Inject(PLATFORM_ID) platformId: any,
    renderer: Renderer2,
    public tt: TreeTable,
    public el: ElementRef,
    public zone: NgZone,
  ) {
    super(platformId, renderer, tt, el, zone);
  }
}

@Directive({
  selector: '[ttSortableColumn]',
  host: {
    class: 'p-element',
    '[class.p-sortable-column]': 'isEnabled()',
    '[class.p-highlight]': 'sorted',
    '[attr.tabindex]': 'isEnabled() ? "0" : null',
    '[attr.role]': '"columnheader"',
  },
})
export class TTSortableColumn extends _TTSortableColumn {
  constructor(tt: TreeTable) {
    super(tt);
  }
}

@Component({
  selector: 'p-treeTableSortIcon',
  template: ` <ng-container *ngIf="!tt.sortIconTemplate">
      <SortAltIcon
        [styleClass]="'p-sortable-column-icon'"
        *ngIf="sortOrder === 0"
      />
      <SortAmountUpAltIcon
        [styleClass]="'p-sortable-column-icon'"
        *ngIf="sortOrder === 1"
      />
      <SortAmountDownIcon
        [styleClass]="'p-sortable-column-icon'"
        *ngIf="sortOrder === -1"
      />
    </ng-container>
    <span *ngIf="tt.sortIconTemplate" class="p-sortable-column-icon">
      <ng-template
        *ngTemplateOutlet="
          tt.sortIconTemplate;
          context: { $implicit: sortOrder }
        "
      ></ng-template>
    </span>`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'p-element',
  },
})
export class TTSortIcon extends _TTSortIcon {
  constructor(tt: TreeTable, cd: ChangeDetectorRef) {
    super(tt, cd);
  }
}

@Directive({
  selector: '[ttResizableColumn]',
  host: {
    class: 'p-element',
  },
})
export class TTResizableColumn extends _TTResizableColumn {
  constructor(
    @Inject(DOCUMENT) document: Document,
    @Inject(PLATFORM_ID) platformId: any,
    renderer: Renderer2,
    public tt: TreeTable,
    public el: ElementRef,
    public zone: NgZone,
  ) {
    super(document, platformId, renderer, tt, el, zone);
  }
}

@Directive({
  selector: '[ttReorderableColumn]',
  host: {
    class: 'p-element',
  },
})
export class TTReorderableColumn extends _TTReorderableColumn {
  constructor(
    @Inject(DOCUMENT) document: Document,
    @Inject(PLATFORM_ID) platformId: any,
    renderer: Renderer2,
    public tt: TreeTable,
    public el: ElementRef,
    public zone: NgZone,
  ) {
    super(document, platformId, renderer, tt, el, zone);
  }
}

@Directive({
  selector: '[ttSelectableRow]',
  host: {
    class: 'p-element',
    '[class.p-highlight]': 'selected',
  },
})
export class TTSelectableRow extends _TTSelectableRow {
  constructor(tt: TreeTable, tts: TreeTableService) {
    super(tt, tts);
  }
}

@Directive({
  selector: '[ttSelectableRowDblClick]',
  host: {
    class: 'p-element',
    '[class.p-highlight]': 'selected',
  },
})
export class TTSelectableRowDblClick extends _TTSelectableRowDblClick {
  constructor(tt: TreeTable, tts: TreeTableService) {
    super(tt, tts);
  }
}

@Directive({
  selector: '[ttContextMenuRow]',
  host: {
    class: 'p-element',
    '[class.p-highlight-contextmenu]': 'selected',
    '[attr.tabindex]': 'isEnabled() ? 0 : undefined',
  },
})
export class TTContextMenuRow extends _TTContextMenuRow {
  constructor(tt: TreeTable, tts: TreeTableService, el: ElementRef<any>) {
    super(tt, tts, el);
  }
}

@Component({
  selector: 'p-treeTableCheckbox',
  template: `
    <div
      class="p-checkbox p-component"
      [ngClass]="{ 'p-checkbox-focused': focused }"
      (click)="onClick($event)"
    >
      <div class="p-hidden-accessible">
        <input
          type="checkbox"
          [checked]="checked"
          (focus)="onFocus()"
          (blur)="onBlur()"
        />
      </div>
      <div
        #box
        [ngClass]="{
          'p-checkbox-box': true,
          'p-highlight': checked,
          'p-focus': focused,
          'p-indeterminate': rowNode.node.partialSelected,
          'p-disabled': disabled
        }"
        role="checkbox"
        [attr.aria-checked]="checked"
      >
        <ng-container *ngIf="!tt.checkboxIconTemplate">
          <CheckIcon [styleClass]="'p-checkbox-icon'" *ngIf="checked" />
          <MinusIcon
            [styleClass]="'p-checkbox-icon'"
            *ngIf="rowNode.node.partialSelected"
          />
        </ng-container>
        <span *ngIf="tt.checkboxIconTemplate">
          <ng-template
            *ngTemplateOutlet="
              tt.checkboxIconTemplate;
              context: {
                $implicit: checked,
                partialSelected: rowNode.node.partialSelected
              }
            "
          ></ng-template>
        </span>
      </div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'p-element',
  },
})
export class TTCheckbox extends _TTCheckbox {
  constructor(tt: TreeTable, tts: TreeTableService, cd: ChangeDetectorRef) {
    super(tt, tts, cd);
  }
}

@Component({
  selector: 'p-treeTableHeaderCheckbox',
  template: `
    <div
      class="p-checkbox p-component"
      [ngClass]="{ 'p-checkbox-focused': focused }"
      (click)="onClick($event, cb.checked)"
    >
      <div class="p-hidden-accessible">
        <input
          #cb
          type="checkbox"
          [checked]="checked"
          (focus)="onFocus()"
          (blur)="onBlur()"
          [disabled]="!tt.value || tt.value.length === 0"
        />
      </div>
      <div
        #box
        [ngClass]="{
          'p-checkbox-box': true,
          'p-highlight': checked,
          'p-focus': focused,
          'p-disabled': !tt.value || tt.value.length === 0
        }"
        role="checkbox"
        [attr.aria-checked]="checked"
      >
        <ng-container *ngIf="!tt.headerCheckboxIconTemplate">
          <CheckIcon *ngIf="checked" [styleClass]="'p-checkbox-icon'" />
        </ng-container>
        <span class="p-checkbox-icon" *ngIf="tt.headerCheckboxIconTemplate">
          <ng-template
            *ngTemplateOutlet="
              tt.headerCheckboxIconTemplate;
              context: { $implicit: checked }
            "
          ></ng-template>
        </span>
      </div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'p-element',
  },
})
export class TTHeaderCheckbox extends _TTHeaderCheckbox {
  constructor(tt: TreeTable, tts: TreeTableService, cd: ChangeDetectorRef) {
    super(tt, tts, cd);
  }
}

@Directive({
  selector: '[ttEditableColumn]',
  host: {
    class: 'p-element',
  },
})
export class TTEditableColumn extends _TTEditableColumn {
  constructor(
    public tt: TreeTable,
    public el: ElementRef,
    public zone: NgZone,
  ) {
    super(tt, el, zone);
  }
}

@Component({
  selector: 'p-treeTableCellEditor',
  template: `
    <ng-container *ngIf="tt.editingCell === editableColumn.el.nativeElement">
      <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
    </ng-container>
    <ng-container
      *ngIf="
        !tt.editingCell || tt.editingCell !== editableColumn.el.nativeElement
      "
    >
      <ng-container *ngTemplateOutlet="outputTemplate"></ng-container>
    </ng-container>
  `,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'p-element',
  },
})
export class TreeTableCellEditor extends _TreeTableCellEditor {
  constructor(tt: TreeTable, ec: TTEditableColumn) {
    super(tt, ec);
  }
}

@Component({
  selector: 'p-treeTableToggler',
  template: `
    <button
      type="button"
      class="p-treetable-toggler p-link"
      (click)="onClick($event)"
      tabindex="-1"
      pRipple
      [style.visibility]="
        rowNode.node.leaf === false ||
        (rowNode.node.children && rowNode.node.children.length)
          ? 'visible'
          : 'hidden'
      "
      [style.marginLeft]="rowNode.level * 16 + 'px'"
    >
      <ng-container *ngIf="!tt.togglerIconTemplate">
        <ChevronDownIcon *ngIf="rowNode.node.expanded" />
        <ChevronRightIcon *ngIf="!rowNode.node.expanded" />
      </ng-container>
      <ng-template
        *ngTemplateOutlet="
          tt.togglerIconTemplate;
          context: { $implicit: rowNode.node.expanded }
        "
      ></ng-template>
    </button>
  `,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'p-element',
  },
})
export class TreeTableToggler extends _TreeTableToggler {
  constructor(public tt: TreeTable) {
    super(tt);
  }
}

// #endregion Copied classes

@NgModule({
  imports: [
    CommonModule,
    PaginatorModule,
    ScrollingModule,
    RippleModule,
    SpinnerIcon,
    SortAltIcon,
    ArrowDownIcon,
    ArrowUpIcon,
    SortAmountUpAltIcon,
    SortAmountDownIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    CheckIcon,
    MinusIcon,
    _TreeTableModule,
  ],
  exports: [
    TreeTable,
    SharedModule,
    TreeTableToggler,
    TTSortableColumn,
    TTSortIcon,
    TTRow,
    TTReorderableColumn,
    TTSelectableRow,
    TTSelectableRowDblClick,
    TTContextMenuRow,
    TTCheckbox,
    TTHeaderCheckbox,
    TTResizableColumn,
    TreeTableCellEditor,
    ScrollingModule,
    TTDraggableRow,
    StatusIconRow,
  ],
  declarations: [
    TreeTable,
    TTDraggableRow,
    TTRow,
    TTScrollableView,
    TTBody,
    TTSortableColumn,
    TTSortIcon,
    TTRow,
    TTReorderableColumn,
    TTSelectableRow,
    TTSelectableRowDblClick,
    TTContextMenuRow,
    TTCheckbox,
    TTHeaderCheckbox,
    TTResizableColumn,
    TTEditableColumn,
    TreeTableCellEditor,
    TreeTableToggler,
    StatusIconRow,
  ],
})
export class TreeTableModule {}
