import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-common';

interface Account extends AccountInfo {
  idTokenClaims?: {
    roles?: string[];
  };
}

//TODO: Replace deprecated
@Injectable({
  providedIn: 'root',
})
export class RoleGuardService implements CanActivate {
  constructor(private authService: MsalService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    try {
      const requiredRole = route.data['requiredRole'];
      const requiredRoles = route.data['requiredRoles'] ?? [];
      const account: Account = this.authService.instance.getAllAccounts()[0];

      if (!account.idTokenClaims?.roles) {
        window.alert(
          'You are not yet authorized to use GenetPDM with this account. An Administrator has yet to approve your access request.'
        );
        console.error("Token misses required claim 'roles'");
        return false;
      }

      if (
        !account.idTokenClaims?.roles?.includes(requiredRole) &&
        !account.idTokenClaims?.roles?.some((role) =>
          requiredRoles.includes(role)
        )
      ) {
        window.alert(
          'You do not have access to the requested page. Please ensure that your account is assigned to an app role and then sign-out and sign-in again.'
        );
        return false;
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}
