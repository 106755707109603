import { EMPTY, Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export function checkDialogCloseResult<T>(): (source: Observable<T | undefined>) => Observable<T> {
  return (source: Observable<T | undefined>): Observable<T> =>
    source.pipe(
      switchMap((result) => {
        if (result === undefined) {
          return EMPTY;
        } else {
          return of(result);
        }
      })
    );
}
