<div class="layout-container" [ngClass]="containerClass">
  <app-topbar class="header"></app-topbar>
  <app-config></app-config>
  <div class="layout-content-wrapper">
    <div class="layout-content">
      <!-- <app-breadcrumb></app-breadcrumb> -->
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- <app-profilemenu></app-profilemenu> -->

  <div class="layout-mask"></div>
</div>
<app-footer class="app-footer" [ngClass]="containerClass"></app-footer>
