import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { LogsReducer } from './reducer';
import { LogStoreEffects } from './effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('Log', LogsReducer),
    EffectsModule.forFeature([LogStoreEffects]),
  ],
})
export class LogStoreModule {}
