import {
  ComponentContainer,
  ComponentInstanceVersion,
  PreviewType,
} from '@genetpdm/model';
import { createAction, props } from '@ngrx/store';
import { CheckInType } from '@genetpdm/model/graphql';
import { ExplorerTreeNode } from '../../model/treenode';
import { ComponentInstanceVersionsDto } from '../../../../../../../libs/model/src/models/component.models';
import { LoggerLevel } from '../../services/logging-service/logger-level';

export enum UserInterfaceActionTypes {
  ActionSuccess = '[Backend Composite] Action Success',
  ActionFailed = '[Backend Composite] Action Failed',
  SelectComponent = '[Component] Select Component',
  SelectTab = '[Component] Select Tab',
  AddTab = '[Component] AddTab',
  RemoveTab = '[Component] Remove Tab',
  CheckOutComponent = '[Component] Check Out',
  CheckOutComponentSucceeded = '[Backend] Check Out succeeded',
  OpenCheckInDialog = '[Component] OpenCheckInDialog',
  CheckInComponent = '[Component] Check In',
  CheckInComponentSucceeded = '[Backend] Check In succeeded',
  UpdateCheckInComponent = '[Component] Update Check In',
  CancelCheckOutComponent = '[Component] Cancel Check Out',
  OpenIncreaseComponentVersionDialog = '[Component] OpenIncreaseComponentVersionDialog',
  IncreaseComponentVersion = '[Component] Increase Component Version',
  OpenRestoreComponentVersionDialog = '[Component] OpenRestoreComponentVersionDialog',
  RestoreComponentVersion = '[Component] Restore Component Version',
  IncreaseComponentVersionAndCheckin = '[Component] Increase Component Version And Checkin',
  OpenCheckOutFolder = '[Component] Open Check Out Folder',
  OpenCheckOutFolderGetProjectConfig = '[Component] Open Check Out Folder and Project Config',
  QuickReplaceComponent = '[Component] QuickReplaceComponent',
  FavoriteComponent = '[Component] FavoriteComponent',
  UnFavoriteComponent = '[Component] UnFavoriteComponent',
  OpenRenameComponentDialog = '[Component] OpenRenameComponentDialog',
  RenameComponent = '[Component] RenameComponent',
  CreateNewVariant = '[Component] CreateNewVariant',
  ClearPositionFromInstance = '[Component] ClearPositionFromInstance',
  FreezeComponent = '[Component] FreezeComponent',
  UnFreezeComponent = '[Component] UnFreezeComponent',
  StartNewReleaseProcess = '[Component] StartNewReleaseProcess',
  OpenStartNewReleaseProcessDialog = '[Component] OpenStartNewReleaseProcessDialog',
  EditReleaseProcess = '[Component] EditReleaseProcess',
  ReleaseReviewedComponents = '[Component] ReleaseReviewedComponents',
  ViewAllCheckedOutParts = '[Component] ViewAllCheckedOutParts',
  ViewMetadataOfComponent = '[Component] ViewMetadataOfComponent',
  ViewPartIn3DViewer = '[Component] ViewPartIn3DViewer',
  ViewLogs = '[Component] ViewLogs',
  ViewTasks = '[Component] ViewTasks',
  ViewAttachedFiles = '[Component] ViewAttachedFiles',
  ViewVariants = '[Component] ViewVariants',
  ViewPosition = '[Component] ViewPosition',
  OpenComponentInCatia = '[Component] OpenComponentInCatia',
  GeneratePreviews = '[Component] GeneratePreviews',
  OpenComponentInTab = '[Component] OpenComponentInTab',
  DuplicateComponent = '[Component] DuplicateComponent',
  DeleteInstance = '[Component] DeleteInstance',
  OpenCreateNewTaskView = '[Component] OpenCreateNewTaskView',
  CreateNewTaskForComponent = '[Component] CreateNewTaskForComponent',
  ViewTags = '[Component] ViewTags',
  OpenAttachFileView = '[Component] Open Attach File View',
  AttachFile = '[Component] AttachFile',
  ChangeParent = '[Component] ChangeParent',
  IntegrateFromUpload = '[Component] IntegrateFromUpload',
  MoveRow = '[Component] MoveRow',
  SearchComponents = '[Component] SearchComponents',
  SearchComponentsSucceeded = '[Backend] SearchComponentsSucceeded',
  NavigateToComponent = '[Component] NavigateToComponent',
  NavigateToComponentSucceeded = '[Component] NavigateToComponentSucceeded',
}

export const ActionSuccess = createAction(
  UserInterfaceActionTypes.ActionSuccess,
  props<{ message: string }>(),
);

export const ActionFailed = createAction(
  UserInterfaceActionTypes.ActionFailed,
  props<{ error: Error; message: string; severity?: LoggerLevel }>(),
);

export const SelectComponent = createAction(
  UserInterfaceActionTypes.SelectComponent,
  props<{ treeNode: ExplorerTreeNode }>(),
);

export const SelectTab = createAction(
  UserInterfaceActionTypes.SelectTab,
  props<{ id: string }>(),
);

export const AddTab = createAction(
  UserInterfaceActionTypes.AddTab,
  props<{ container: ComponentContainer }>(),
);

export const RemoveTab = createAction(
  UserInterfaceActionTypes.RemoveTab,
  props<{ id: string }>(),
);

export const CheckOutComponent = createAction(
  UserInterfaceActionTypes.CheckOutComponent,
  props<{ component: ComponentInstanceVersion }>(),
);

export const CheckOutComponentSucceeded = createAction(
  UserInterfaceActionTypes.CheckOutComponentSucceeded,
  props<{ message: string; component: ComponentInstanceVersion }>(),
);

export const OpenCheckInDialog = createAction(
  UserInterfaceActionTypes.OpenCheckInDialog,
  props<{ component: ComponentInstanceVersion; checkInType: CheckInType }>(),
);

export const CancelCheckOutComponent = createAction(
  UserInterfaceActionTypes.CancelCheckOutComponent,
  props<{ component: ComponentInstanceVersion }>(),
);

export const CheckInComponent = createAction(
  UserInterfaceActionTypes.CheckInComponent,
  props<{
    component: ComponentInstanceVersion;
    comment: string;
    additionalBlobRoutes: string[];
  }>(),
);

export const CheckInComponentSucceeded = createAction(
  UserInterfaceActionTypes.CheckInComponentSucceeded,
  props<{ message: string; component: ComponentInstanceVersion }>(),
);

export const UpdateCheckInComponent = createAction(
  UserInterfaceActionTypes.UpdateCheckInComponent,
  props<{
    component: ComponentInstanceVersion;
    comment: string;
    additionalBlobRoutes: string[];
  }>(),
);

export const IncreaseComponentVersion = createAction(
  UserInterfaceActionTypes.IncreaseComponentVersion,
  props<{ component: ComponentInstanceVersion; comment: string }>(),
);

export const OpenRestoreComponentVersionDialog = createAction(
  UserInterfaceActionTypes.OpenRestoreComponentVersionDialog,
  props<{ component: ComponentInstanceVersion }>(),
);

export const RestoreComponentVersion = createAction(
  UserInterfaceActionTypes.RestoreComponentVersion,
  props<{ component: ComponentInstanceVersion; version: number }>(),
);

export const IncreaseComponentVersionAndCheckin = createAction(
  UserInterfaceActionTypes.IncreaseComponentVersionAndCheckin,
  props<{
    component: ComponentInstanceVersion;
    comment: string;
    additionalBlobRoutes: string[];
  }>(),
);

export const OpenCheckOutFolderGetProjectConfig = createAction(
  UserInterfaceActionTypes.OpenCheckOutFolderGetProjectConfig,
  props<{ component: ComponentInstanceVersion }>(),
);

export const OpenCheckOutFolder = createAction(
  UserInterfaceActionTypes.OpenCheckOutFolder,
  props<{ component: ComponentInstanceVersion; project_path: string }>(),
);

export const QuickReplaceComponent = createAction(
  UserInterfaceActionTypes.QuickReplaceComponent,
  props<{ component: ComponentInstanceVersion }>(),
);

export const FavoriteComponent = createAction(
  UserInterfaceActionTypes.FavoriteComponent,
  props<{ component: ComponentInstanceVersion }>(),
);

export const UnFavoriteComponent = createAction(
  UserInterfaceActionTypes.UnFavoriteComponent,
  props<{ component: ComponentInstanceVersion }>(),
);

export const OpenRenameComponentDialog = createAction(
  UserInterfaceActionTypes.OpenRenameComponentDialog,
  props<{ component: ComponentInstanceVersion }>(),
);

export const RenameComponent = createAction(
  UserInterfaceActionTypes.RenameComponent,
  props<{ component: ComponentInstanceVersion; newName: string }>(),
);

export const CreateNewVariant = createAction(
  UserInterfaceActionTypes.CreateNewVariant,
  props<{ component: ComponentInstanceVersion }>(),
);

export const ClearPositionFromInstance = createAction(
  UserInterfaceActionTypes.ClearPositionFromInstance,
  props<{ component: ComponentInstanceVersion }>(),
);

export const FreezeComponent = createAction(
  UserInterfaceActionTypes.FreezeComponent,
  props<{ component: ComponentInstanceVersion }>(),
);

export const UnFreezeComponent = createAction(
  UserInterfaceActionTypes.UnFreezeComponent,
  props<{ component: ComponentInstanceVersion }>(),
);

export const StartNewReleaseProcess = createAction(
  UserInterfaceActionTypes.StartNewReleaseProcess,
  props<{
    id: uuid;
    component: ComponentInstanceVersion;
    templateId: string;
  }>(),
);
export const OpenStartNewReleaseProcessDialog = createAction(
  UserInterfaceActionTypes.OpenStartNewReleaseProcessDialog,
  props<{ component: ComponentInstanceVersion }>(),
);

export const EditReleaseProcess = createAction(
  UserInterfaceActionTypes.EditReleaseProcess,
  props<{ component: ComponentInstanceVersion }>(),
);

export const ReleaseReviewedComponents = createAction(
  UserInterfaceActionTypes.ReleaseReviewedComponents,
);

export const ViewAllCheckedOutParts = createAction(
  UserInterfaceActionTypes.ViewAllCheckedOutParts,
  props<{ projectId: uuid }>(),
);

export const ViewPartIn3DViewer = createAction(
  UserInterfaceActionTypes.ViewPartIn3DViewer,
  props<{ component: ComponentInstanceVersion }>(),
);

export const ViewMetadataOfComponent = createAction(
  UserInterfaceActionTypes.ViewMetadataOfComponent,
  props<{ component: ComponentInstanceVersion }>(),
);

export const ViewLogs = createAction(
  UserInterfaceActionTypes.ViewLogs,
  props<{ component: ComponentInstanceVersion }>(),
);

export const ViewTasks = createAction(
  UserInterfaceActionTypes.ViewTasks,
  props<{ component: ComponentInstanceVersion }>(),
);

export const ViewAttachedFiles = createAction(
  UserInterfaceActionTypes.ViewAttachedFiles,
  props<{ component: ComponentInstanceVersion }>(),
);

export const ViewVariants = createAction(
  UserInterfaceActionTypes.ViewVariants,
  props<{ component: ComponentInstanceVersion }>(),
);

export const ViewPosition = createAction(
  UserInterfaceActionTypes.ViewPosition,
  props<{ component: ComponentInstanceVersion }>(),
);

export const OpenComponentInCatia = createAction(
  UserInterfaceActionTypes.OpenComponentInCatia,
  props<{ component: ComponentInstanceVersion }>(),
);

export const GeneratePreviews = createAction(
  UserInterfaceActionTypes.GeneratePreviews,
  props<{ component: ComponentInstanceVersion; previewType: PreviewType }>(),
);

export const OpenComponentInTab = createAction(
  UserInterfaceActionTypes.OpenComponentInTab,
  props<{ component: ComponentInstanceVersion }>(),
);

export const DuplicateComponent = createAction(
  UserInterfaceActionTypes.DuplicateComponent,
  props<{ component: ComponentInstanceVersion }>(),
);

export const DeleteInstance = createAction(
  UserInterfaceActionTypes.DeleteInstance,
  props<{ component: ComponentInstanceVersion }>(),
);

export const OpenCreateNewTaskView = createAction(
  UserInterfaceActionTypes.OpenCreateNewTaskView,
  props<{ component: ComponentInstanceVersion }>(),
);

export const CreateNewTaskForComponent = createAction(
  UserInterfaceActionTypes.CreateNewTaskForComponent,
  props<{
    component: ComponentInstanceVersion;
    title: string;
    description: string;
    dateEnd: Date;
    workers: string[];
    componentIds: string[];
    blobRoutes: string[];
    checkListTemplateId: string;
  }>(),
);

export const ViewTags = createAction(
  UserInterfaceActionTypes.ViewTags,
  props<{ component: ComponentInstanceVersion }>(),
);

export const OpenAttachFileView = createAction(
  UserInterfaceActionTypes.OpenAttachFileView,
  props<{ component: ComponentInstanceVersion }>(),
);

export const AttachFile = createAction(
  UserInterfaceActionTypes.AttachFile,
  props<{ component: ComponentInstanceVersion }>(),
);

export const MoveRow = createAction(
  UserInterfaceActionTypes.MoveRow,
  props<{ orderedSiblingInstances: { instanceId: uuid; row: number }[] }>(),
);

export const SearchComponents = createAction(
  UserInterfaceActionTypes.SearchComponents,
  props<{ searchText: string }>(),
);

export const SearchComponentsSucceeded = createAction(
  UserInterfaceActionTypes.SearchComponentsSucceeded,
  props<{ components: ComponentInstanceVersionsDto }>(),
);

export const ChangeParent = createAction(
  UserInterfaceActionTypes.ChangeParent,
  props<{
    component: ComponentInstanceVersion;
    newParentComponent: ComponentInstanceVersion;
  }>(),
);

export const IntegrateFromUpload = createAction(
  UserInterfaceActionTypes.IntegrateFromUpload,
  props<{
    component: ComponentInstanceVersion;
    newParentComponent: ComponentInstanceVersion;
    isExternal: boolean;
    uploadId: string;
  }>(),
);

export const NavigateToComponent = createAction(
  UserInterfaceActionTypes.NavigateToComponent,
  props<{
    instanceId: uuid;
  }>(),
);

export const NavigateToComponentSucceeded = createAction(
  UserInterfaceActionTypes.NavigateToComponentSucceeded,
  props<{
    selectedInstanceId: uuid;
    componentsDto: ComponentInstanceVersionsDto;
    expandedComponentIds: uuid[];
  }>(),
);
