import { of, EMPTY, throwError, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, map, take } from 'rxjs/operators';
import {
  RefreshSessions,
  LoadSessions,
  LoadSessionsSuceeded,
  LoadSessionsFailed,
  DeleteSession,
  DeleteSessionSucceeded,
  DeleteSessionFailed,
  CreateSession,
  CreateSessionSucceeded,
  CreateSessionFailed,
  PublishSession,
  UnPublishSession,
  FreezeSession,
  UnFreezeSession,
  OpenRenameSessionDialog,
  ClearSession,
  GenerateSession,
  AdaptSession,
  ExtractPositionsFromSession,
  NavigateToPart,
  OpenCreateSessionDialog,
  SetPositions,
  RenameSession,
  ExpandSessionInstance,
  AddToSession,
  RemoveFromSession,
  AddToSessionFailed,
  RemoveFromSessionFailed,
  OpenLastSession,
} from './actions';
import {
  ComponentMetadataService,
  LoggerLevel,
  LoggingService,
  SessionService,
  SettingsService,
} from '../../services';
import {
  LoadComponents,
  LoadComponentsFailed,
  LoadComponentsSuceeded,
} from '../@app-state/app.actions';
import {
  ActionFailed,
  ActionSuccess,
} from '../@user-interface/user-interface.actions';
import { CatiaProxyService } from '../../services/catia-service/catia-proxy.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ChoosePositionsDialogComponent } from '../../../@shared/dialogs/choose-positions-dialog/choose-positions-dialog.component';
import { NewSessionDialogComponent } from '../../../@shared/dialogs/new-session-dialog/new-session-dialog.component';
import { RenameSessionDialogComponent } from '../../../@shared/dialogs/rename-session-dialog/rename-session-dialog.component';

@Injectable()
export class SessionStoreEffects {
  constructor(
    private sessionService: SessionService,
    private settingsService: SettingsService,
    private catiaProxyService: CatiaProxyService,
    private metadataService: ComponentMetadataService,
    private actions$: Actions,
    private dialogService: DialogService,
    private logger: LoggingService,
  ) {}

  public loadSessionsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSessions),
      tap(() => console.log('loadSessionsEffect triggered')),
      switchMap(() => this.settingsService.getSelectedProjectId$()),
      switchMap((projectId) =>
        this.sessionService.getSessionsForProjectId$(projectId).pipe(
          map((result) => LoadSessionsSuceeded({ sessions: result })),
          catchError((error) =>
            of(
              LoadSessionsFailed({
                error: error,
                projectId: projectId,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public loadSessionsSucceededEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSessionsSuceeded),
      switchMap((result) =>
        of(
          LoadComponents({
            ids: result.sessions.flatMap((s) =>
              s.components.map((c) => c.component_id),
            ),
          }),
        ),
      ),
    ),
  );

  public expandSessionComponentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpandSessionInstance),
      switchMap((request) =>
        this.metadataService
          .getComponentsByLeftIds$([request.componentId])
          .pipe(
            take(1),
            map((result) => LoadComponentsSuceeded({ components: result })),
            catchError((error) =>
              of(
                LoadComponentsFailed({
                  error: error,
                  ids: [request.componentId],
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public addToSessionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddToSession),
      switchMap((request) =>
        this.sessionService
          .addToSession$(request.sessionId, request.component.component.id)
          .pipe(
            take(1),
            map((result) =>
              ActionSuccess({
                message: 'Successfully added component to session.',
              }),
            ),
            catchError((error) =>
              of(
                AddToSessionFailed({
                  sessionId: request.sessionId,
                  componentId: request.component.component.id,
                  error: error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public removeFromSessionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveFromSession),
      switchMap((request) =>
        this.sessionService
          .removeFromSession$(request.sessionId, request.component.component.id)
          .pipe(
            take(1),
            map((result) =>
              ActionSuccess({
                message: 'Successfully removed component from session.',
              }),
            ),
            catchError((error) =>
              of(
                RemoveFromSessionFailed({
                  sessionId: request.sessionId,
                  component: request.component,
                  error: error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public refreshSessionsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefreshSessions),
      switchMap((request) =>
        this.sessionService.getSessionById$(request.sessionId).pipe(
          map((result) => LoadSessionsSuceeded({ sessions: [result] })),
          catchError((error) =>
            of(
              ActionFailed({
                error: error,
                message: `Error while refreshing: ${request.sessionId}`,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public openCreateSessionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpenCreateSessionDialog),
      switchMap(() =>
        this.dialogService
          .open(NewSessionDialogComponent, {
            header: 'New Session',
            width: '70%',
          })
          .onClose.pipe(
            map((session) =>
              CreateSession({
                session: session,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Create Session failed`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public createSessionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateSession),
      switchMap((request) =>
        this.sessionService.createSession$(request.session).pipe(
          map(() => CreateSessionSucceeded(request)),
          catchError((error) =>
            of(
              CreateSessionFailed({
                error: error,
                sessionId: request.session.id,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public deleteSessionsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteSession),
      switchMap((request) =>
        this.sessionService.deleteSession$(request.session.id).pipe(
          map(() => DeleteSessionSucceeded()),
          catchError((error) =>
            of(
              DeleteSessionFailed({
                error: error,
                session: request.session,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public publishSessionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PublishSession),
      switchMap((request) =>
        this.sessionService
          .updateSession$({ ...request.session, shared: true })
          .pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully published Session: ${request.session.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while publishing Session: ${request.session.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public unPublishSessionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnPublishSession),
      switchMap((request) =>
        this.sessionService
          .updateSession$({ ...request.session, shared: false })
          .pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully unpublished Session: ${request.session.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while unpublishing Session: ${request.session.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public freezeSessionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FreezeSession),
      switchMap((request) =>
        this.sessionService
          .updateSession$({ ...request.session, readonly: true })
          .pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully froze Session: ${request.session.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while freezing Session: ${request.session.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public unfreezeSessionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnFreezeSession),
      switchMap((request) =>
        this.sessionService
          .updateSession$({ ...request.session, readonly: false })
          .pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully unfroze Session: ${request.session.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while unfreezing Session: ${request.session.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public openRenameSessionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpenRenameSessionDialog),
      switchMap((request) =>
        this.dialogService
          .open(RenameSessionDialogComponent, {
            header: 'Rename Session',
            width: '70%',
            data: {
              session: request.session,
            },
          })
          .onClose.pipe(
            map((result) =>
              RenameSession({
                session: request.session,
                newName: result.name,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while renaming Session: ${request.session.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public renameSessionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RenameSession),
      switchMap((request) =>
        this.sessionService
          .updateSession$({ ...request.session, name: request.newName })
          .pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully renamed Session: ${request.session.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while renaming Session: ${request.session.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public clearSessionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClearSession),
      switchMap((request) =>
        this.sessionService.clearSession$(request.session).pipe(
          map(() =>
            ActionSuccess({
              message: `Successfully cleared Session: ${request.session.name}`,
            }),
          ),
          catchError((error) =>
            of(
              ActionFailed({
                error: error,
                message: `Error while clearing Session: ${request.session.name}`,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public openLastSessionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpenLastSession),
      switchMap((request) =>
        this.catiaProxyService.openLastSession$(request.session).pipe(
          map(() =>
            ActionSuccess({
              message: `Successfully opened Session: ${request.session.name}`,
            }),
          ),
          catchError((error) =>
            of(
              ActionFailed({
                error: error,
                message: `Error while opening Session: ${request.session.name}`,
                severity: LoggerLevel.Critical,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public generateSessionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GenerateSession),
      switchMap((request) =>
        this.catiaProxyService
          .generateStructure$(request.session.id, request.generationType)
          .pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully generated Session: ${request.session.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while generating Session: ${request.session.name}`,
                  severity: LoggerLevel.Critical,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public adaptSessionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdaptSession),
      switchMap((request) =>
        this.catiaProxyService
          .adaptStructure$(request.session.id, request.generationType)
          .pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully adapted Session: ${request.session.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while adapting Session: ${request.session.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public extractPositionsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExtractPositionsFromSession),
      switchMap(() =>
        this.catiaProxyService.getInstancePositions$().pipe(
          map((positions) =>
            SetPositions({
              positions: positions,
            }),
          ),
          catchError((error) =>
            of(
              ActionFailed({
                error: error,
                message: `Error while extracting positions from Session`,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public setPositionsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetPositions),
      switchMap((payload) =>
        this.dialogService
          .open(ChoosePositionsDialogComponent, {
            header: 'Choose Positions',
            width: '70%',
            data: {
              positionResult: payload.positions,
            },
          })
          .onClose.pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully set positions`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Failed to set positions`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public navigateToPartEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigateToPart),
      switchMap((request) =>
        throwError(() => new Error('Not yet implemented')).pipe(
          map(() =>
            ActionSuccess({
              message: `Successfully navigated to component: ${request.instanceId}`,
            }),
          ),
          catchError((error) =>
            of(
              ActionFailed({
                error: error,
                message: `Error while navigating to component: ${request.instanceId}`,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public loadSessionsSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadSessionsSuceeded),
        tap((result) =>
          this.logger.logSuccess('Successfully loaded Sessions.'),
        ),
      ),
    { dispatch: false },
  );

  public loadSessionsFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadSessionsFailed),
        tap((result) =>
          this.logger.logFailed(
            'Load Sessions failed: ' + result.error.message,
          ),
        ),
      ),
    { dispatch: false },
  );

  public addToSessionFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AddToSessionFailed),
        tap((result) =>
          this.logger.logFailed('Could not add component to session.'),
        ),
      ),
    { dispatch: false },
  );

  public removeFromSessionFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RemoveFromSessionFailed),
        tap((result) =>
          this.logger.logFailed('Could not remove component from session.'),
        ),
      ),
    { dispatch: false },
  );

  public createSessionSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CreateSessionSucceeded),
        tap((result) =>
          this.logger.logSuccess('Successfully created Session.'),
        ),
      ),
    { dispatch: false },
  );

  public createSessionsFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CreateSessionFailed),
        tap((result) =>
          this.logger.logFailed(
            'Create Session failed: ' + result.error.message,
          ),
        ),
      ),
    { dispatch: false },
  );

  public deleteSessionSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeleteSessionSucceeded),
        tap((result) =>
          this.logger.logSuccess('Successfully deleted Session.'),
        ),
      ),
    { dispatch: false },
  );

  public deleteSessionsFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeleteSessionFailed),
        tap((result) =>
          this.logger.logFailed(
            'Delete Session failed: ' + result.error.message,
          ),
        ),
      ),
    { dispatch: false },
  );
}
