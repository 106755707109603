import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ActionFailed,
  ActionSuccess,
  CancelCheckOutComponent,
  CheckOutComponent,
  IncreaseComponentVersion,
  RestoreComponentVersion,
  UpdateCheckInComponent,
  CheckInComponent,
  OpenCheckOutFolder,
  QuickReplaceComponent,
  FavoriteComponent,
  UnFavoriteComponent,
  RenameComponent,
  CreateNewVariant,
  ClearPositionFromInstance,
  FreezeComponent,
  UnFreezeComponent,
  StartNewReleaseProcess,
  EditReleaseProcess,
  ReleaseReviewedComponents,
  ViewAllCheckedOutParts,
  ViewMetadataOfComponent,
  ViewLogs,
  ViewTasks,
  ViewAttachedFiles,
  ViewVariants,
  ViewPosition,
  OpenComponentInCatia,
  GeneratePreviews,
  DuplicateComponent,
  DeleteInstance,
  CreateNewTaskForComponent,
  ViewTags,
  AttachFile,
  MoveRow,
  ChangeParent,
  IntegrateFromUpload,
  OpenAttachFileView,
  OpenCreateNewTaskView,
  OpenCheckInDialog,
  IncreaseComponentVersionAndCheckin,
  OpenStartNewReleaseProcessDialog,
  AddTab,
  OpenCheckOutFolderGetProjectConfig,
  ViewPartIn3DViewer,
  CheckInComponentSucceeded,
  CheckOutComponentSucceeded,
  SearchComponents,
  NavigateToComponentSucceeded,
  OpenRenameComponentDialog,
} from './user-interface.actions';
import { combineLatest, map, of, throwError } from 'rxjs';
import {
  catchError,
  filter,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  CheckInType,
  IntegrateUploadComponentInput,
  ProductCategory,
} from '@genetpdm/model/graphql';
import { PromptService } from '../../services/prompt-service/prompt-service';
import { DialogService } from 'primeng/dynamicdialog';
import { LoggingService } from '../../services/logging-service/logging.service';
import {
  ComponentMetadataService,
  ComponentMutationService,
  LoggerLevel,
  SettingsService,
} from '../../services';
import { ActionsService } from '../../services/actions-service/actions.service';
import { QuickReplaceComponentDialogComponent } from '../../../@shared/dialogs/quick-replace-component-dialog/quick-replace-component-dialog.component';
import { RenameDialogComponent } from '../../../@shared/dialogs/rename-dialog/rename-dialog.component';
import { NewVariantDialogComponent } from '../../../@shared/dialogs/new-variant-dialog/new-variant-dialog.component';
import { ChecklistSearchDialogComponent } from '../../../@shared/dialogs/checklist-search-dialog/checklist-search-dialog.component';
import { ChecklistEditDialogComponent } from '../../../@shared/dialogs/checklist-edit-dialog/checklist-edit-dialog.component';
import { ExportReviewedComponentsDialogComponent } from '../../../@shared/dialogs/export-reviewed-components-dialog/export-reviewed-components-dialog';
import { Guid } from 'guid-typescript';
import { NewTaskDialogComponent } from '../../../@shared/dialogs/new-task-dialog/new-task-dialog.component';
import { CatiaProxyService } from '../../services/catia-service/catia-proxy.service';
import { FileSystemAPIService } from '../../services/filesystem-api-service/filesystem-api.service';
import { AttachedFilesViewComponent } from '../../../@shared/views/attached-files-view/attached-files-view.component';
import { VariantsViewComponent } from '../../../@shared/views/variants-view/variants-view.component';
import { CheckInDialogComponent } from '../../../@shared/dialogs/check-in-dialog/check-in-dialog.component';
import { LogsViewComponent } from '../../../@shared/views/logs-view/logs-view.component';
import { PartDetailViewComponent } from '../../../@shared/views/part-detail-view/part-detail-view.component';
import { AddComponentTagDialogComponent } from '../../../@shared/dialogs/add-component-tag-dialog/add-component-tag-dialog.component';
import { checkDialogCloseResult } from '../../services/utility/dialog-result-check';
import {
  ComponentContainer,
  ComponentInstanceVersionsDto,
} from '@genetpdm/model';
import { ThreeJSViewerViewComponent } from '../../../@shared/views/three-js-viewer-view/three-js-viewer-view.component';
import { ProjectService } from '../../../@core/services';
import { Store } from '@ngrx/store';
import { selectCurrentProject } from '../project';
import {
  SearchComponentsSucceeded,
  NavigateToComponent,
} from './user-interface.actions';
import {
  ExpandComponents,
  LoadComponents,
  LoadComponentsSuceeded,
} from '../@app-state/app.actions';

@Injectable()
export class UserInterfaceEffects {
  constructor(
    private actionsService: ActionsService,
    private promptService: PromptService,
    private fileSystemApiService: FileSystemAPIService,
    private metadataService: ComponentMetadataService,
    private dialogService: DialogService,
    private sessionService: CatiaProxyService,
    private projectService: ProjectService,
    private settingsService: SettingsService,
    private mutationService: ComponentMutationService,
    private actions$: Actions,
    private store: Store,
    private logger: LoggingService,
  ) {}

  public onActionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActionSuccess),
        tap((result) => {
          if (result.message.length > 0) {
            this.logger.logSuccess(result.message);
          }
        }),
      ),
    { dispatch: false },
  );

  public onActionFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActionFailed),
        tap((result) => {
          if (result.severity == LoggerLevel.Critical) {
            this.logger.logCritical(
              `${result.message}:\n\n${result.error?.message ?? ''}`,
            );
          } else {
            this.logger.logFailed(
              `${result.message}:\n\n${result.error?.message ?? ''}`,
            );
          }
        }),
      ),
    { dispatch: false },
  );

  public onCheckOutComponentSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CheckOutComponentSucceeded),
        tap((result) => {
          if (result.message.length > 0) {
            this.logger.logSuccess(result.message);
          }
        }),
      ),
    { dispatch: false },
  );

  public onCheckInComponentSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CheckInComponentSucceeded),
        tap((result) => {
          if (result.message.length > 0) {
            this.logger.logSuccess(result.message);
          }
        }),
      ),
    { dispatch: false },
  );

  public checkOutComponentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckOutComponent),
      tap(() => this.logger.logTrace('checkOutComponentEffect triggered.')),
      tap((request) =>
        this.logger.logInfo(
          `Checking Out: ${request.component.component.name}`,
        ),
      ),
      switchMap((request) =>
        this.actionsService.checkOut$(request.component).pipe(
          map(() =>
            CheckOutComponentSucceeded({
              message: `Successfully checked out: ${request.component.component.name}`,
              component: request.component,
            }),
          ),
          catchError((error) =>
            of(
              ActionFailed({
                error: error,
                message: `Error while checking out: ${request.component.component.name}`,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public openCheckInViewEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpenCheckInDialog),
      tap(() => this.logger.logTrace('openCheckInViewEffect triggered.')),
      switchMap((request) =>
        this.dialogService
          .open(CheckInDialogComponent, {
            header: 'Check-In Component',
            width: '70%',
            data: {
              component: request.component,
              checkInType: request.checkInType,
            },
          })
          .onClose.pipe(
            checkDialogCloseResult(),
            map((dialogResult) => {
              if (dialogResult.checkInType == CheckInType.Update)
                return UpdateCheckInComponent({
                  component: dialogResult.component,
                  comment: dialogResult.comment,
                  additionalBlobRoutes: dialogResult.additionalBlobs,
                });
              if (dialogResult.checkInType == CheckInType.IncreaseVersion)
                return IncreaseComponentVersionAndCheckin({
                  component: dialogResult.component,
                  comment: dialogResult.comment,
                  additionalBlobRoutes: dialogResult.additionalBlobs,
                });

              return CheckInComponent({
                component: dialogResult.component,
                comment: dialogResult.comment,
                additionalBlobRoutes: dialogResult.additionalBlobs,
              });
            }),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while opening Check-In view of: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public cancelCheckOutComponentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CancelCheckOutComponent),
      tap(() =>
        this.logger.logTrace('cancelCheckOutComponentEffect triggered.'),
      ),
      this.promptService.yesNoPromptPipe(
        () => 'Do you really want to Cancel the CheckOut?',
      ),
      tap((request) =>
        this.logger.logInfo(
          `Cancelling Checkout: ${request.component.component.name}`,
        ),
      ),
      switchMap((request) =>
        this.actionsService
          .checkIn$(request.component, CheckInType.Cancel, '', [])
          .pipe(
            map(() =>
              CheckInComponentSucceeded({
                message: `Successfully canceled Check-Out for: ${request.component.component.name}`,
                component: request.component,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while cancelling Check-Out for: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public checkInComponentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckInComponent),
      tap(() => this.logger.logTrace('checkInComponentEffect triggered.')),
      tap((request) =>
        this.logger.logInfo(`Checking In: ${request.component.component.name}`),
      ),
      switchMap((request) =>
        this.actionsService
          .checkIn$(
            request.component,
            CheckInType.Default,
            request.comment,
            request.additionalBlobRoutes,
          )
          .pipe(
            map(() =>
              CheckInComponentSucceeded({
                message: `Successfully checked in: ${request.component.component.name}`,
                component: request.component,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while checking in: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public updateCheckInComponentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateCheckInComponent),
      tap(() =>
        this.logger.logTrace('updateCheckInComponentEffect triggered.'),
      ),
      tap((request) =>
        this.logger.logInfo(`Updating: ${request.component.component.name}`),
      ),
      switchMap((request) =>
        this.actionsService
          .checkIn$(
            request.component,
            CheckInType.Update,
            request.comment,
            request.additionalBlobRoutes,
          )
          .pipe(
            map(() =>
              CheckInComponentSucceeded({
                message: `Successfully checked in: ${request.component.component.name}`,
                component: request.component,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while checking in: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public increaseVersionAndCheckInComponentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncreaseComponentVersionAndCheckin),
      tap(() =>
        this.logger.logTrace(
          'increaseVersionAndCheckInComponentEffect triggered.',
        ),
      ),
      tap((request) =>
        this.logger.logInfo(
          `Checking In and Increasing Version: ${request.component.component.name}`,
        ),
      ),
      switchMap((request) =>
        this.actionsService
          .checkIn$(
            request.component,
            CheckInType.IncreaseVersion,
            request.comment,
            request.additionalBlobRoutes,
          )
          .pipe(
            map(() =>
              CheckInComponentSucceeded({
                message: `Successfully checked in: ${request.component.component.name}`,
                component: request.component,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while checking in: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public incrementComponentVersionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncreaseComponentVersion),
      tap(() =>
        this.logger.logTrace('incrementComponentVersionEffect triggered.'),
      ),
      this.promptService.yesNoPromptPipe(
        () => 'Do you really want to increment the Component Version?',
      ),
      tap((request) =>
        this.logger.logInfo(
          `Increasing Version: ${request.component.component.name}`,
        ),
      ),
      switchMap((request) =>
        this.actionsService
          .createNextComponentVersion$(request.component, request.comment)
          .pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully created next version for: ${request.component.component.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while creating next version for: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public restoreComponentVersionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestoreComponentVersion),
      tap(() =>
        this.logger.logTrace('restoreComponentVersionEffect triggered.'),
      ),
      this.promptService.yesNoPromptPipe(
        () => 'Do you really want to restore the old version?',
      ),
      switchMap((request) =>
        this.actionsService
          .restoreComponentVersion$(request.component, request.version)
          .pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully restored version ${request.version} of: ${request.component.component.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while restoring version ${request.version} of: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public openCheckOutFolderGetProjectConfigEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpenCheckOutFolderGetProjectConfig),
      tap(() => this.logger.logTrace('openCheckOutFolderEffect triggered.')),
      switchMap((request) =>
        combineLatest([
          this.projectService.getProjectConfiguration$(
            request.component.component.project_id,
          ),
          this.settingsService.getLocation$,
        ]).pipe(
          map(([config, loc]) =>
            OpenCheckOutFolder({
              component: request.component,
              project_path: config.locations.find((l) => l.location == loc)
                .path,
            }),
          ),
          catchError((error) =>
            of(
              ActionFailed({
                error: error,
                message: `Error while opening Check-Out Folder of: ${request.component.component.name}: Error: ${error}`,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public openCheckOutFolderEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpenCheckOutFolder),
      tap(() => this.logger.logTrace('openCheckOutFolderEffect triggered.')),
      switchMap((request) =>
        this.fileSystemApiService
          .openDirectory$(
            request.project_path.replace(/\\/g, '/') +
              '/' +
              request.component.latestVersion.check_out_route.substring(
                0,
                request.component.latestVersion.check_out_route.lastIndexOf(
                  '/',
                ),
              ),
          )
          .pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully opened Check-Out Folder of: ${request.component.component.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while opening Check-Out Folder of: ${request.component.component.name}: Error: ${error}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public quickReplaceComponentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickReplaceComponent),
      tap(() => this.logger.logTrace('quickReplaceComponentEffect triggered.')),
      this.promptService.yesNoPromptPipe(
        () => 'Do you really want to replace the file of the component?',
      ),
      tap((request) =>
        this.logger.logInfo(
          `Replacing file of: ${request.component.component.name}`,
        ),
      ),
      switchMap((request) =>
        this.dialogService
          .open(QuickReplaceComponentDialogComponent, {
            header: 'Quick Replace Component',
            width: '70%',
            data: {
              component: request.component,
            },
          })
          .onClose.pipe(
            checkDialogCloseResult(),
            map(() =>
              ActionSuccess({
                message: `Successfully replaced file of: ${request.component.component.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while replacing file of: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public favoriteComponentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteComponent),
      tap(() => this.logger.logTrace('favoriteComponentEffect triggered.')),
      switchMap((request) =>
        //TODO: Implement
        this.notImplemented$().pipe(
          map(() =>
            ActionSuccess({
              message: `Successfully added to favorites: ${request.component.component.name}`,
            }),
          ),
          catchError((error) =>
            of(
              ActionFailed({
                error: error,
                message: `Error while adding to favorites: ${request.component.component.name}`,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public unFavoriteComponentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnFavoriteComponent),
      tap(() => this.logger.logTrace('unFavoriteComponentEffect triggered.')),
      switchMap((request) =>
        //TODO: Implement
        this.notImplemented$().pipe(
          map(() =>
            ActionSuccess({
              message: `Successfully removed from favorites: ${request.component.component.name}`,
            }),
          ),
          catchError((error) =>
            of(
              ActionFailed({
                error: error,
                message: `Error while removing from favorites: ${request.component.component.name}`,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public openRenameDialogEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpenRenameComponentDialog),
      tap(() => console.log('Opening RenameDialogComponent...')),
      switchMap((action) =>
        this.dialogService
          .open(RenameDialogComponent, {
            header: 'Rename Component',
            width: '70%',
            data: { component: action.component },
          })
          .onClose.pipe(
            checkDialogCloseResult(),
            tap((newName) => {
              console.log('New name received from dialog:', newName); // Logge den Namen direkt nach dem Dialog
            }),
            map((newName) =>
              RenameComponent({ component: action.component, newName })
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error,
                  message: `Error opening rename dialog for: ${action.component.component.name}`,
                })
              )
            )
          )
      )
    )
  );

  public renameComponentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RenameComponent),
      tap((action) => {
        console.log('renameComponentEffect triggered.');
        console.log('Component name before renaming:', action.component.component.name);
        console.log('New name provided:', action.newName);

        if (action.newName == null || action.newName === '') {
          console.error('Error: New name is null, undefined, or empty.');
        }
      }),
      switchMap((action) =>
        of(
          ActionSuccess({
            message: `Successfully renamed: ${action.component.component.name} to ${action.newName}`,
          })
        ).pipe(
          catchError((error) => {
            console.log('Error encountered while renaming:', error);
            return of(
              ActionFailed({
                error,
                message: `Error while renaming: ${action.component.component.name}`,
              })
            );
          })
        )
      )
    )
  );


  public createVariantEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateNewVariant),
      tap(() => this.logger.logTrace('createVariantEffect triggered.')),
      this.promptService.yesNoPromptPipe(
        () => 'Do you really want to create a new variant?',
      ),
      switchMap((request) =>
        this.dialogService
          .open(NewVariantDialogComponent, {
            header: 'Create New Variant',
            width: '70%',
            data: {
              component: request.component,
            },
          })
          .onClose.pipe(
            checkDialogCloseResult(),
            map(() =>
              ActionSuccess({
                message: `Successfully created new Variant for: ${request.component.component.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while ocreating new variant for: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public clearPositionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClearPositionFromInstance),
      tap(() => this.logger.logTrace('clearPositionEffect triggered.')),
      this.promptService.yesNoPromptPipe(
        () => 'Do you really want to reset the position of this instance?',
      ),
      switchMap((request) =>
        this.mutationService
          .deletePosition$(Guid.parse(request.component.instance_id))
          .pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully cleared Position of: ${request.component.instance.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while clearing position of: ${request.component.instance.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public freezeComponentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FreezeComponent),
      tap(() => this.logger.logTrace('freezeComponentEffect triggered.')),
      this.promptService.yesNoPromptPipe(
        () => 'Do you really want to freeze this component?',
      ),
      switchMap((request) =>
        this.actionsService
          .freezeComponent$(
            request.component.component.id,
            request.component.version,
            true,
          )
          .pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully frozen: ${request.component.component.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while freezing: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public unfreezeComponentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnFreezeComponent),
      tap(() => this.logger.logTrace('unfreezeComponentEffect triggered.')),
      switchMap((request) =>
        this.actionsService
          .freezeComponent$(
            request.component.component.id,
            request.component.version,
            false,
          )
          .pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully unfrozen: ${request.component.component.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while unfreezing: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public openStartReleaseProcessDialogEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpenStartNewReleaseProcessDialog),
      tap(() =>
        this.logger.logTrace('openStartReleaseProcessDialogEffect triggered.'),
      ),
      switchMap((request) =>
        this.dialogService
          .open(ChecklistSearchDialogComponent, {
            header: 'Select Release Checklist',
            width: '50%',
            data: {
              releaseChecklist: true,
            },
          })
          .onClose.pipe(
            checkDialogCloseResult(),
            map((result) =>
              StartNewReleaseProcess({
                id: Guid.create().toString(),
                component: request.component,
                templateId: result.templateId,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while ocreating Release Process for: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public startReleaseProcessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StartNewReleaseProcess),
      tap(() => this.logger.logTrace('startReleaseProcessEffect triggered.')),
      switchMap((request) =>
        this.actionsService
          .startReleaseProcess$(
            request.id,
            request.component.component.id,
            request.component.version,
            request.templateId,
            request.component.component.project_id,
          )
          .pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully started new Release Process for: ${request.component.component.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while ocreating Release Process for: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public editReleaseProcessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditReleaseProcess),
      tap(() => this.logger.logTrace('editReleaseProcessEffect triggered.')),
      switchMap((request) =>
        this.dialogService
          .open(ChecklistEditDialogComponent, {
            header: 'Edit Release Checklist',
            width: '50%',
            data: {
              releaseChecklist: true,
              component: request.component,
            },
          })
          .onClose.pipe(
            checkDialogCloseResult(),
            map(() =>
              ActionSuccess({
                message: `Successfully updated Release Process for: ${request.component.component.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while updating Release Process: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public releaseReviewedComponentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReleaseReviewedComponents),
      tap(() =>
        this.logger.logTrace('releaseReviewedComponentsEffect triggered.'),
      ),
      this.promptService.yesNoPromptPipe(
        () => 'Do you really want to release all reviewed components?',
      ),
      switchMap(() =>
        this.dialogService
          .open(ExportReviewedComponentsDialogComponent, {
            header: 'Export Reviewed Components',
            width: '50%',
          })
          .onClose.pipe(
            checkDialogCloseResult(),
            map(() =>
              ActionSuccess({
                message: `Successfully released reviewed components.`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while releasing reviewed components.`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public viewCheckedOutPartsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewAllCheckedOutParts),
      tap(() => this.logger.logTrace('viewCheckedOutPartsEffect triggered.')),
      switchMap((request) =>
        this.metadataService
          .getAllCheckedOutComponents$(request.projectId)
          .pipe(
            map((result) =>
              AddTab({
                container: <ComponentContainer>{
                  componentIds: result.map((c) => c.component_id),
                  icon: 'fa-solid fa-download',
                  index: 5, // TODO: check index later
                  id: '5', // TODO: check id later
                  title: 'Checked out components',
                },
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while retrieving checked out parts.`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public viewPartIn3DViewerEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewPartIn3DViewer),
      tap(() => this.logger.logTrace('viewPartIn3DViewerEffect triggered.')),
      switchMap((request) =>
        this.dialogService
          .open(ThreeJSViewerViewComponent, {
            header: '3D View',
            width: '100%',
            data: {
              component: request.component,
            },
          })
          .onClose.pipe(
            checkDialogCloseResult(),
            map(() =>
              ActionSuccess({
                message: ``,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while retrieving metadata of: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public viewMetadataOfComponentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewMetadataOfComponent),
      tap(() =>
        this.logger.logTrace('viewMetadataOfComponentEffect triggered.'),
      ),
      switchMap((request) =>
        this.dialogService
          .open(PartDetailViewComponent, {
            header: 'Component Metadata',
            width: '70%',
            data: {
              component: request.component,
            },
          })
          .onClose.pipe(
            checkDialogCloseResult(),
            map(() =>
              ActionSuccess({
                message: ``,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while retrieving metadata of: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public viewLogsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewLogs),
      tap(() => this.logger.logTrace('viewLogsEffect triggered.')),
      switchMap((request) =>
        this.dialogService
          .open(LogsViewComponent, {
            header: 'Change Logs',
            width: '90%',
            data: {
              component: request.component,
            },
          })
          .onClose.pipe(
            checkDialogCloseResult(),
            map(() =>
              ActionSuccess({
                message: ``,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while retrieving logs for: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public viewTasksEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewTasks),
      tap(() => this.logger.logTrace('viewTasksEffect triggered.')),
      switchMap((request) =>
        //TODO: Implement
        this.notImplemented$().pipe(
          map(() =>
            ActionSuccess({
              message: ``,
            }),
          ),
          catchError((error) =>
            of(
              ActionFailed({
                error: error,
                message: `Error while retrieving tasks for: ${request.component.component.name}`,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public viewAttachedFilesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewAttachedFiles),
      tap(() => this.logger.logTrace('viewAttachedFilesEffect triggered.')),
      switchMap((request) =>
        this.dialogService
          .open(AttachedFilesViewComponent, {
            header: 'Attached Files',
            width: '70%',
            data: {
              componentId: request.component.component.id,
              projectId: request.component.component.project_id,
              partNumber: request.component.part_number,
              version: request.component.version,
            },
          })
          .onClose.pipe(
            checkDialogCloseResult(),
            map(() =>
              ActionSuccess({
                message: ``,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while retrieving attached files for: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public viewVariantsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewVariants),
      tap(() => this.logger.logTrace('viewVariantsEffect triggered.')),
      switchMap((request) =>
        this.dialogService
          .open(VariantsViewComponent, {
            header: 'Variants',
            width: '70%',
            data: {
              component: request.component,
            },
          })
          .onClose.pipe(
            checkDialogCloseResult(),
            map(() =>
              ActionSuccess({
                message: ``,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while retrieving variants for: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public viewPositionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewPosition),
      tap(() => this.logger.logTrace('viewPositionEffect triggered.')),
      this.promptService.yesNoPromptPipe(
        () => 'Do you really want to increment the Component Version?',
      ),
      switchMap((request) =>
        this.notImplemented$().pipe(
          map(() =>
            ActionSuccess({
              message: ``,
            }),
          ),
          catchError((error) =>
            of(
              ActionFailed({
                error: error,
                message: `Error while retrieving position for: ${request.component.component.name}`,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public openComponentInCatiaEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpenComponentInCatia),
      tap(() => this.logger.logTrace('openComponentInCatiaEffect triggered.')),
      switchMap((request) =>
        this.sessionService.openStructure$(request.component.component.id).pipe(
          map(() =>
            ActionSuccess({
              message: `Successfully opened ${request.component.component.name} in Catia.`,
            }),
          ),
          catchError((error) =>
            of(
              ActionFailed({
                error: error,
                message: `Error while opening component in catia: ${request.component.component.name}`,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public generatePreviewsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneratePreviews),
      tap(() => this.logger.logTrace('generatePreviewsEffect triggered.')),
      switchMap((request) =>
        this.actionsService
          .createPreviews$(
            [request.component.component.id],
            request.previewType,
          )
          .pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully triggered Generate Preview Process for: ${request.component.component.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while generating previews for: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public duplicateComponentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DuplicateComponent),
      tap(() => this.logger.logTrace('duplicateComponentEffect triggered.')),
      switchMap((request) =>
        this.actionsService.duplicateInstance$(request.component).pipe(
          map(() =>
            ActionSuccess({
              message: `Successfully duplicated instance of: ${request.component.instance.name}`,
            }),
          ),
          catchError((error) =>
            of(
              ActionFailed({
                error: error,
                message: `Error while duplicating instance of: ${request.component.instance.name}`,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public deleteInstanceEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteInstance),
      tap(() => this.logger.logTrace('deleteInstanceEffect triggered.')),
      this.promptService.yesNoPromptPipe(
        () => 'Do you really want to delete this instance?',
      ),
      switchMap((request) =>
        this.mutationService
          .deleteComponentInstances$([request.component])
          .pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully deleted instance: ${request.component.instance.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while deleting instance: ${request.component.instance.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public openCreateNewTaskForComponentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpenCreateNewTaskView),
      tap(() =>
        this.logger.logTrace('openCreateNewTaskForComponentEffect triggered.'),
      ),
      switchMap((request) =>
        this.dialogService
          .open(NewTaskDialogComponent, {
            header: 'New Task',
            width: '70%',
            data: {
              partNumber: request.component.component_name,
              componentId: request.component.component.id,
              projectId: request.component.component.project_id,
            },
          })
          .onClose.pipe(
            checkDialogCloseResult(),
            map((result) =>
              CreateNewTaskForComponent({
                component: request.component,
                title: result.title,
                description: result.description,
                dateEnd: result.dateEnd,
                workers: result.workers,
                componentIds: result.componentIds,
                blobRoutes: result.blobRoutes,
                checkListTemplateId: result.checkListTemplateId,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while creating task for: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public createNewTaskForComponentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateNewTaskForComponent),
      switchMap((request) =>
        this.actionsService
          .createTask$(
            request.title,
            request.description,
            new Date(),
            request.dateEnd,
            request.workers,
            request.component.component.project_id,
            request.componentIds,
            request.blobRoutes,
            request.checkListTemplateId,
          )
          .pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully created task for: ${request.component.component.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while creating task for: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public openManageTagsDialogEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewTags),
      tap(() => this.logger.logTrace('viewTagsEffect triggered.')),
      switchMap((request) =>
        this.dialogService
          .open(AddComponentTagDialogComponent, {
            header: 'Add Tag',
            width: '70%',
            data: {
              component: request.component,
            },
          })
          .onClose.pipe(
            checkDialogCloseResult(),
            map(() =>
              ActionSuccess({
                message: ``,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while retrieving tags for: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public openAttachFileViewEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpenAttachFileView),
      tap(() => this.logger.logTrace('openAttachFileViewEffect triggered.')),
      switchMap((request) =>
        this.dialogService
          .open(AttachedFilesViewComponent, {
            header: 'Attached Files',
            width: '70%',
            data: {
              componentId: request.component.component.id,
              projectId: request.component.component.project_id,
              partNumber: request.component.component_name,
              version: request.component.version,
            },
          })
          .onClose.pipe(
            checkDialogCloseResult(),
            map((result) =>
              AttachFile({
                component: request.component,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while attaching file to: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public attachFileEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachFile),
      tap(() => this.logger.logTrace('attachFileEffect triggered.')),
      switchMap((request) =>
        //TODO: Implement - Attach file disabled true?
        this.notImplemented$().pipe(
          map(() =>
            ActionSuccess({
              message: `Successfully attached file to: ${request.component.component.name}`,
            }),
          ),
          catchError((error) =>
            of(
              ActionFailed({
                error: error,
                message: `Error while attaching file to: ${request.component.component.name}`,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public moveRowEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MoveRow),
      tap(() => this.logger.logTrace('moveRowEffect triggered.')),
      switchMap((request) =>
        this.mutationService
          .changeRowIndexes$(
            request.orderedSiblingInstances.map((n) => ({
              instance_id: n.instanceId,
              rowindex: n.row,
            })),
          )
          .pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully updated order of ${request.orderedSiblingInstances.length} instances.`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while updating order of ${request.orderedSiblingInstances.length} instances.`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public searchComponentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchComponents),
      filter((request) => request.searchText.length > 3),
      tap(() => this.logger.logTrace('searchComponentsEffect triggered.')),
      withLatestFrom(this.store.select(selectCurrentProject)),
      switchMap(([request, project]) =>
        this.metadataService
          .searchComponents$(project.id, request.searchText)
          .pipe(
            map((c) =>
              SearchComponentsSucceeded({
                components: c,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while searching: ${error.message}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public searchComponentsSideEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchComponentsSucceeded),
      tap(() => this.logger.logTrace('searchComponentsSideEffect triggered.')),
      map((payload) =>
        LoadComponentsSuceeded({ components: payload.components }),
      ),
    ),
  );

  public searchComponentsSideEffect2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchComponentsSucceeded),
      tap(() => this.logger.logTrace('searchComponentsSideEffect2 triggered.')),
      map((result) =>
        AddTab({
          container: <ComponentContainer>{
            componentIds: result.components.components.map((c) => c.id),
            icon: 'fa-solid fa-magnifying-glass',
            index: 5, // TODO: check index later
            id: '5', // TODO: check id later
            title: 'Search',
          },
        }),
      ),
    ),
  );

  public changeParentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeParent),
      tap(() => this.logger.logTrace('changeParentEffect triggered.')),
      switchMap((request) =>
        this.mutationService
          .changeParent$(
            Guid.parse(request.component.instance.id),
            Guid.parse(request.newParentComponent.component.id),
          )
          .pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully changed Parent of: ${request.component.component.name} to ${request.newParentComponent.component.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while changing parent of: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public integrateFromUploadEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrateFromUpload),
      tap(() => this.logger.logTrace('integrateFromUploadEffect triggered.')),
      this.promptService.yesNoPromptPipe(
        (request) =>
          `Do you want to integrate ${request.component.component.name} into ${request.newParentComponent.component.name}?`,
      ),
      switchMap((request) =>
        this.mutationService
          .integrateUploadComponent$(
            <IntegrateUploadComponentInput>{
              upload_component_id: request.component.component.id,
              target_left_id: request.newParentComponent.component.id,
              overwrite_existing_blobs: true,
              product_category: request.isExternal
                ? ProductCategory.ExternalData
                : ProductCategory.Design,
            },
            request.uploadId,
          )
          .pipe(
            map(() =>
              ActionSuccess({
                message: `Successfully integrated: ${request.component.component.name} to ${request.newParentComponent.component.name}`,
              }),
            ),
            catchError((error) =>
              of(
                ActionFailed({
                  error: error,
                  message: `Error while integrating: ${request.component.component.name}`,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public navigateToComponentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigateToComponent),
      tap(() => this.logger.logTrace('navigateToComponentEffect triggered.')),
      switchMap((request) =>
        this.metadataService.getParentTreeInstances$(request.instanceId).pipe(
          map((result) => {
            const instanceToSelect = request.instanceId;
            const componentsToExpand = getParentComponentIds(
              result,
              instanceToSelect,
            );
            return NavigateToComponentSucceeded({
              selectedInstanceId: instanceToSelect,
              componentsDto: result,
              expandedComponentIds: componentsToExpand,
            });
          }),
        ),
      ),
    ),
  );

  public navigateToComponentSucceededEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigateToComponentSucceeded),
      tap(() =>
        this.logger.logTrace('navigateToComponentSucceededEffect triggered.'),
      ),
      map((result) =>
        ExpandComponents({ componentIds: result.expandedComponentIds }),
      ),
    ),
  );

  public navigateToComponentSucceededEffect2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigateToComponentSucceeded),
      tap(() =>
        this.logger.logTrace('navigateToComponentSucceededEffect2 triggered.'),
      ),
      map((result) =>
        LoadComponentsSuceeded({ components: result.componentsDto }),
      ),
    ),
  );

  private notImplemented$() {
    return throwError(() => new Error('Function not implemented yet!'));
  }
}

function getParentComponentIds(
  componentDto: ComponentInstanceVersionsDto,
  instanceId: uuid,
): uuid[] {
  const instances = componentDto.instances;

  const parents: uuid[] = [];
  let finished = false;
  let currentId = instances.find((i) => i.id == instanceId).right_id;
  do {
    const parent = instances.find((i) => i.right_id == currentId)?.left_id;
    if (parent) {
      parents.push(parent);
      currentId = parent;
    } else {
      finished = true;
    }
  } while (!finished);

  return parents;
}
