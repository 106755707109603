import { Dictionary } from "@ngrx/entity";

//const uniques: string[] = array.filter(uniqueFilter);
export function distinct(value, index, self) {
  return self.indexOf(value) === index;
}

export function distinctBy(array, keySelector) {
  const uniqueValues = new Set();
  const result = [];

  for (const item of array) {
    const key = keySelector(item);

    if (!uniqueValues.has(key)) {
      uniqueValues.add(key);
      result.push(item);
    }
  }
  return result;
}

export function dictionaryToArray<T>(dict: Dictionary<T>): T[] {
  const result: T[] = [];
  for (const key in dict) {
      // eslint-disable-next-line no-prototype-builtins
      if (dict.hasOwnProperty(key) && dict[key] !== undefined) {
          result.push(dict[key] as T);
      }
  }
  return result;
}
