import { TreeNode as TN } from 'primeng/api';
import { Upload } from '@genetpdm/model';

export class TreeNode implements TN {
	public label: string;
	public data: Upload;
	public leaf?: boolean;

	constructor(data: Upload) {
		this.data = data;
		this.label = data.id;
		this.leaf = true;
	}
}
