import { Injectable } from '@angular/core';
import { BlobServiceClient } from '@azure/storage-blob';
import { from, Observable, of, throwError } from 'rxjs';
import { map, mergeMap, tap, catchError, take } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { environment } from '../../../../environments/environment';
import { VersionedBlob } from './versioned-blob.model';
import { AuthService, getBlobTokenCredential } from '../auth-service';
import { LoggingService } from '../logging-service/logging.service';

@Injectable({
  providedIn: 'root',
})
export class BlobService {
  blobServiceClient: BlobServiceClient;

  constructor(
    authService: AuthService,
    private logger: LoggingService,
  ) {
    this.blobServiceClient = new BlobServiceClient(
      `https://${environment.blobStorageOptions.account}.blob.core.windows.net`,
      getBlobTokenCredential(authService),
    );
  }

  public downloadFromBlob$(blob: Blob) {
    return of(blob).pipe(
      mergeMap((blob) => {
        return new Observable<string>((observer) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            observer.next(reader.result.toString());
            observer.complete();
          };
          //output: base64 image
          reader.readAsDataURL(blob);
        });
      }),
    );
  }

  public downloadBlobAsDataUrl$(
    route: string,
    projectId: uuid,
  ): Observable<string> {
    if (!projectId || projectId?.length < 35) return of('/assets/img/empty.jpg');

    return this.downloadBlob$(route, projectId).pipe(
      mergeMap((blob) => {
        return new Observable<string>((observer) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            observer.next(reader.result.toString());
            observer.complete();
          };
          //output: base64 image
          reader.readAsDataURL(blob);
        });
      }),
    );
  }

  public downloadBlob$(route: string, projectId: uuid): Observable<Blob> {
    return from(
      this.blobServiceClient
        .getContainerClient(projectId.toString())
        .getBlobClient(route)
        .download()
        .then((blob) => blob.blobBody)
        .catch((err) =>
          Promise.reject(
            new Error(
              `Error while downloading File: ${projectId.toString()} ${route}: ${
                err.message
              }`,
            ),
          ),
        ),
    );
  }

  public downloadBlobVersion$(
    route: string,
    projectId: uuid,
    versionId: string,
  ): Observable<Blob> {
    return from(
      this.blobServiceClient
        .getContainerClient(projectId.toString())
        .getBlobClient(route)
        .withVersion(versionId)
        .download()
        .then((blob) => blob.blobBody)
        .catch((err) =>
          Promise.reject(
            new Error(
              `Error while downloading File: ${projectId.toString()} ${route}: ${
                err.message
              }`,
            ),
          ),
        ),
    );
  }

  public uploadBlob$(
    blob: Blob,
    route: string,
    projectId: uuid,
  ): Observable<VersionedBlob> {
    return from(
      this.blobServiceClient
        .getContainerClient(projectId.toString())
        .getBlobClient(route)
        .getBlockBlobClient()
        .upload(blob, blob.size),
    ).pipe(
      map((r) => {
        if (r.errorCode) {
          throwError(() => r.errorCode);
        }
        return <VersionedBlob>{
          ...r,
          containerName: projectId.toString(),
          route: route,
        };
      }),
    );
  }

  public downloadToFileSystem(route: string, projectId: uuid) {
    const filename = route.split('/').slice(-1)[0];
    this.downloadBlob$(route, projectId).subscribe((blob) =>
      saveAs(blob, filename),
    );
  }

  public downloadVersionToFileSystem(
    route: string,
    projectId: uuid,
    versionId: string,
  ) {
    const filename = versionId + '_' + route.split('/').slice(-1)[0];
    this.downloadBlobVersion$(route, projectId, versionId)
      .pipe(
        tap(() => {
          this.logger.logTrace(
            `Download Blob: ${route} version ${versionId} of project ${projectId}`,
          );
        }),
        take(1),
        catchError((err) => {
          console.log(err.message);
          throw new Error(err.message);
        }),
      )
      .subscribe((blob) => {
        saveAs(blob, filename);
      });
  }
}
