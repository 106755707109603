import {
  Component,
  ComponentContainer,
  ComponentLastWrite,
} from '@genetpdm/model';
import {
  LoadComponents,
  LoadComponentsSuceeded,
  LoadComponentsFailed,
  UpdateComponent,
  UpdateComponentSucceeded,
  UpdateComponentFailed,
  DeleteComponents,
  DeleteComponentsSucceeded,
  DeleteComponentsFailed,
  CollapseComponents,
  ExpandComponents,
  LoadRootComponentsSucceeded,
  LoadRootComponents,
  LoadTabComponentsSuceeded,
  LoadTabComponents,
  LoadComponentVersionsSuceeded,
  RemoveInstancesFromState,
  ComponentSynchronizationStatusChanged,
} from './app.actions';
import {
  ComponentAdapter,
  ComponentInstanceAdapter,
  ComponentSubversionAdapter,
  ComponentVersionAdapter,
} from './app.adapter';
import { on } from '@ngrx/store';
import { from } from 'linq-to-typescript';
import { ReleaseProcessAdapter } from './app.adapter';
import { ExplorerStoreReducerArray } from '../state-model';

/**
 * createReducer is called in component-composite.state.ts
 * @remarks
 * Install VSCode Extension "Colored Regions" and add colors to your settings.json, to display colored regions here.
 */
export const ComponentsReducer: ExplorerStoreReducerArray = [
  //#region [Load]
  on(LoadComponents, (state) => ({ ...state, isLoading: true })),
  on(LoadRootComponents, (state) => ({ ...state, isLoading: true })),
  on(LoadRootComponentsSucceeded, (state, result) => ({
    ...state,
    tabs: AddOrReplaceRootTab(state.tabs, result.components.components),
    isLoading: false,
    error: null,
  })),
  on(LoadComponentVersionsSuceeded, (state, payload) => ({
    ...state,
    componentVersions: ComponentVersionAdapter.upsertMany(
      payload.components,
      state.componentVersions,
    ),
    isLoading: false,
    error: null,
  })),
  on(LoadComponentsSuceeded, (state, result) => ({
    ...state,
    components: ComponentAdapter.upsertMany(
      result.components.components,
      state.components,
    ),
    componentVersions: ComponentVersionAdapter.upsertMany(
      result.components.versions,
      state.componentVersions,
    ),
    componentSubversions: ComponentSubversionAdapter.upsertMany(
      result.components.subversions,
      state.componentSubversions,
    ),
    instances: ComponentInstanceAdapter.upsertMany(
      result.components.instances,
      state.instances,
    ),
    releaseProcesses: ReleaseProcessAdapter.upsertMany(
      result.components.releaseProcesses,
      state.releaseProcesses,
    ),
    isLoading: false,
    error: null,
  })),
  on(LoadTabComponents, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(LoadTabComponentsSuceeded, (state, payload) => ({
    ...state,
    tabs: state.tabs.map((t) => {
      if (t.id == 'myParts' || t.id == 'search') {
        return {
          ...t,
          componentIds: [
            ...new Set(
              payload.components.components
                .filter((c) => c.type == 'part')
                .map((c) => c.id),
            ),
          ],
        };
      }
      if (t.id == 'checkedOut') {
        return {
          ...t,
          componentIds: [
            ...new Set(
              payload.components.versions
                .filter((c) => c.checked_out)
                .map((c) => c.component_id),
            ),
          ],
        };
      }
      return t;
    }),
    isLoading: false,
    error: null,
  })),
  on(LoadComponentsFailed, (state, result) => ({
    ...state,
    isLoading: false,
    error: result.error,
  })),
  //#endregion

  //#region [Update]
  on(UpdateComponent, (state, payload) => ({
    ...state,
    componentVersions: ComponentVersionAdapter.updateOne(
      payload.component,
      state.componentVersions,
    ),
    isLoading: true,
    error: null,
  })),
  on(UpdateComponentSucceeded, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(UpdateComponentFailed, (state, result) => ({
    ...state,
    components: ComponentAdapter.upsertOne(
      result.oldComponent.component,
      state.components,
    ),
    componentVersions: ComponentVersionAdapter.upsertOne(
      result.oldComponent.latestVersion,
      state.componentVersions,
    ),
    isLoading: false,
    error: result.error,
  })),
  on(RemoveInstancesFromState, (state, payload) => ({
    ...state,
    instances: ComponentInstanceAdapter.removeMany(
      payload.instance_ids,
      state.instances,
    ),
    isLoading: false,
    error: null,
  })),
  //#endregion

  //#region [Delete]
  on(DeleteComponents, (state, payload) => ({
    ...state,
    components: ComponentAdapter.removeMany(
      payload.components.map((c) => c.component.id),
      state.components,
    ),
    isLoading: true,
    error: null,
  })),
  on(DeleteComponentsSucceeded, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(DeleteComponentsFailed, (state, result) => ({
    ...state,
    components: ComponentAdapter.addMany(
      result.components.map((c) => c.component),
      state.components,
    ),
    isLoading: false,
    error: result.error,
  })),
  //#endregion

  on(ExpandComponents, (state, payload) => ({
    ...state,
    expandedComponentIds: from([
      ...state.expandedComponentIds,
      ...payload.componentIds,
    ])
      .distinct()
      .toArray(),
  })),
  on(CollapseComponents, (state, payload) => ({
    ...state,
    expandedComponentIds: state.expandedComponentIds.filter(
      (i) => !payload.componentIds.some((e) => e == i),
    ),
  })),

  on(ComponentSynchronizationStatusChanged, (state, { components }) => {
    return {
      ...state,
      synchronizingComponentIds: components
        .filter((c) => c.synchronized === false)
        .map((component) => component.component_id),
      componentLastWriteTimes: components
        .filter((c) => c.synchronized === true)
        .map(
          (component) =>
            <ComponentLastWrite>{
              component_id: component.component_id,
              last_write_time: component.last_write_time,
            },
        ),
    };
  }),
];

function AddOrReplaceRootTab(
  tabs: ComponentContainer[],
  components: Component[],
): ComponentContainer[] {
  if (!components.some((c) => c.is_root)) return tabs;

  const container = <ComponentContainer>{
    id: 'root',
    componentIds: [components.find((c) => c.is_root).id],
    title: 'Root',
    icon: 'fas fa-home',
    index: 0,
  };

  return tabs.filter((t) => t.id != 'root').concat(container);
}
