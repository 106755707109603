import { Component, OnInit } from '@angular/core';
import { Guid } from 'guid-typescript';
import moment from 'moment';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { forkJoin } from 'rxjs';
import { AddComponentDialogComponent } from '../add-component-dialog/add-component-dialog.component';
import { ChecklistSearchDialogComponent } from '../checklist-search-dialog/checklist-search-dialog.component';
import { UserSearchDialogComponent } from '../user-search-dialog/user-search-dialog.component';
import { ActionsService, BlobService } from '../../../@core/services';
import { Store } from '@ngrx/store';
import { selectCurrentProject } from '../../../@core/state';
import { checkDialogCloseResult } from '../../../@core/services/utility/dialog-result-check';

@Component({
  selector: 'options-dialog',
  template: `
    <div class="card">
      <div class="card-body">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="row">
                <div
                  class="col d-xxl-flex justify-content-xxl-center align-items-xxl-center"
                >
                  <span
                    class="p-float-label"
                    style="min-width:100%; width: 100%;"
                  >
                    <input
                      style=" min-width:100%; width: 100%;"
                      type="text"
                      pInputText
                      [(ngModel)]="title"
                    />
                    <label for="float-input">Title</label>
                  </span>
                </div>
              </div>

              <div class="row">
                <div
                  class="col d-xxl-flex justify-content-xxl-center align-items-xxl-center"
                  style="padding-top: 20px;"
                >
                  <span class="p-float-label" style="min-width: 100%;">
                    <input
                      style="min-width: 100%;"
                      id="float-input"
                      type="text"
                      pInputText
                      [(ngModel)]="dateStart"
                    />
                    <label for="float-input">Start</label>
                  </span>
                </div>

                <div
                  class="col d-xxl-flex justify-content-xxl-center align-items-xxl-center"
                  style="padding-top: 20px;"
                >
                  <span class="p-float-label" style="min-width: 100%;">
                    <p-calendar
                      [(ngModel)]="dateEnd"
                      [showTime]="true"
                      [showIcon]="true"
                      [minDate]="minDate"
                      inputId="time"
                      dateFormat="dd.mm.yy"
                    >
                    </p-calendar>
                    <label for="float-input">End</label>
                  </span>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <div
                    class="col d-xxl-flex justify-content-xxl-center align-items-xxl-center"
                    style="padding-top: 20px;"
                  >
                    <span class="p-float-label" style="min-width: 100%;">
                      <textarea
                        pInputTextarea
                        [(ngModel)]="description"
                        style="min-width: 100%; width:100%;
											height: 281px;"
                      ></textarea>
                      <label for="float-input">Description</label>
                    </span>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <p>Project ID: <br />{{ projectId }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="row">
                <div class="col">
                  <p>
                    Components:
                    <button
                      pButton
                      type="button"
                      class="p-button-rounded-sm p-button-text"
                      icon="pi pi-plus"
                      (click)="addComponent($event)"
                    ></button>
                    <button
                      pButton
                      type="button"
                      class="p-button-rounded-sm p-button-text"
                      icon="pi pi-trash"
                      (click)="removeComponent($event)"
                    ></button>
                  </p>
                  <p-listbox
                    [options]="components"
                    [(ngModel)]="selectedComponent"
                    optionLabel="component_name"
                    [listStyle]="{ 'max-height': '120px' }"
                    [style]="{ width: '25rem' }"
                  ></p-listbox>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <p>
                    Workers:
                    <button
                      pButton
                      type="button"
                      class="p-button-rounded-sm p-button-text"
                      icon="pi pi-plus"
                      (click)="addWorker($event)"
                    ></button>
                    <button
                      pButton
                      type="button"
                      class="p-button-rounded-sm p-button-text"
                      icon="pi pi-trash"
                      (click)="removeWorker($event)"
                    ></button>
                  </p>
                  <p-listbox
                    [options]="workers"
                    [(ngModel)]="selectedWorker"
                    optionLabel="userName"
                    [listStyle]="{ 'max-height': '120px' }"
                    [style]="{ width: '25rem' }"
                  ></p-listbox>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <p>
                    Add Checklist:
                    <button
                      pButton
                      type="button"
                      class="p-button-rounded-sm p-button-text"
                      icon="pi pi-plus"
                      (click)="addChecklist($event)"
                    ></button>
                    <button
                      pButton
                      type="button"
                      class="p-button-rounded-sm p-button-text"
                      icon="pi pi-trash"
                      (click)="removeChecklist($event)"
                    ></button>
                  </p>
                  <input
                    id="disabled-input"
                    type="text"
                    pInputText
                    [disabled]=""
                    [(ngModel)]="checklistname"
                    [style]="{ width: '25rem' }"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <p>Files:</p>

                  <div class="form-group" *ngIf="!disableUpload">
                    <p-fileUpload
                      name="CheckInAddedFiles"
                      [customUpload]="true"
                      (uploadHandler)="handleBlobUpload($event)"
                      [multiple]="true"
                      ><ng-template pTemplate="content" let-files>
                        <div
                          style="height: 50px; text-align: center"
                          *ngIf="!files.length"
                        >
                          <i
                            class="far fa-copy fa-3x"
                            style="opacity: 0.2;"
                          ></i>
                          Drag & Drop files here...
                        </div>
                      </ng-template></p-fileUpload
                    >
                  </div>
                  <div class="form-group" *ngIf="disableUpload">
                    <h4>Upload succeeded!</h4>
                    <p>You can now proceed to Check In.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div>
            <p-button
              label="Submit"
              icon="pi pi-check"
              (onClick)="handleSubmit($event)"
            ></p-button>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class NewTaskDialogComponent implements OnInit {
  title: string;
  dateStart = moment(Date.now()).format('DD.MM.YYYY HH:mm');
  dateEnd: Date;
  minDate = new Date(Date.now());
  description: string;

  additionalBlobs: string[] = [];
  workers: Worker[] = [];
  components: CadComponent[] = [];

  projectId: uuid;

  selectedWorker: Worker;
  selectedComponent: CadComponent;

  disableUpload = false;

  checklist: Checklist;

  public get checklistname(): string {
    return this.checklist?.name;
  }

  public set checklistname(v: string) {}

  constructor(
    private dialogService: DialogService,
    private blobService: BlobService,
    private store: Store,
    private ref: DynamicDialogRef,
    private actionService: ActionsService
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectCurrentProject)
      .subscribe((p) => (this.projectId = p.id));
  }

  addComponent(event) {
    const ref = this.dialogService.open(AddComponentDialogComponent, {
      header: 'Select Component',
      width: '70%',
      data: {},
    });
    ref.onClose.pipe(checkDialogCloseResult()).subscribe((w) => {
      this.components = this.components.concat(w.components);
    });
  }

  removeComponent(event) {
    this.components = this.components.filter(
      (w) => w.componentId != this.selectedComponent.componentId
    );
  }

  addWorker(event) {
    const ref = this.dialogService.open(UserSearchDialogComponent, {
      header: 'Select User',
      width: '70%',
    });
    ref.onClose.pipe(checkDialogCloseResult()).subscribe((w) => {
      this.workers = this.workers.concat(w.workers);
    });
  }

  removeWorker(event) {
    this.workers = this.workers.filter((w) => w.id != this.selectedWorker.id);
  }

  addChecklist(event) {
    const ref = this.dialogService.open(ChecklistSearchDialogComponent, {
      header: 'Select Checklist',
      width: '50%',
    });
    ref.onClose.pipe(checkDialogCloseResult()).subscribe((c) => {
      this.checklist = c;
    });
  }

  removeChecklist(even) {
    this.checklist = null;
  }

  handleBlobUpload(event) {
    const routePrefix = `Upload/Tasks/${moment(Date.now()).format(
      'YYYYMMDDHHmmss'
    )}/`;
    const files = <File[]>event.files;
    const uploads$ = files.map((file) =>
      this.blobService.uploadBlob$(
        file,
        routePrefix + file.name,
        this.projectId
      )
    );
    forkJoin(uploads$).subscribe((r) => {
      this.additionalBlobs = r.map((b) => b.route);
      this.disableUpload = true;
    });
  }

  public close() {
    this.ref.close({
      title: this.title,
      description: this.description,
      dateEnd: this.dateEnd,
      workers: this.workers.map((w) => w.id),
      componentIds: this.components.map((c) => c.componentId),
      blobRoutes: this.additionalBlobs,
      checkListTemplateId: this.checklist?.templateId,
    });
  }

  handleSubmit(event) {
    this.actionService.createTask$(
      this.title,
      this.description,
      new Date(),
      this.dateEnd,
      this.workers.map((w) => w.id),
      this.projectId,
      this.components.map((c) => c.componentId),
      this.additionalBlobs,
      this.checklist?.templateId
    );
    this.ref.close();
  }
}

interface Worker {
  id: uuid;
  userName: string;
}

interface CadComponent {
  componentName: string;
  componentId: uuid;
}

interface Checklist {
  templateId: uuid;
  name: string;
}
