/**
 * Provides a CatiaAPIClientFactory, so that CatiaAPIClient can be used from dependency injection
 */

import { CatiaAPIClient } from '@genetsystems/genet-pdm-messaging-model';
import { environment } from '../../../../environments/environment';

export function catiaApiClientFactory() {
  return new CatiaAPIClient(environment.localApiOptions.endpoint);
}

export const CATIA_API_PROVIDERS = [
  {
    provide: CatiaAPIClient,
    useFactory: catiaApiClientFactory,
  },
];
