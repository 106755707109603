/**
 * Container to be able to use multiple Error handlers.
 */

import { Injectable, ErrorHandler } from '@angular/core';
import { LogErrorHandler } from './sub-error-handler/log-error-handler';
import { ToastErrorHandler } from './sub-error-handler/toast-error-handler';

//This class is neccessary, due to angular only being able to support one ErrorHandler at once
@Injectable()
export class ErrorHandlerContainer implements ErrorHandler {
	constructor(
		private consoleLogErrorHandler: LogErrorHandler,
    private toastErrorHandler: ToastErrorHandler
	) {}

	handleError(err: any): void {
		this.toastErrorHandler.handleError(err);
	}
}
