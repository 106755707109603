import { on } from '@ngrx/store';
import {
  LoadSessions,
  LoadSessionsSuceeded,
  LoadSessionsFailed,
  CreateSession,
  CreateSessionSucceeded,
  CreateSessionFailed,
  DeleteSession,
  DeleteSessionSucceeded,
  DeleteSessionFailed,
  SelectSession,
  CollapseSessionInstance,
  RemoveFromSessionFailed,
  AddToSessionFailed,
  FreezeSession,
  UnFreezeSession,
  PublishSession,
  UnPublishSession,
  ClearSession,
} from './actions';
import { SessionAdapter } from './state';
import { ExplorerStoreReducerArray } from '../state-model';
import {
  ExpandSessionInstance,
  AddToSession,
  RemoveFromSession,
} from './actions';
import { from } from 'linq-to-typescript';
import { RenameSession } from './actions';

export const SessionsReducer: ExplorerStoreReducerArray = [
  //#region [Load]
  on(LoadSessions, (state) => ({ ...state, isLoading: true })),
  on(LoadSessionsSuceeded, (state, result) => ({
    ...state,
    sessions: SessionAdapter.upsertMany(result.sessions, state.sessions),
    selectedSessionId: result.sessions.find(() => true)?.id ?? '',
    isLoading: false,
    error: null,
  })),
  on(LoadSessionsFailed, (state, result) => ({
    ...state,
    isLoading: false,
    error: result.error,
  })),
  on(SelectSession, (state, payload) => ({
    ...state,
    selectedSessionId: payload.sessionId,
    expandedSessionComponentIds: [],
  })),
  on(ExpandSessionInstance, (state, payload) => ({
    ...state,
    expandedSessionComponentIds: [
      ...state.expandedSessionComponentIds,
      payload.componentId,
    ],
  })),
  on(CollapseSessionInstance, (state, payload) => ({
    ...state,
    expandedSessionComponentIds: state.expandedSessionComponentIds.filter(
      (id) => id != payload.componentId,
    ),
  })),
  //#endregion

  //#region [Add]
  on(CreateSession, (state, payload) => ({
    ...state,
    sessions: SessionAdapter.upsertOne(payload.session, state.sessions),
    selectedSessionId: payload.session.id,
  })),
  on(CreateSessionSucceeded, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(CreateSessionFailed, (state, result) => ({
    ...state,
    sessions: SessionAdapter.removeOne(result.sessionId, state.sessions),
    isLoading: false,
    error: result.error,
  })),

  on(FreezeSession, (state, payload) => ({
    ...state,
    sessions: SessionAdapter.updateOne(
      {
        id: payload.session.id,
        changes: {
          readonly: true,
        },
      },
      state.sessions,
    ),
  })),
  on(UnFreezeSession, (state, payload) => ({
    ...state,
    sessions: SessionAdapter.updateOne(
      {
        id: payload.session.id,
        changes: {
          readonly: false,
        },
      },
      state.sessions,
    ),
  })),
  on(PublishSession, (state, payload) => ({
    ...state,
    sessions: SessionAdapter.updateOne(
      {
        id: payload.session.id,
        changes: {
          shared: true,
        },
      },
      state.sessions,
    ),
  })),
  on(UnPublishSession, (state, payload) => ({
    ...state,
    sessions: SessionAdapter.updateOne(
      {
        id: payload.session.id,
        changes: {
          shared: false,
        },
      },
      state.sessions,
    ),
  })),
  on(RenameSession, (state, payload) => ({
    ...state,
    sessions: SessionAdapter.updateOne(
      {
        id: payload.session.id,
        changes: {
          name: payload.newName,
        },
      },
      state.sessions,
    ),
  })),

  on(AddToSession, (state, payload) => ({
    ...state,
    isLoading: false,
    error: null,
    sessions: SessionAdapter.updateOne(
      {
        id: payload.sessionId,
        changes: {
          components: from([
            ...SessionAdapter.getSelectors().selectEntities(state.sessions)[
              payload.sessionId
            ].components,
            { component_id: payload.component.component.id },
          ])
            .distinct((a, b) => a.component_id == b.component_id)
            .toArray(),
        },
      },
      state.sessions,
    ),
  })),
  on(RemoveFromSession, (state, payload) => ({
    ...state,
    isLoading: false,
    error: null,
    sessions: SessionAdapter.updateOne(
      {
        id: payload.sessionId,
        changes: {
          components: [
            ...SessionAdapter.getSelectors()
              .selectEntities(state.sessions)
              [payload.sessionId].components.filter(
                (c) => c.component_id != payload.component.component.id,
              ),
          ],
        },
      },
      state.sessions,
    ),
  })),
  on(ClearSession, (state, payload) => ({
    ...state,
    isLoading: false,
    error: null,
    sessions: SessionAdapter.updateOne(
      {
        id: payload.session.id,
        changes: {
          components: [],
        },
      },
      state.sessions,
    ),
  })),
  on(RemoveFromSessionFailed, (state, payload) => ({
    ...state,
    error: payload.error,
    sessions: SessionAdapter.updateOne(
      {
        id: payload.sessionId,
        changes: {
          components: [
            ...SessionAdapter.getSelectors().selectEntities(state.sessions)[
              payload.sessionId
            ].components,
            { component_id: payload.component.component.id },
          ],
        },
      },
      state.sessions,
    ),
  })),
  on(AddToSessionFailed, (state, payload) => ({
    ...state,
    isLoading: false,
    error: null,
    sessions: SessionAdapter.updateOne(
      {
        id: payload.sessionId,
        changes: {
          components: [
            ...SessionAdapter.getSelectors()
              .selectEntities(state.sessions)
              [payload.sessionId].components.filter(
                (c) => c.component_id != payload.componentId,
              ),
          ],
        },
      },
      state.sessions,
    ),
  })),
  //#endregion

  //#region [Delete]
  on(DeleteSession, (state, payload) => ({
    ...state,
    sessions: SessionAdapter.removeOne(payload.session.id, state.sessions),
  })),
  on(DeleteSessionSucceeded, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(DeleteSessionFailed, (state, result) => ({
    ...state,
    sessions: SessionAdapter.addOne(result.session, state.sessions),
    isLoading: false,
    error: result.error,
  })),
  //#endregion
];
