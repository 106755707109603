import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-common';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { SettingsService } from '../settings-service/settings.service';
import { environment } from '../../../../environments/environment';
import { Logger } from './logger.interface';
import { LoggerLevel, getLogLevel } from './logger-level';

@Injectable({
  providedIn: 'root',
})
export class AzureLoggingService implements Logger {
  private appInsights: ApplicationInsights;
  private currentUser: AccountInfo;
  private userContextSet: boolean;

  constructor(private settingsService: SettingsService) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true, //  option to log all route changes
      },
    });
    this.appInsights.loadAppInsights();
    this.userContextSet = false;
    this.settingsService
      .getUser$()
      .subscribe((user) => (this.currentUser = user));
  }

  logTrace(message: string): void {
    if (getLogLevel(environment.logLevel.azure) <= LoggerLevel.Trace)
      this.logToAzure(message);
  }

  logDebug(message: string): void {
    if (getLogLevel(environment.logLevel.azure) <= LoggerLevel.Debug)
      this.logToAzure(message);
  }

  logInfo(message: string): void {
    if (getLogLevel(environment.logLevel.azure) <= LoggerLevel.Info)
      this.logToAzure(message);
  }

  logSuccess(message: string): void {
    if (getLogLevel(environment.logLevel.azure) <= LoggerLevel.Info)
      this.logToAzure(message);
  }

  logWarn(message: string): void {
    if (getLogLevel(environment.logLevel.azure) <= LoggerLevel.Warning)
      this.logToAzure(message);
  }

  logError(message: string): void {
    if (getLogLevel(environment.logLevel.azure) <= LoggerLevel.Error)
      this.logException(this.getErrorObject(message), 3);
  }

  logFailed(message: string): void {
    if (getLogLevel(environment.logLevel.azure) <= LoggerLevel.Error)
      this.logException(this.getErrorObject(message), 3);
  }

  logCritical(message: string): void {
    if (getLogLevel(environment.logLevel.azure) <= LoggerLevel.Critical)
      this.logException(this.getErrorObject(message), 4);
  }

  private trySetUser() {
    if (this.currentUser && !this.userContextSet) {
      this.appInsights.setAuthenticatedUserContext(
        this.currentUser.localAccountId,
        this.currentUser.name,
        true
      );

      const telemetryInitializer = (envelope) => {
        envelope.tags['ai.cloud.role'] = this.currentUser?.name ?? '';
        envelope.tags['ai.cloud.roleInstance'] = 'Genet.PDM.Frontend';
        envelope.data.application = 'Genet.PDM.Frontend';
        envelope.data.userName = this.currentUser?.name ?? '';
      };

      this.appInsights.addTelemetryInitializer(telemetryInitializer);
      this.userContextSet = true;
    }
  }

  private flushIfNeeded() {
    if (environment.appInsights.sendImmediately) {
      this.appInsights.flush();
    }
  }

  logException(exception: Error, severityLevel?: number) {
    if (environment.production) return;

    this.trySetUser();
    this.appInsights.trackException({
      exception: exception,
      severityLevel: severityLevel,
    });
    this.flushIfNeeded();
  }

  logToAzure(message: string, properties?: { [key: string]: any }) {
    if (environment.production) return;

    this.trySetUser();
    this.appInsights.trackTrace({ message: message }, properties);
    this.flushIfNeeded();
  }

  private getErrorObject(message: string | Error, caller?: string) {
    if (typeof message === 'string') {
      const _message = this.getMessage(message, caller);
      return new Error(_message);
    } else {
      return <Error>message;
    }
  }

  private getMessage(message: string, caller?: string) {
    if (caller) return `[${caller}]: ${message}`;
    return `${message}`;
  }
}
