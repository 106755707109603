import { Component, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { DialogService } from 'primeng/dynamicdialog';
import { LabeledProject } from '@genetpdm/model';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import {
  AuthService,
  ProjectService,
  SettingsService,
} from '../../@core/services';

@Component({
  selector: 'home-view',
  template: ` <div class="highlight">
    <div class="card">
      <div class="row">
        <div class="col-md-8">
          <div class="intro">
            <h2>Welcome to GenetPDM</h2>
            <div *ngIf="loggedIn && !noProjectSelected">
              <p>Please wait, while the Backend is booting up...</p>
              <p-progressSpinner></p-progressSpinner>
              <p>
                If you cannot access the PDM after 2 Minutes. Try refreshing the
                page.
              </p>
              <p>
                If it takes longer than 5 Minutes:
                <a href="mailto:genetpdm@arrk-engineering.com"
                  >Contact an Administrator</a
                >.
              </p>
            </div>
            <div *ngIf="!loggedIn">
              <p>
                If you are not logged in yet, please continue by pressing the
                Login-Button below.
              </p>
              <p>Make sure you have Popups enabled to be able to login.</p>
              <p-button
                label="Login"
                (onClick)="handleLogin()"
                icon="fas fa-upload"
                iconPos="left"
              ></p-button>
            </div>
            <div *ngIf="noProjectSelected">
              <p>You have not yet chosen a Project.</p>
              <p>Please choose a project from the dropdown menu below.</p>
              <p>
                If you don't see a project below, request permissions on the
                work-Folder of the desired project.
                <br />
              </p>
              <p-dropdown
                [options]="projects"
                [(ngModel)]="selectedProject"
                [ngModelOptions]="{ standalone: true }"
                placeholder="Select a Project"
                optionLabel="label"
                [showTransitionOptions]="'200ms'"
                [hideTransitionOptions]="'200ms'"
                (onChange)="onProjectChange($event)"
              ></p-dropdown>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="d-none d-md-block mockup">
            <img class="device" src="favicon.ico" />
            <div class="screen"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="troubleshooting">
        <h2>Troubleshooting</h2>
        <p>If you have trouble logging in try the following solutions:</p>
        <ul>
          <li>
            If it is the first time using the PDM, ask an administrator to add
            you to the allowed users group.
          </li>
          <li>
            If no Login Popup appears on Chrome do the following:
            <ul>
              <li>
                Go to Settings -> Privacy and Security -> Site settings ->
                portal2.genetpdm.com
              </li>
              <li>Set Pop-ups and redirects: Allow</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>`,
  styleUrls: ['./home.component.scss'],
  providers: [DialogService],
})
export class HomeComponent implements OnInit {
  loggedIn: boolean;

  noProjectSelected: boolean;

  selectedProject: LabeledProject;
  projects: LabeledProject[];

  constructor(
    private authService: AuthService,
    private settingsService: SettingsService,
    private projectService: ProjectService,
    private router: Router,
  ) {}

  handleLogin() {
    this.authService.login();
  }

  handleLoggedInEvent(isLoggedIn: boolean) {
    this.loggedIn = isLoggedIn;
    if (isLoggedIn) {
      this.settingsService.getSelectedProjectId$().subscribe((p) => {
        if (p) {
          this.router.navigate(['/design']);
        } else {
          this.noProjectSelected = true;
        }
      });
    }
  }

  ngOnInit(): void {
    this.authService.loggedIn$.subscribe((loggedIn) =>
      this.handleLoggedInEvent(loggedIn),
    );

    this.projectService
      .getAccessibleProjects$()
      .pipe(
        tap((r) => {
          this.projects = r.map((p) => ({
            ...p,
            label: `${p.project_number} - ${p.name}`,
          }));
        }),
      )
      .subscribe();
  }

  onProjectChange(event) {
    this.settingsService
      .setCurrentProject$(this.selectedProject.id)
      .subscribe();
  }
}

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    FormsModule,
    ProgressSpinnerModule,
    DropdownModule,
    ButtonModule,
  ],
  exports: [HomeComponent],
})
export class HomeModule {}
