import { Injectable } from '@angular/core';
import {
  CadAdaptFilesCompletedMessage,
  CadAdaptFilesRequiredMessage,
  CadGenerateFilesCompletedMessage,
  CadGenerateFilesRequiredMessage,
  CadGetInstancePositionsCompletedMessage,
  CadGetInstancePositionsRequiredMessage,
  CadImportStructureRequiredMessage,
  CadImportStructureStartedMessage,
  CadReadStructureCompletedMessage,
  CadReadStructureRequiredMessage,
  CadStructureMessage,
  CatiaAPIClient,
  OpenCadStructureCompletedMessage,
  OpenCadStructureRequiredMessage,
  OpenLastSessionCompletedMessage,
  OpenLastSessionRequiredMessage,
  PingMessage,
  PongMessage,
} from '@genetsystems/genet-pdm-messaging-model';
import { ServiceError } from '@genetsystems/genet-pdm-messaging-model/lib/api-cad-service_pb_service';
import { Observable, of, timer } from 'rxjs';
import moment from 'moment';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  timeout,
  tap,
} from 'rxjs/operators';
import { BrowserHeaders } from 'browser-headers';
import { LoadingAnimationService } from '../loading-animation-service/loading-animation.service';
import { Guid } from 'guid-typescript';
import { AuthService, getBrowserHeaders$ } from '../auth-service';
import { LoggingService } from '../logging-service/logging.service';

@Injectable({
  providedIn: 'root',
})
export class CatiaService {
  constructor(
    private catiaAPI: CatiaAPIClient,
    private authService: AuthService,
    private loadingAnimationService: LoadingAnimationService,
    private logger: LoggingService,
  ) {}

  /**
   * Starts the Import Structure process on the connected grpc service.
   * @param projectId
   * @param targetRoutePrefix
   * @param paths
   */
  public startImportStructure$(
    uploadId: string,
    projectId: string,
    variantId: string,
    targetRoutePrefix: string,
    paths: string[],
  ): Observable<CadImportStructureStartedMessage.OkResult> {
    const action$ = (headerMap: BrowserHeaders) =>
      new Observable<CadImportStructureStartedMessage.OkResult>((observer) => {
        try {
          if (projectId == Guid.EMPTY)
            throw new Error(
              'Could not fetch ProjectId for importing structure!',
            );

          const request = new CadImportStructureRequiredMessage();
          request.setUploadid(uploadId);
          request.setId(Guid.create().toString());
          request.setProjectid(projectId);
          request.setVariantid(variantId);
          request.setTargetrouteprefix(targetRoutePrefix);
          request.setUncfilepathsList(paths);

          this.catiaAPI.startImportStructure(
            request,
            headerMap,
            (error, response) => {
              this.loadingAnimationService.setShow(false);
              if (error) {
                this.error(error, observer);
                return;
              }
              if (
                response.getResultCase() ==
                CadImportStructureStartedMessage.ResultCase.ERROR
              ) {
                observer.error(response.getError().getMessage());
                return;
              }
              observer.next(response.getOk());
              observer.complete();
            },
          );
        } catch (error) {
          this.logger.logError(error.message);
          observer.error(error.message);
        }
      });

    return this.execute(action$);
  }

  /**
   * Starts the Read Structure process on the connected grpc service and returns an Observable of the Result.
   * @param projectId
   * @param targetRoutePrefix
   * @param paths
   */
  public readStructure$(
    projectId: string,
    targetRoutePrefix: string,
    paths: string[],
  ): Observable<CadReadStructureCompletedMessage.OkResult> {
    const action$ = (headerMap: BrowserHeaders) =>
      new Observable<CadReadStructureCompletedMessage.OkResult>((observer) => {
        try {
          const request = new CadReadStructureRequiredMessage();
          request.setId(Guid.create().toString());
          request.setProjectid(projectId);
          request.setTargetrouteprefix(targetRoutePrefix);
          request.setUncfilepathsList(paths);

          this.catiaAPI.readStructure(request, headerMap, (error, response) => {
            this.loadingAnimationService.setShow(false);
            if (error) {
              this.error(error, observer);
              return;
            }
            if (
              response.getResultCase() ==
              CadReadStructureCompletedMessage.ResultCase.ERROR
            ) {
              observer.error(response.getError().getMessage());
              return;
            }
            observer.next(response.getOk());
            observer.complete();
          });
        } catch (error) {
          this.logger.logError(error.message);
          observer.error(error.message);
        }
      });

    return this.execute(action$);
  }

  /**
   * Starts the Open Structure process on the connected grpc service and returns an Observable of the Result.
   * @param projectId
   * @param uncRootDirectory
   * @param cadStructure
   */
  public openStructure$(
    projectId: string,
    uncRootDirectory: string,
    cadStructure: CadStructureMessage,
  ): Observable<OpenCadStructureCompletedMessage.OkResult> {
    const action$ = (headerMap: BrowserHeaders) =>
      new Observable<OpenCadStructureCompletedMessage.OkResult>((observer) => {
        try {
          const request = new OpenCadStructureRequiredMessage();
          request.setProjectid(projectId);
          request.setUncrootdirectory(uncRootDirectory);
          request.setStructure(cadStructure);

          this.catiaAPI.openStructure(request, headerMap, (error, response) => {
            this.loadingAnimationService.setShow(false);
            if (error) {
              this.error(error, observer);
              return;
            }
            if (
              response.getResultCase() ==
              OpenCadStructureCompletedMessage.ResultCase.ERROR
            ) {
              observer.error(response.getError().getMessage());
              return;
            }
            observer.next(response.getOk());
            observer.complete();
          });
        } catch (error) {
          this.logger.logError(error.message);
          observer.error(error);
        }
      });

    return this.execute(action$);
  }

  /**
   * Starts the Generate Structure process on the connected grpc service and returns an Observable of the Result.
   * @param projectId
   * @param uncRootDirectory
   * @param cadStructure
   */
  public generateStructure$(
    projectId: string,
    uncRootDirectory: string,
    uncSessionRootDirectory: string,
    sessionName: string,
    sessionId: string,
    cadStructure: CadStructureMessage,
    useComponents: boolean,
    useFastGeneration: boolean,
  ): Observable<CadGenerateFilesCompletedMessage.OkResult> {
    const action$ = (headerMap: BrowserHeaders) =>
      new Observable<CadGenerateFilesCompletedMessage.OkResult>((observer) => {
        try {
          const request = new CadGenerateFilesRequiredMessage();
          request.setProjectid(projectId);
          request.setUncrootdirectory(uncRootDirectory);
          request.setSessionname(sessionName);
          request.setSessionid(sessionId);
          request.setUncsessionrootdirectory(uncSessionRootDirectory);
          request.setStructure(cadStructure);
          request.setUsefastgeneration(useFastGeneration);

          if (useComponents) request.setStrategy(0);
          else request.setStrategy(1);

          this.catiaAPI.generateStructure(
            request,
            headerMap,
            (error, response) => {
              this.loadingAnimationService.setShow(false);
              if (error) {
                this.error(error, observer);
                return;
              }
              if (
                response.getResultCase() ==
                CadGenerateFilesCompletedMessage.ResultCase.ERROR
              ) {
                observer.error(response.getError().getMessage());
                return;
              }
              observer.next(response.getOk());
              observer.complete();
            },
          );
        } catch (error) {
          this.logger.logError(error.message);
          observer.error(error);
        }
      });

    return this.execute(action$);
  }

  /**
   * Starts the Adapt Structure process on the connected grpc service and returns an Observable of the Result.
   * @param projectId
   * @param uncRootDirectory
   * @param cadStructure
   */
  public adaptStructure$(
    projectId: string,
    uncRootDirectory: string,
    uncSessionRootDirectory: string,
    sessionName: string,
    sessionId: string,
    cadStructure: CadStructureMessage,
    useComponents: boolean,
  ): Observable<CadAdaptFilesCompletedMessage.OkResult> {
    const action$ = (headerMap: BrowserHeaders) =>
      new Observable<CadAdaptFilesCompletedMessage.OkResult>((observer) => {
        try {
          const request = new CadAdaptFilesRequiredMessage();
          request.setProjectid(projectId);
          request.setUncrootdirectory(uncRootDirectory);
          request.setSessionname(sessionName);
          request.setSessionid(sessionId);
          request.setUncsessionrootdirectory(uncSessionRootDirectory);
          request.setStructure(cadStructure);

          if (useComponents) request.setStrategy(0);
          else request.setStrategy(1);

          this.catiaAPI.adaptStructure(
            request,
            headerMap,
            (error, response) => {
              this.loadingAnimationService.setShow(false);
              if (error) {
                this.error(error, observer);
                return;
              }
              if (
                response.getResultCase() ==
                CadAdaptFilesCompletedMessage.ResultCase.ERROR
              ) {
                observer.error(response.getError().getMessage());
                return;
              }
              observer.next(response.getOk());
              observer.complete();
            },
          );
        } catch (error) {
          this.logger.logError(error.message);
          observer.error(error);
        }
      });

    return this.execute(action$);
  }

  /**
   * Opens the session with the sessionId on the clients location if it exists.
   * @param projectId
   * @param uncRootDirectory
   * @param sessionName
   */
  public openLastSession$(
    projectId: string,
    uncRootDirectory: string,
    sessionName: string,
    userName: string,
    isPublicSession: boolean,
  ): Observable<OpenLastSessionCompletedMessage.OkResult> {
    const action$ = (headerMap: BrowserHeaders) =>
      new Observable<OpenLastSessionCompletedMessage.OkResult>((observer) => {
        try {
          const request = new OpenLastSessionRequiredMessage();
          request.setProjectid(projectId);
          request.setUncrootdirectory(uncRootDirectory);
          request.setSessionid(sessionName);
          request.setUsername(userName);
          request.setAccesstype(isPublicSession ? 0 : 1);

          this.catiaAPI.openLastSession(
            request,
            headerMap,
            (error, response) => {
              this.loadingAnimationService.setShow(false);
              if (error) {
                this.error(error, observer);
                return;
              }
              if (
                response.getResultCase() ==
                OpenLastSessionCompletedMessage.ResultCase.ERROR
              ) {
                observer.error(response.getError().getMessage());
                return;
              }
              observer.next(response.getOk());
              observer.complete();
            },
          );
        } catch (error) {
          this.logger.logError(error.message);
          observer.error(error);
        }
      });

    return this.execute(action$);
  }

  /**
   * Starts the Get Positions process on the connected grpc service and returns an Observable of the Result.
   * @param projectId
   * @param cadStructure
   */
  public getInstancePositions$(
    projectId: string,
    cadStructure: CadStructureMessage,
  ): Observable<CadGetInstancePositionsCompletedMessage> {
    const action$ = (headerMap: BrowserHeaders) =>
      new Observable<CadGetInstancePositionsCompletedMessage>((observer) => {
        try {
          const request = new CadGetInstancePositionsRequiredMessage();
          request.setProjectid(projectId);
          request.setStructure(cadStructure);

          this.catiaAPI.getPositions(request, headerMap, (error, response) => {
            this.loadingAnimationService.setShow(false);
            if (error) {
              this.error(error, observer);
              return;
            }
            if (
              response.getResultCase() ==
              CadGetInstancePositionsCompletedMessage.ResultCase.ERROR
            ) {
              observer.error(response.getError().getMessage());
              return;
            }
            observer.next(response);
            observer.complete();
          });
        } catch (error) {
          this.logger.logError(error.message);
          observer.error(error);
        }
      });

    return this.execute(action$);
  }

  public ping$(
    frequencySeconds: number,
    timeoutSeconds: number,
  ): Observable<PingResponse> {
    const ping$ = new Observable<PongMessage>((observer) => {
      try {
        const request = new PingMessage();
        request.setPing(moment(Date.now()).format('YYYYMMDDHHmmss'));

        this.catiaAPI.ping(request, (error, response) => {
          if (error) {
            this.error(error, observer);
            return;
          }
          observer.next(response);
          observer.complete();
        });
      } catch (error) {
        this.loadingAnimationService.setShow(false);
        observer.error(error);
      }
    });

    const timer$ = timer(0, frequencySeconds * 1000);

    return timer$.pipe(
      concatMap((_) =>
        ping$.pipe(
          timeout(timeoutSeconds * 1000),
          map((__) => ({ success: true })),
          catchError((__) => of({ success: false })),
        ),
      ),
    );
  }

  private execute<T>(action$: (b: BrowserHeaders) => Observable<T>) {
    this.loadingAnimationService.setShow(true);
    this.loadingAnimationService.setIndeterminate(true);

    return getBrowserHeaders$(this.authService).pipe(
      mergeMap((headerMap) => action$(headerMap)),
      tap((_) => this.loadingAnimationService.setShow(false)),
    );
  }

  private error(error: ServiceError, observer: any) {
    this.loadingAnimationService.setShow(false);

    if (error.message != '') {
      observer.error(error.message);
    } else {
      observer.error('Internal Service Error from your local GenetPDM API.');
    }
  }
}

export interface PingResponse {
  success: boolean;
}
