import { Variant } from '@genetpdm/model';
import { createAction, props } from '@ngrx/store';

export enum VariantActionTypes {
  LoadRootVariantByProjectId = '[Component] Load Root Variant by Project Id',
  LoadVariantsById = '[Component] Load Root Variant by Project Id',
  LoadVariantsSucceeded = '[Backend] Load Variants Succeeded',
  LoadVariantsFailed = '[Backend] Load Variants Failed',
}

//#region [Load]

/**
 * Request to load all variants with specified ids from backend.
 */
export const LoadVariantsById = createAction(
  VariantActionTypes.LoadVariantsById,
  props<{ ids: uuid[] }>()
);

/**
 * Request to load all variants with specified ids from backend.
 */
export const LoadRootVariantByProjectId = createAction(
  VariantActionTypes.LoadRootVariantByProjectId,
  props<{ projectId: uuid }>()
);

/**
 * Backend returns variants.
 */
export const LoadVariantsSuceeded = createAction(
  VariantActionTypes.LoadVariantsSucceeded,
  props<{ variants: Variant[] }>()
);

/**
 * Backend returns error.
 * Failed to load variants with specified ids.
 *
 * @remarks
 * Use the ids to reset the variants in the state.
 */
export const LoadVariantsFailed = createAction(
  VariantActionTypes.LoadVariantsFailed,
  props<{ error: Error }>()
);

//#endregion
