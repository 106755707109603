import { Component, OnInit } from '@angular/core';
import { Guid } from 'guid-typescript';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ComponentMetadataService } from '../../../@core/services';
import { ActionsService } from '../../../@core/services/actions-service/actions.service';
import { ComponentInstanceVersion } from '@genetpdm/model';

@Component({
  selector: 'rename-dialog',
  template: `
    <div class="card">
      <div class="card-header">
        <h3>
          Rename and Create new Version for: {{ component.component_name }} -
          {{ component.component.id }}
        </h3>
        <p>
          Current Version: {{ currentVersion }} => New Version:
          {{ nextVersion }}
        </p>
      </div>
      <div class="card-body">
        <form>
          <div class="form-group" style="margin-top: 15px;">
            <span class="p-float-label">
              <input
                type="text"
                pInputText
                id="PartNumber"
                [(ngModel)]="newPartNumber"
                [ngModelOptions]="{ standalone: true }"
                style="width: 100%"
              />
              <label for="PartNumber">New Partnumber</label>
            </span>
          </div>
          <div class="form-group" style="margin-top: 25px;">
            <span class="p-float-label">
              <input
                type="text"
                pInputText
                id="Filename"
                [(ngModel)]="newFileName"
                [ngModelOptions]="{ standalone: true }"
                style="width: 100%"
              />
              <label for="Filename">New Filename</label>
            </span>
          </div>
          <hr />
          <div class="form-group">
            <p>
              Are you sure, you want to rename the component? Renaming will also
              create a new Version?
            </p>
            <p-checkbox
              [(ngModel)]="enableAction"
              [ngModelOptions]="{ standalone: true }"
              [binary]="true"
              inputId="update"
            ></p-checkbox>
            <label for="update" style="margin-left: 5px; margin-bottom: 0px"
              >Rename Component</label
            >
          </div>
          <hr />
          <div class="form-group">
            <p-button
              label="Rename Component"
              (onClick)="handleSubmit($event)"
              icon="fas fa-upload"
              iconPos="left"
              *ngIf="enableAction"
            ></p-button>
            <p-button
              label="Cancel"
              (onClick)="handleCancel($event)"
              icon="fas fa-times"
              iconPos="left"
              [style]="{ 'margin-left': '10px' }"
            ></p-button>
          </div>
        </form>
      </div>
    </div>
  `,
})
export class RenameDialogComponent implements OnInit {
  currentVersion = 0;

  component: ComponentInstanceVersion;

  enableAction = false;

  newFileName: string;
  newPartNumber: string;

  public get nextVersion(): number {
    return this.currentVersion + 1;
  }

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private componentService: ComponentMetadataService,
    private actionsService: ActionsService
  ) {}

  ngOnInit(): void {
    this.component = this.config.data.component;
    this.newFileName = this.getFileName(this.component.route);

    this.componentService
      .getLatestVersionIndex$(this.component.component.id)
      .subscribe((r) => (this.currentVersion = r));
  }

  // TODO Enforce naming rules
  async handleSubmit(event) {
    if (!this.enableAction) this.ref.close(null);

    await this.actionsService.renameComponent$(
      this.component.component.id,
      this.newPartNumber,
      this.newFileName
    );

    this.ref.close(this.newFileName);
  }

  handleCancel(event) {
    this.ref.close(null);
  }

  private getFileName(route: string) {
    return route.split('\\').pop().split('/').pop();
  }
}
