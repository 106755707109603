import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppLayoutModule } from './layout/app.layout.module';
import { DialogModule } from 'primeng/dialog';
import { AuthModule } from './@core/services/auth-service/auth.module';
import { MessagesModule } from 'primeng/messages';
import { ConfirmationService, MessageService, TreeDragDropService } from 'primeng/api';
import { CoreModule } from './@core/core.module';
import { HomeModule } from './modules/home/home.component';
import { ExplorerStateReducer } from './@core/state/@app-state/app.state';
import { ExplorerStoreEffects, UserInterfaceEffects } from './@core/state';
import { GraphQLModule } from './@core/services';
import { SessionStoreEffects } from './@core/state/session/effects';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProjectStoreEffects } from './@core/state/project/effects';
import { ToastModule } from 'primeng/toast';
import { SettingsStoreEffects } from './@core/state/settings/effects';
import { LoadUploadsEffects } from './@core/state/upload/upload.command';

//Components that can be loaded dynamically
const ENTRY_COMPONENTS = [];

//All Components inside of the Module
const COMPONENTS = [...ENTRY_COMPONENTS, AppComponent];

//All Modules to be used by the Components inside the Module
const MODULES = [
  BrowserModule,
  RouterModule.forRoot(appRoutes),
  CoreModule.forRoot(),
  AuthModule.forRoot(),
  StoreModule.forRoot({ explorer: ExplorerStateReducer }),
  !environment.production
    ? StoreDevtoolsModule.instrument({
        maxAge: 25, // Retains last 25 states
        logOnly: false, // Restrict extension to log-only mode
        autoPause: true, // Pauses recording actions and state changes when the extension window is not open
        trace: true, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
        traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      })
    : [],
  EffectsModule.forRoot([
    UserInterfaceEffects,
    ExplorerStoreEffects,
    SessionStoreEffects,
    LoadUploadsEffects,
    ProjectStoreEffects,
    SettingsStoreEffects,
  ]),
  GraphQLModule,
  HttpClientModule,
  AppLayoutModule,
  DialogModule,
  ConfirmDialogModule,
  MessagesModule,
  HomeModule,
  ToastModule,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...MODULES],
  providers: [MessageService, DialogService, ConfirmationService, TreeDragDropService],
  bootstrap: [AppComponent],
})
export class AppModule {}
