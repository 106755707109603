import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AddFolderToFolderMutationGQL,
  FilesOfComponentQueryGQL,
  GetFilesByIdsQueryGQL,
  RelationsFileOfFileInsertInput,
  FileInsertInput,
  RelationsFileOfComponentInsertInput,
  EnumsFileTypeEnum,
  AddFolderToComponentMutationGQL,
} from '@genetpdm/model/graphql';
import { map, take } from 'rxjs/operators';
import { BlobService } from '../blob-service/blob.service';
import { LoggingService } from '../logging-service/logging.service';
import { Guid } from 'guid-typescript';
import { SettingsService } from '../settings-service/settings.service';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  private currentUserId: string;

  constructor(
    private filesOfComponentQuery: FilesOfComponentQueryGQL,
    private getFilesByIdsQuery: GetFilesByIdsQueryGQL,
    private addFolderToFolderMutation: AddFolderToFolderMutationGQL,
    private addFolderToComponentMutation: AddFolderToComponentMutationGQL,
    private blobService: BlobService,
    private settingsService: SettingsService,
    private logger: LoggingService
  ) {
    this.settingsService
      .getUser$()
      .subscribe((user) => (this.currentUserId = user.localAccountId));
  }

  public getFilesOfComponent(componentId: uuid): Observable<File[]> {
    return this.filesOfComponentQuery
      .watch({ component_id: componentId })
      .valueChanges.pipe(
        this.logger.catchAndLog(),
        map((r) =>
          r.data.cad_component_by_pk.files.map((f) => ({
            ...f.file,
            worker: f.file.worker.name,
            child_files: f.file.child_files.map((c) => c.child_id),
            tags: f.file.tags.map((t) => t.tag),
          }))
        ),
        take(1),
      );
  }

  public getFilesByIds(fileIds: uuid[]): Observable<File[]> {
    return this.getFilesByIdsQuery
      .watch({ file_ids: fileIds.map((id) => id.toString()) })
      .valueChanges.pipe(
        this.logger.catchAndLog(),
        map((r) =>
          r.data.file.map((f) => ({
            ...f,
            worker: f.worker.name,
            child_files: f.child_files.map((c) => c.child_id),
            tags: f.tags.map((t) => t.tag),
          }))
        ),
        take(1)
      );
  }

  public downloadFile(projectId: uuid, route: string) {
    this.blobService.downloadToFileSystem(route, projectId);
  }

  public addFolderToComponent(
    projectId: uuid,
    componentId: uuid,
    name: string
  ) {
    const id = Guid.create().toString();

    const input = <FileInsertInput>{
      id: id.toString(),
      name: name,
      project_id: projectId.toString(),
      type: EnumsFileTypeEnum.Folder,
      worker_id: this.currentUserId,
    };

    const relation = <RelationsFileOfComponentInsertInput>{
      file_id: id.toString(),
      component_id: componentId.toString(),
    };

    this.addFolderToComponentMutation
      .mutate({ file: input, relation: relation })
      .pipe(this.logger.catchAndLog())
      .subscribe();
  }

  public addFolderToFolder(projectId: uuid, folderId: uuid, name: string) {
    const id = Guid.create().toString();

    const input = <FileInsertInput>{
      id: id.toString(),
      name: name,
      project_id: projectId.toString(),
      type: EnumsFileTypeEnum.Folder,
      worker_id: this.currentUserId,
    };

    const relation = <RelationsFileOfFileInsertInput>{
      parent_id: folderId.toString(),
      child_id: id.toString(),
    };

    this.addFolderToFolderMutation
      .mutate({ file: input, relation: relation })
      .pipe(this.logger.catchAndLog())
      .subscribe();
  }
}

export interface File {
  id: uuid;
  name: string;
  route: string;
  extension: string;
  updated_at: Date;
  type: EnumsFileTypeEnum;
  project_id: uuid;
  worker: string;
  child_files: uuid[];
  tags: string[];
}
