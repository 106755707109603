import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BlobService } from '../blob-service/blob.service';
import { catchError, map, take } from 'rxjs/operators';
import { LoggingService } from '../logging-service/logging.service';

@Injectable({
  providedIn: 'root',
})
export class StlService {
  constructor(
    private blobService: BlobService,
    private logger: LoggingService
  ) {}

  getStlDataUrl$(
    stlRoute: string | undefined,
    projectId: uuid
  ): Observable<string> {
    return new Observable<string>((observer) => {
      const stlBlobObservable = this.blobService.downloadBlob$(
        stlRoute,
        projectId
      );

      stlBlobObservable.subscribe({
        next: (stlBlob: Blob) => {
          const stlUrl = URL.createObjectURL(stlBlob);
          observer.next(stlUrl);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        },
      });
    });
  }
}
