<div *ngIf="component as comp" class="scrollable">
  <div class="form-row">
    <div class="col">
      <div class="d-xl-flex justify-content-xl-end">
        <em
          *ngIf="comp.collision_detected"
          data-toggle="tooltip"
          data-placement="bottom"
          class="fas fa-car-crash"
          style="font-size: 25px; margin: 7px"
          title="Collision detected"
        ></em
        ><em
          *ngIf="comp.tasks_attached"
          class="fas fa-project-diagram"
          data-toggle="tooltip"
          data-placement="bottom"
          style="font-size: 25px; margin: 7px"
          title="Task(s) attached"
        ></em
        ><em
          *ngIf="comp.files_attached"
          data-toggle="tooltip"
          data-placement="bottom"
          class="fas fa-link"
          style="font-size: 25px; margin: 7px"
          title="File(s) attached"
        ></em
        ><em
          *ngIf="comp.has_error"
          data-toggle="tooltip"
          data-placement="bottom"
          class="fa fa-warning"
          style="font-size: 25px; margin: 7px"
          title="Warning"
        ></em
        ><em
          *ngIf="comp.is_positioned"
          data-toggle="tooltip"
          data-placement="bottom"
          class="fas fa-map-pin"
          style="font-size: 25px; margin: 7px"
          title="Positioned"
        ></em
        ><em
          *ngIf="!comp.is_root_variant"
          data-toggle="tooltip"
          data-placement="bottom"
          class="fa fa-sitemap"
          style="font-size: 25px; margin: 7px"
          title="Variant"
        ></em
        ><em
          *ngIf="comp.checked_out"
          data-toggle="tooltip"
          data-placement="bottom"
          class="fa fa-download"
          style="font-size: 25px; margin: 7px"
          title="Checked Out"
        ></em>
        <em
          *ngIf="comp.synchronizing"
          data-toggle="tooltip"
          data-placement="bottom"
          class="fa fa-refresh"
          style="font-size: 25px; margin: 7px"
          title="Synchronizing"
        ></em
        ><em
          *ngIf="comp.frozen"
          data-toggle="tooltip"
          data-placement="bottom"
          class="fa fa-snowflake-o"
          style="font-size: 25px; margin: 7px"
          title="Frozen"
        ></em>
      </div>
    </div>
  </div>
  <form>
    <div class="container" style="padding-top: 20px">
      <div class="form-row">
        <div class="col-3">
          <img
            class="img-fluid border rounded"
            [src]="getImage$(comp) | async"
            style="margin: 10px; width: 80%"
            alt
          />
        </div>
        <div class="col">
          <div class="form-row">
            <div class="col-3">
              <div class="form-group">
                <label>Instance ID</label
                ><input
                  nbInput
                  type="text"
                  value="{{ comp.instance_id }}"
                  fieldSize="small"
                  class="form-control"
                  readonly
                  disabled
                  title="ID of the instance"
                />
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label>Component ID</label
                ><input
                  nbInput
                  type="text"
                  value="{{ comp.component_id }}"
                  fieldSize="small"
                  class="form-control"
                  readonly
                  disabled
                  title="ID of the component"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Component Name</label
                ><input
                  nbInput
                  type="text"
                  value="{{ comp.component_name }}"
                  fieldSize="small"
                  class="form-control"
                  required
                  title="Display name for the Component in the PDM"
                />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <label>Instance Name</label
                ><input
                  nbInput
                  type="text"
                  value="{{ comp.instance_name }}"
                  fieldSize="small"
                  class="form-control"
                  required
                  pattern="[0-9]{3}"
                  title="Instance Name must match the projects naming schema"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Part Number</label
                ><input
                  nbInput
                  type="text"
                  value="{{ comp.part_number }}"
                  fieldSize="small"
                  class="form-control"
                  required
                  pattern="[0-9]{3}"
                  title="Part Number must match the projects naming schema"
                />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <label>Last Edited</label
                ><input
                  nbInput
                  type="date"
                  [value]="comp.last_edit | date"
                  fieldSize="small"
                  class="form-control"
                  readonly
                  disabled
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Worker</label
                ><input
                  nbInput
                  type="text"
                  value="{{ comp.worker }}"
                  fieldSize="small"
                  class="form-control"
                  title="Name of the Worker, who checked in the part at last"
                  readonly
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-3"></div>
        <div class="col-3">
          <div class="form-group">
            <label>Variant</label
            ><input
              nbInput
              type="text"
              value="{{ comp.variant_name }}"
              fieldSize="small"
              class="form-control"
              title="Variant of the current component"
              readonly
              disabled
            />
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label>Version</label
            ><input
              nbInput
              type="number"
              value="{{ comp.version }}"
              fieldSize="small"
              class="form-control"
              title="Version of the current component"
              readonly
              disabled
            />
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label>Subversion</label
            ><input
              nbInput
              type="number"
              value="{{ comp.subversion }}"
              fieldSize="small"
              class="form-control"
              title="Subversion of the current component"
              readonly
              disabled
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label>Nomenclature</label
            ><input
              nbInput
              type="text"
              value="{{ comp.nomenclature }}"
              fieldSize="small"
              class="form-control"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Definition</label
            ><input
              nbInput
              type="text"
              value="{{ comp.definition }}"
              fieldSize="small"
              class="form-control"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Description</label
            ><input
              nbInput
              type="text"
              value="{{ comp.description }}"
              fieldSize="small"
              class="form-control"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="maturity">Maturity</label
            ><p-dropdown
              [options]="maturities"
              [ngModel]="comp.maturity"
              [ngModelOptions]="{ standalone: true }"
              scrollHeight="100px"
              optionLabel="name"
              inputId="maturity"
              [style]="{
                width: '100%',
                'max-width': '100%'
              }"
            ></p-dropdown>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label>Misc</label><textarea class="form-control"></textarea>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
