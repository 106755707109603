import { Component, OnInit } from '@angular/core';
import { Guid } from 'guid-typescript';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OperatorFunction } from 'rxjs';
import { ActionsService } from '../../../@core/services/actions-service/actions.service';
import { ObservableToastService } from '../../../@core/utils/observable-operators/observable-toast.service';
import {
  GetChecklistByPkQueryGQL,
  GetReleaseProcessQueryGQL,
} from '@genetpdm/model/graphql';
import { ComponentInstanceVersion } from '@genetpdm/model';

@Component({
  selector: 'checklist-edit-dialog',
  template: `
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <p-table
              [value]="checklistItems"
              [(selection)]="selectedChecklistItems"
              (onRowSelect)="onRowSelect($event)"
              (onRowUnselect)="onRowUnselect($event)"
              dataKey="Index"
              responsiveLayout="scroll"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Worker</th>
                  <th>Checked-Date</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item>
                <tr>
                  <td>
                    <p-tableCheckbox [value]="item"></p-tableCheckbox>
                  </td>
                  <td>{{ item.Title }}</td>
                  <td>{{ item.Description }}</td>
                  <td>{{ item.Worker }}</td>
                  <td>{{ item.CheckedDate | date : 'medium' }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <div>
            <p-button
              label="Close"
              icon="pi pi-check"
              (onClick)="close()"
            ></p-button>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class ChecklistEditDialogComponent implements OnInit {
  checklistId: uuid;
  checklistName = '';

  checklistItems: ChecklistItem[] = [];
  selectedChecklistItems: ChecklistItem[] = [];

  component: ComponentInstanceVersion;

  isReleaseChecklistType: boolean;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private actionsService: ActionsService,
    private observableToastService: ObservableToastService,
    private getReleaseProcess: GetReleaseProcessQueryGQL,
    private getChecklistbyPk: GetChecklistByPkQueryGQL
  ) {}

  ngOnInit(): void {
    this.component = this.config?.data?.component;
    this.isReleaseChecklistType = this.config?.data?.releaseChecklist;

    if (this.component.component.id == null || this.component.version == null) {
      throw new Error('No valid checklist data provided.');
    }

    if (this.isReleaseChecklistType) {
      this.getReleaseProcess
        .watch({
          component_id: this.component.component.id,
          component_version: this.component.version,
        })
        .valueChanges.pipe(this.catchAndToast())
        .subscribe((r) => {
          this.checklistId = r.data.release_process[0].id;
          this.checklistName = r.data.release_process[0].name;
          const items = JSON.parse(r.data.release_process[0].checklist);
          this.checklistItems = items;
          this.selectedChecklistItems = this.checklistItems.filter(
            (i) => i.Checked
          );
        });
    } else {
      throw new Error('Not yet implemented');
    }
  }

  async onRowUnselect(event) {
    await this.checkStep(event.data.Index);
  }

  async onRowSelect(event) {
    await this.checkStep(event.data.Index);
  }

  private async checkStep(stepIndex: number) {
    if (!this.isReleaseChecklistType) throw new Error('Not yet implemented!');

    await this.actionsService.checkReleaseProcessStep$(
      this.checklistId,
      stepIndex,
      false,
      false
    );
  }

  close() {
    this.ref.close();
  }

  private catchAndToast<T>(defaultValue?: T): OperatorFunction<T, T> {
    return this.observableToastService.catchAndToast(defaultValue);
  }
}

interface ChecklistItem {
  Title: string;
  Description: string;
  Worker: string;
  Checked: boolean;
  CheckedDate: Date;
  Index: number;
}
