<div class="card">
  <div class="card-header">
    <p>Choose, which positions, you want to add to the root structure.</p>
  </div>

  <div class="card-body">
    <p-table
      [value]="items"
      [(selection)]="selectedItems"
      dataKey="instance_id"
      responsiveLayout="scroll"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th>Name</th>
          <th>Position</th>
          <th>Instance ID</th>
          <th>Component ID</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td>
            <p-tableCheckbox [value]="product"></p-tableCheckbox>
          </td>
          <td>{{ product.instance_name }}</td>
          <td>{{ product.position }}</td>
          <td>{{ product.instance_id }}</td>
          <td>{{ product.component_id }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="card-footer">
    <form class="form-inline">
      <div>
        <button
          pButton
          type="button"
          label="Accept"
          (click)="handleClickAccept($event)"
          style="width: auto; margin-right: 21px"
        ></button>
        <button
          pButton
          type="button"
          label="Cancel"
          (click)="handleClickCancel($event)"
        ></button>
      </div>
    </form>
  </div>
</div>
