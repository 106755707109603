import { Route } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from '../environments/environment';
import { HomeComponent } from './modules/home/home.component';
import { RoleGuardService } from './@core/services';

export const appRoutes: Route[] = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: 'design',
        data: {
          breadcrumb: 'Design',
          requiredRole: environment.azureAd.roles.user,
        },
        canActivate: [MsalGuard, RoleGuardService],
        loadChildren: () =>
          import('./modules/design/design.module').then((m) => m.DesignModule),
      },
      {
        path: 'data-management',
        data: {
          breadcrumb: 'Data-Management',
          requiredRole: environment.azureAd.roles.user,
        },
        canActivate: [MsalGuard, RoleGuardService],
        loadChildren: () =>
          import('./modules/data-management/data-management.module').then(
            (m) => m.DataManagementModule
          ),
      },
      {
        path: 'project-management',
        data: {
          breadcrumb: 'Project Management',
          requiredRole: environment.azureAd.roles.user,
        },
        canActivate: [MsalGuard, RoleGuardService],
        loadChildren: () =>
          import('./modules/project-management/project-management.module').then(
            (m) => m.ProjectManagementModule
          ),
      },
      {
        path: 'faq',
        data: {
          breadcrumb: 'FAQs',
          requiredRole: environment.azureAd.roles.user,
        },
        canActivate: [MsalGuard, RoleGuardService],
        loadChildren: () =>
          import('./modules/faq/faq.module').then(
            (m) => m.FaqModule
          ),
      },
      {
        path: 'calendar',
        data: {
          breadcrumb: 'Calendar',
          requiredRole: environment.azureAd.roles.user,
        },
        canActivate: [MsalGuard, RoleGuardService],
        loadChildren: () =>
          import('./modules/calendar/calendar.module').then(
            (m) => m.CalendarModule
          ),
      },
      {
        path: 'activity',
        data: {
          breadcrumb: 'Activity',
          requiredRole: environment.azureAd.roles.user,
        },
        canActivate: [MsalGuard, RoleGuardService],
        loadChildren: () =>
          import('./modules/activity/activity.module').then(
            (m) => m.ActivityModule
          ),
      },
      {
        path: 'settings',
        data: {
          breadcrumb: 'Settings',
          requiredRole: environment.azureAd.roles.user,
        },
        canActivate: [MsalGuard, RoleGuardService],
        loadChildren: () =>
          import('./modules/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
      },
      {
        path: 'administration',
        loadChildren: () =>
          import('./modules/administration/administration.module').then(
            (m) => m.AdministrationModule
          ),
        canActivate: [MsalGuard, RoleGuardService],
        data: {
          requiredRoles: [
            environment.azureAd.roles.admin,
            environment.azureAd.roles.manager,
          ],
        },
      },
    ],
  },
  {
    path: 'notfound',
    loadChildren: () =>
      import('./modules/not-found/notfound.module').then(
        (m) => m.NotfoundModule
      ),
  },
  { path: '**', redirectTo: '/notfound' },
];
