import { Injectable } from '@angular/core';
import { Session } from '@genetpdm/model';
import {
  ClearSessionGQL,
  DeleteSessionComponentMutationGQL,
  DeleteSessionGQL,
  GetAccessibleSessionsByProjectIdGQL,
  GetSessionByIdGQL,
  InsertSessionComponentMutationGQL,
  InsertSessionGQL,
  SessionInsertInput,
  UpdateSessionGQL,
} from '@genetpdm/model/graphql';
import { Observable, map, take, tap, throwError } from 'rxjs';
import { handleGraphQlErrors } from '../utility/graphql-utilities';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(
    private getAccessibleSessionsByProjectId: GetAccessibleSessionsByProjectIdGQL,
    private getSessionById: GetSessionByIdGQL,
    private insertSession: InsertSessionGQL,
    private deleteSession: DeleteSessionGQL,
    private updateSession: UpdateSessionGQL,
    private clearSession: ClearSessionGQL,
    private addToSession: InsertSessionComponentMutationGQL,
    private removeFromSession: DeleteSessionComponentMutationGQL
  ) {}

  public getSessionsForProjectId$(projectId: uuid): Observable<Session[]> {
    try {
      return this.getAccessibleSessionsByProjectId
        .watch({ project_id: projectId })
        .valueChanges.pipe(
          handleGraphQlErrors(),
          map((result) => [...result.data.session]),
          take(1)
        );
    } catch (error) {
      return throwError(() => error);
    }
  }

  public getSessionById$(sessionId: uuid): Observable<Session> {
    try {
      return this.getSessionById.watch({ id: sessionId }).valueChanges.pipe(
        handleGraphQlErrors(),
        map((result) => result.data.session_by_pk),
        take(1)
      );
    } catch (error) {
      return throwError(() => error);
    }
  }

  public createSession$(session: Session) {
    try {
      const { components: _, ...sessionInput } = session;

      return this.insertSession
        .mutate({
          session: sessionInput as SessionInsertInput,
        })
        .pipe(handleGraphQlErrors(), take(1));
    } catch (error) {
      return throwError(() => error);
    }
  }

  public addToSession$(sessionId: uuid, component_id: uuid) {
    try {
      return this.addToSession
        .mutate({
          session_id: sessionId,
          component_id: component_id,
        })
        .pipe(handleGraphQlErrors(), take(1));
    } catch (error) {
      return throwError(() => error);
    }
  }

  public removeFromSession$(sessionId: uuid, component_id: uuid) {
    try {
      return this.removeFromSession
        .mutate({
          session_id: sessionId,
          component_id: component_id,
        })
        .pipe(handleGraphQlErrors(), take(1));
    } catch (error) {
      return throwError(() => error);
    }
  }

  public deleteSession$(sessionId: uuid) {
    try {
      return this.deleteSession
        .mutate({
          id: sessionId,
        })
        .pipe(handleGraphQlErrors(), take(1));
    } catch (error) {
      return throwError(() => error);
    }
  }

  public updateSession$(session: Session) {
    try {
      return this.updateSession
        .mutate({
          session_id: session.id,
          name: session.name,
          shared: session.shared,
          readonly: session.readonly,
        })
        .pipe(handleGraphQlErrors(), take(1));
    } catch (error) {
      return throwError(() => error);
    }
  }

  public clearSession$(session: Session) {
    return this.clearSession
      .mutate({ session_id: session.id })
      .pipe(handleGraphQlErrors(), take(1));
  }
}
