import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { selectIsLoading } from '../../state/@user-interface/user-interface.selectors';

@Injectable({
  providedIn: 'root',
})
export class LoadingAnimationService {
  private _show$ = new Subject<boolean>();
  public show$: Observable<boolean>;

  private _progress$ = new Subject<number>();
  public progress$: Observable<number>;

  private _indeterminate$ = new Subject<boolean>();
  public indeterminate$: Observable<boolean>;

  constructor(store: Store) {
    this.show$ = this._show$.asObservable().pipe(shareReplay(1));

    this.progress$ = this._progress$.asObservable().pipe(shareReplay(1));

    this.indeterminate$ = this._indeterminate$
      .asObservable()
      .pipe(shareReplay(1));

    this._indeterminate$.next(true);

    store
      .select(selectIsLoading)
      .subscribe((loading) => this._show$.next(loading));
  }

  //TODO: Logic to handle parallel requests, maybe with an ID per request

  public setProgress(progress: number) {
    if (progress >= 100) {
      this._progress$.next(100);
      return;
    }
    if (progress <= 0) {
      this._progress$.next(0);
      return;
    }
    this._progress$.next(progress);
  }

  public setIndeterminate(indeterminate: boolean) {
    this._indeterminate$.next(indeterminate);
  }

  public setShow(show: boolean) {
    this._show$.next(show);
  }
}
